/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, CloseCircleFilled, DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Pagination, Row, Segmented, Select, Space, Switch, Table, Tabs, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import ContactsListing from '../general/contacts_listing';
import { setClearFormFields } from '../../util/clear_empty';
import { URLQuery } from '../../util/url_queries';

export default function SteamShipLineForm(props:any) {
  interface steamShipLine {
    uuid: string|null,
    company_name: string|null,
    is_active?: 'y'|'n'|null,
    primary_contact_uuid?: string|null,
    clear?:string[]|undefined
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);
  const [selectedTab, setSelectedTab] = useState<'contacts'|'consignees'>('contacts');
  const [steamShipLine, setSteamShipLine] = useState<steamShipLine>({
    uuid: uuid||null,
    company_name: null
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    if(uuid) {
      getSteamShipLine();
    }
    
    setIsReady(true);
  }, [])

  const getSteamShipLine = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getSteamShipLine();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setSteamShipLine(res.data);
      form.setFieldsValue(res.data)
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async (customValues:any) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.primary_contact_uuid = customValues?.primary_contact_uuid||steamShipLine.primary_contact_uuid
    
    // handle cleared fields
    if(steamShipLine.uuid) {
      // check clearables
      steamShipLine.clear = setClearFormFields(steamShipLine)||undefined;
    }

    Object.assign(EV, steamShipLine)
    var res:any = null;

    if(steamShipLine.uuid) res = await EV.updateSteamShipLine();
    else res = await EV.createSteamShipLine();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!steamShipLine.uuid) {
        setSteamShipLine({
          ...steamShipLine,
          uuid: res.data.uuid
        });

        navigate('/steam-ship-lines/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const deleteSteamShipLine = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = steamShipLine?.uuid;
    
    var res:any = await EV.deleteSteamShipLine();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(steamShipLine.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteSteamShipLine();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(steamShipLine?.uuid) ? 'Manage Steam Ship Line' : 'New Steam Ship Line'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={steamShipLine?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid,
        company_name: steamShipLine.company_name,
        is_active: steamShipLine.is_active === 'y' ? true : false
      }}
      onFinish={processSubmit}
    >
      <Form.Item
        label={'Company Name'} 
        name={'company_name'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setSteamShipLine({
            ...steamShipLine,
            company_name: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Switch 
          checked={steamShipLine.is_active === 'y' ? true : false} 
          onChange={(e) => setSteamShipLine({
            ...steamShipLine,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>

    {(steamShipLine.uuid) && <>
      <Tabs
        defaultActiveKey={selectedTab||'contacts'}
        onChange={(e:any) => setSelectedTab(e)}
        items={[
          {
            key: 'contacts',
            label: 'Contacts',
            children: <ContactsListing 
            showView
            showLinking
            additionalColumns={[
              {
                key: 'primary',
                title: 'Primary',
                render: (_:any, record:any) => {
                  if (steamShipLine.primary_contact_uuid === record.uuid)
                    return <CheckOutlined style={{color: 'green'}} />
                  else {
                    return <Button 
                      type={'default'}
                      color={'red'}
                      size={'small'}
                      children={'Set Primary'}
                      onClick={() => {
                        setSteamShipLine({
                          ...steamShipLine,
                          primary_contact_uuid: record.uuid
                        });

                        processSubmit({
                          primary_contact_uuid: record.uuid
                        })
                      }}
                    />
                  }
                }
              }
            ]}
            showTags
            type='steam-ship-lines'
            typeUuid={steamShipLine.uuid}
          />
          }
        ]}
      />
    </>}
  </BlankPage>
}