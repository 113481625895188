/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */
import { useState, useEffect } from 'react';

import { CheckOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, ExportOutlined, FormOutlined, InteractionOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, Input, Menu, Pagination, Popconfirm, Popover, Row, Segmented, Space, Table, TableColumnProps, Tag, Typography, message } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../../util/url_queries';
import { userSettings } from '../../../lib/user_settings';
import { userPermissions } from '../../../lib/user_permissions';

export default function SettingsDocumentsBin(props:any) {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRestoring, setIsRestoring] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  
  const [searchTerm, setSearchTerm] = useState<string|undefined>(props.searchTerm||undefined);
  const [documents, setDocuments] = useState<any>(null);
  const [documentsCount, setDocumentsCount] = useState<number>(0);
  const [documentsPerPage, setDocumentsPerPage] = useState<number>(15);
  const [documentsCurrentPage, setDocumentsCurrentPage] = useState<number>(1);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(
        profile.type === 'management'
        && userPermissions('manageDocuments')
      ) {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getDocuments();
    setIsReady(true);
  }, [])
  
  useEffect(() => {
    getDocuments();
  }, [searchTerm])

  const getDocuments = async () => {
    if(props.documents && props.documents.length > 0) {
      setDocuments(props.documents);
      return;
    }

    if(isLoading) return;
    setIsLoading(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = searchTerm;

    EV.resPerPage = documentsPerPage;
    EV.page = documentsCurrentPage;
    EV.showDeleted = 'y';

    EV.orderBy = localStorage.getItem('prefDocumentsListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefDocumentsListingOrderDirection');

    var res:any = await EV.getDocuments();
    
    if(!res || res.status !== 'success') {
      // > handle error
      console.error('Failed to load', res)
    }

    else {
      setDocuments([...res.data]);
      setDocumentsCount(res.count);
    }
    
    setIsLoading(false);    
    return;
  }

  const cleanDocuments = async (uuid?:string) => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.cleanDocuments();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong, please try again...',
      });
    }
    
    else if(res.status !== 'success') {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else {
      messageApi.open({
        type: 'success',
        content: 'Process started...',
      });

      if(uuid === 'all') {
        getDocuments();
      }

      else {
        // clean up the doucments array
        var tempDocuments:any = [];

        documents.map((doc:any, index:number) => {
          if(doc.uuid !== uuid) tempDocuments.push(doc);

          if((index + 1) === documents.length) setDocuments([...tempDocuments]);
        })
      }
    }

    setIsDeleting(false);
    return;
  }

  const restoreDocuments = async (uuid?:string) => {
    if(isRestoring) return;
    setIsRestoring(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.restoreDocuments();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Something went wrong, please try again...',
      });
    }
    
    else if(res.status !== 'success') {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else {
      messageApi.open({
        type: 'success',
        content: 'Process started...',
      });

      // clean up the doucments array
      var tempDocuments:any = [];

      documents.map((doc:any, index:number) => {
        if(doc.uuid !== uuid) tempDocuments.push(doc);

        if((index + 1) === documents.length) setDocuments([...tempDocuments]);
      });
    }

    setIsRestoring(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      <Button
        danger
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Clean All Deleted'}
        disabled={documents?.length < 1}
        onClick={() => setLayoutModal({
          open: true,
          title: 'Are you sure?',
          children: [<>
            <Typography.Text>
              You are about to permanently remove deleted files. This action
              may not be reversable. Are you sure you want to continue?
            </Typography.Text>
          </>],
          okText: 'Confirm',
          onOk: () => {
            setLayoutModal({open: false});
            cleanDocuments('all');
          },
          onCancel: () => setLayoutModal({open: false})
        })}
      />
    </Space>}
  >
    {contextHolder}
    
    <Typography.Title children={'Bin'} />

    <Input 
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
      onChange={(e) => setSearchTerm(e.target.value)}
    />

    <div style={{marginBottom: 20}} />

    <Table
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 1300 }}
      loading={isLoading}
      pagination={{
        total: documentsCount||undefined,
        showSizeChanger: true,
        pageSizeOptions: [15, 50, 100, 200],
        defaultPageSize: 15,
        pageSize: documentsPerPage,
        onChange: (page:number, pagesSize:number) => {
          localStorage.setItem('prefDocumentsListingResPerPage', pagesSize.toString())
          setDocumentsPerPage(pagesSize);
          setDocumentsCurrentPage(page);
        }
      }}
      columns={[
        {
          key: 'uuid',
          title: 'UUID',
          fixed: 'left',
          dataIndex: 'uuid',
          width: 130,
          render: (_:any, record:any) => {
            return record.uuid.substr(record.uuid.length - 10)
          }
        },
        {
          key: 'purpose', 
          title: 'Purpose / Category', 
          dataIndex: 'purpose', 
          width: 300,
          render: (_:any, record:any) => {
            if(record.purpose) return <div>
              <div>
                <span style={{textTransform: 'capitalize'}}>
                  {record.purpose}
                </span>
                <span> / </span>
                <span style={{textTransform: 'capitalize'}}>
                  {record.category}
                </span>
              </div>
              <div>
                <a>
                  {record.purpose_uuid}
                </a>
              </div>
            </div>;

            else return '-';
          }
        },
        {
          key: 'notes', 
          title: 'Notes', 
          width: 300, 
          dataIndex: 'notes'
        },
        {
          key: 'ext', 
          title: 'Extension', 
          width: 100, 
          dataIndex: 'ext'
        },
        {
          key: 'size', 
          title: 'Size (MB)', 
          width: 140, 
          dataIndex: 'size',
          render: (_:any, record:any) => {
            if(record.size) {
              
              var size = Number(record.size) / 1000000;
              return <div>
                {size.toFixed(2)} <span>MB</span>
              </div>
            }

            else return '-';
          }
        },
        {
          key: 'optimized', 
          title: 'Optimized', 
          width: 100, 
          dataIndex: 'optimized',
          render: (_:any, record:any) => {
            if(record.optimized === 'y') return 'Yes';
            else if(record.optimized === 'n') return 'No';
            else return '-';
          }
        },
        {
          key: 'is_public', 
          title: 'Public', 
          width: 80, 
          dataIndex: 'is_public',
          render: (_:any, record:any) => {
            if(record.is_public === 'y') return 'Yes';
            else if(record.is_public === 'n') return 'No';
            else return '-';
          }
        },
        {
          key: 'created_at', 
          title: 'Created', 
          dataIndex: 'created_at',
          width: 170,
          render: (_:any, record:any) => {
            return momentTz(record.created_at)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY hh:mm A');
          }
        },
        {
          key: 'deleted_at', 
          title: 'Deleted', 
          dataIndex: 'deleted_at',
          width: 170,
          render: (_:any, record:any) => {
            return momentTz(record.deleted_at)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY hh:mm A');
          }
        },
        {
          key: 'actions',
          title: 'Actions',
          fixed: 'right',
          width: 130,
          render: (_:any, record:any) => <Space size={'small'}>
            <Button
              type='link'
              icon={<UndoOutlined />}
              title={'View'}
              onClick={() => restoreDocuments(record.uuid)}
            />
            <Popconfirm
              placement="top"
              title={'Delete confirmation'}
              description={'Are you sure you want to delete this selected document?'}
              onConfirm={() => cleanDocuments(record.uuid)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button
                danger
                type='link'
                icon={<DeleteOutlined />}
                title={'Clean'}
              />
            </Popconfirm>
          </Space>
        }
      ]}
      dataSource={documents||[]}
    />
  </BlankPage>
}