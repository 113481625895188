/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

interface OptionsProps {
  label:string, 
  value:string, 
  state_name?:string, 
  country_name?:string
}

export const filterGeoArray = (
  type:'city'|'state',
  data_source:OptionsProps[],
  compare_state?:string|null,
  compare_country?:string|null
) => {
  if(
    !data_source
    || typeof data_source !== 'object'
    || data_source.length < 1
  ) return undefined;

  var filteredDataset:OptionsProps[] = [];

  // cities has two filters to be applies state and country
  // country is shared with states type filteration while
  // state is only limited to city

  if(type === 'city') {
    if(compare_state) {
      data_source?.map((city:OptionsProps, key:number) => {
        if(city.state_name === compare_state) {
          filteredDataset.push(city);
        }
      });
    }
    else if (compare_country) {
      data_source?.map((city:OptionsProps, key:number) => {
        if(city.country_name === compare_country) {
          filteredDataset.push(city);
        }
      });
    }
    else return data_source;
  }
  else if (type === 'state') {
    if (compare_country) {
      data_source?.map((state:OptionsProps, key:number) => {
        if(state.country_name === compare_country) {
          filteredDataset.push(state);
        }
      });
    }
    else return data_source;
  }

  return filteredDataset;
}