/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */
import { useEffect, useState } from 'react';

import { Button, Card, Col, Layout, Menu, message, Modal, Row, Skeleton, Space, theme, Typography } from "antd";
import { AppstoreOutlined, ProductOutlined, ClusterOutlined, ContactsOutlined, SettingOutlined, AppstoreAddOutlined, VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons';

import Sider from "antd/es/layout/Sider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import packageManager from '../../package.json';
import NotFound from './states/not_found';
import Unauthorized from './states/unauthorized';
import { userSettings } from '../lib/user_settings';

export interface LayoutModalProps {
  key?:string,
  open:boolean,
  okText?:string,
  onOk?:any,
  cancelText?:string,
  onCancel?:any,
  title?:string,
  children?:any[],
  footer?:any[]
}

export default function BlankPage(props:any) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  let profile:any = localStorage.getItem('profile');

  const [userType, setUserType] = useState<'management'|'exporter'|undefined>(undefined);
  const [userUuid, setUserUuid] = useState<string|undefined>(undefined);
  const [hideRightSidePanel, setHideRightSidePanel] = useState<boolean>(
    userSettings('hide_right_side_menu_by_default') === 'y' ? true : false
  );

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      setUserType(profile.type);
      setUserUuid(profile.uuid);
    }
    catch(e) {
      console.warn(e)
    };
  }, []);

  // load the profile to process the side menu links
  let globalSettings:string|any[]|undefined|null = localStorage.getItem('dispatchGlobalSettings');
  let globalSettingsObj:any = {}

  if(globalSettings) try {
    if(typeof globalSettings === 'string') {
      globalSettings = JSON.parse(globalSettings);
    }

    if(
      globalSettings
      && typeof globalSettings === 'object' 
      && globalSettings.length > 0
    ) {
      globalSettings.map((setting:{uuid:any, value:any}, key:number) => {
        globalSettingsObj[`${setting.uuid}`] = setting.value
      })
    }
  }
  catch(e) {
    if(process.env.REACT_APP_MODE === 'development') {
      console.warn(
        'Failed to parse the global settings'
      );
    }
  }

  const generalMenu = [
    {
      title: 'Main',
      items: [
        { key: '', label: 'Main Menu', icon: <AppstoreOutlined /> },
        { key: '/', label: 'Dashboard', icon: <div /> },
        { key: `/system/settings/users/view/${userUuid}`, label: 'Profile', icon: <div /> },
        { key: '/login?logout=true', label: 'Logout', icon: <div /> }
      ]
    },
  ];

  const managementMenu:any = [
    {
      title: 'Drop Off',
      items: [
        { key: '', label: 'Drop Off', icon: <ProductOutlined /> },
        { key: '/drop-off/appointments', label: 'Appointments', icon: <div /> },
      ]
    },
    {
      title: 'Inventory',
      items: [
        { key: '', label: 'Inventory', icon: <AppstoreAddOutlined /> },
        { key: '/inventory/cargoes', label: 'Cargoes', icon: <div /> },
      ]
    },
    {
      title: 'Containers',
      items: [
        { key: '', label: 'Containers', icon: <ClusterOutlined /> },
        { key: '/containers/packing-plans', label: 'Packing Plans', icon: <div /> },
        { key: '/containers/containers', label: 'Containers', icon: <div /> }
      ]
    },
    {
      title: 'Accounting',
      items: [
        { key: '', label: 'Accounting', icon: <ClusterOutlined /> },
        { key: '/accounting/invoices/invoices', label: 'Invoices', icon: <div /> },
      ]
    },
    {
      title: 'Address Book',
      items: [
        { key: '', label: 'Address Book', icon: <ContactsOutlined /> },
        { key: '/address-book', label: 'All Contacts', icon: <div /> },
        { key: '/exporters', label: 'Exporters', icon: <div /> },
        { key: '/intermediate-consignees', label: 'Intr. Consignees', icon: <div /> },
      ]
    },
    {
      title: 'System',
      items: [
        { key: '', label: 'System', icon: <SettingOutlined /> },
        { key: '/system/settings', label: 'Settings', icon: <div /> },
        { key: '/external/feedback', label: 'Feedback', icon: <div /> },
        { key: '/external/help', label: 'Help', icon: <div /> },
        { key: '/system/about', label: 'About', icon: <div /> },
      ]
    }
  ];

  const exporterMenu:any = [
    {
      title: 'Drop Off',
      items: [
        { key: '', label: 'Drop Off', icon: <ProductOutlined /> },
        { key: '/drop-off/appointments', label: 'Appointments', icon: <div /> },
      ]
    },
    {
      title: 'Inventory',
      items: [
        { key: '', label: 'Inventory', icon: <AppstoreAddOutlined /> },
        { key: '/inventory/cargoes', label: 'Cargoes', icon: <div /> },
      ]
    },
    {
      title: 'Containers',
      items: [
        { key: '', label: 'Containers', icon: <ClusterOutlined /> },
        { key: '/containers/packing-plans', label: 'Packing Plans', icon: <div /> },
        { key: '/containers/containers', label: 'Containers', icon: <div /> }
      ]
    },
    {
      title: 'Accounting',
      items: [
        { key: '', label: 'Accounting', icon: <ClusterOutlined /> },
        { key: '/accounting/invoices/invoices', label: 'Invoices', icon: <div /> },
      ]
    },
  ];

  const isDarkMode = localStorage.getItem('isDarkMode') || false;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const headerStyle = {
    background: colorBgContainer,
    paddingVertical: 10,
    lineHeight: 'unset',
    height: 'unset',
    paddingInline: 0
  }

  // handle messageApi
  let  messageApiKeys:any[] = [];

  if(props.messageApi) {
    if(messageApiKeys.includes(props.messageApi.key)) return null;

    messageApiKeys.push(props.messageApi.key);
    messageApi.open(props.messageApi);

    setTimeout(() => {
      var index = messageApiKeys.indexOf(props.messageApi.key);
      messageApiKeys = messageApiKeys.splice(index, 1);
    });
  }

  const _renderScreenContent = () => {
    if(props.isLoading) {
      return <Skeleton active />
    }
    else if(props.notFound) return <NotFound />
    else if(props.unauthorized) return <Unauthorized />
    else if(props.blockContent) return props.blockContent;
    else if(props.children) return props.children;
    else return <NotFound />
  }

  return (
    <Layout style={{height: '100vh'}}>
      {(props.showSideMenu) && <Sider 
        className="hide-scrollbar"
        style={{
          overflow: 'scroll',
          background: colorBgContainer,
          padding: 10
        }}
      >
        <div style={{marginLeft: 30, width: 150, height: 30}}>
          <img src={require('../framework/assets/logo.png')} style={{height: '100%'}} />
        </div>

        {generalMenu?.map((item:any, key:number) => <Menu
          mode="vertical"
          theme={isDarkMode ? 'dark' : 'light'}
          style={{
            border: 0, 
            background: colorBgContainer,
            marginTop: 15
          }}
          title={item.title}
          items={item.items}
          onClick={(e:any) => navigate(`${e.key}`,{replace: true})}
        />)}

        {(userType === 'management') && managementMenu?.map((item:any, key:number) => <Menu
          mode="vertical"
          theme={isDarkMode ? 'dark' : 'light'}
          style={{
            border: 0, 
            background: colorBgContainer,
            marginTop: 15
          }}
          title={item.title}
          items={item.items}
          onClick={(e:any) => {
            if(e.key === '/external/feedback') {
              window.open(globalSettingsObj[`feedback-uri`])
              return;
            }
            else if(e.key === '/external/help') {
              window.open(globalSettingsObj[`help-uri`])
              return;
            }

            navigate(`${e.key}`,{replace: true})
          }}
        />)}

        {(userType === 'exporter') && exporterMenu?.map((item:any, key:number) => <Menu
          mode="vertical"
          theme={isDarkMode ? 'dark' : 'light'}
          style={{
            border: 0, 
            background: colorBgContainer,
            marginTop: 15
          }}
          title={item.title}
          items={item.items}
          onClick={(e:any) => navigate(`${e.key}`,{replace: true})}
        />)}

        <div style={{padding: 20}}>
          <Typography.Text style={{fontSize: '9pt'}} type={'secondary'}>
            Release {packageManager.version}
          </Typography.Text>
        </div>
      </Sider>}
      <Layout.Content
        id={'layout-content-container'}
        className="hide-scrollbar"
        style={{
          flex: 3,
          overflow: 'scroll',
          background: colorBgContainer,
          padding: props.padded ? 10 : 0
        }}
      >
        {(props.showDefaultHeader) && <Layout.Header
          style={headerStyle}
        >
          
        </Layout.Header>}
        {(props.customHeader) && <Layout.Header
          style={headerStyle}
        >
          {props.customHeader}

          {(hideRightSidePanel) && <div style={{float: 'right'}}>
            <Button
              type={'default'}
              icon={<VerticalRightOutlined />}
              children={'Open Side Panel'}
              onClick={() => setHideRightSidePanel(!hideRightSidePanel)}
            />
          </div>}
        </Layout.Header>}
        
        {contextHolder}
        
        {(props.modal) && <Modal
          title={props.modal?.title}
          open={props.modal?.open}
          onOk={props.modal?.onOk}
          okText={props.modal?.okText}
          onCancel={props.modal?.onCancel}
          cancelText={props.modal?.cancelText}
          children={props.modal?.children}
          footer={props.modal?.footer}
        />}
        
        <div style={{paddingBottom: 80}}>
          {_renderScreenContent()}
        </div>
        
        {(props.showDefaultFooter) && <Layout.Footer>
          
        </Layout.Footer>}
        {(props.customFooter) && <Layout.Footer>
          {props.customFooter}
        </Layout.Footer>}
      </Layout.Content>
      
      {(props.rightSideComponent && !hideRightSidePanel) && <Layout.Content
        style={{
          flex: 1,
          overflow: 'scroll',
          background: colorBgContainer,
          padding: props.padded ? 10 : 0,
        }}
      >
        <Space>
          <Button
            type={'default'}
            iconPosition={'end'}
            icon={<VerticalLeftOutlined />}
            children={'Close Panel'}
            onClick={() => setHideRightSidePanel(!hideRightSidePanel)}
          />
        </Space>

        {props.rightSideComponent()}
      </Layout.Content>}
    </Layout>
  )
}