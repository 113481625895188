/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, DownOutlined, FormOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Pagination, Row, Segmented, Space, Table, Tag, Typography } from "antd";

import BlankPage from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export default function TrcukingCompaniesListing(props:any) {
  const navigate = useNavigate();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [truckingCompanies, setTruckingCompanies] = useState<any>(null);
  
  const [searchTerm, setSearchTerm] = useState<any>(null);

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getTruckingCompanies();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
    getTruckingCompanies();
  }, [searchTerm]);

  const getTruckingCompanies = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.searchTerm = searchTerm;

    var res:any = await EV.getTruckingCompanies();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          key: item.uuid,
          ...item
        });
      });
    }

    setTruckingCompanies(sortedData);
  }

  const columns = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      fixed: 'left' as 'left',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'company_name', 
      title: 'Company Name', 
      dataIndex: 'company_name',
      render: (_:any, record:any) => {
        if(record.is_active !== 'y') {
          return <>
            <Tag>Inactive</Tag>
            <Typography.Text type={'secondary'} delete>{record.company_name}</Typography.Text>
          </>
        }

        return <>
          <Typography.Text>{record.company_name}</Typography.Text>
        </>
      }
    },
    {
      key: 'created_at', 
      title: 'Created', 
      dataIndex: 'created_at',
      width: 170,
      render: (_:any, record:any) => {
        return momentTz(record.created_at)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      key: 'action',
      title: 'Action', 
      fixed: 'right' as 'right',
      width: 100,
      render: (_:any, record:any) => <Space size={'small'}>
        <Button
          type='link'
          icon={<FormOutlined />}
          title={'Manage'}
          onClick={() => navigate(`/trucking-companies/form/${record.uuid}`)}
        />
      </Space>
    },
  ];

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized) && <>
        <Button
          type={'primary'}
          icon={<PlusCircleFilled />}
          children={'Add New'}
          onClick={() => navigate('/trucking-companies/form')}
        />
      </>}
    </Space>}
  >
    
    <Typography.Title children={'Trucking Companies'} />

    <div style={{marginBottom: 20}} />

    <Input 
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
    />

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 900 }}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={truckingCompanies} 
      columns={columns} 
    />
  </BlankPage>
}