/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { ReactElement, useEffect, useState } from 'react';

import { BorderOuterOutlined, BoxPlotOutlined, ExpandAltOutlined, ExportOutlined, EyeOutlined, FormOutlined, PlusCircleFilled, PlusCircleOutlined, ReadOutlined, SearchOutlined, TableOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Dropdown, Empty, Form, Input, Menu, MenuProps, Pagination, Row, Segmented, Select, Space, Table, Tag, Typography } from "antd";

import BlankPage from "../framework/blank_page";
import { useNavigate } from 'react-router-dom';
import { config } from '../config';
import EVAPI from '../lib/ev_lib/main';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useForm } from 'antd/es/form/Form';
import { filterGeoArray } from '../lib/cities';
import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../common/TableColumnsCuztomization';

import TableColumnSortIcon from '../common/TableColumnSortIcon';
import { resortTable } from '../lib/table_sorting';
import { URLQuery } from '../util/url_queries';
import { userSettings } from '../lib/user_settings';

export function DropOffContainer(props:any) {
  const navigate = useNavigate();

  const _renderDate = () => {
    if(props.dataSource?.drop_off_at) {
      return moment(props.dataSource?.drop_off_at).format('YYYY-MM-DD hh:mm A');
    }

    return '-';
  }

  return <Card 
    style={{marginBottom: 20}}
    title={
      '# ' 
      + (props.dataSource?.uuid?.substr(props.dataSource?.uuid?.length - 10)||'-')
    }
    extra={<Space size={'large'}>
      <Tag
        color={props.dataSource?.modules_records_statuses_highlight_color}
        children={props.dataSource?.modules_records_statuses_label} 
      />
      {(props.allowEdit) && <Button
        type='link'
        icon={<FormOutlined />}
        title={'Manage'}
        onClick={() => navigate(`/containers/containers/form/${props.dataSource.uuid}`)}
      />}
    </Space>}
  >
    <Row gutter={10}>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Date & Time'} />
          <Typography.Text>{_renderDate()}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Warehouse'} />
          <Typography.Text>
            {props.dataSource?.warehouses_name||'-'}
          </Typography.Text>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Trucking Company'} />
          <Typography.Text>{props.dataSource?.trucking_company_name||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Exporter'} />
          <Typography.Text>{props.dataSource?.exporters_company_name||'-'}</Typography.Text>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Driver Name'} />
          <Typography.Text>{props.dataSource?.exporters_company_name||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Contact Phone'} />
          <Typography.Text>
            <a href={'tel:' + props.dataSource?.contact_phone}>
              {props.dataSource?.contact_phone}
            </a>
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Contact Email'} />
          <Typography.Text>
            <a href={'mailto:' + props.dataSource?.contact_email}>
              {props.dataSource?.contact_email}
            </a>
          </Typography.Text>
        </div>
      </Col>
    </Row>
  </Card>
}

export default function DropOffsAppointmentsListing(props:any) {
  interface AdvanceFiltersProps {
    warehouses_uuids?: string|null|undefined,
    exporters_uuid?: string|null|undefined,
    modules_records_statuses_uuid?: string|null|undefined,
    drop_off_at_from?:string|string[],
    drop_off_at_from_obj?: any,
    drop_off_at_to?:string|string[],
    drop_off_at_to_obj?: any,
    created_at_from?:string|string[],
    created_at_from_obj?: any,
    created_at_to?:string|string[],
    created_at_to_obj?: any,
  }

  const navigate = useNavigate();
  const [form] = Form.useForm();

  let profile:any = localStorage.getItem('profile');
  let prefDropOffAppointmentsListingView:() => 'table'|'boxes' = () => {
    var stored = localStorage.getItem('prefDropOffAppointmentsListingView');
    
    if(stored && stored === 'boxes') return stored;
    else return 'table';
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const [allowCreateNew, setAllowCreateNew] = useState<boolean>(false);

  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [dropOffAppointmentsStatusesUuid, setDropOffAppointmentsStatusesUuid] = useState<any>(null);

  const [toggleAdvancedFilters, setToggleAdvancedFilters] = useState<boolean>(false);
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({});

  const [dropOffAppointmentsStatuses, setDropOffAppointmentsStatuses] = useState<any[]>([]);
  const [dropOffAppointments, setDropOffAppointments] = useState<any[]>([]);
  const [dropOffAppointmentsCount, setDropOffAppointmentsCount] = useState<number>(0);
  const [dropOffAppointmentsPerPage, setDropOffAppointmentsPerPage] = useState<number>(15);
  const [dropOffAppointmentsCurrentPage, setDropOffAppointmentsCurrentPage] = useState<number>(1);

  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);

  const [listingView, setListingView] = useState<'boxes'|'table'>(prefDropOffAppointmentsListingView);
  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'warehouse', label: 'Warehouse', default: true},
    { id: 'drop_off_at', label: 'Drop Off Time', default: true},
    { id: 'exporter', label: 'Exporter', default: true},
    { id: 'trucking_company_name', label: 'Tracking Company', default: true},
    { id: 'driver_full_name', label: 'Driver Name', default: true},
    { id: 'contact_phone', label: 'Phone', default: true},
    { id: 'contact_email', label: 'Email', default: true},
    { id: 'created_at', label: 'Created' }
  ];

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management'].includes(profile.type)) {
        setAllowEdit(true);
        setAllowCreateNew(true);
      }
    }
    catch(e) {
      console.warn(e)
    };

    // set the default ResPerPage
    if(localStorage.getItem('prefDropOffAppointmentsListingResPerPage')) {
      setDropOffAppointmentsPerPage(Number(localStorage.getItem('prefDropOffAppointmentsListingResPerPage')));
    }

    processPrefTableColumns();
    
    getDropOffAppointmentsStatuses();
    getWarehouses();
    getExporters();

    getDropOffAppointments();
  }, []);
  
  useEffect(() => {
    getDropOffAppointments();
  }, [
    searchTerm, 
    dropOffAppointmentsStatusesUuid,
    dropOffAppointmentsCurrentPage,
    dropOffAppointmentsPerPage
  ]);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefDropOffAppointmentsListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setisLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
  }

  const getDropOffAppointmentsStatuses = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'drop off appointments';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [{
      value: null,
      label: 'All'
    }];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(item.is_default === 'y') {
          setDropOffAppointmentsStatusesUuid(item.uuid);
        }
        
        sortedData.push({
          value: item.uuid,
          label: item.label,
          highlight_type: item.highlight_type,
          highlight_color: item.highlight_color
        });
      });
    }

    setDropOffAppointmentsStatuses(sortedData);
  }

  const getDropOffAppointments = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.resPerPage = dropOffAppointmentsPerPage;
    EV.page = dropOffAppointmentsCurrentPage;
    EV.searchTerm = searchTerm;
    EV.modulesRecordsStatusesUuid = dropOffAppointmentsStatusesUuid;

    EV.orderBy = localStorage.getItem('prefDropOffAppointmentsListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefDropOffAppointmentsListingOrderDirection');

    EV = Object.assign(EV, advanceFilters);

    var res:any = await EV.getDropOffAppointments();
    
    if(res.data) { 
      setDropOffAppointmentsCount(Number(res.data.count));
      setDropOffAppointments(res.data);
    }

    setIsLoading(false);
    return;
  }

  const _renderFitlersToURL = () => {
    var linkQuery:string = '';
    
    if(searchTerm) {
      linkQuery += 'searchTerm=' + searchTerm + '&&';
    }

    if(
      advanceFilters
      && Object.keys(advanceFilters).length > 0
    ) {
      Object.entries(advanceFilters).map((property:[string, any], index:number) => {
        linkQuery += `${property[0]}=` 
          + property[1] 
          + '&&';
      })
    }

    return linkQuery;
  }

  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      {(allowCreateNew) && <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate('/drop-off/appointments/form')}
      />}
    </Space>}
  >
    
    <Typography.Title children={'Appointments'} />

    <Row gutter={15}>
      <Col>
        <Segmented 
          value={dropOffAppointmentsStatusesUuid||0}
          style={{textTransform: 'capitalize'}}
          options={dropOffAppointmentsStatuses}
          onChange={(value:any) => setDropOffAppointmentsStatusesUuid(value)}
        />
      </Col>
      <Col>
        <Segmented
          value={listingView}
          options={[
            { value: 'table', icon: <TableOutlined />},
            { value: 'boxes', icon: <BorderOuterOutlined />},
          ]}
          onChange={(value:any) => {
            setListingView(value);
            localStorage.setItem('prefDropOffAppointmentsListingView', value);
          }}
        />
      </Col>
      <Col flex={'auto'} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Space>
          <Button
            type={'default'}
            onClick={() => window.open(`/drop-off/appointments/doc/export-list/?${_renderFitlersToURL()}`)}
            icon={<ExportOutlined />}
            children={'Export PDF'}
          />
          <Button
            type={'default'}
            onClick={() => setShowSideMenu(!showSideMenu)}
            icon={<EyeOutlined />}
            children={'Customize View'}
          />
        </Space>
      </Col>
    </Row>

    <TableColumnsCuztomization
      showDrawer={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      onPrcessComplete={processPrefTableColumns}
      localStorageKey='prefDropOffAppointmentsListingTableViewColumns'
      tableColumnsList={tableColumnsList}
    />

    <div style={{marginBottom: 20}} />

    <Row gutter={15}>
      <Col span={19}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <Button 
          style={{width: '100%'}}
          type={"default"}
          onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
        >
          Toggle Advanced Filters
        </Button>
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    {(toggleAdvancedFilters) && <>
      <Typography.Title level={3}>
        Advance Search
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          getDropOffAppointments();
        }}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'exporters_uuid'}
              label={'Exporter'}
            >
              <Select 
                showSearch
                allowClear
                placeholder={'Select one'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  exporters_uuid: value
                })}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={exporters||[]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'warehouses_uuids'}
              label={'Warehouses'}
            >
              <Select 
                mode="tags"
                allowClear
                placeholder={'Warehouses'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  warehouses_uuids: value
                })}
                options={warehouses||[]}
                tokenSeparators={[',']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name={'drop_off_at_from'}
              label={'Drop Off (from)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.drop_off_at_from_obj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  drop_off_at_from: dateString,
                  drop_off_at_from_obj: date
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'drop_off_at_to'}
              label={'Drop Off (to)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.drop_off_at_to_obj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  drop_off_at_to: dateString,
                  drop_off_at_to_obj: date
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                >
                  Search
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => {
                    setAdvanceFilters({});
                    form.resetFields();
                    setTimeout(() => getDropOffAppointments, 500);
                  }}
                >
                  Reset
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
                >
                  Toggle Advanced Filters
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>}

    <div style={{marginBottom: 20}} />

    {(listingView === 'table') ? <>
      <Table
        size={userSettings('compact_view') ? 'small' : undefined}
        scroll={{ x: 1300 }}
        loading={isLoading}
        pagination={{
          total: dropOffAppointmentsCount||undefined,
          showSizeChanger: true,
          pageSizeOptions: [15, 50, 100, 200],
          defaultPageSize: 15,
          pageSize: dropOffAppointmentsPerPage,
          onChange: (page:number, pagesSize:number) => {
            localStorage.setItem('prefDropOffAppointmentsListingResPerPage', pagesSize.toString())
            setDropOffAppointmentsPerPage(pagesSize);
            setDropOffAppointmentsCurrentPage(page);
          }
        }}
        columns={[
          {
            key: 'modules_records_statuses_label', 
            title: 'Status',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => <Tag
              color={record.modules_records_statuses_highlight_color}
              children={record.modules_records_statuses_label} 
            />
          },
          (selectedColumns?.includes('uuid')) ? {
            key: 'uuid',
            title: 'UUID',
            fixed: 'left',
            dataIndex: 'uuid',
            width: 130,
            render: (_:any, record:any) => {
              return record.uuid.substr(record.uuid.length - 10)
            }
          } : {},
          (selectedColumns?.includes('warehouse')) ? {
            key: 'warehouses_name', 
            title: 'Warehouse', 
            width: 200, 
            dataIndex: 'warehouses_name'
          } : {},
          (selectedColumns?.includes('drop_off_at')) ? {
            key: 'drop_off_at', 
            title: 'Date / Time', 
            dataIndex: 'drop_off_at',
            width: 170,
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefDropOffAppointmentsListingOrder'}
              columnId={'drop_off.drop_off_at'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'DropOffAppointments',
                  'drop_off.drop_off_at',
                  getDropOffAppointments
                )
              };
            },
            render: (_:any, record:any) => {
              return momentTz(record.created_at)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY hh:mm A');
            }
          } : {},
          (selectedColumns?.includes('exporter')) ? {
            key: 'exporters_company_name', 
            title: 'Exporter', 
            width: 200,
            dataIndex: 'exporters_company_name',
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefDropOffAppointmentsListingOrder'}
              columnId={'exporters.company_name'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'DropOffAppointments',
                  'exporters.company_name',
                  () => getDropOffAppointments()
                )
              };
            },
          } : {},
          (selectedColumns?.includes('trucking_company_name')) ? {
            key: 'trucking_company_name', 
            title: 'Trucking Company', 
            width: 300,
            dataIndex: 'trucking_company_name'
          } : {},
          (selectedColumns?.includes('driver_full_name')) ? {
            key: 'driver_full_name', 
            title: 'Driver Name', 
            dataIndex: 'driver_full_name', 
            width: 300
          } : {},
          (selectedColumns?.includes('contact_phone')) ? {
            key: 'contact_phone', 
            title: 'Contact Phone', 
            dataIndex: 'contact_phone', 
            width: 300
          } : {},
          (selectedColumns?.includes('contact_email')) ? {
            key: 'contact_email', 
            title: 'Email', 
            dataIndex: 'contact_email', 
            width: 300
          } : {},
          {
            key: 'created_at', 
            title: 'Created', 
            dataIndex: 'created_at',
            width: 170,
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefDropOffAppointmentsListingOrder'}
              columnId={'drop_off.created_at'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'DropOffAppointments',
                  'drop_off.created_at',
                  getDropOffAppointments
                )
              };
            },
            render: (_:any, record:any) => {
              return momentTz(record.created_at)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY hh:mm A');
            }
          },
          {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 100,
            render: (_:any, record:any) => <Space size={'small'}>
              <Button
                type='link'
                icon={<FormOutlined />}
                title={'Manage'}
                onClick={() => navigate(`/drop-off/appointments/form/${record.uuid}`)}
              />
            </Space>
          }
        ]}
        dataSource={dropOffAppointments||[]}
      />
    </>
    : <>
      {(!dropOffAppointments || dropOffAppointments.length < 1) && <>
        <Empty style={{margin: 100}}/>
      </>}

      {(
        dropOffAppointments 
        && dropOffAppointments.length > 0
      ) && dropOffAppointments.map((dropOffAppointment:any, key:number) => {
        return <DropOffContainer 
          key={key} 
          allowEdit={allowEdit} 
          dataSource={dropOffAppointment} 
        />
      })}
    </>}
  </BlankPage>
}