/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EVAPI from '../../../../lib/ev_lib/main';
import { config } from '../../../../config';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { Alert, Button, Form, Input, Skeleton, Typography } from 'antd';

import jsPDF from 'jspdf';
import { URLQuery } from '../../../../util/url_queries';

export default function ExportInvoicesListing(props:any) { 
  const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const searchTerm = URLQuery('searchTerm', window);
  const status = URLQuery('status', window);
  const exportersUuids = URLQuery('exportersUuids', window);
  const issueDateFrom = URLQuery('issueDateFrom', window);
  const issueDateTo = URLQuery('issueDateTo', window);
  const dueDateFrom = URLQuery('dueDateFrom', window);
  const dueDateTo = URLQuery('dueDateTo', window);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordsCount, setRecordsCount] = useState<number>(0);
  const [records, setRecords] = useState<any>(null);

  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    InvoicesListing();
  }, []);

  useEffect(() => {
    updateDocumentMeta();
  }, records);

  const InvoicesListing = async (count:boolean = false) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    if(status && status !== 'all') {
      EV.status = status;
    }

    EV.searchTerm = searchTerm;
    EV.exportersUuids = exportersUuids||undefined;
    EV.issueDateFrom = issueDateFrom||undefined;
    EV.issueDateTo = issueDateTo||undefined;
    EV.dueDateFrom = dueDateFrom||undefined;
    EV.dueDateTo = dueDateTo||undefined;
    EV.showAll = true;

    EV.orderBy = localStorage.getItem('prefInvoicesListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefInvoicesListingOrderDirection');

    var res:any = await EV.getInvoices();

    if(res.data) { 
      setRecordsCount(Number(res.count));
      setRecords(res.data);
    }
    
    return;
  }

  const updateDocumentMeta = () => {
    document.title = 'Invoices List';
  }

  const _renderDate = (date:any) => {
    try {
      var processedDate = moment(date);
      
      return processedDate.format('YYYY/MM/DD');
    }
    catch(e) {
      // console the error
      return '//** Err'
    }
  }

  if(!records || isLoading) return <div>
    <Skeleton />
  </div>
  
  else if(errors && errors.length > 0) return <div style={{
    width: 400,
    marginTop: 20, 
    flexDirection: 'column'
  }} >
    <Typography.Title level={2}>Oops!</Typography.Title>

    <Alert
      message={errors?.map((error:any, key:number) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <div>{error}</div>;
      })}
      style={{marginBottom: 20}}
      type='error'
    />

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => window.close()}
    >
      Close and Try Again
    </Button>
  </div>
  
  var sumSubTotal = 0;
  var sumTaxTotal = 0;
  var sumGrandTotal = 0; 
  var sumTotalPaid = 0; 
  var sumBalance = 0; 

  return <div className='doc-page'>
    <div className={'document-action-bar execlude-print'}>
      <button className={'document-button'} onClick={() => {
        var doc = new jsPDF("landscape", "mm", [1400, 1000]);
        var pdfjs:any= document.querySelector('#element-to-print');

        doc.html(
          pdfjs, {
            callback: function(doc) {
              doc.save(`invoices_list_${Date.now()}.pdf`);
            },
            x: 12,
            y: 12,
            margin: 15
          }
        );    
      }}>
        Save as PDF
      </button>
    </div>
    <div id={'element-to-print'} style={{flex: 1, flexDirection: 'column', padding: 10}}>
      <div style={{marginBottom: 20}}>
        <h2 style={{padding: 0}}>Invoices List</h2>
        <div>Results found: {recordsCount}</div>
      </div>
      <table style={{width: '1330px'}} className={'docDataTable'} cellSpacing={0}>
        <thead>
          <tr>
            <th>UUID #</th>
            <th>Ref #</th>
            <th>Status</th>
            <th>Exporter</th>
            <th>Booking #</th>
            <th>Container #</th>
            <th>Issue Date</th>
            <th>Sub Total</th>
            <th>Tax</th>
            <th>Total</th>
            <th>Unpaid Balance</th>
          </tr>
        </thead>
        <tbody>
          {records?.map((invoice:any, index:number) => {
            var rowBackgroundColor = '#fff';
            
            if(index % 2 > 0) {
              rowBackgroundColor = '#f9f9f9';
            }

            if(invoice.sub_total) sumSubTotal += Number(invoice.sub_total);
            if(invoice.tax_total) sumTaxTotal += Number(invoice.tax_total);
            if(invoice.grand_total) sumGrandTotal += Number(invoice.grand_total);
            if(invoice.total_paid) sumTotalPaid += Number(invoice.total_paid);
            if(invoice.balance) sumBalance += Number(invoice.grand_total - invoice.total_paid);

            return <tr style={{backgroundColor: rowBackgroundColor}}>
              <td width={"90px"}>{invoice.uuid.substr(invoice.uuid.length - 10)}</td>
              <td width={"90px"}>{invoice.reference_number}</td>
              <td width={"150px"}>{invoice.status}</td>
              <td width={"300px"}>{invoice.exporters_company_name}</td>
              <td width={"300px"}>{invoice.booking_number}</td>
              <td width={"300px"}>{invoice.container_number}</td>
              <td width={"100px"}>{_renderDate(invoice.issue_date)}</td>
              <td width={"100px"} align={"right"}>
                {invoice.sub_total ? formatter.format(Number(invoice.sub_total)) : '-'}
              </td>
              <td width={"100px"} align={"right"}>
                {invoice.tax_total ? formatter.format(Number(invoice.tax_total)) : '-'}
              </td>
              <td width={"100px"} align={"right"}>
                {invoice.grand_total ? formatter.format(Number(invoice.grand_total)) : '-'}
              </td>
              <td width={"100px"} align={"right"}>
                {invoice.total_paid ? formatter.format(Number(invoice.grand_total) - Number(invoice.total_paid)) : '-'}
                </td>
            </tr>
          })}

          <tr>
            <td style={{background: '#ddd'}} colSpan={7}></td>
            <td style={{background: '#ddd'}} align={"right"}>
              <strong>{formatter.format(Number(sumSubTotal))}</strong>
            </td>
            <td style={{background: '#ddd'}} align={"right"}>
              <strong>{formatter.format(Number(sumTaxTotal))}</strong>
            </td>
            <td style={{background: '#ddd'}} align={"right"}>
              <strong>{formatter.format(Number(sumGrandTotal))}</strong>
            </td>
            <td style={{background: '#ddd'}} align={"right"}>
              <strong>{formatter.format(Number(sumBalance))}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{padding: '10px 0px', marginTop: 20, borderTop: '1px dashed #aaa'}}>
        <h4 style={{margin: '3px 0'}}>Filters:</h4> 
        <p style={{margin: '3px 0'}}>Following are the fitlers used to generate the list above:</p>
        {searchTerm && <div>- Search Term: {searchTerm}</div>}
        {status && <div>- Status: {status}</div>}
        {exportersUuids && <div>- Exporters UUID: {exportersUuids}</div>}
        {issueDateFrom && <div>- Issue Date (From): {issueDateFrom}</div>}
        {issueDateTo && <div>- Issue Date (To): {issueDateTo}</div>}
        {dueDateFrom && <div>- Due Date (From): {dueDateFrom}</div>}
        {dueDateTo && <div>- Due Date (To): {dueDateTo}</div>}
      </div>

      <div style={{borderTop: '1px dashed #aaa'}}>
        <p style={{fontSize: '9pt'}}>Generated using EVessel - <a href="https://evessel.io/" target="_blank">https://evessel.io</a></p>
      </div>
    </div>
  </div>
}