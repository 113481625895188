/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export const userSettings = (
  key?:string
) => {
  let userSettings:any = localStorage.getItem('user_settings');

  if(!userSettings) return null;

  try {
    // > This should be fixed in the future to insure TypeScript is affective
    userSettings = JSON.parse(userSettings);

    if(
      key
      && userSettings?.hasOwnProperty(key)
    ) {
      return userSettings[`${key}`]
    }
  }
  catch(e) {
    console.error(
      'System failed to parse user settings',
      e
    );

    return undefined;
  }
}