// URL should be the {window} object
export function URLQuery(variable:string, URL:any) {
  var query = URL.location.search.substring(1);
  var vars = query.split("&");

  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === variable) return pair[1];
  }

  return undefined;
}