/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useNavigate, useParams } from "react-router-dom";

import { Alert, Button, Card, CardProps, Col, Divider, Drawer, Empty, Form, Image, Input, InputNumber, Row, Segmented, Select, Skeleton, Space, Table, Tag, Tooltip, Typography, message } from "antd";
import { DeleteFilled, EyeFilled, LeftCircleFilled, MailFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";

import BlankPage, { LayoutModalProps } from "../framework/blank_page";
import { useEffect, useState } from "react";
import EVAPI from "../lib/ev_lib/main";

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { setClearFormFields } from "../util/clear_empty";
import { filterGeoArray } from "../lib/cities";
import { blue, green, gray, red } from "@ant-design/colors";
import Documents from "../common/documents";
import { URLQuery } from "../util/url_queries";
import DaySlot from "./day_slot";
import TimeSlot from "./time_slot";

export interface DropOffAppointmentProp {
  uuid: string|undefined,
  modules_records_statuses_uuid?: string|number|null|undefined,
  exporters_uuid?: string|null,
  drop_off_at?:string|null|undefined,
  warehouses_uuid?: string|null|undefined,
  trucking_company_name?: string|null|undefined,
  driver_full_name?: string|null|undefined,
  contact_phone?: string|null|undefined,
  contact_email?: string|null|undefined,
  number_of_items?: number|null|undefined,
  public_notes?: string|null|undefined,
  private_notes?: string|null|undefined,
  clear?: string[]|undefined,
  created_at?: string|null|undefined,
  updated_at?: string|null|undefined,
  deleted_at?: string|null|undefined
}

export interface ExporterProps {
  uuid: string|null,
  notify_parties?:any[]|null
  consignees?:any[]|null
}

export default function DropOffsAppointmentsForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();

  const [form] = Form.useForm();
  const [isNewAppointment, setIsNewAppointment] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [errors, setErrors] = useState<any[]|undefined>(undefined);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);

  const [isLoadingDropOffAppointment, setIsLoadingDropOffAppointment] = useState<boolean>(false);
  const [dropOffAppointment, setDropOffAppointment] = useState<DropOffAppointmentProp>({
    uuid: uuid||undefined,
  });

  const [statuses, setStatuses] = useState<any[]>([]);
  const [isLoadingStatuses, setisLoadingStatuses] = useState<boolean>(false);

  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setIsLoadingExporters] = useState<boolean>(false);
  
  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<any>(undefined);
  const [disabledDates, setDisabledDates] = useState<any[]>([]);
  const [isLoadingDisabledDates, setIsLoadingDisabledDates] = useState<boolean>(false);
  const [daysDropOffAppointments, setDaysDropOffAppointments] = useState<any[]>([]);
  const [isLoadingDaysDropOffAppointments, setIsLoadingDaysDropOffAppointments] = useState<boolean>(false);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>(undefined);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState<boolean>(false);

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }

      if(
        dropOffAppointment.uuid
        && !['management'].includes(profile.type)
      ) {
        setAuthorized(false);
      }
      
      else if(['management'].includes(profile.type)) {
        setAuthorized(true);
      }

      // if management set is managemnet
      setIsManagement(profile.type === 'management' ? true : false);
    }
    catch(e) {
      console.warn(e);
    };

    getStatuses();
    getExporters();
    getWarehouses();

    if(uuid) getDropOffAppointment();
  }, []);

  useEffect(() => {
    if(dropOffAppointment.drop_off_at) return;

    setSelectedDate(undefined);
    setSelectedTimeSlot(undefined);
    setTimeSlots([]);
    setDaysDropOffAppointments([]);

    getDisabledDates();
  }, [dropOffAppointment.warehouses_uuid]);

  useEffect(() => {
    if(dropOffAppointment.drop_off_at) return;

    setSelectedDate(undefined);
    setSelectedTimeSlot(undefined);
    setDaysDropOffAppointments([]);
  }, [dropOffAppointment.number_of_items]);

  useEffect(() => {
    if(dropOffAppointment.drop_off_at) return;

    setSelectedTimeSlot(undefined);
    setDaysDropOffAppointments([]);

    getTimeSlots();
    getDaysDropOffAppointments();
  }, [selectedDate]);

  useEffect(() => {
    document.getElementById('layout-content-container')?.scrollTo(
      { top: 0, left: 0, behavior: 'smooth' }
    );
  }, [errors]);

  useEffect(() => {
    setErrors(undefined);
  }, [selectedDate, selectedTimeSlot, dropOffAppointment]);
  
  
  const getSettings = async () => {
    // load the drop-off-scheduling-period
  }

  const getStatuses = async () => {
    if(isLoadingStatuses) return null;
    setisLoadingStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'drop off appointments';
    EV.orderDirection = 'ASC';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setisLoadingStatuses(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: item.label,
          highlight_type: item.highlight_type,
          highlight_color: item.highlight_color
        });
      });
    }

    setStatuses(sortedData);
    return;
  }

  const getDropOffAppointment = async () => {
    if(isLoadingDropOffAppointment) return null;
    setIsLoadingDropOffAppointment(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = dropOffAppointment.uuid;

    var res:any = await EV.getDropOffAppointment();
    
    setIsLoadingDropOffAppointment(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull appointment details',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      // setup the selected date and time from drop_off_at
      var isolatedDate = moment(res.data.drop_off_at).format('YYYY-MM-DD');
      var isolatedTime = moment(res.data.drop_off_at).format('HH:mm A');

      setSelectedDate(isolatedDate); 
      setSelectedTimeSlot(isolatedTime);

      setDropOffAppointment(res.data)
      form.setFieldsValue(res.data);
      setIsNewAppointment(false);
    }

    return;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setIsLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setIsLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            dropOffAppointment.exporters_uuid
            && (item.uuid === dropOffAppointment.exporters_uuid)
          )  
        ) sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
    return;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getDisabledDates = async () => {
    if(isLoadingDisabledDates) return null;
    setIsLoadingDisabledDates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.fromDate = moment().format('YYYY-MM-DD');
    EV.toDate = moment().add(14, 'days').format('YYYY-MM-DD');
    EV.warehouseUuid = dropOffAppointment.warehouses_uuid;
    EV.orderDirection = 'ASC';
    EV.showAll = true;

    var res:any = await EV.getDropOffDisabledDates();
    
    if(res?.data?.length > 0) {
      var tempArray:string[] = [];
      res.data.map((item:any, index:number) => {
        tempArray.push(moment(item.date).format('YYYY-MM-DD'));

        if(res.data.length === tempArray.length) {
          setDisabledDates(tempArray)
        } 
      })
    }

    setIsLoadingDisabledDates(false);
    return;
  }

  const getTimeSlots = async () => {
    if(!selectedDate || isLoadingTimeSlots) return null;
    setIsLoadingTimeSlots(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouseUuid = dropOffAppointment.warehouses_uuid;
    EV.capacity = dropOffAppointment.number_of_items;
    EV.day = moment(selectedDate).format('dddd');
    EV.orderDirection = 'ASC';
    EV.showAll = true;
    
    var res:any = await EV.getDropOffSlots();
    
    setIsLoadingTimeSlots(false);

    if(res?.data?.length > 0) {
      setTimeSlots(res.data)
    }

    return;
  }

  const getDaysDropOffAppointments = async () => {
    if(!selectedDate || isLoadingDaysDropOffAppointments) return null;
    setIsLoadingDaysDropOffAppointments(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.warehouseUuid = dropOffAppointment.warehouses_uuid;
    EV.dropOffAtFrom = moment(selectedDate).toISOString();
    EV.dropOffAtTo = moment(selectedDate).add(1, 'days').toISOString();
    EV.orderDirection = 'ASC';
    EV.showAll = true;
    
    var res:any = await EV.getDropOffAppointments();

    if(res?.data?.length > 0) {
      // loop to create the day booked slots
      var tempBookedSlots:string[] = [];
      
      for(var i = 0; i < res.data.length; i++) {
        tempBookedSlots.push(moment(res.data[i].drop_off_at).format('HH:mm'));
      }
      
      setDaysDropOffAppointments(tempBookedSlots);
    }

    setIsLoadingDaysDropOffAppointments(false);
    return;
  }

  const processSubmit = async () => {
    if(isProcessing) return;

    // validate input
    var formValidation:string[] = [];

    if(!dropOffAppointment.exporters_uuid) {
      formValidation.push(
        'Must select an exporter.'
      );  
    }

    if(!dropOffAppointment.warehouses_uuid) {
      formValidation.push(
        'Must select a warehouse / drop off location.'
      );  
    }

    if(
      !dropOffAppointment.number_of_items
      || dropOffAppointment.number_of_items < 1
    ) {
      formValidation.push(
        'Must enter a valid number of items larger than 0.'
      );  
    }

    if(!selectedDate) {
      formValidation.push(
        'Must select a date.'
      );  
    }

    if(!selectedTimeSlot) {
      formValidation.push(
        'Must select a time slot.'
      );  
    }

    if(!dropOffAppointment.trucking_company_name) {
      formValidation.push(
        'Must provide the trucking company name.'
      );  
    }

    if(!dropOffAppointment.driver_full_name) {
      formValidation.push(
        'Must provide the driver\'s full name.'
      );  
    }

    if(!dropOffAppointment.contact_phone) {
      formValidation.push(
        'Must provide a contact phone number.'
      );  
    }

    if(!dropOffAppointment.contact_email) {
      formValidation.push(
        'Must provide a contact email.'
      );  
    }

    if(formValidation.length > 0) {
      setErrors(formValidation);
      return;
    }

    var dropOffAt = selectedDate + ' '
      + moment(selectedTimeSlot, 'h:mm A').format('HH:mm');
      
    // validate the phone number
    // validate the email address

    // start processing
    setIsProcessing(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.drop_off_at = dropOffAt;
    
    // handle cleared fields
    if(!isNewAppointment) {
      // check clearables
      dropOffAppointment.clear = setClearFormFields({
        ...dropOffAppointment,
        drop_off_at: dropOffAt
      })||undefined;
    }

    if(
      (statuses?.length > 0)
      && !dropOffAppointment.modules_records_statuses_uuid
    ) {
      dropOffAppointment.modules_records_statuses_uuid = statuses[0].value
    }
    
    EV = Object.assign(EV, dropOffAppointment);

    var res:any = null;

    if(dropOffAppointment.uuid && !isNewAppointment) res = await EV.updateDropOffAppointment();
    else res = await EV.createDropOffAppointment();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!dropOffAppointment.uuid) {
        setDropOffAppointment({
          ...dropOffAppointment,
          uuid: res.data.uuid
        });

        navigate('/drop-off/appointments/form/' + res.data.uuid, {replace: true});
      }

      setIsNewAppointment(false);
    }

    setIsProcessing(false);
    return;
  }

  const deleteDropOffAppointment = async () => {
    if(isProcessing) return;
    setIsProcessing(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = dropOffAppointment.uuid;
    
    EV = Object.assign(EV, dropOffAppointment);

    var res:any = await EV.deleteDropOffAppointment();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate('/drop-off/appointments/', {replace: true});
      setIsNewAppointment(false);
    }

    setIsProcessing(false);
    return;
  }

  const _renderDays = () => {
    if(selectedDate) {
      return <div>
        <span>{moment(selectedDate).format('dddd Do MMMM, YYYY')} - </span>
        <a onClick={() => {
          setDropOffAppointment({
            ...dropOffAppointment,
            drop_off_at: undefined
          });

          setSelectedDate(undefined);
          setSelectedTimeSlot(undefined);
          setTimeSlots([]);
        }}>Change</a>
      </div>
    }

    var items =[];
    var day:any = moment().format('YYYY-MM-DD');

    for(var i = 0; i <= 14; i++) {
      // move date on
      day = moment().add(i, 'days');
      var textColor = 'default';

      if(
        selectedDate
        && selectedDate === day
      ) {
        textColor = green[5];
      }

      // check the date against the disabled dates to make
      // sure it is valide
      
      items.push(<DaySlot 
        hoverable={!disabledDates.includes(day.format('YYYY-MM-DD'))}
        disabled={disabledDates.includes(day.format('YYYY-MM-DD'))}
        textColor={textColor}
        day={day}
        onClick={(pValue:string) => {
          setSelectedDate(pValue)
        }}
      />)
    }

    return items;
  }

  const _renderTimeSlots = () => {
    if(
      isLoadingTimeSlots
      || isLoadingDaysDropOffAppointments
    ) {
      return <div>
        <Skeleton active />
      </div>
    }
    
    if(!selectedDate) {
      return <Alert
        showIcon
        type="warning"
        message='Select a day to list available time slots.'
      />
    }
    
    if(selectedTimeSlot) {
      return <div>
        <span>{moment(selectedTimeSlot, ['HH:mm:ss']).format('h:mm A')} - </span>
        <a onClick={() => {
          setDropOffAppointment({
            ...dropOffAppointment,
            drop_off_at: undefined
          });
          
          setSelectedTimeSlot(undefined)
        }}>Change</a>
      </div>
    }

    if(!timeSlots || timeSlots.length < 1) {
      return <Alert
        showIcon
        type="warning"
        message='No time slots available.'
      />
    }

    return timeSlots.map((item:any, index:number) => {
      // check the date against the disabled dates to make
      // sure it is valide
      return <TimeSlot 
        hoverable={!daysDropOffAppointments.includes(moment(item.time, ['HH:mm:ss']).format('HH:mm'))}
        disabled={daysDropOffAppointments.includes(moment(item.time, ['HH:mm:ss']).format('HH:mm'))}
        time={item.time}
        capacity={item.capacity}
        onClick={(pValue:string) => {
          setSelectedTimeSlot(pValue)
        }}
      />
    });
  }

  return <BlankPage
    isLoading={isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    showSideMenu
    padded
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(
        authorized 
        && !notFound 
        && dropOffAppointment.uuid 
        && !isNewAppointment
      ) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteDropOffAppointment();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(dropOffAppointment?.uuid) ? 'Manage Drop Off Appointment' : 'New Drop Off Appointment'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={dropOffAppointment?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          return <li>{error?.en}</li>
        }

        else return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(undefined)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={dropOffAppointment}
      onFinish={processSubmit}
    >
      <Form.Item
        label={'UUID'} 
        name={'uuid'}
      >
        <Input 
          placeholder={'Auto generated by the system'}
          value={dropOffAppointment?.uuid||undefined}
          disabled={true}
        />
      </Form.Item>

      <Segmented
        block
        style={{textTransform: 'capitalize'}}
        options={statuses}
        value={dropOffAppointment.modules_records_statuses_uuid||0}
        name={'modules_records_statuses_uuid'}
        onChange={(value) => setDropOffAppointment({
          ...dropOffAppointment,
          modules_records_statuses_uuid: value.toString()
        })}
      />

      <div style={{marginBottom: 15}} />

      <Form.Item
        label={'Exporter'} 
        name={'exporters_uuid'}
        required
      >
        <Select 
          allowClear
          showSearch
          placeholder={'Select one'}
          onChange={(value) => setDropOffAppointment({
            ...dropOffAppointment,
            exporters_uuid: value
          })}
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={exporters||[]}
        />
      </Form.Item>

      <Form.Item
        label={'Warehouse'} 
        name={'warehouses_uuid'}
        required
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value) => setDropOffAppointment({
            ...dropOffAppointment,
            warehouses_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={warehouses||[]}
        />
      </Form.Item>

      {(dropOffAppointment.warehouses_uuid) && <>
        <Typography.Title 
          level={2}
          children={'Number of Items'}
        />

        <Alert
          style={{marginBottom: 10}}
          showIcon
          type="info"
          message="Time Slot Capacity"
          description={"The system will only list the time slots "
            + "available that allow capacity equal or less than the "
            + "selected number below."}
        />

        <Form.Item>
          <InputNumber
            changeOnWheel
            min={0}
            value={dropOffAppointment.number_of_items||0}
            onChange={(value) => setDropOffAppointment({
              ...dropOffAppointment,
              number_of_items: value||0
            })}
          />
        </Form.Item>
      </>}

      {(
        dropOffAppointment.warehouses_uuid
        && (Number(dropOffAppointment?.number_of_items) > 0)
      ) && <>
        <Typography.Title 
          level={2}
          children={'Day'}
        />

        { _renderDays() }

        <Typography.Title 
          level={2}
          children={'Time Slot'}
        />

        { _renderTimeSlots() }
      </>}

      {(
        selectedDate
        && selectedTimeSlot
      ) && <>
        <Typography.Title 
          level={2}
          children={'Details'}
        />

        <Form.Item
          label={'Trucking Company Name'} 
          name={'trucking_company_name'}
          required
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setDropOffAppointment({
              ...dropOffAppointment,
              trucking_company_name: e.target.value
            })}
          />
        </Form.Item>

        <Form.Item
          label={'Driver\'s Name'} 
          name={'driver_full_name'}
          required
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setDropOffAppointment({
              ...dropOffAppointment,
              driver_full_name: e.target.value
            })}
          />
        </Form.Item>

        <Form.Item
          label={'Contact Phone'} 
          name={'contact_phone'}
          required
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setDropOffAppointment({
              ...dropOffAppointment,
              contact_phone: e.target.value
            })}
          />
        </Form.Item>

        <Form.Item
          label={'Contact Email'} 
          name={'contact_email'}
          required
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setDropOffAppointment({
              ...dropOffAppointment,
              contact_email: e.target.value
            })}
          />
        </Form.Item>

        {isManagement && <>
          <Form.Item>
            <Space>
              <Button 
                type={"primary"} 
                htmlType={"submit"}
                loading={isProcessing}
              >
                Submit
              </Button>
              <Button type={"default"}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </>}
      </>}
    </Form>
  </BlankPage>
}