/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, ReactElement } from 'react';

import { ApiFilled, AppstoreAddOutlined, CheckCircleFilled, CheckOutlined, ClockCircleFilled, CloseCircleFilled, CloseCircleOutlined, DeleteFilled, FireTwoTone, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, PlusCircleOutlined, ReloadOutlined, SearchOutlined, SendOutlined, ThunderboltFilled, ThunderboltTwoTone, WarningFilled } from "@ant-design/icons";
import { Alert, Button, Card, Col, DatePicker, Divider, Form, Input, List, Menu, message, Modal, Pagination, Row, Segmented, Select, Space, Switch, Table, Tabs, Tag, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import Documents from '../../common/documents';
import Comments from '../../common/comments';
import { filterGeoArray } from '../../lib/cities';
import { setClearFormFields } from '../../util/clear_empty';
import SelectionStyledOption from '../../common/SelectionStyledOption';
import { CompanySettingsProps } from '../../common/ts_props/company';
import { displayName } from '../../lib/ev_lib/builders';
import { URLQuery } from '../../util/url_queries';
import { convertSystemSettingsArrayDatasetToObject } from '../../lib/ev_lib/data_processors';
import { gold, lime, red, yellow } from '@ant-design/colors';
import { userSettings } from '../../lib/user_settings';

export interface CargoProps {
  uuid: string|undefined,
  reference_id?: string|null,
  packing_orders_uuid?: string|null,
  warehouses_uuid?: string|null,
  exporters_uuid?: string|null,
  exporters_primary_contact_uuid?: string|null,
  exporters_primary_contact_email?: string|null,
  exporters_consignees_uuid?: string|null,
  modules_records_statuses_uuid?: string|null,
  date_in?:string|string[],
  date_in_obj?: any,
  destination_country?: string|null,
  destination_state?: string|null,
  destination_city?: string|null,
  type?: 'general'|'vehicle'|null,
  is_owner_known?: 'y'|'n'|null,
  quantity?: string|null,
  dimensions?: string|null,
  value?: string|null,
  weight?: string|null,
  insurance_value?: string|null,
  insurance_type?: 'partial coverage'|'full coverage'|'total loss'|'none'|null,
  mbl_notes?: string|null,
  public_notes?: string|null,
  private_notes?: string|null,
  vin_number?: string|null,
  year?: number|string|null,
  make?: string|null,
  model?: string|null,
  fuel_type?: 'gasoline'|'hybrid'|'electric'|'',
  color?: string|null,
  mileages?: number|string|null,
  number_of_keys?: number|string|null,
  has_title?: 'y'|'n'|null,
  title?: string|null,
  title_country?: string|null,
  title_state?: string|null,
  lot_number?: string|null,
  is_salvage?: 'y'|'n'|null,
  is_dismantled?: 'y'|'n'|null,
  is_running?: 'y'|'n'|null,
  documents?: any[]|null,
  is_public?: 'y'|'n'|null,
  clear?: string[]|undefined
}

export default function CargoesForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  /**
   * Set default values
   * Load user and company settings for default values setup
   */

  let companySettings:CompanySettingsProps|string|undefined|null = localStorage.getItem('company_settings');
  

  if(companySettings) {
    companySettings = JSON.parse(companySettings);
  }

  let defaultDate = undefined;
  let defaultAccessStatus:'y'|'n'|undefined = undefined;
  let defaultTitleCountry:string|undefined = undefined;

  if(
    userSettings('auto_fill_cargo_date_in') === 'y'
  ) {
    defaultDate = dayjs();
  }

  if(
    typeof companySettings === 'object'
    && companySettings?.cargoes_default_is_public
  ) {
    switch(companySettings.cargoes_default_is_public) {
      case 'n':
        defaultAccessStatus = 'n';
        break;

      case 'y':
        defaultAccessStatus = 'y';
        break;

      case 'default':
      default:
        defaultAccessStatus = 'n';
        break;
    }
  }

  if(
    typeof companySettings === 'object'
    && companySettings?.cargoes_preselect_title_country
  ) {
    defaultTitleCountry = companySettings?.cargoes_preselect_title_country;
  }

  /**
   * End of setting up default values
   */
  
  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [mailingForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isNotifyingExporter, setIsNotifyingExporter] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);
  
  const [isLoadingOptimizationSettings, setIsLoadingOptimizationSettings] = useState<boolean>(false);
  const [optimizationSettings, setOptimizationSettings] = useState<any>(undefined);
  
  const [newCargo, setNewCargo] = useState<boolean>(true);
  const [isCheckingReferenceId, setIsCheckingReferenceId] = useState<boolean>(false);
  const [referenceIdAvailable, setReferenceIdAvailable] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [cargo, setCargo] = useState<CargoProps>({
    uuid: uuid||undefined,
    type: 'general',
    date_in_obj: defaultDate,
    is_public: defaultAccessStatus||'n',
    title_country: defaultTitleCountry
  });

  const [relatedEmailingList, setRelatedEmailingList] = useState<any[]>([]);

  const [cargoesStatuses, setCargosStatuses] = useState<any[]>([]);
  const [isLoadingCargoesStatuses, setisLoadingCargoesStatuses] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [isDecoding, setIsDecoding] = useState<boolean>(false);
  const [showDecoderConfirmation, setShowDecoderConfirmation] = useState<boolean>(false);
  const [decodedValue, setDecodedValue] = useState<any>(null);
  const [showTrims, setShowTrims] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCargoesStatuses();
    getWarehouses();
    getExporters();

    getCities();
    getStates();
    getCountries();

    getSettingsOptimization();

    if(uuid) getCargo();
  }, []);

  useEffect(() => {
    checkCargoeReferenceIdAvailability();
  }, [cargo.reference_id]);

  /**
   * Rerender the exporters selection list
   * This code was intreduced to show the exporter selection
   * if it was deactivated after it was already selected
   * for a cargo.
   */

  useEffect(() => {
    getExporters()
  }, [newCargo]);

  useEffect(() => {
    getCargoRelatedEmailingList();
  }, [
    cargo.exporters_uuid,
    cargo.exporters_consignees_uuid,
    cargo.warehouses_uuid,
  ]);

  const getSettingsOptimization = async () => {
    if(isLoadingOptimizationSettings) return null;
    setIsLoadingOptimizationSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }

    else if(res.code === 200) {
      var data = res.data;
      
      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);
      setOptimizationSettings(tempSettings);
    }

    setIsLoadingOptimizationSettings(false);
    return;
  }

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getCargoesStatuses = async () => {
    if(isLoadingCargoesStatuses) return null;
    setisLoadingCargoesStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'cargoes';
    EV.orderDirection = 'ASC';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setisLoadingCargoesStatuses(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: item.label,
          highlight_type: item.highlight_type,
          highlight_color: item.highlight_color
        });
      });
    }

    setCargosStatuses(sortedData);
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    setisLoadingWarehouses(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`
        });
      });
    }

    setWarehouses(sortedData);
    return;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setisLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];
    
    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            cargo.exporters_uuid
            && (item.uuid === cargo.exporters_uuid)
          )  
        ) {
          var reformattedUuid = item.uuid.substring(item.uuid.length - 5);

          sortedData.push({
            value: item.uuid,
            searchable: `${item.company_name} ${item.reference_id} ${item.uuid}`,
            label: <SelectionStyledOption
              inactive={item.is_active !== 'y'}
              label={item.company_name}
              tail={`(Ref # ${item.reference_id} | UUID # ...${reformattedUuid})`}
            />
          });
        }
      });
    }

    setExporters(sortedData);
    return;
  }

  const getCargo = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getCargo();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if ([400, 404].includes(res.code)) {
      // record not found
      setNotFound(true);
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var data = res.data;
      
      if(data.date_in) {
        var convertedDate = momentTz(data.date_in).tz(moment.tz.guess());
        data.date_in_obj = dayjs(convertedDate.format())
        
        try {
          data.date_in = convertedDate.format();
          data.date_in = data.date_in.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert date_in time', data.date_in, e
          );
        }
      }
      
      setCargo(data);
      form.setFieldsValue(data);
      setNewCargo(false);
    }

    setIsLoading(false);
    return null;
  }

  const getCargoRelatedEmailingList = async () => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getCargoRelatedEmailingList();
    
    if(res && res.data?.length > 0) {
      var tempArray:any[] = [];

      res.data.map((record:any, key:number) => {
        // clean up the tags
        if(record.tags) {
          record.tags = record.tags.replaceAll("[", "");
          record.tags = record.tags.replaceAll("]", "");
          record.tags = record.tags.replaceAll("\"", "");
        }
        
        var companyName = record.company_name || record.name;

        tempArray.push({
          value: record.email,
          searchable: `${companyName} ${record.first_name} ${record.last_name} ${record.email} ${record.tags} ${record.uuid}`,
          label: <SelectionStyledOption
            layout={2}
            label={record.company_name}
            tail={displayName(
              record.name_prefix,
              record.first_name,
              record.last_name
            ) + (' <' + record.email + '>') as any}
          />
        })
      });

      setRelatedEmailingList(tempArray);
    }

    return;
  }

  const vinDecoder = async (skipHistory?:boolean) => {
    if(
      isDecoding 
      || !cargo.vin_number
      || cargo.vin_number.length != 17
    ) return null;

    setIsDecoding(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.vin_number = cargo.vin_number;

    if(skipHistory) {
      EV.skip_history_check = 'y';
    }
    
    var res:any = await EV.vinDecoder();
    
    if(res && res.status === 'success') {
      var data = res.data;
      // convert lbs to kg
      if(data.weight) {
        var weight = data.weight;
        var unit = data.weight.split(' ')[1];

        if(unit === 'pounds') {
          weight = parseFloat(data.weight.split(' ')[0]) * 0.45359237
          weight = weight.toFixed(2)
        }

        data = {
          ...data,
          weight: weight
        }
      }

      // process fuel type
      var primaryFuel = data?.fuel_type_primary;
      var secondaryFuel = data?.fuel_type_secondary;
      var fuelType:'gasoline'|'hybrid'|'electric'|'' = '';

      if (primaryFuel) primaryFuel = primaryFuel.toLowerCase(); 
      if (secondaryFuel) secondaryFuel = secondaryFuel.toLowerCase();

      if(
        primaryFuel 
        && !secondaryFuel
        && primaryFuel === 'gasoline'
      ) { fuelType = 'gasoline' }

      else if (
        primaryFuel
        && secondaryFuel
        && primaryFuel === 'gasoline'
        && secondaryFuel === 'electric'
      ) { fuelType = 'hybrid' }

      else if (
        primaryFuel
        && !secondaryFuel
        && primaryFuel === 'electric'
      ) { fuelType = 'electric' }

      data = {
        ...data,
        fuel_type: fuelType
      }
      
      // auto select base trim
      if(
        !weight
        && data.trims?.length > 0
      ) {
        var baseWeight = 0;
        var height, width, length = undefined;

        for(var i = 0; i < data.trims.length; i++) {
          console.log('Number(data.trims[i].weight)', Number(data.trims[i].weight))
          if(!baseWeight) {
            baseWeight = Number(data.trims[i].weight);
            height = data.trims[i].height;
            width = data.trims[i].width;
            length = data.trims[i].length;
          }

          else if(
            data.trims[i].weight 
            && Number(data.trims[i].weight) < baseWeight
          ) {
            baseWeight = data.trims[i].weight;
            height = data.trims[i].height;
            width = data.trims[i].width;
            length = data.trims[i].length;
          }
        }

        data = {
          ...data,
          trims: undefined,
          weight: baseWeight,
          height: height,
          width: width,
          length: length
        }
      }
      
      setShowDecoderConfirmation(true);
      setDecodedValue(data)
    }

    else if (res.errors) {
      messageApi.open({
        type: 'error',
        content: 'Failed to process the VIN number'
      })

      setErrors(res.errors);
    }

    setIsDecoding(false);
  }

  const checkCargoeReferenceIdAvailability = async () => {
    setIsCheckingReferenceId(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.reference_id = cargo.reference_id;
    
    var res:any = await EV.checkCargoeReferenceIdAvailability();
    
    if(
      res 
      && res.status === 'success'
    ) {
      setReferenceIdAvailable(true);
    }
    else {
      setReferenceIdAvailable(false);
    }

    setIsCheckingReferenceId(false);
    return;
  }

  const processSubmit = async (customValues:any) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(!newCargo) {
      // check clearables
      cargo.clear = setClearFormFields(cargo)||undefined;console.log('cargo.clear', cargo.clear)
    }
    
    EV = Object.assign(EV, cargo);

    var res:any = null;

    if(cargo.uuid && !newCargo) res = await EV.updateCargo();
    else res = await EV.createCargo();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!cargo.uuid) {
        setCargo({
          ...cargo,
          uuid: res.data.uuid
        });

        navigate('/inventory/cargoes/form/' + res.data.uuid, {replace: true});
      }

      setNewCargo(false);
    }

    setIsProcessing(false);
    return null;
  }

  const emailCargo = async (replyTo:string, to:string, cc:string) => {
    if(isNotifyingExporter) return null;
    setIsNotifyingExporter(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = cargo?.uuid;
    EV.to = to;
    EV.cc = cc;
    EV.replyTo = replyTo;

    // call the function by the type
    var res:any = await EV.emailCargoes();

    // handle the results
    if(res && res.status === 'success') {
      mailingForm.resetFields();

      setLayoutModal({open: false});
      
      messageApi.open({
        type: 'success',
        content: res.message?.en
      });
    }
    else if(res.errors) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <div><Typography.Text>Errors submitting your data:</Typography.Text></div>
          <ul>{res.errors.map((error:any, key:number) => {
            if(typeof error === 'object') {
              error = error.en
            }

            return <li>{error}</li>;
          })}</ul>
        </>],
        footer: [<Button
          type={'default'}
          children={'Ok'}
          onClick={() => setLayoutModal({open: false})}
        />]
      })
    }

    setIsNotifyingExporter(false);
    return null;
  }

  const handleEmailingModal = () => {
    var title = 'Email Cargo';
    var replyTo:any = null;
    var to:any = null;
    var cc:any = null;

    var profileEmail = null;

    var profile:any = localStorage.getItem('profile');

    if(profile) {
      try {
        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }

        profileEmail = profile.email;
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') {
          console.error(
            'Failed to parse user profile',
            e
          );
        }
      }
    }

    var replyToOptions = [
      { label: 'System Default', value: null },
      { label: `Me - ${profileEmail}`, value: profileEmail }
    ];

    var children = [
      <Alert
        style={{marginBottom: 15}}
        type='warning'
        showIcon
        message={'Before sending to external users'}
        description={'Please make sure the record privacy is set to "Public" '
          + 'before including non-company staff emails such as '
          + 'clients, exporters or non-users.'}
      />,
      <Form
        form={mailingForm}
        layout="horizontal"
        labelCol={{span: 4}}
        style={{minWidth: '100%'}}
      >
        <Form.Item
          label={'Reply to'} 
          name={'reply_to'}
        >
          <Select
            placeholder={'Select one'}
            onChange={(value) => replyTo = value}
            options={replyToOptions}
          />
          <div style={{marginTop: 10}}>
            Recommended to leave this to the default value unless
            expected to get a response from any of the receivers.
          </div>
        </Form.Item>
        <Form.Item
          label={'To'} 
          name={'to'}
          required
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => to = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
        <Form.Item
          label={'CC'} 
          name={'cc'}
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => cc = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
      </Form>
    ];

    setLayoutModal({
      open: true,
      title: title,
      onCancel: () => {
        mailingForm.resetFields();
        setLayoutModal({open: false});
      },
      children: children,
      footer: [
        <Button
          type='default'
          children='Cancel'
          onClick={() => {
            mailingForm.resetFields();
            setLayoutModal({open: false});
          }}
        />,
        <Button
          type='primary'
          children='Email'
          icon={<SendOutlined />}
          onClick={() => emailCargo(replyTo, to, cc)}
        />,
      ]
    })
  }

  const _renderReferenceIdCheck = () => {
    if(referenceIdAvailable) {
      return <CheckOutlined style={{color: 'green', fontSize: 20}} />
    }

    else if(isCheckingReferenceId) {
      return <LoadingOutlined style={{color: 'gray', fontSize: 20}} />
    }

    else {
      return <CloseCircleFilled style={{color: 'red', fontSize: 16}} />
    }
  }

  const deleteCargo = async () => {
    if(isDeleting) return;
    setIsDeleting(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = cargo?.uuid;

    var res:any = await EV.deleteCargo();
    // var res:any = false;

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }
  
  return <BlankPage
    isLoading={isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    showSideMenu
    padded
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(cargo.packing_orders_uuid) && <>
        <Button
          type={'default'}
          icon={<AppstoreAddOutlined />}
          children={'Packing Order'}
          onClick={() => navigate(`/containers/packing-orders/view/${cargo.packing_orders_uuid}`)}
        />
      </>}
      {(authorized && !notFound) && <>
        <Button
          type={'primary'}
          icon={<MailFilled />}
          children={(!isNotifyingExporter) ? 'Email' : 'Please wait ...'}
          onClick={handleEmailingModal}
        />
      </>}
      {(authorized && !notFound && cargo.uuid && !newCargo) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteCargo();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
    rightSideComponent={() => {
      return <Comments 
        module={'cargoes'}
        moduleUuid={cargo.uuid}
      />
    }}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(cargo?.uuid) ? 'Manage Cargo' : 'New Cargo'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={cargo?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          return <li>{error?.en}</li>
        }

        else return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      requiredMark={'optional'}
      initialValues={cargo}
      onFinish={processSubmit}
    >
      
      <Row gutter={10}>
        <Col span={16}>
          <Form.Item
            label={'Reference #'} 
            name={'reference_id'}
            tooltip={
              'The company internal identification number' 
              + ' for documentation and other purposes'
            }
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setCargo({
                ...cargo,
                reference_id: e.target.value
              })}
              addonAfter={_renderReferenceIdCheck()}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Access'} 
            name={'is_public'}
            tooltip={'Private records are only accessable to '
              + 'management. Public records are accessable '
              + 'to clients as well.'
            }
          >
            <Select 
              placeholder={'Select one'}
              defaultValue={cargo?.is_public||'n'}
              onChange={(value) => setCargo({
                ...cargo,
                is_public: value
              })}
              options={[
                { value: 'n', label: 'Private' },
                { value: 'y', label: 'Public' }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Segmented
        block
        style={{textTransform: 'capitalize'}}
        options={cargoesStatuses}
        value={cargo.modules_records_statuses_uuid||0}
        name={'modules_records_statuses_uuid'}
        onChange={(value) => setCargo({
          ...cargo,
          modules_records_statuses_uuid: value.toString()
        })}
      />

      <div className={'compact-view-hide'} style={{marginBottom: 15}} />

      <Form.Item
        label={'Exporter'} 
        name={'exporters_uuid'}
        tooltip={
          'The exporters dropdown include the Reference ID number '
          + 'and the last 5 digits of the record UUID.'
        }
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value) => setCargo({
            ...cargo,
            exporters_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(exporters||[])}
        />
      </Form.Item>

      <Typography.Title 
        level={2} 
        children={'Storage'}
        className={'compact-view-hide'}
      />
      
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Date In'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={cargo.date_in_obj}
              onChange={(date, dateString) => setCargo({
                ...cargo,
                date_in: dateString,
                date_in_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label={'Warehouse'} 
            name={'warehouses_uuid'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setCargo({
                ...cargo,
                warehouses_uuid: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={warehouses||[]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title 
        level={2} 
        children={'Destination'} 
        className={'compact-view-hide'}
      />

      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Country'} 
            name={'destination_country'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setCargo({
                ...cargo,
                destination_country: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countries||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'State'}
            name={'destination_state'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setCargo({
                ...cargo,
                destination_state: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'state',
                states||[],
                undefined,
                cargo.destination_country
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'City'} 
            name={'destination_city'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setCargo({
                ...cargo,
                destination_city: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'city',
                cities||[],
                cargo.destination_state,
                cargo.destination_country
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={'Cargo type'} 
        name={'type'}
      >
        <Select 
          showSearch
          placeholder={'Select one'}
          defaultValue={cargo.type||'general'}
          onChange={(value) => setCargo({
            ...cargo,
            type: value
          })}
          options={[
            { value: 'general', label: 'General' },
            { value: 'vehicle', label: 'Vehicle' }
          ]}
        />
      </Form.Item>

      {(cargo.type === 'vehicle') && <>
        <Typography.Title 
          level={2} 
          children={'Vehicle Information'} 
          className={'compact-view-hide'}
        />

        <Form.Item
          label={'VIN #'}
          name={'vin_number'}
          className='vinDecoderInput'
          rules={[
            {
              type: 'string',
              len: 17,
              message: "Must be 17 charechters and digits"
            }
          ]}
        >
          <Input
            status={(cargo.vin_number && cargo.vin_number.length > 0 && cargo.vin_number.length !== 17) ? 'error' : undefined}
            placeholder={'123...'}
            onChange={(e) => setCargo({
              ...cargo,
              vin_number: e.target.value
            })}
            addonAfter={<Button 
              type='link' 
              icon={<SearchOutlined />}
              size='small'
              children={'Decode'}
              onClick={() => vinDecoder(false)}
            />}
          />
        </Form.Item>
        <Modal 
          title="VIN Decoder Results"
          open={showDecoderConfirmation}
          onOk={() => {
            // handle decoded value
            setCargo({
              ...cargo,
              make: decodedValue?.make,
              model: decodedValue?.model,
              year: decodedValue?.year,
              weight: decodedValue?.weight,
              dimensions: `${decodedValue?.height} x ${decodedValue?.width} x ${decodedValue?.length}`,
              fuel_type: decodedValue?.fuelType
            });

            form.setFieldValue('make', decodedValue?.make);
            form.setFieldValue('model', decodedValue?.model);
            form.setFieldValue('year', decodedValue?.year);
            form.setFieldValue('weight', decodedValue?.weight);
            form.setFieldValue('dimensions', `${decodedValue?.height} x ${decodedValue?.width} x ${decodedValue?.length}`);
            form.setFieldValue('fuel_type', decodedValue?.fuel_type);
          
            setShowDecoderConfirmation(false);
          }} 
          onCancel={() => setShowDecoderConfirmation(false)}
        >
          {(decodedValue?.from_history) && <>
            <Alert
              type='warning'
              showIcon
              message={'Record Retrived From History'}
              description={
                'This record has been retrived from a previous '
                + 'attempt to decod the same VIN number. If '
                + 'you believe the VIN has new information registered '
                + 'Press here to decode the VIN again wihtout '
                + 'checking the history.'
              }
            />
            <br />
            <Button 
              size={'middle'} 
              type='primary'
              icon={<ReloadOutlined color={'white'} />}
              onClick={() => vinDecoder(true)}
            >
              Decode Again Without History Check 
            </Button>
          </>}

          <p>Here are the information found on the VIN # you 
            provided. Click okay to link the data to your cargo 
            or cancel to try again.</p>

          <Row gutter={[15,15]}>
            <Col span={8}>
              <div className='info-label'>Make:</div>
              <div>{decodedValue?.make}</div>
            </Col>
            <Col span={8}>
              <div className='info-label'>Model:</div>
              <div>{decodedValue?.model}</div>
            </Col>
            <Col span={8}>
              <div className='info-label'>Year:</div>
              <div>{decodedValue?.year}</div>
            </Col>

            <Col span={8}>
              <div className='info-label'>Fuel Type:</div>
              <div style={{marginTop: 5}}>
                {(decodedValue?.fuel_type_primary === 'Gasoline') && <FireTwoTone
                  twoToneColor={red[4]}
                  style={{fontSize: 22}}
                />}
                {(decodedValue?.fuel_type_primary === 'Electric') && <ThunderboltTwoTone
                  twoToneColor={lime[5]}
                  style={{fontSize: 22}}
                />}
                {(decodedValue?.fuel_type_secondary === 'Gasoline') && <FireTwoTone
                  twoToneColor={red[4]}
                  style={{fontSize: 22}}
                />}
                {(decodedValue?.fuel_type_secondary === 'Electric') && <ThunderboltTwoTone
                  twoToneColor={lime[5]}
                  style={{fontSize: 22}}
                />}
              </div>
            </Col>
            <Col span={16}>
              <div className='info-label'>Electric Type:</div>
              {(decodedValue?.electrification_level) 
              ? <div style={{marginTop: 5}}>
                <Tag 
                  color={'lime'}
                  icon={<ThunderboltFilled />}
                  children={decodedValue?.electrification_level}
                />
              </div>
              : <div>
                N/A
              </div>}
            </Col>

            {(!decodedValue?.trims || decodedValue?.trims.length < 1) && <>
              <Col span={8}>
                <div className='info-label'>weight:</div>
                <div>{decodedValue?.weight}</div>
              </Col>
              <Col span={8}>
                <div className='info-label'>Dimensions:</div>
                <div>{decodedValue?.height} x {decodedValue?.width} x {decodedValue?.length}</div>
              </Col>
            </>}
          </Row>

          {(!decodedValue?.weight) && <Divider children={'Found Trims'} />}

          {(
            (!decodedValue?.trims || decodedValue.trims.length < 1)
            && (!decodedValue?.weight)
           ) && <>
            <Alert
              type='warning'
              showIcon
              message={'No Trims Found'}
              description={
                'No trims found for this specified VIN number'
              }
            />
          </>}

          {decodedValue?.trims?.map((trim:any, index:number) => {
            return <Card 
              hoverable
              style={{marginBottom: 15}}
              onClick={() => {
                // handle decoded value
                setCargo({
                  ...cargo,
                  make: decodedValue?.make,
                  model: decodedValue?.model,
                  year: decodedValue?.year,
                  weight: trim?.weight,
                  dimensions: `${trim?.height} x ${trim?.width} x ${trim?.length}`,
                  fuel_type: decodedValue?.fuel_type
                });
    
                form.setFieldValue('make', decodedValue?.make);
                form.setFieldValue('model', decodedValue?.model);
                form.setFieldValue('year', decodedValue?.year);
                form.setFieldValue('weight', trim?.weight);
                form.setFieldValue('dimensions', `${trim?.height} x ${trim?.width} x ${trim?.length}`);
                form.setFieldValue('fuel_type', decodedValue?.fuel_type);
                
                setShowDecoderConfirmation(false);
              }} 
            >
              <Row gutter={[15,15]}>
                <Col span={24}>
                  <div>
                    {trim.description}
                  </div>
                </Col>
                <Col span={6}>
                  <div className='info-label'>Weight:</div>
                  <div>{trim.weight} KG</div>
                </Col>
                <Col span={6}>
                  <div className='info-label'>Engine:</div>
                  <div>{trim.engine_type}</div>
                </Col>
                <Col span={4}>
                  <div className='info-label'>Width:</div>
                  <div>{trim.width}</div>
                </Col>
                <Col span={4}>
                  <div className='info-label'>Height:</div>
                  <div>{trim.height}</div>
                </Col>
                <Col span={4}>
                  <div className='info-label'>Length:</div>
                  <div>{trim.length}</div>
                </Col>
              </Row>
            </Card>
          })}
              
        </Modal>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item
              label={'Make'} 
              name={'make'}
            >
              <Input 
                placeholder={'Make'}
                onChange={(e) => setCargo({
                  ...cargo,
                  make: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={'Model'} 
              name={'model'}
            >
              <Input 
                placeholder={'...'}
                onChange={(e) => setCargo({
                  ...cargo,
                  model: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={'Fuel Type'} 
              name={'fuel_type'}
            >
              <Select 
                showSearch
                placeholder={'Select one'}
                onChange={(value) => {
                  setCargo({
                    ...cargo,
                    fuel_type: value||''
                  })
                }}
                options={[
                  { value: 'gasoline', label: 'Gasoline' },
                  { value: 'hybrid', label: 'Hybrid' },
                  { value: 'electric', label: 'Electric' }
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={'Year'} 
              name={'year'}
            >
              <Input 
                placeholder={'...'}
                onChange={(e) => setCargo({
                  ...cargo,
                  year: e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item
              label={'Number of Keys'} 
              name={'number_of_keys'}
            >
              <Input 
                placeholder={'0'}
                onChange={(e) => setCargo({
                  ...cargo,
                  number_of_keys: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={'Color'} 
              name={'color'}
            >
              <Input 
                placeholder={'...'}
                onChange={(e) => setCargo({
                  ...cargo,
                  color: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={'Mileages'} 
              name={'mileages'}
            >
              <Input 
                placeholder={'...'}
                onChange={(e) => setCargo({
                  ...cargo,
                  mileages: e.target.value
                })}
              />
            </Form.Item>
        </Col>  
        </Row>

        <Form.Item
          label={'LOT #'} 
          name={'lot_number'}
        >
          <Input 
            placeholder={'...'}
            onChange={(e) => setCargo({
              ...cargo,
              lot_number: e.target.value
            })}
          />
        </Form.Item>

        <Form.Item
          label={'Has a Title'} 
          name={'has_title'}
        >
          <Select 
            showSearch
            placeholder={'Select one'}
            onChange={(value) => {
              var updateObj = {
                ...cargo,
                has_title: value
              }

              if(value === 'n') {
                form.setFieldValue('title', '');
                form.setFieldValue('title_country', '');
                form.setFieldValue('title_state', '');
              }

              setCargo(updateObj)
            }}
            options={[
              { value: 'n', label: 'No' },
              { value: 'y', label: 'Yes' }
            ]}
          />
        </Form.Item>
        {(cargo?.has_title === "y") && <>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                label={'Title #'} 
                name={'title'}
              >
                <Input 
                  placeholder={'0'}
                  onChange={(e) => setCargo({
                    ...cargo,
                    title: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'County'} 
                name={'title_country'}
              >
                <Select 
                  showSearch
                  allowClear
                  placeholder={'Select one'}
                  onChange={(value) => setCargo({
                    ...cargo,
                    title_country: value||''
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={countries||[]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'State'} 
                name={'title_state'}
              >
                <Select 
                  showSearch
                  allowClear
                  placeholder={'Select one'}
                  onChange={(value) => setCargo({
                    ...cargo,
                    title_state: value||''
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterGeoArray(
                    'state',
                    states||[],
                    undefined,
                    cargo.title_country
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </>}

        <Form.Item
          label={'Does the vehicle run?'} 
          name={'is_running'}
        >
          <Select 
            showSearch
            allowClear
            placeholder={'Select one'}
            onChange={(value) => setCargo({
              ...cargo,
              is_running: value||''
            })}
            options={[
              { value: 'n', label: 'No' },
              { value: 'y', label: 'Yes' }
            ]}
          />
        </Form.Item>

        <Form.Item
          label={'Is the vehicle salvage?'} 
          name={'is_salvage'}
        >
          <Select 
            showSearch
            allowClear
            placeholder={'Select one'}
            onChange={(value) => setCargo({
              ...cargo,
              is_salvage: value||''
            })}
            options={[
              { value: 'n', label: 'No' },
              { value: 'y', label: 'Yes' }
            ]}
          />
        </Form.Item>

        <Form.Item
          label={'Is the vehicle dismantled?'} 
          name={'is_dismantled'}
        >
          <Select 
            showSearch
            allowClear
            placeholder={'Select one'}
            onChange={(value) => setCargo({
              ...cargo,
              is_dismantled: value||''
            })}
            options={[
              { value: 'n', label: 'No' },
              { value: 'y', label: 'Yes' }
            ]}
          />
        </Form.Item>
      </>}

      <Typography.Title 
        level={2} 
        children={'General Information'} 
        className={'compact-view-hide'}
      />

      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            label={'Quantity'} 
            name={'quantity'}
          >
            <Input 
              placeholder={'Quantity'}
              onChange={(e) => setCargo({
                ...cargo,
                quantity: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Value'} 
            name={'value'}
          >
            <Input 
              placeholder={'0.00'}
              addonAfter={'USD'}
              onChange={(e) => setCargo({
                ...cargo,
                value: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Weight'} 
            name={'weight'}
          >
            <Input 
              placeholder={'0.00'}
              addonAfter={'kg'}
              onChange={(e) => setCargo({
                ...cargo,
                weight: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Dimensions (H x W x L)'} 
            name={'dimensions'}
          >
            <Input 
              placeholder={'Dimensions'}
              onChange={(e) => setCargo({
                ...cargo,
                dimensions: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Insurance Type'} 
            name={'insurance_type'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setCargo({
                ...cargo,
                insurance_type: value||''
              })}
              options={[
                {value: 'partial coverage', label: 'Partial Coverage'},
                {value: 'full coverage', label: 'Full Coverage'},
                {value: 'total loss', label: 'Total Loss'},
                {value: '', label: 'None'}
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Insurance Value'} 
            name={'insurance_value'}
          >
            <Input
              placeholder={'0.00'}
              addonAfter={'USD'}
              onChange={(e) => setCargo({
                ...cargo,
                insurance_value: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={10} style={{marginBottom: 20}}>
        {(cargo?.type === 'vehicle' && cargo?.has_title === 'y') && <>
          <Col span={8}>
            <Documents
              purpose={'cargo'}
              purposeUuid={cargo.uuid}
              category={'title'}
              allowManage
              allowUpload
              title={'Title Images & Documents'}
              titleLevel={5}
              optimization={{
                max_width: optimizationSettings?.image_optimization_max_width,
                quality: optimizationSettings?.image_optimization_quality_value
              }}
            />
          </Col>
        </>}
        <Col span={(cargo?.type === 'vehicle' && cargo?.has_title === 'y') ? 16 : 24}>
          <Documents
            purpose={'cargo'}
            purposeUuid={cargo.uuid}
            category={'general'}
            allowManage
            allowUpload
            title={'Cargo Images & Documents'}
            titleLevel={5}
            optimization={{
              max_width: optimizationSettings?.image_optimization_max_width,
              quality: optimizationSettings?.image_optimization_quality_value
            }}
          />
        </Col>
      </Row>

      <Typography.Title 
        level={2} 
        children={'Documentation'} 
        className={'compact-view-hide'}
      />

      <Form.Item
        label={'Master Bill of Lading Notes'} 
        name={'mbl_notes'}
      >
        <TextArea 
          rows={8}
          placeholder={'Type here to add notes to the generated Master Bill of Lading files'}
          onChange={(e) => setCargo({
            ...cargo,
            mbl_notes: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Public Notes'} 
        name={'public_notes'}
        >
        <TextArea
          rows={8}
          placeholder={'Type here to add notes to this record'}
          onChange={(e) => setCargo({
            ...cargo,
            public_notes: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Private Notes'} 
        name={'private_notes'}
        >
        <TextArea
          rows={8}
          placeholder={'Type here to add notes to this record'}
          onChange={(e) => setCargo({
            ...cargo,
            private_notes: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isProcessing}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}