/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Modal, Pagination, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../../../framework/blank_page";
import EVAPI from '../../../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { setClearFormFields } from '../../../../util/clear_empty';
import { URLQuery } from '../../../../util/url_queries';

export default function SettingsIncomeDepositAccountsForm(props:any) {
  interface IncomeDepositAccountProps {
    uuid:string|null,
    name:string|null|undefined,
    description?: string|null|undefined,
    is_active?: 'y'|'n'|null,
    clear?:string[]|undefined
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);
  const [incomeDepositAccount, setIncomeDepositAccount] = useState<IncomeDepositAccountProps>({
    uuid: uuid||null,
    name: null
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    if(uuid) {
      getIncomeDepositAccount();
    }
    
    setIsReady(true);
  }, []);

  const getIncomeDepositAccount = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getIncomeDepositAccount();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setIncomeDepositAccount(res.data);
      form.setFieldsValue(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const processCargoesStatus = async () => {
    if(isLoading) return null;
    setIsLoading(true);
    setErrors(null);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(incomeDepositAccount.uuid) {
      // check clearables
      incomeDepositAccount.clear = setClearFormFields(incomeDepositAccount)||undefined;
    }
    
    Object.assign(EV, incomeDepositAccount);

    var res:any = null;

    if(incomeDepositAccount.uuid) res = await EV.updateIncomeDepositAccount();
    else res = await EV.createIncomeDepositAccount();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!incomeDepositAccount.uuid) {
        setIncomeDepositAccount({
          ...incomeDepositAccount,
          uuid: res.data.uuid
        });

        navigate('/system/settings/accounting/income-deposit-accounts/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const deleteIncomeDepositAccount = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = incomeDepositAccount?.uuid;
    
    var res:any = await EV.deleteIncomeDepositAccount();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(incomeDepositAccount.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteIncomeDepositAccount();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(incomeDepositAccount?.uuid) ? 'Manage Cargo Status' : 'New Cargo Status'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={incomeDepositAccount?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid,
        name: incomeDepositAccount.name,
        description: incomeDepositAccount.description,
        is_active: incomeDepositAccount.is_active === 'y' ? true : false
      }}
      onFinish={processCargoesStatus}
    >
      <Form.Item
        label={'Name'} 
        name={'name'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setIncomeDepositAccount({
            ...incomeDepositAccount,
            name: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Description'} 
        name={'description'}
      >
        <TextArea 
          placeholder={'Type here'}
          onChange={(e) => setIncomeDepositAccount({
            ...incomeDepositAccount,
            description: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Switch 
          checked={incomeDepositAccount.is_active === 'y' ? true : false} 
          onChange={(e) => setIncomeDepositAccount({
            ...incomeDepositAccount,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}