/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, FormOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Space, Table, Tag, Typography } from "antd";

import BlankPage from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export default function IntermediateConsigneesListing(props:any) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [intermediateConsignees, setIntermediateConsignees] = useState<any>(null);
  
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [isActive, setIsActive] = useState<'y'|'n'|undefined>(undefined);

  useEffect(() => {
    getIntermediateConsignees();
  }, []);
  
  useEffect(() => {
    getIntermediateConsignees();
  }, [searchTerm, isActive]);

  const getIntermediateConsignees = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = isActive;

    var res:any = await EV.getIntermediateConsignees();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          key: item.uuid,
          ...item
        });
      });
    }

    setIntermediateConsignees(sortedData);
    return;
  }

  const columns = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {
      key: 'company_name', 
      title: 'Company Name', 
      dataIndex: 'company_name',
      render: (_:any, record:any) => {
        if(record.is_active !== 'y') {
          return <>
            <Tag>Inactive</Tag>
            <Typography.Text type={'secondary'} delete>{record.company_name}</Typography.Text>
          </>
        }

        return <>
          <Typography.Text>{record.company_name}</Typography.Text>
        </>
      }
    },
    {
      key: 'created_at', 
      title: 'Created', 
      dataIndex: 'created_at',
      width: 170,
      render: (_:any, record:any) => {
        return momentTz(record.created_at)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      key: 'action',
      title: 'Action', 
      width: 100,
      render: (_:any, record:any) => <Space size={'small'}>
        <Button
          type='link'
          icon={<FormOutlined />}
          title={'Manage'}
          onClick={() => navigate(`/intermediate-consignees/form/${record.uuid}`)}
        />
      </Space>
    },
  ];

  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate(`/intermediate-consignees/form/`)}
      />
    </Space>}
  >
    
    <Typography.Title children={'Intermediate Consignees'} />

    <div style={{marginBottom: 20}} />

    <Row gutter={[15, 15]}>
      <Col span={20}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={4}>
        <Select
          placeholder={'Active Status'}
          style={{width: '100%'}}
          allowClear
          options={[
            { value: 'y', label: 'Active' },
            { value: 'n', label: 'Inactive' },
          ]}
          onChange={(e) => {
            setIsActive(e)
          }}
        />
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={intermediateConsignees} 
      columns={columns} 
    />
  </BlankPage>
}