/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';

import { BorderOuterOutlined, CheckCircleFilled, CheckCircleOutlined, ExpandAltOutlined, EyeOutlined, FormOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, TableOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Input, Menu, Pagination, Row, Segmented, Select, Space, Table, Tag, Typography, message } from "antd";

import BlankPage from "../../framework/blank_page";
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import EVAPI from '../../lib/ev_lib/main';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { filterGeoArray } from '../../lib/cities';
import { gray, yellow } from '@ant-design/colors';
import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../../common/TableColumnsCuztomization';

import TableColumnSortIcon from '../../common/TableColumnSortIcon';
import { resortTable } from '../../lib/table_sorting';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export function PackingPlan(props:any) {
  const navigate = useNavigate();
  
  const warningIcon = <WarningFilled size={20} style={{color: 'orange'}} />

  const _renderDestination = () => {
    var destination = '';

    if(props.destinationCountry) {
      destination += props.destinationCountry;
    }
    

    if(props.destinationCity) {
      if(destination.length > 0) destination += ', ';
      destination += props.destinationCity;
    }

    if(destination.length < 1) {
      destination = 'Destination not set';
    }

    return destination;
  }

  const _renderCargoCount = () => {
    if(props.cargoesUuids) {
      return props.cargoesUuids.split(',').length
    }
    else {
      return 0;
    }
  }

  const _renderDate = (date:string) => {
    if(!date) return '-';

    else {
      date = momentTz(date)
      .tz(moment.tz.guess())
      .format('MM/DD/YYYY hh:mm A');

      return date;
    }
  }

  const _renderTitle = () => {
    var title = '# ';

    if(props.dataSource?.uuid) {
      title += props.dataSource?.uuid?.substr(
        props.dataSource?.uuid?.length - 10
      );
    }
    else {
      title += '-';
    }

    return title;
  }

  const _renderStatus = () => {
    var label = null;
    var color = gray[5];

    if(!props.submittedAt) {
      label = 'Draft';
    }
    else {
      label = props.statusLabel;
      color = props.statusColor;
    }

    return <Tag
      color={color}
      children={label} 
    />
  }

  return <Card 
    style={{marginBottom: 20}}
    title={_renderTitle()} 
    extra={<Space size={'large'}>
      {_renderStatus()}
      <Button
        type='link'
        icon={<FormOutlined />}
        title={'Manage'}
        onClick={() => navigate(`/containers/packing-plans/form/${props.dataSource.uuid}`)}
      />
    </Space>}
  >
    <Row gutter={10}>
      <Col span={6}>
        <div>
          <Typography.Title level={5} children={'Exporter'} />
          <Typography.Text>{props.dataSource?.company_name}</Typography.Text>
        </div>
      </Col>
      <Col span={6}>
        <div>
          <Typography.Title level={5} children={'Number of Cargoes'} />
          <Typography.Text>{_renderCargoCount()}</Typography.Text>
        </div>
      </Col>
      <Col span={6}>
        <div>
          <Typography.Title level={5} children={'Submitted at'} />
          <Typography.Text>{_renderDate(props.submittedAt)}</Typography.Text>
        </div>
      </Col>
      <Col span={6}>
        <div>
          <Typography.Title level={5} children={'Created at'} />
          <Typography.Text>{_renderDate(props.createdAt)}</Typography.Text>
        </div>
      </Col>
    </Row>
    <Row gutter={10}>
      <Col span={6}>
        <div>
          <Typography.Title level={5} children={'Warehouse'} />
          <Typography.Text>{props.warehousesName||'-'}</Typography.Text>
        </div>
      </Col>
      <Col span={18}>
        <div>
          <Typography.Title level={5} children={'Destination'} />
          <Typography.Text>{_renderDestination()}</Typography.Text>
        </div>
      </Col>
    </Row>
  </Card>
}

export default function PackingPlansListing(props:any) {
  interface AdvanceFiltersProps {
    warehouses_uuids?: string|null|undefined,
    exporters_uuid?: string|null|undefined,
    exporters_reference_id?: string|null|undefined,
    modules_records_statuses_uuid?: string|null|undefined,
    destination_country?: string|null|undefined,
    destination_state?: string|null|undefined,
    destination_city?: string|null|undefined,
    cargoes_uuids?: string|null|undefined,
  }

  const navigate = useNavigate();
  const [form] = Form.useForm();

  let profile:any = localStorage.getItem('profile');

  let prefPackingPlansListingView:() => 'table'|'boxes' = () => {
    var stored = localStorage.getItem('prefPackingPlansListingView');
    
    if(stored && stored === 'boxes') return stored;
    else return 'table';
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowCreateNew, setAllowCreateNew] = useState<boolean>(false);

  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);

  const [statuses, setStatuses] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<any>(null);

  const [packingPlans, setPackingPlans] = useState<any[]>([]);
  const [packingPlansCount, setPackingPlansCount] = useState<number>(0);
  const [packingPlansPerPage, setPackingPlansPerPage] = useState<number>(15);
  const [packingPlansCurrentPage, setPackingPlansCurrentPage] = useState<number>(1);

  const [toggleAdvancedFilters, setToggleAdvancedFilters] = useState<boolean>(false);
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({
    modules_records_statuses_uuid: 'submitted'
  });

  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setIsLoadingExporters] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [listingView, setListingView] = useState<'boxes'|'table'>(prefPackingPlansListingView);
  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const [messageApi, contextHolder] = message.useMessage();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'exporter', label: 'Exporter', default: true},
    { id: 'warehouse', label: 'Warehouse', default: true},
    { id: 'number_of_cargoes', label: 'Count', default: true},
    { id: 'destination', label: 'Destination', default: true}
  ];

  useEffect(() => {
    // set the default ResPerPage
    if(localStorage.getItem('prefPackingPlansListingResPerPage')) {
      setPackingPlansPerPage(Number(localStorage.getItem('prefPackingPlansListingResPerPage')));
    }
    
    processPrefTableColumns();

    getCities();
    getStates();
    getCountries();
    getWarehouses();
    getExporters();

    getStatuses();
    getPackingPlans();
    getPackingPlans(true);
  }, []);
  
  useEffect(() => {
    getPackingPlans();
    getPackingPlans(true);
  }, [
    searchTerm, 
    advanceFilters.modules_records_statuses_uuid,
    packingPlansCurrentPage,
    packingPlansPerPage
  ]);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefPackingPlansListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}`
            + `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getStatuses = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'packing plans';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoading(false);

    var data = res.data;

    var sortedData:any = [
      { value: 'submitted', label: 'Submitted' },
      { value: 'draft', label: 'Draft' }
    ];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(item.is_default === 'y') {
          setAdvanceFilters({
            ...advanceFilters,
            modules_records_statuses_uuid: item.uuid
          });
        }

        sortedData.push({
          value: item.uuid,
          label: item.label,
          ...item
        });
      });
    }
    
    setStatuses(sortedData);
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setIsLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setIsLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
    return;
  }
  
  const getPackingPlans = async(count:boolean = false) => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.searchTerm = searchTerm;

    if(advanceFilters.modules_records_statuses_uuid === 'draft') {
      EV.showSubmitted = 'n';
    }
    else if(advanceFilters.modules_records_statuses_uuid === 'submitted') {
      EV.showSubmitted = 'y';
    }
    else {
      EV.showSubmitted = 'y';
      EV.modulesRecordsStatusesUuid = advanceFilters.modules_records_statuses_uuid;
    }

    EV.orderBy = localStorage.getItem('prefPackingPlansListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefPackingPlansListingOrderDirection');

    EV.warehousesUuids = advanceFilters?.warehouses_uuids||undefined;
    EV.destinationCountry = advanceFilters?.destination_country||undefined;
    EV.destinationState = advanceFilters?.destination_state||undefined;
    EV.destinationCity = advanceFilters?.destination_city||undefined;
    EV.exportersUuid = advanceFilters?.exporters_uuid||undefined;
    EV.exportersReferenceId = advanceFilters?.exporters_reference_id||undefined;

    // handle count if set
    EV.count = count;

    var res:any = await EV.getPackingPlans();
    
    if(!res.data) {
      // trigger error
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }
    else if(count) { 
      if(res.data?.count) {
        setPackingPlansCount(Number(res.data.count)); 
      }
      else setPackingPlansCount(0);
    }
    else setPackingPlans(res.data);
    
    setIsLoading(false);
    return;
  }


  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate('/containers/packing-plans/form')}
      />
    </Space>}
  >
    {contextHolder}
    
    <Typography.Title children={'Packing Plans'} />

    <Row gutter={15}>
      <Col>
        <Segmented 
          value={advanceFilters.modules_records_statuses_uuid||0}
          style={{textTransform: 'capitalize'}}
          options={statuses||[]}
          onChange={(value:any) => setAdvanceFilters({
            ...advanceFilters,
            modules_records_statuses_uuid: value
          })}
        />
      </Col>
      <Col>
        <Segmented
          value={listingView}
          options={[
            { value: 'table', icon: <TableOutlined />},
            { value: 'boxes', icon: <BorderOuterOutlined />},
          ]}
          onChange={(value:any) => {
            setListingView(value);
            localStorage.setItem('prefPackingPlansListingView', value);
          }}
        />
      </Col>
      <Col flex={'auto'} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Space>
          <Button
            type={'default'}
            onClick={() => setShowSideMenu(!showSideMenu)}
            icon={<EyeOutlined />}
            children={'Customize View'}
          />
        </Space>
      </Col>
    </Row>

    <TableColumnsCuztomization
      showDrawer={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      onPrcessComplete={processPrefTableColumns}
      localStorageKey='prefPackingPlansListingTableViewColumns'
      tableColumnsList={tableColumnsList}
    />

    <div style={{marginBottom: 20}} />

    <Row gutter={15}>
      <Col span={19}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <Button 
          style={{width: '100%'}}
          type={"default"}
          onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
        >
          Toggle Advanced Filters
        </Button>
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    {(toggleAdvancedFilters) && <>
      <Typography.Title level={3}>
        Advance Search
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={getPackingPlans}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'warehouses_uuids'}
              label={'Warehouses'}
            >
              <Select 
                mode="tags"
                showSearch
                allowClear
                placeholder={'Select one'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  warehouses_uuids: value
                })}
                options={warehouses||[]}
                tokenSeparators={[',']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'exporters_uuid'}
              label={'Exporter'}
            >
              <Select 
                showSearch
                allowClear
                placeholder={'Select one'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  exporters_uuid: value
                })}
                options={exporters||[]}
                tokenSeparators={[',']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              name={'destination_country'}
              label={'Destination Country'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_country: value
                })}
                options={countries||[]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_state'}
              label={'Destination State'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_state: value
                })}
                options={filterGeoArray(
                  'state',
                  states||[],
                  undefined,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_city'}
              label={'Destination City'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_city: value
                })}
                options={filterGeoArray(
                  'city',
                  cities||[],
                  advanceFilters.destination_state,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                >
                  Search
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => {
                    setAdvanceFilters({});
                    form.resetFields();
                    setTimeout(() => getPackingPlans, 500);
                  }}
                >
                  Reset
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
                >
                  Toggle Advanced Filters
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>}

    <div style={{marginBottom: 20}} />

    {(listingView === 'table') ? <>
      <Table
        size={userSettings('compact_view') ? 'small' : undefined}
        scroll={{ x: 1300 }}
        loading={isLoading}
        pagination={{
          total: packingPlansCount||undefined,
          showSizeChanger: true,
          pageSizeOptions: [15, 50, 100, 200],
          defaultPageSize: 15,
          pageSize: packingPlansPerPage,
          onChange: (page:number, pagesSize:number) => {
            localStorage.setItem('prefCargoesListingResPerPage', pagesSize.toString())
            setPackingPlansPerPage(pagesSize);
            setPackingPlansCurrentPage(page);
          }
        }}
        columns={[
          {
            key: 'modules_records_statuses_label', 
            title: 'Status',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => <Tag
              color={record.modules_records_statuses_highlight_color}
              children={record.modules_records_statuses_label} 
            />
          },
          (selectedColumns?.includes('uuid')) ? {
            key: 'uuid',
            title: 'UUID',
            dataIndex: 'uuid',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => {
              return record.uuid.substr(record.uuid.length - 10)
            }
          } : {},
          {
            key: 'exporter',
            title: 'Exporter',
            dataIndex: 'company_name',
            width: 250,
          },
          {
            key: 'warehouses_name', 
            title: 'Warehouse', 
            width: 200, 
            dataIndex: 'warehouses_name'
          },
          {
            key: 'number_of_cargoes', 
            title: '', 
            width: 50, 
            render: (_:any, record:any) => {
              if(record.cargoes_uuids) {
                return record.cargoes_uuids.split(',').length
              }
              else {
                return 0;
              }
            }
          },
          {
            key: 'destination', 
            title: 'Destination', 
            width: 250,
            dataIndex: 'destination',
            render: (_:any, record:any) => {
              var destination = '';
          
              if(record.destination_country) {
                destination += record.destination_country;
              }
              
          
              if(record.destination_city) {
                if(destination.length > 0) destination += ', ';
                destination += record.destination_city;
              }

              return destination || null;
            }
          },
          {
            key: 'created_at', 
            title: 'Created', 
            dataIndex: 'created_at',
            width: 170,
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefPackingPlansListingOrder'}
              columnId={'packing_plans.created_at'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'PackingPlans',
                  'packing_plans.created_at',
                  getPackingPlans
                )
              };
            },
            render: (_:any, record:any) => {
              return momentTz(record.created_at)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY hh:mm A');
            }
          },
          {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 100,
            render: (_:any, record:any) => <Space size={'small'}>
              <Button
                type='link'
                icon={<FormOutlined />}
                title={'Manage'}
                onClick={() => navigate(`/containers/packing-plans/form/${record.uuid}`)}
              />
            </Space>
          }
        ]}
        dataSource={packingPlans||[]}
      />
    </>
    : <>
      {(!packingPlans || packingPlans.length < 1) && <>
        <Empty style={{margin: 100}}/>
      </>}

      {(
        packingPlans 
        && packingPlans.length > 0
      ) && packingPlans.map((packingPlan:any, key:number) => {
        return <PackingPlan
          key={key} 
          dataSource={packingPlan} 
          cargoesUuids={packingPlan.cargoes_uuids}
          warehousesName={packingPlan.warehouses_name}
          destinationCity={packingPlan.destination_city}
          destinationState={packingPlan.destination_state}
          destinationCountry={packingPlan.destination_country}
          statusLabel={packingPlan.modules_records_statuses_label}
          statusColor={packingPlan.modules_records_statuses_highlight_color}
          createdAt={packingPlan.created_at}
          submittedAt={packingPlan.submitted_at}
        />
      })}
    </>}
  </BlankPage>
}