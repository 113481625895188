/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, PushpinFilled, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Modal, Pagination, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { setClearFormFields } from '../../../util/clear_empty';
import { URLQuery } from '../../../util/url_queries';

export default function SettingsModulesRecordsStatusesForm(props:any) {
  interface moduleRecordStatus {
    uuid: string|null,
    module: 'cargoes'|'packing orders'|'containers'|null,
    listing_order?: number|null,
    label: string|null,
    description?: string|null,
    highlight_type?: string|null,
    highlight_color?: string|null,
    is_active?: 'y'|'n'|null,
    is_default?: 'y'|'n'|null,
    clear?:string[]|undefined
  }

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[]|null>(null);
  const [moduleRecordStatus, setModuleRecordStatus] = useState<moduleRecordStatus>({
    uuid: uuid||null,
    module: null,
    label: null
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    if(uuid) {
      getModuleRecordStatus();
    }
    
    setIsReady(true);
  }, []);

  const getModuleRecordStatus = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getModuleRecordStatus();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setModuleRecordStatus(res.data);
      form.setFieldsValue(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const processModuleStatus = async (setDefault:boolean) => {
    if(isLoading) return null;
    setIsLoading(true);
    setErrors(null);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(moduleRecordStatus.uuid) {
      // check clearables
      moduleRecordStatus.clear = setClearFormFields(moduleRecordStatus)||undefined;
    }
    
    Object.assign(EV, moduleRecordStatus);

    if(setDefault) {
      EV.is_default = 'y';
    }

    var res:any = null;

    if(moduleRecordStatus.uuid) res = await EV.updateModuleRecordStatus();
    else res = await EV.createModuleRecordStatus();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!moduleRecordStatus.uuid) {
        setModuleRecordStatus({
          ...moduleRecordStatus,
          uuid: res.data.uuid
        });

        navigate('/system/settings/modules-records-statuses/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const getRandomColor = () => {
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    return '#' + randomColor;
  }

  const deleteModuleRecordStatus = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = moduleRecordStatus?.uuid;
    
    var res:any = await EV.deleteModuleRecordStatus();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(moduleRecordStatus.uuid) && <>
        <Button
          type={'default'}
          disabled={moduleRecordStatus.is_default === 'y'}
          icon={<PushpinFilled />}
          children={'Set as Default'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                Are you sure you want to set this status as the default 
                status for this module? If another status is already set 
                as the default one it will be replaced.
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setModuleRecordStatus({
                ...moduleRecordStatus,
                is_default: 'y'
              });

              processModuleStatus(true);
              setLayoutModal({open: false});
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteModuleRecordStatus();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(moduleRecordStatus?.uuid) ? 'Manage Module Status' : 'New Module Status'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={moduleRecordStatus?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid,
        label: moduleRecordStatus.label,
        description: moduleRecordStatus.description,
        highlight_type: moduleRecordStatus.highlight_type,
        highlight_color: moduleRecordStatus.highlight_color,
        is_active: moduleRecordStatus.is_active === 'y' ? true : false
      }}
      onFinish={processModuleStatus}
    >
      <Form.Item
        label={'Module'} 
        name={'module'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Select
          placeholder={'Select one'}
          onChange={(value) => {
            form.setFieldValue(
              'module', value
            );

            setModuleRecordStatus({
              ...moduleRecordStatus,
              module: value
            })
          }}
        >
          <Select.Option value='cargoes'>Cargoes</Select.Option>
          <Select.Option value='packing plans'>Packing Plans</Select.Option>
          <Select.Option value='packing orders'>Packing Orders</Select.Option>
          <Select.Option value='containers'>Containers</Select.Option>
          <Select.Option value='drop off appointments'>Drop Off Appointments</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={'Listing Order'} 
        name={'listing_order'}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setModuleRecordStatus({
            ...moduleRecordStatus,
            listing_order: Number(e.target.value)
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Label'} 
        name={'label'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setModuleRecordStatus({
            ...moduleRecordStatus,
            label: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Description'} 
        name={'description'}
      >
        <TextArea 
          placeholder={'Type here'}
          onChange={(e) => setModuleRecordStatus({
            ...moduleRecordStatus,
            description: e.target.value
          })}
        />
      </Form.Item>
      
      <Row gutter={15}>
        <Col span={10}>
          <Form.Item
            label={'Highlight Type'} 
            name={'highlight_type'}
          >
            <Select
              allowClear
              placeholder={'Select one'}
              onChange={(value) => {
                form.setFieldValue(
                  'highlight_type', value
                );

                setModuleRecordStatus({
                  ...moduleRecordStatus,
                  highlight_type: value||''
                })
              }}
            >
              <Select.Option value=''>None</Select.Option>
              <Select.Option value='dot'>Dot</Select.Option>
              <Select.Option value='ribben'>Ribben</Select.Option>
              <Select.Option value='row'>Background Color</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={14}>
          <Space direction="horizontal">
            <Form.Item
              label={'Color'} 
              name={'highlight_color'}
            >
              <Input 
                addonAfter={<Button 
                  size='small'
                  type="link"
                  children={<ReloadOutlined />}
                  onClick={() =>{
                    var color = getRandomColor();

                    form.setFieldValue(
                      'highlight_color', color
                    );

                    setModuleRecordStatus({
                      ...moduleRecordStatus,
                      highlight_color: color
                    })
                  }}
                />}
                placeholder={'Type here'}
                onChange={(e) => setModuleRecordStatus({
                  ...moduleRecordStatus,
                  highlight_color: e.target.value
                })}
              />
            </Form.Item>
          </Space>
        </Col>
      </Row>

      <Form.Item>
        <Switch 
          checked={moduleRecordStatus.is_active === 'y' ? true : false} 
          onChange={(e) => setModuleRecordStatus({
            ...moduleRecordStatus,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}