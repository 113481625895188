/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DeleteFilled, FileAddFilled, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Segmented, Select, Space, Table, Tag, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import PackingOrdersListing from '../packing_orders/listing';
import Documents from '../../common/documents';
import Comments from '../../common/comments';
import { DefaultOptionType } from 'antd/es/select';
import { filterGeoArray } from '../../lib/cities';
import { setClearFormFields } from '../../util/clear_empty';
import SelectionStyledOption from '../../common/SelectionStyledOption';
import { displayName } from '../../lib/ev_lib/builders';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export interface ContainerProps {
  uuid: string|undefined,
  modules_records_statuses_uuid?: string|null,
  consignee_contacts_uuid?: string|null,
  intermediate_consignees_uuid?: string|null,
  notify_party_contacts_uuid?: string|null,
  forwarding_agent_contacts_uuid?: string|null,
  container_number?: string|null,
  warehouses_uuid?: string|null,
  warehouses_name?: string|null,
  equipments_types_uuid?: string|null,
  vessel?: string|null,
  trucking_companies_uuid?: string|null,
  steam_ship_lines_uuid?: string|null,
  booking_number?: string|null,
  seal_number?: string|null,
  pickup_terminal?: string|null,
  return_terminal?: string|null,
  port_of_export_country?: string|null,
  port_of_export_state?: string|null,
  port_of_export_city?: string|null,
  destination_country?: string|null,
  destination_state?: string|null,
  destination_city?: string|null,
  place_of_delivery_city?: string|null,
  place_of_delivery_state?: string|null,
  place_of_delivery_country?: string|null,
  received_date?:string|string[],
  received_date_obj?: any,
  packing_date?:string|string[],
  packing_date_obj?: any,
  return_date?:string|string[],
  return_date_obj?: any,
  departure_date?:string|string[],
  departure_date_obj?: any,
  arrival_date?:string|string[],
  arrival_date_obj?: any,
  cut_off_date?:string|string[],
  cut_off_date_obj?: any,
  job_numbers?: string|any[]|null|undefined,
  freight_type?: 'prepaid'|'collect'|null,
  release_type?: 'express release'|'obl'|'telex release'|null,
  move_type?: 'CY - CY'|'door - door'|'door - port'|'port - door'|'port - port'|null,
  is_custom_hold?: 'y'|'n'|null,
  public_notes?: string|null,
  private_notes?: string|null,
  clear?: string[]|undefined
}

export default function ContainersForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [mailingForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [newContainer, setNewContainer] = useState<boolean>(true);
  const [errors, setErrors] = useState<any[]|null>(null);
  
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [container, setContainer] = useState<ContainerProps>({
    uuid: uuid||undefined,
    port_of_export_country: userSettings('auto_fill_container_port_of_export_country') || undefined,
    port_of_export_state: userSettings('auto_fill_container_port_of_export_state') || undefined,
    port_of_export_city: userSettings('auto_fill_container_port_of_export_city') || undefined,
  });

  const [contacts, setContacts] = useState<any[]>([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState<boolean>(false);

  const [containerStatuses, setContainerStatuses] = useState<any[]>([]);
  const [isLoadingContainerStatuses, setIsLoadingContainerStatuses] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [equipmentsTypes, setEquipmentsTypes] = useState<any[]|null>(null);
  const [isLoadingEquipmentsTypes, setIsLoadingEquipmentsTypes] = useState<boolean>(false);
  const [isLoadingVessels, setisLoadingVessels] = useState<boolean>(false);
  const [truckingCompanies, setTruckingCompanies] = useState<any[]|null>(null);
  const [isLoadingTruckingCompanies, setisLoadingTruckingCompanies] = useState<boolean>(false);
  const [steamShipLines, setSteamShipLines] = useState<any[]|null>(null);
  const [isLoadingSteamShipLines, setisLoadingSteamShipLines] = useState<boolean>(false);
  
  const [intermediateConsignees, setIntermediateConsignees] = useState<any[]|null>(null);
  const [isLoadingIntermediateConsignees, setIsLoadingIntermediateConsignees] = useState<boolean>(false);

  const [destinationAsPlaceOfDelivery, setDestinationAsPlaceOfDelivery] = useState<boolean>(false);

  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [relatedEmailingList, setRelatedEmailingList] = useState<any[]>([]);

  const [docsModal, setDocsModal] = useState<{
    open:boolean,
    children?:any[]|null,
    okText?:string,
    onOk?:any,
    onCancel?:any,
    title?:string,
    footer?:any[]
  }>({open: false});

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getContainerStatuses();
    getWarehouses();
    getEquipmentsTypes();
    getTruckingCompanies();
    getSteamShipLines();
    getIntermediateConsignees();
    getContacts();

    getCities();
    getStates();
    getCountries();

    if(uuid) getContainer();

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  /**
   * Rerender the intermediate consingees selection list
   * after processing the container. This was to solve
   * the selection list with items that are inactive
   */

  useEffect(() => {
    getIntermediateConsignees();
  }, [newContainer])

  useEffect(() => {
    getContainerRelatedEmailingList();
  }, [docsModal]);

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })
      
      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`, country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getContainerStatuses = async () => {
    if(isLoadingContainerStatuses) return null;
    setIsLoadingContainerStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'containers';
    EV.showAll = true;
    EV.active = 'y';

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          ...item
        });
      });
    }

    setContainerStatuses(sortedData);

    setIsLoadingContainerStatuses(false);
    return null;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getIntermediateConsignees = async () => {
    if(isLoadingIntermediateConsignees) return null;
    setIsLoadingIntermediateConsignees(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getIntermediateConsignees();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            container.intermediate_consignees_uuid
            && (item.uuid === container.intermediate_consignees_uuid)
          )  
        ) {
          sortedData.push({
            value: item.uuid,
            label: item.company_name,
            ...item
          });
        }
      });
    }

    setIntermediateConsignees(sortedData);

    setIsLoadingIntermediateConsignees(false);
    return;
  }

  const getContacts = async () => {
    if(isLoadingContacts) return null;
    setIsLoadingContacts(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getContacts();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        var tags = '';

        // buidl the tags
        if(item.tags) {
          try {
            if(typeof item.tags === 'string') {
              item.tags = JSON.parse(item.tags)
            }

            item.tags.map((tag:any, tkey:number) => {
              tags += tag + ', '
            })
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') console.warn(
              'Failed to parse tags', item.tags
            )
          }
        }
          
        var formatUUID = item.uuid.substring(item.uuid.length - 5);

        sortedData.push({
          value: item.uuid,
          searchable: `${item.company_name} ${item.uuid} ${item.first_name} ${item.last_name} ${tags}`,
          label: <SelectionStyledOption
            label={item.company_name}
            tail={`${displayName(
              item.name_prefix,
              item.first_name,
              item.last_name
            )} | UUID # ...${formatUUID}`}
          />,
          ...item
        });
      });
    }

    setContacts(sortedData);

    setIsLoadingContacts(false);
    return null;
  }

  const getEquipmentsTypes = async () => {
    if(isLoadingEquipmentsTypes) return null;
    setIsLoadingEquipmentsTypes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getEquipmentsTypes();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.label}`
        });
      });
    }

    setEquipmentsTypes(sortedData);

    setIsLoadingEquipmentsTypes(false);
    return null;
  }

  const getTruckingCompanies = async () => {
    if(isLoadingTruckingCompanies) return null;
    setisLoadingTruckingCompanies(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getTruckingCompanies();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.company_name}`
        });
      });
    }

    setTruckingCompanies(sortedData);

    setisLoadingTruckingCompanies(false);
    return null;
  }

  const getSteamShipLines = async () => {
    if(isLoadingSteamShipLines) return null;
    setisLoadingSteamShipLines(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getSteamShipLines();

    setisLoadingSteamShipLines(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.company_name}`
        });
      });
    }

    setSteamShipLines(sortedData);
  }

  const getContainerRelatedEmailingList = async () => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getContainerRelatedEmailingList();
    
    if(res && res.data?.length > 0) {
      var tempArray:any[] = [];

      res.data.map((record:any, key:number) => {
        // clean up the tags
        if(record.tags) {
          record.tags = record.tags.replaceAll("[", "");
          record.tags = record.tags.replaceAll("]", "");
          record.tags = record.tags.replaceAll("\"", "");
        }

        var companyName = record.company_name || record.name;

        tempArray.push({
          value: record.email,
          searchable: `${companyName} ${record.first_name} ${record.last_name} ${record.email} ${record.tags} ${record.uuid}`,
          label: <SelectionStyledOption
            layout={2}
            label={record.company_name}
            tail={displayName(
              record.name_prefix,
              record.first_name,
              record.last_name
            ) + (' <' + record.email + '>') as any}
          />
        })
      });

      setRelatedEmailingList(tempArray);
    }
  }

  const getContainer = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getContainer();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull container data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var data = res.data;
      
      if(data.received_date) {
        var convertedDate = momentTz(data.received_date).tz(moment.tz.guess());
        data.received_date_obj = dayjs(convertedDate.format())
        
        try {
          data.received_date = convertedDate.format();
          data.received_date = data.received_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert received_date time', data.received_date, e
          );
        }
      }
      
      if(data.packing_date) {
        var convertedDate = momentTz(data.packing_date).tz(moment.tz.guess());
        data.packing_date_obj = dayjs(convertedDate.format())
        
        try {
          data.packing_date = convertedDate.format();
          data.packing_date = data.packing_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert packing_date time', data.packing_date, e
          );
        }
      }
      
      if(data.return_date) {
        var convertedDate = momentTz(data.return_date).tz(moment.tz.guess());
        data.return_date_obj = dayjs(convertedDate.format())
        
        try {
          data.return_date = convertedDate.format();
          data.return_date = data.return_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert return_date time', data.return_date, e
          );
        }
      }
      
      if(data.departure_date) {
        var convertedDate = momentTz(data.departure_date).tz(moment.tz.guess());
        data.departure_date_obj = dayjs(convertedDate.format())
        
        try {
          data.departure_date = convertedDate.format();
          data.departure_date = data.departure_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert departure_date time', data.departure_date, e
          );
        }
      }
      
      if(data.arrival_date) {
        var convertedDate = momentTz(data.arrival_date).tz(moment.tz.guess());
        data.arrival_date_obj = dayjs(convertedDate.format())
        
        try {
          data.arrival_date = convertedDate.format();
          data.arrival_date = data.arrival_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert arrival_date time', data.arrival_date, e
          );
        }
      }
      
      if(data.cut_off_date) {
        var convertedDate = momentTz(data.cut_off_date).tz(moment.tz.guess());
        data.cut_off_date_obj = dayjs(convertedDate.format())
        
        try {
          data.cut_off_date = convertedDate.format();
          data.cut_off_date = data.cut_off_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert cut_off_date time', data.cut_off_date, e
          );
        }
      }

      // check if destination and place of delivery match
      if(
        (data.destination_city === data.place_of_delivery_city)
        && (data.destination_state === data.place_of_delivery_state)
        && (data.destination_country === data.place_of_delivery_country)
      ) {
        setDestinationAsPlaceOfDelivery(true);
      }
      else {
        setDestinationAsPlaceOfDelivery(false);
      }
      
      setContainer(data);
      form.setFieldsValue(data);
      setNewContainer(false);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(!newContainer) {
      // check clearables
      container.clear = setClearFormFields(container)||undefined;
    }
    
    EV = Object.assign(EV, container);

    var res:any = null;

    if(container.uuid && !newContainer) res = await EV.updateContainer();
    else res = await EV.createContainer();

    if(!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!container.uuid) {
        setContainer({
          ...container,
          uuid: res?.data?.uuid
        });
        
        navigate('/containers/containers/form/' + res?.data?.uuid, {replace: true})
      }

      setNewContainer(false);
    }

    setIsLoading(false);
    return null;
  }

  const emailDoc = async (type:string, replyTo:any, to:any, cc:any) => {
    // initiate the library
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.to = to;
    EV.cc = cc;
    EV.replyTo = replyTo;
    EV.uuid = container.uuid;

    // call the function by the type
    var res:any = null;

    switch(type) {
      case 'bmbl':
        res = await EV.emailContainerBookingMasterBillOfLading();
      break;
      case 'mbl':
        res = await EV.emailContainerMasterBillOfLading();
      break;
      case 'packing-order':
        res = await EV.emailContainerPackingOrderDoc();
      break;
      case 'dock-receipt':
        res = await EV.emailContainerDockReceipt();
      break;
    }
    
    // handle the results
    if(res && res.status === 'success') {
      mailingForm.resetFields();

      setDocsModal({open: false});
      
      messageApi.open({
        type: 'success',
        content: res.message?.en
      });
    }
    else if(res.errors) {
      setLayoutModal({
        open: true,
        title: 'Oops!',
        children: [<>
          <div><Typography.Text>Errors submitting your data:</Typography.Text></div>
          <ul>{res.errors.map((error:any, key:number) => {
            if(typeof error === 'object') {
              error = error.en
            }

            return <li>{error}</li>;
          })}</ul>
        </>],
        footer: [<Button
          type={'default'}
          children={'Ok'}
          onClick={() => setLayoutModal({open: false})}
        />]
      })
    }

    return null;
  }

  const handleDocsModal = (type:'bmbl'|'mbl'|'packing-order'|'dock-receipt') => {
    var title = 'Unset';
    var previewAction:any = () => null;
    var replyTo:any = null;
    var to:any = null;
    var cc:any = null;

    var profileEmail = null;

    var profile:any = localStorage.getItem('profile');

    if(profile) {
      try {
        if(typeof profile === 'string') {
          profile = JSON.parse(profile);
        }

        profileEmail = profile.email;
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') {
          console.error(
            'Failed to parse user profile',
            e
          );
        }
      }
    }

    var replyToOptions = [
      { label: 'System Default', value: null },
      { label: `Me - ${profileEmail}`, value: profileEmail }
    ];

    var children = [
      <Form
        form={mailingForm}
        layout="horizontal"
        labelCol={{span: 4}}
        style={{minWidth: '100%'}}
      >
        <Form.Item
          label={'Reply to'} 
          name={'reply_to'}
        >
          <Select
            placeholder={'Select one'}
            showSearch
            onChange={(value) => replyTo = value}
            options={replyToOptions}
          />
          <div style={{marginTop: 10}}>
            Recommended to leave this to the default value unless
            expected to get a response from any of the receivers.
          </div>
        </Form.Item>
        <Form.Item
          label={'To'} 
          name={'to'}
          required
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => to = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
        <Form.Item
          label={'CC'} 
          name={'cc'}
        >
          <Select 
            mode="tags"
            placeholder={'Select one'}
            onChange={(value) => cc = value}
            options={relatedEmailingList}
            tokenSeparators={[',']}
          />
        </Form.Item>
      </Form>
    ];

    switch(type) {
      case 'bmbl':
        title = 'Booking Master Bill of Lading (BMBL)';
        previewAction = () => window.open(
          `/containers/containers/docs/${container.uuid}/?type=bookingMasterBillOfLading`, 
          '_blank'
        );
      break;
      case 'mbl':
        title = 'Master Bill of Lading (MBL)';
        previewAction = () => window.open(
          `/containers/containers/docs/${container.uuid}/?type=masterBillOfLading`, 
          '_blank'
        );
      break;
      case 'packing-order':
        title = 'Packing Order';
        previewAction = () => window.open(
          `/containers/containers/docs/${container.uuid}/?type=packingOrder`, 
          '_blank'
        );
      break;
      case 'dock-receipt':
        title = 'Dock Receipt';
        previewAction = () => window.open(
          `/containers/containers/docs/${container.uuid}/?type=dockReceipt`, 
          '_blank'
        );
      break;
    }

    setDocsModal({
      open: true,
      title: title,
      onCancel: () => setDocsModal({open: false}),
      children: children,
      footer: [
        <Button
          type='default'
          children='Cancel'
          onClick={() => setDocsModal({open: false})}
        />,
        <Button
          type='default'
          children='Preview PDF'
          onClick={previewAction}
        />,
        <Button
          type='primary'
          children='Email'
          icon={<SendOutlined />}
          onClick={() => emailDoc(type, replyTo, to, cc)}
        />,
      ]
    })
  }

  const packingOrdersCount = () => {
    var count = [];

    if(container.job_numbers) {
      if(typeof container.job_numbers === 'string') {
        count = container.job_numbers?.split(',');
      }
      else {
        count = container.job_numbers;
      }
    }

    return count.length;
  }

  const deleteContainer = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = container?.uuid;
    
    var res:any = await EV.deleteContainer();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    notFound={notFound}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized && !notFound) && <>
        <Button
          type={'default'}
          children={'Booking MBL'}
          onClick={() => handleDocsModal('bmbl')}
          disabled={!container.uuid && newContainer}
        />
        <Button
          type={'default'}
          children={'MBL'}
          onClick={() => handleDocsModal('mbl')}
          disabled={!container.uuid && newContainer}
        />
        <Button
          type={'default'}
          children={'Packing Order'}
          onClick={() => handleDocsModal('packing-order')}
          disabled={!container.uuid && newContainer}
        />
        <Button
          type={'default'}
          children={'Doc Receipt'}
          onClick={() => handleDocsModal('dock-receipt')}
          disabled={!container.uuid && newContainer}
        />
      </>}
      {(authorized && !notFound && container.uuid && !newContainer) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteContainer();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
    rightSideComponent={() => {
      return <Comments 
        module={'containers'}
        moduleUuid={container.uuid}
      />
    }}
  >
    {contextHolder}

    <Modal
      title={docsModal.title}
      open={docsModal?.open}
      onOk={docsModal.onOk}
      onCancel={docsModal.onCancel}
      okText={docsModal.okText}
      children={docsModal.children}
      footer={docsModal.footer}
    />

    <Typography.Title 
      level={1}
      children={(container?.uuid) ? 'Manage Container' : 'New Container'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={container?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={container}
      onFinish={processSubmit}
    >
      {(packingOrdersCount() < 2) && <>
        <Alert
          type='warning'
          showIcon
          message={'Single Packing Order Containers'}
          description={
            'Containers that have a single packing order will use the packing orders\' '
            + 'consignee, notify party, and intermediate consignee by default.'
          }
        />

        <div style={{height: 15}} />
      </>}
      
      <Form.Item
        label={'Consignee'} 
        name={'consignee_contacts_uuid'}
        tooltip={'Select the consignee that will be placed on the Master Bill of Lading'}
      >
        <Select 
          placeholder={'Select one'}
          allowClear
          showSearch
          value={container?.consignee_contacts_uuid||null}
          onChange={(value) => setContainer({
            ...container,
            consignee_contacts_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          optionFilterProp="children"
          options={contacts||[]}
          disabled={packingOrdersCount() < 2}
        />
      </Form.Item>

      <Form.Item
        label={'Intermediate Consignee'} 
        name={'intermediate_consignees_uuid'}
        tooltip={'Select the intermediate consignee that will be placed on the Master Bill of Lading'}
      >
        <Select 
          placeholder={'Select one'}
          allowClear
          value={container?.intermediate_consignees_uuid||null}
          onChange={(value) => setContainer({
            ...container,
            intermediate_consignees_uuid: value||''
          })}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          optionFilterProp="children"
          options={intermediateConsignees||[]}
          disabled={packingOrdersCount() < 2}
        />
      </Form.Item>

      <Form.Item
        label={'Notify Party'} 
        name={'notify_party_contacts_uuid'}
        tooltip={'Select the notify party that will be placed on the Master Bill of Lading'}
      >
        <Select 
          allowClear
          placeholder={'Select one'}
          value={container?.notify_party_contacts_uuid||null}
          onChange={(value) => setContainer({
            ...container,
            notify_party_contacts_uuid: value||''
          })}
          showSearch
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          optionFilterProp="children"
          options={contacts||[]}
          disabled={packingOrdersCount() < 2}
        />
      </Form.Item>

      <Form.Item
        label={'Forwarding Agent'} 
        name={'forwarding_agent_contacts_uuid'}
        tooltip={'Select the forwarding agent that will be placed on the Master Bill of Lading'}
      >
        <Select 
          allowClear
          placeholder={'Select one'}
          value={container?.forwarding_agent_contacts_uuid||null}
          onChange={(value) => setContainer({
            ...container,
            forwarding_agent_contacts_uuid: value||''
          })}
          showSearch
          filterOption={(input, option) =>
            (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
          }
          optionFilterProp="children"
          options={contacts||[]}
          disabled={packingOrdersCount() < 2}
        />
      </Form.Item>

      <Form.Item
        label={'Warehouse'} 
        name={'warehouses_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          onChange={(value) => setContainer({
            ...container,
            warehouses_uuid: value||''
          })}
          options={warehouses||[]}
        />
      </Form.Item>
      
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Container #'} 
            name={'container_number'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                container_number: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Equpment Type'} 
            name={'equipments_types_uuid'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              value={container?.equipments_types_uuid||null}
              onChange={(value) => setContainer({
                ...container,
                equipments_types_uuid: value||''
              })}
              options={equipmentsTypes||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Vessel'} 
            name={'vessel'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                vessel: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Segmented
        block
        style={{textTransform: 'capitalize'}}
        options={containerStatuses}
        value={container.modules_records_statuses_uuid||0}
        name={'modules_records_statuses_uuid'}
        onChange={(value) => setContainer({
          ...container,
          modules_records_statuses_uuid: value.toString()
        })}
      />

      <div className={'compact-view-hide'} style={{marginBottom: 15}} />

      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            label={'Freight Type'} 
            name={'freight_type'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                freight_type: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: '', label: 'None' },
                { value: 'prepaid', label: 'Prepaid' },
                { value: 'collect', label: 'Collect' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Release Type'} 
            name={'release_type'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                release_type: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: '', label: 'None' },
                { value: 'express release', label: 'Express Release' },
                { value: 'obl', label: 'OBL' },
                { value: 'telex release', label: 'TELEX Release' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Move Type'} 
            name={'move_type'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
              ...container,
              move_type: value||''
            })}
              options={[
                { value: '', label: 'None' },
                { value: 'CY - CY', label: 'CY - CY' },
                { value: 'door - door', label: 'Door - Door' },
                { value: 'door - port', label: 'Door - Port' },
                { value: 'port - door', label: 'Port - Door' },
                { value: 'port - port', label: 'Port - Port' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={'Custom Hold'} 
            name={'is_custom_hold'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                is_custom_hold: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: '', label: 'None' },
                { value: 'y', label: 'Yes' },
                { value: 'n', label: 'No' }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title 
        level={2} 
        children={'Logistics'} 
        className={'compact-view-hide'}
      />
      
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Trucking Company'} 
            name={'trucking_companies_uuid'}
          >
            <Select 
              allowClear
              showSearch
              placeholder={'Select one'}
              value={container?.trucking_companies_uuid||null}
              onChange={(value) => setContainer({
                ...container,
                trucking_companies_uuid: value||''
              })}
              options={truckingCompanies||[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Steam Ship Line'} 
            name={'steam_ship_lines_uuid'}
          >
            <Select 
              allowClear
              showSearch
              placeholder={'Select one'}
              value={container?.steam_ship_lines_uuid||null}
              onChange={(value) => setContainer({
                ...container,
                steam_ship_lines_uuid: value||''
              })}
              options={steamShipLines||[]}
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Booking #'} 
            name={'booking_number'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                booking_number: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Seal #'} 
            name={'seal_number'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                seal_number: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Pickup Terminal'} 
            name={'pickup_terminal'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                pickup_terminal: e.target.value
              })}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Return Terminal'} 
            name={'return_terminal'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setContainer({
                ...container,
                return_terminal: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Port of Export Country'} 
            name={'port_of_export_country'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                port_of_export_country: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countries||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Port of Export State'}
            name={'port_of_export_state'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                port_of_export_state: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'state',
                states||[],
                undefined,
                container.port_of_export_country
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Port of Export City'} 
            name={'port_of_export_city'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                port_of_export_city: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'city',
                cities||[],
                container.port_of_export_state,
                container.port_of_export_country
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Typography.Title 
        level={3} 
        children={"Destination"} 
        className={'compact-view-hide'}
      />

      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Destination Country'} 
            name={'destination_country'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                destination_country: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countries||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Destination State'}
            name={'destination_state'}
          >
            <Select 
              allowClear
              showSearch
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                destination_state: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'state',
                states||[],
                undefined,
                container.destination_country
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Destination City'} 
            name={'destination_city'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                destination_city: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'city',
                cities||[],
                container.destination_state,
                container.destination_country
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Checkbox
        checked={destinationAsPlaceOfDelivery}
        onChange={(e) => {
          setDestinationAsPlaceOfDelivery(e.target.checked);

          if(e.target.checked) {
            setContainer({
              ...container,
              place_of_delivery_city: container.destination_city,
              place_of_delivery_state: container.destination_state,
              place_of_delivery_country: container.destination_country
            });

            form.setFieldsValue({
              place_of_delivery_city: container.destination_city,
              place_of_delivery_state: container.destination_state,
              place_of_delivery_country: container.destination_country
            })
          }
        }}
        children="Use the destination information for place of delivery as well."
      />

      <div className={'compact-view-hide'} style={{marginBottom: 15}} />

      {(!destinationAsPlaceOfDelivery) && <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label={'Place of Delivery Country'} 
            name={'place_of_delivery_country'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                place_of_delivery_country: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countries||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Place of Delivery State'} 
            name={'place_of_delivery_state'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                place_of_delivery_state: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'state',
                states||[],
                container.place_of_delivery_state,
                container.place_of_delivery_country
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'Place of Delivery City'} 
            name={'place_of_delivery_city'}
          >
            <Select 
              allowClear
              showSearch
              placeholder={'Select one'}
              onChange={(value) => setContainer({
                ...container,
                place_of_delivery_city: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'city',
                cities||[],
                container.place_of_delivery_state,
                container.place_of_delivery_country
              )}
            />
          </Form.Item>
        </Col>
      </Row> }

      <Typography.Title 
        level={2} 
        children={'Dates'} 
        className={'compact-view-hide'}
      />

      <Row gutter={10}>
        <Col span={4}>
          <Form.Item
            label={'Cut Off Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.cut_off_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                cut_off_date: dateString,
                cut_off_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={'Departure Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.departure_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                departure_date: dateString,
                departure_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={'Arrival Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.arrival_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                arrival_date: dateString,
                arrival_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={'Received Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.received_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                received_date: dateString,
                received_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={'Packing Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.packing_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                packing_date: dateString,
                packing_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={'Return Date'}
          >
            <DatePicker
              style={{width: '100%'}}
              format="YYYY-MM-DD"
              value={container.return_date_obj}
              onChange={(date, dateString) => setContainer({
                ...container,
                return_date: dateString,
                return_date_obj: date
              })}
            />
          </Form.Item>
        </Col>
      </Row>
      
      {(container.uuid) && <PackingOrdersListing
        showTitle={!userSettings('compact_view')}
        showEdit
        showNewButton
        showBulkUpdate
        showView
        showInternalRate
        showSalesTotal
        showDueBalance
        containerUuid={container.uuid}
      />}

      {(container.uuid) && <Documents
        purpose={'container'}
        purposeUuid={container?.uuid}
        allowManage
        allowUpload
        title={!userSettings('compact_view') ? 'Images & Documents' : undefined}
      />}

      <div style={{paddingBottom: 50}} />

      <Typography.Title 
        level={2} 
        children={'Notes'}
        className={'compact-view-hide'} 
      />

      <Form.Item
        label={'Public Notes'} 
        name={'public_notes'}
        tooltip={'Public notes are visible for external users'}
      >
        <TextArea 
          rows={8}
          placeholder={'Type here to add public notes'}
          onChange={(e) => setContainer({
            ...container,
            public_notes: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item
        label={'Private Notes'} 
        name={'private_notes'}
        tooltip={'Public notes are invisible to external users'}
      >
        <TextArea 
          rows={8}
          placeholder={'Type here to add public notes'}
          onChange={(e) => setContainer({
            ...container,
            private_notes: e.target.value
          })}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}