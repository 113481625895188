/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useNavigate, useParams } from "react-router-dom";

import { Alert, Button, Card, CardProps, Col, Divider, Drawer, Empty, Form, Image, Input, Row, Segmented, Select, Space, Table, Tag, Typography, message } from "antd";
import { DeleteFilled, EyeFilled, LeftCircleFilled, MailFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import { useEffect, useState } from "react";
import EVAPI from "../../lib/ev_lib/main";

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { setClearFormFields } from "../../util/clear_empty";
import { filterGeoArray } from "../../lib/cities";
import Comments from "../../common/comments";
import { blue, green, gray, red } from "@ant-design/colors";
import Documents from "../../common/documents";
import { URLQuery } from "../../util/url_queries";
import { convertSystemSettingsArrayDatasetToObject } from "../../lib/ev_lib/data_processors";
import { userSettings } from "../../lib/user_settings";

export interface PackingPlanProps {
  uuid: string|undefined,
  modules_records_statuses_uuid?: string|number|null|undefined,
  exporters_uuid?: string|null,
  exporters_consignees_contacts_uuid?: string|null|undefined,
  destination_country?: string|null|undefined,
  destination_state?: string|null|undefined,
  destination_city?: string|null|undefined,
  cargoes_uuids?: string|null|undefined,
  notes?: string|null|undefined,
  clear?: string[]|undefined,
  submitted_at?: string|null|undefined,
  created_at?: string|null|undefined,
  deleted_at?: string|null|undefined
}

export interface ExporterProps {
  uuid: string|null,
  notify_parties?:any[]|null
  consignees?:any[]|null
}

export default function PackingPlanForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);

  const [newPackingPlan, setNewPackingPlan] = useState<boolean>(true);;
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const [packingPlan, setPackingPlan] = useState<PackingPlanProps>({
    uuid: uuid||undefined,
  });

  const [statuses, setStatuses] = useState<any[]>([]);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setIsLoadingExporters] = useState<boolean>(false);
  const [exporter, setExporter] = useState<ExporterProps|null>(null);
  const [isLoadingExporter, setIsLoadingExporter] = useState<boolean>(false);
  
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [openAvailableCargoesDrawer, setOpenAvailableCargoesDrawer] = useState(false);
  const [availableCargoesSearchTerm, setAvailableCargoesSearchTerm] = useState<string|null>(null);
  const [availableCargoes, setAvailableCargoes] = useState<any[]|null>(null);
  const [isLoadingAvailableCargoes, setisLoadingAvailableCargoes] = useState<boolean>(false);
  const [loadingAvailableCargoesErrors, setLoadingAvailableCargoesErrors] = useState<any[]>([]);


  const [isLoadingOptimizationSettings, setIsLoadingOptimizationSettings] = useState<boolean>(false);
  const [optimizationSettings, setOptimizationSettings] = useState<any>(undefined);

  const [isLoadingCargoes, setIsLoadingCargoes] = useState<boolean>(false);
  const [loadingCargoesErrors, setLoadingCargoesErrors] = useState<any[]|undefined>(undefined);
  const [cargoes, setCargoes] = useState<any[]|undefined>(undefined);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management', 'exporter'].includes(profile.type)) {
        setAuthorized(true);

        // if management set is managemnet
        setIsManagement(profile.type === 'management' ? true : false);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getStatuses();
    getExporters();

    getCities();
    getStates();
    getCountries();

    if(uuid) getPackingPlan();
  }, []);

  useEffect(() => {
    getAvailableCargoes();
  }, [availableCargoesSearchTerm]);

  useEffect(() => {
    getCargoes();
  }, [packingPlan.cargoes_uuids])

  useEffect(() => {
    getExporter();
  }, [packingPlan.exporters_uuid])

  useEffect(() => {
    if(
      isManagement
      || !packingPlan.submitted_at
    ) {
      setCanEdit(true);
    }
    else {
      setCanEdit(false);
    }
  }, [packingPlan]);

  const getSettingsOptimization = async () => {
    if(isLoadingOptimizationSettings) return null;
    setIsLoadingOptimizationSettings(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    var res:any = await EV.getSettings();

    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      }); 
    }

    else if(res.code === 200) {
      var data = res.data;
      
      // build the settings object {uuid, value}
      var tempSettings:any = convertSystemSettingsArrayDatasetToObject(data);
      setOptimizationSettings(tempSettings);
    }

    setIsLoadingOptimizationSettings(false);
    return;
  }

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getStatuses = async () => {
    if(isLoadingStatuses) return null;
    setIsLoadingStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'packing plans';
    EV.orderDirection = 'ASC';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoadingStatuses(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: item.label,
          highlight_type: item.highlight_type,
          highlight_color: item.highlight_color
        });
      });
    }

    setStatuses(sortedData);
    return;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setIsLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setIsLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(
          item.is_active === 'y'
          || (
            packingPlan.exporters_uuid
            && (item.uuid === packingPlan.exporters_uuid)
          )  
        ) sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
    return;
  }

  const getExporter = async () => {
    if(isLoadingExporter || !packingPlan.exporters_uuid) return null;
    setIsLoadingExporter(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingPlan.exporters_uuid;

    var res:any = await EV.getExporter();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to exporter\'s data from the server',
      });  
    }

    else if(res.data) {
      // process the consignees as dropdown selection
      var consignees = res.data.consignees;

      if(consignees && consignees.length > 0) {
        var tempConsignees:any[] = [];

        consignees.map((item:any, key:number) => {
          var tags = '';
  
          // buidl the tags
          if(item.tags) {
            try {
              if(typeof item.tags === 'string') {
                item.tags = JSON.parse(item.tags)
              }
  
              item.tags.map((tag:any, tkey:number) => {
                tags += tag + ', '
              })
            }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn(
                'Failed to parse tags', item.tags
              )
            }
          }

          tempConsignees.push({
            ...item,
            value: item.contacts_uuid,
            label: `${item.company_name || 'Not Set'} (${item.name_prefix?.toUpperCase()}. ${item.first_name} ${item.last_name} - Tags: ${tags})`
          })
        })

        consignees = tempConsignees;
      }

      // notify parties
      var notifyParties = res.data.notify_parties;

      if(notifyParties && notifyParties.length > 0) {
        var tempNotifyParties:any[] = [];

        notifyParties.map((item:any, key:number) => {
          var tags = '';
  
          // buidl the tags
          if(item.tags) {
            try {
              if(typeof item.tags === 'string') {
                item.tags = JSON.parse(item.tags)
              }
  
              item.tags.map((tag:any, tkey:number) => {
                tags += tag + ', '
              })
            }
            catch(e) {
              if(process.env.NODE_MODE === 'development') console.warn(
                'Failed to parse tags', item.tags
              )
            }
          }
          tempNotifyParties.push({
            ...item,
            value: item.contacts_uuid,
            label: `${item.company_name || 'Not Set'} (${item.name_prefix?.toUpperCase()}. ${item.first_name} ${item.last_name} - Tags: ${tags})`
          })
        })

        notifyParties = tempNotifyParties;
      }

      setExporter({
        ...res.data,
        consignees: consignees,
        notify_parties: notifyParties
      });
    }

    setIsLoadingExporter(false);
    return null;
  }

  const toggleOpenAvailableCargoesDrawer = () => {
    getAvailableCargoes();
    setOpenAvailableCargoesDrawer(!openAvailableCargoesDrawer);
  };

  const updateCargoesUuidsArray = async (uuid:string) => {
    if(!uuid) return;
    
    setCargoes([]);

    var currentArray:any = packingPlan?.cargoes_uuids||undefined;
    var tempArray = '';

    if(!currentArray || currentArray.length < 0) {
      tempArray = uuid;
    }
    else if(currentArray.includes(uuid)) {
      currentArray = currentArray.split(',');
      currentArray.map((cargo:any, index:number) => {
        if(uuid !== cargo) {
          if(tempArray.length > 0) { tempArray += ','; }
          tempArray += cargo;
        }
      })
    }
    else {
      tempArray = currentArray + ',' + uuid;
    }
    
    setPackingPlan({
      ...packingPlan,
      cargoes_uuids: tempArray,
    });
  }

  const getCargoes = async () => {
    if(
      !packingPlan 
      || !packingPlan.cargoes_uuids 
      || packingPlan.cargoes_uuids.length < 1
    ) {
      return;
    }

    setIsLoadingCargoes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.exportersUuid = packingPlan.exporters_uuid;
    EV.includeUuids = packingPlan.cargoes_uuids;
    
    var res:any = await EV.getCargoes();
    
    if(!res) {
      setLoadingCargoesErrors([
        'Failed to download records from the server'
      ]);
    }

    else if(res.status === 'fail') {
      setLoadingCargoesErrors(res.errors);  
    }
    
    else if(res.data) {
      setCargoes([...res.data]);
    }

    setIsLoadingCargoes(false);
    return null;
  }

  // get all available cargoe linked to the exporter with
  // the same container destination
  const getAvailableCargoes = async () => {
    // check requirements
    setLoadingAvailableCargoesErrors([]);

    if(
      !newPackingPlan
      && (
        !packingPlan
        || !packingPlan.destination_country
        || !packingPlan.destination_city
        || !packingPlan.exporters_uuid
      )
    ) {
      setLoadingAvailableCargoesErrors([
        'Missing information to load avaialble cargoes'
      ]);

      return null;
    }
    
    setisLoadingAvailableCargoes(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.exportersUuid = packingPlan.exporters_uuid;
    EV.linkedToPackingOrder = 'n';
    EV.searchTerm = availableCargoesSearchTerm;
    EV.excludeUuids = packingPlan.cargoes_uuids;

    var res:any = await EV.getCargoes();

    if(!res) {
      setLoadingAvailableCargoesErrors([
        'Failed to download records from the server'
      ]);
    }

    else if(res.status === 'fail') {
      setLoadingAvailableCargoesErrors(res.errors);  
    }
    
    else if(res.data) {
      setAvailableCargoes(res.data);
    }

    setisLoadingAvailableCargoes(false);
    return null;
  }

  const getPackingPlan = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getPackingPlan();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if ([400, 404].includes(res.code)) {
      // record not found
      setNotFound(true);
    }

    else if(res.data) {
      if(
        res.data.deleted_at
        && !isManagement
      ) {
        setNotFound(true);
      }
      else {
        setPackingPlan(res.data);
  
        form.setFieldsValue(res.data);
        setNewPackingPlan(false);
      }
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async (submit?:boolean) => {
    if(isProcessing) return null;
    setErrors(null)
    setIsProcessing(true);

    if(
      !packingPlan
      || !packingPlan.destination_country
      || !packingPlan.destination_city
      || !packingPlan.exporters_uuid
    ) {
      setErrors([
        'Required exporter, destination country, and city to create new packing plan.'
      ]);

      setIsProcessing(false);
      return;
    }

    if(
      !packingPlan
      || !packingPlan.destination_country
      || !packingPlan.destination_city
    ) {
      setErrors([
        'Required destination country and city to create new packing plan.'
      ]);

      setIsProcessing(false);
      return;
    }

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(!newPackingPlan) {
      // check clearables
      packingPlan.clear = setClearFormFields(packingPlan)||undefined;
    }
    
    // handle the submit option
    if(submit) {
      packingPlan.submitted_at = 'y';
    }
    
    EV = Object.assign(EV, packingPlan);

    var res:any = null;

    if(packingPlan.uuid && !newPackingPlan) res = await EV.updatePackingPlan();
    else res = await EV.createPackingPlan();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!packingPlan.uuid) {
        setPackingPlan({
          ...packingPlan,
          submitted_at: submit ? 'y' : packingPlan.submitted_at,
          uuid: res.data.uuid
        });

        navigate('/containers/packing-plans/form/' + res.data.uuid, {replace: true});
      }

      else if (submit) {
        setPackingPlan({
          ...packingPlan,
          submitted_at: 'y'
        });
      }

      setNewPackingPlan(false);
    }

    setIsProcessing(false);
    return null;
  }

  const deletePackingPlan = async () => {
    if(isDeleting) return;
    setIsDeleting(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingPlan?.uuid;

    var res:any = await EV.deletePackingPlan();
    // var res:any = false;

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }
  
  let avaialbleCargoesColumns = [
    {
      key: 'modules_records_statuses_label', 
      title: 'Status',
      width: 130,
      render: (_:any, record:any) => <Tag
        color={record.modules_records_statuses_highlight_color}
        children={record.modules_records_statuses_label} 
      />
    },
    {
      key: 'uuid',
      title: 'UUID',
      dataIndex: 'uuid',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid.substr(record.uuid.length - 10)
      }
    },
    {key: 'type', title: 'Type', dataIndex: 'type'},
    {key: 'make', title: 'Make', dataIndex: 'make'},
    {key: 'model', title: 'Model', dataIndex: 'model'},
    {key: 'color', title: 'Color', dataIndex: 'color'},
    {key: 'vin_number', title: 'VIN #', dataIndex: 'vin_number'}
  ];

  let avaialbleCargoesColumnsSelectionColumns = [
    ...avaialbleCargoesColumns,
    {
      key: 'link',
      title: 'Action',
      render: (_:any, record:any) => (
        <Button 
          type={'link'}
          size={'small'}
          icon={<PlusCircleFilled />}
          onClick={() => updateCargoesUuidsArray(record.uuid)}
        />
      )
    }
  ]

  return <BlankPage
    isLoading={isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    showSideMenu
    padded
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(
        authorized 
        && !notFound 
        && packingPlan.uuid 
        && !newPackingPlan
        && (
          !isManagement
          && !packingPlan.submitted_at
        )
      ) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deletePackingPlan();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    {packingPlan.deleted_at && <Alert
      style={{marginTop: 10}}
      type={'warning'}
      showIcon
      message={
        'This record was deleted ' 
        + momentTz(packingPlan.deleted_at).tz(moment.tz.guess()).fromNow()
      }
    />}

    {(!canEdit) && <Alert
      style={{marginTop: 10}}
      type={'info'}
      showIcon
      message={
        'This record is being processed and cannot be modified. ' 
        + 'Please contact us for changes and updates.'
      }
    />}

    <Typography.Title 
      level={1}
      children={(packingPlan?.uuid) ? 'Manage Packing Plan' : 'New Packing Plan'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={packingPlan?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error:any, key:number) => {
        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={packingPlan}
      onFinish={processSubmit}
    >
      {(
        isManagement
        || packingPlan.submitted_at
      ) && <>
        <Segmented
          block
          disabled={!isManagement}
          style={{textTransform: 'capitalize'}}
          options={statuses}
          value={packingPlan.modules_records_statuses_uuid||0}
          name={'modules_records_statuses_uuid'}
          onChange={(value) => setPackingPlan({
            ...packingPlan,
            modules_records_statuses_uuid: value.toString()
          })}
        />

        <div style={{height: 25}} />
      </>}
      <Form.Item
        required
        label={'Exporter'} 
        name={'exporters_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          disabled={!newPackingPlan}
          onChange={(value:any) => setPackingPlan({
            ...packingPlan,
            exporters_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(exporters||[])}
        />
      </Form.Item>

      <Form.Item
        required
        label={'Consignee'} 
        name={'exporters_consignees_contacts_uuid'}
      >
        <Select 
          showSearch
          allowClear
          placeholder={'Select one'}
          disabled={!newPackingPlan}
          onChange={(value:any) => setPackingPlan({
            ...packingPlan,
            exporters_consignees_contacts_uuid: value||''
          })}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={(exporter?.consignees||[])}
        />
      </Form.Item>

      <Typography.Title level={2} children={'Destination'} />

      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            required
            label={'Country'} 
            name={'destination_country'}
          >
            <Select 
              showSearch
              allowClear
              placeholder={'Select one'}
              disabled={!newPackingPlan}
              onChange={(value:any) => setPackingPlan({
                ...packingPlan,
                destination_country: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={countries||[]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={'State'}
            name={'destination_state'}
          >
            <Select 
              showSearch
              allowClear
              disabled={!newPackingPlan}
              placeholder={'Select one'}
              onChange={(value) => setPackingPlan({
                ...packingPlan,
                destination_state: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'state',
                states||[],
                undefined,
                packingPlan.destination_country
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            label={'City'} 
            name={'destination_city'}
          >
            <Select 
              showSearch
              allowClear
              disabled={!newPackingPlan}
              placeholder={'Select one'}
              onChange={(value) => setPackingPlan({
                ...packingPlan,
                destination_city: value||''
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={filterGeoArray(
                'city',
                cities||[],
                packingPlan.destination_state,
                packingPlan.destination_country
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      {(newPackingPlan) && <>
        <Alert
          type="warning"
          showIcon
          message="Address cannot be changes once packing plan is created."
          style={{ marginBottom: 15 }}
        />

        <Form.Item>
          <Space>
            <Button 
              type={"primary"} 
              htmlType={"submit"}
              loading={isLoading}
              onClick={() => processSubmit()}
            >
              Save
            </Button>
            <Button type={"default"}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </> }

      {(!newPackingPlan) && <>
        <Drawer 
          title="Available Cargoes" 
          width={'70%'}
          placement="right" 
          onClose={toggleOpenAvailableCargoesDrawer} 
          open={openAvailableCargoesDrawer}
        >
          {loadingAvailableCargoesErrors.map((message, key) => {
            return <div style={{marginBottom: 5}} key={key}>
              <Alert
                message={message}
                type='error'
              />
            </div>
          })}

          <Alert
            message={'Only cargoes with matching destination city,'
              + ' and country, and exporter will be listed below'}
            type='warning'
          />

          <div style={{marginBottom: 5}} />

          <Input 
            placeholder={"Type to search"}
            suffix={<SearchOutlined />}
            onChange={(e) => setAvailableCargoesSearchTerm(
              e.target.value
            )}
          />
    
          <div style={{marginBottom: 20}} />
    
          <Table
            size={userSettings('compact_view') ? 'small' : undefined}
            pagination={{ showSizeChanger: true }} 
            loading={isLoadingAvailableCargoes}
            dataSource={availableCargoes||[]} 
            columns={avaialbleCargoesColumnsSelectionColumns} 
          />
        </Drawer>

        <Divider children={'Packed Cargoe'} />

        <Row gutter={10}>
          {(
            !canEdit 
            && (
              !cargoes 
              || cargoes.length < 1
            )
          ) && <Col span={24}>
            <Empty description={"No cargo selected"} />
          </Col>}
          {cargoes?.map((cargo:any, index:number) => {
            return <Col>      
              <Card
                style={{width: 200}}
                cover={<>
                  <Image.PreviewGroup
                    items={[]}
                  >
                    <div
                      style={{
                        width: 200, height: 200,
                        backgroundImage: '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: 10
                      }}
                    />
                  </Image.PreviewGroup>
                </>}
                actions={[
                  <Button type="text">
                    <EyeFilled key="setting" style={{color: gray[2]}} />
                  </Button>,
                  <Button 
                    type="text"
                    disabled={!canEdit}
                    onClick={() => updateCargoesUuidsArray(cargo.uuid)}
                  >
                    <DeleteFilled key="delete" style={{color: red[5]}} />
                  </Button>
                ]}
              >
                <div>
                  <Typography.Text strong>
                    # {cargo.uuid.substr(0, 10)}
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    VIN # {cargo.vin_number}
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    {cargo.reference_id}
                  </Typography.Text>
                </div>
              </Card>
            </Col>
          })}
          {(canEdit) && <Col>
            <div
              style={{
                width: 200,
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                padding: '30px 0px'
              }}
              onClick={toggleOpenAvailableCargoesDrawer}
            >
              <div style={{paddingBottom: 25}}>
                <PlusCircleOutlined 
                  key="add-new" 
                  style={{
                    fontSize: 50
                  }} 
                />
              </div>
              <Typography.Text>Add New</Typography.Text>
            </div>
          </Col>}
        </Row>

        <Divider style={{borderWidth: 0}} />
        
        <Form.Item
          label={'Notes'} 
          name={'notes'}
        >
          <Input.TextArea
            rows={8}
            disabled={!canEdit}
            placeholder={'Type here to add notes to this record'}
            onChange={(e) => setPackingPlan({
              ...packingPlan,
              notes: e.target.value
            })}
          />
        </Form.Item>

        <Documents
          purpose={'packing plan'}
          purposeUuid={packingPlan.uuid}
          allowUpload={canEdit||isManagement}
          allowManage={canEdit||isManagement}
          title={'Documents & Attachments'}
          optimization={{
            max_width: optimizationSettings?.image_optimization_max_width,
            quality: optimizationSettings?.image_optimization_quality_value
          }}
        />

        <Divider style={{border: 0}} />
        
        {(canEdit) && <Form.Item>
          <Space>
            {(!isManagement) && <Button 
              type={"dashed"} 
              htmlType={"submit"}
              loading={isLoading}
              onClick={() => processSubmit()}
            >
              Save as Draft
            </Button>}
            <Button 
              type={"primary"}
              htmlType={"submit"}
              loading={isLoading}
              onClick={() => processSubmit(true)}
            >
              Save & Submit
            </Button>
            <Button type={"default"}>
              Cancel
            </Button>
          </Space>
        </Form.Item>}
      </> }
    </Form>

    {(isManagement) && <>
      <Divider />

      <Comments 
        module={'packing plans'}
        moduleUuid={packingPlan.uuid}
      />
    </>}
  </BlankPage>
}