/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import BlankPage, { LayoutModalProps } from '../../framework/blank_page';
import { Button, Col, Divider, Empty, Row, Segmented, Space, Steps, Typography, message } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';
import RecordNotFound from '../../framework/states/not_found';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import EVAPI from '../../lib/ev_lib/main';
import PackingOrdersListing from '../packing_orders/listing';
import Comments from '../../common/comments';
import { URLQuery } from '../../util/url_queries';

export default function ContainersView(props:any) {
  interface ContainerProps {
    uuid: string|undefined,
    modules_records_statuses_uuid?: string|null,
    consignee_contacts_uuid?: string|null,
    consignee_company_name?:string|null|undefined,
    consignee_name_prefix?:string|null|undefined,
    consignee_first_name?:string|null|undefined,
    consignee_last_name?:string|null|undefined,
    intermediate_consignees_uuid?: string|null,
    intermediate_consignee_company_name?:string|null|undefined,
    intermediate_consignee_name_prefix?:string|null|undefined,
    intermediate_consignee_first_name?:string|null|undefined,
    intermediate_consignee_last_name?:string|null|undefined,
    notify_party_contacts_uuid?: string|null,
    notify_party_company_name?:string|null|undefined,
    notify_party_name_prefix?:string|null|undefined,
    notify_party_first_name?:string|null|undefined,
    notify_party_last_name?:string|null|undefined,
    container_number?: string|null,
    warehouses_uuid?: string|null,
    warehouses_name?: string|null,
    warehouses_city?:string|null|undefined,
    warehouses_state?:string|null|undefined,
    warehouses_country?:string|null|undefined,
    equipments_types_uuid?: string|null,
    equipments_types_label?: string|null,
    vessel?: string|null,
    trucking_companies_uuid?: string|null,
    steam_ship_lines_uuid?: string|null,
    booking_number?: string|null,
    seal_number?: string|null,
    pickup_terminal?: string|null,
    return_terminal?: string|null,
    port_of_export_country?: string|null,
    port_of_export_state?: string|null,
    port_of_export_city?: string|null,
    destination_country?: string|null,
    destination_state?: string|null,
    destination_city?: string|null,
    place_of_delivery_city?: string|null,
    place_of_delivery_state?: string|null,
    place_of_delivery_country?: string|null,
    received_date?: string|null,
    received_date_obj?: any,
    packing_date?: string|null,
    packing_date_obj?: any,
    return_date?: string|null,
    return_date_obj?: any,
    departure_date?: string|null,
    departure_date_obj?: any,
    arrival_date?: string|null,
    arrival_date_obj?: any,
    cut_off_date?: string|null,
    cut_off_date_obj?: any,
    job_numbers?: string|any[]|null|undefined,
    freight_type?: 'prepaid'|'collect'|null,
    release_type?: 'express release'|'obl'|'telex release'|null,
    move_type?: 'CY - CY'|'door - door'|'door - port'|'port - door'|'port - port'|null,
    is_custom_hold?: 'y'|'n'|null,
    public_notes?: string|null,
    private_notes?: string|null
  }

  const navigate = useNavigate();

  let { uuid } = useParams<any>();

  const [messageApi, contextHolder] = message.useMessage();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);

  const [containerStatuses, setContainerStatuses] = useState<any[]>([]);
  const [containerStatusIndex, setContainerStatusIndex] = useState<number|undefined>(undefined);
  const [isLoadingContainerStatuses, setIsLoadingContainerStatuses] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [container, setContainer] = useState<ContainerProps>({
    uuid: uuid||undefined,
    return_date_obj: undefined,
    departure_date_obj: undefined,
    arrival_date_obj: undefined,
    cut_off_date_obj: undefined
  });

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management', 'exporter'].includes(profile.type)) {
        setAuthorized(true);
      }

      if(['management'].includes(profile.type)) {
        setIsManagement(true);
      }

      
    }
    catch(e) {
      console.warn(e);
    };

    getContainerStatuses();

    if(uuid) getContainer();

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  useEffect(() => {
    if(containerStatuses?.length > 0) {
      containerStatuses.map((status:any, key:number) => {
        if(status.value === container.modules_records_statuses_uuid) {
          setContainerStatusIndex(key);
        }
      })
    }
  }, [container])

  const getContainerStatuses = async () => {
    if(isLoadingContainerStatuses) return null;
    setIsLoadingContainerStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'containers';
    EV.showAll = true;

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          title: item.label,
          description: item.description
        });
      });
    }

    setContainerStatuses(sortedData);

    setIsLoadingContainerStatuses(false);
    return null;
  }

  const getContainer = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getContainer();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull container data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var data = res.data;
      
      if(data.received_date) {
        var convertedDate = momentTz(data.received_date).tz(moment.tz.guess());
        data.received_date_obj = dayjs(convertedDate.format())
        
        try {
          data.received_date = convertedDate.format();
          data.received_date = data.received_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert received_date time', data.received_date, e
          );
        }
      }
      
      if(data.packing_date) {
        var convertedDate = momentTz(data.packing_date).tz(moment.tz.guess());
        data.packing_date_obj = dayjs(convertedDate.format())
        
        try {
          data.packing_date = convertedDate.format();
          data.packing_date = data.packing_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert packing_date time', data.packing_date, e
          );
        }
      }
      
      if(data.return_date) {
        var convertedDate = momentTz(data.return_date).tz(moment.tz.guess());
        data.return_date_obj = dayjs(convertedDate.format())
        
        try {
          data.return_date = convertedDate.format();
          data.return_date = data.return_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert return_date time', data.return_date, e
          );
        }
      }
      
      if(data.departure_date) {
        var convertedDate = momentTz(data.departure_date).tz(moment.tz.guess());
        data.departure_date_obj = dayjs(convertedDate.format())
        
        try {
          data.departure_date = convertedDate.format();
          data.departure_date = data.departure_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert departure_date time', data.departure_date, e
          );
        }
      }
      
      if(data.arrival_date) {
        var convertedDate = momentTz(data.arrival_date).tz(moment.tz.guess());
        data.arrival_date_obj = dayjs(convertedDate.format())
        
        try {
          data.arrival_date = convertedDate.format();
          data.arrival_date = data.arrival_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert arrival_date time', data.arrival_date, e
          );
        }
      }
      
      if(data.cut_off_date) {
        var convertedDate = momentTz(data.cut_off_date).tz(moment.tz.guess());
        data.cut_off_date_obj = dayjs(convertedDate.format())
        
        try {
          data.cut_off_date = convertedDate.format();
          data.cut_off_date = data.cut_off_date.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert cut_off_date time', data.cut_off_date, e
          );
        }
      }

      // set the cargo status index
      if(containerStatuses?.length > 0) {
        containerStatuses.map((status:any, key:number) => {
          if(status.value === data.modules_records_statuses_uuid) {
            setContainerStatusIndex(key + 1);
          }
        })
      }
      
      setContainer(data);
    }

    setIsLoading(false);
    return null;
  }

  const _renderYesNo = (value:string|null|undefined) => {
    if(value === 'n') return 'No';
    else if (value === 'y') return 'Yes';
    else return '-';
  }
  
  return <BlankPage
    isLoading={!isReady||isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    padded
    showSideMenu
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
    rightSideComponent={() => {
      if(!isManagement) return null;

      return <Comments 
        module={'containers'}
        moduleUuid={container.uuid}
      />
    }}
  >
    {contextHolder}

    <Typography.Title level={1} children={'Container Details'} />

    <div style={{marginTop: '30px'}}>
      <Steps
        type="navigation"
        current={containerStatusIndex}
        items={containerStatuses}
      />
    </div>

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'ID:'} /></div>
        <div><Typography.Text children={container?.uuid} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Container #:'}/></div>
        <div><Typography.Text children={container?.container_number} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Custom Hold:'}/></div>
        <div><Typography.Text children={_renderYesNo(container?.is_custom_hold)} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'Consignee:'} /></div>
        <div>
          <Typography.Text children={container?.consignee_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${container?.consignee_name_prefix}. ${container?.consignee_first_name}, ${container?.consignee_last_name}`} />
        </div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Intermediate Consignee:'}/></div>
        <div>
          <Typography.Text children={container?.intermediate_consignee_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${container?.intermediate_consignee_name_prefix}. ${container?.intermediate_consignee_first_name}, ${container?.intermediate_consignee_last_name}`} />
        </div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Notify Party:'}/></div>
        <div>
          <Typography.Text children={container?.notify_party_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${container?.notify_party_name_prefix}. ${container?.notify_party_first_name}, ${container?.notify_party_last_name}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed />
    
    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={11}>
        <div><Typography.Text strong children={'Warehouse:'}/></div>
        <div>
          <Typography.Text children={`${container?.warehouses_city||'-'}, ${container?.warehouses_state||'-'}, ${container?.warehouses_country||'-'}`} /><br />
          <Typography.Text italic children={container?.warehouses_name} />
        </div>
      </Col>
      <Col span={1}>
        <Typography.Title level={5} children={'\u2794'} />
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Destination:'} /></div>
        <div>
          <Typography.Text children={`${container?.destination_city||'-'}, ${container?.destination_state||'-'}, ${container?.destination_country||'-'}`} />
        </div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Place of Delivery:'} /></div>
        <div>
          <Typography.Text children={`${container?.place_of_delivery_city||'-'}, ${container?.place_of_delivery_state||'-'}, ${container?.place_of_delivery_country||'-'}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Equipment Type:'} /></div>
        <div><Typography.Text children={container?.equipments_types_label||'-'} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Vessel:'} /></div>
        <div><Typography.Text children={container?.vessel||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Freight Type:'} /></div>
        <div><Typography.Text style={{textTransform: 'capitalize'}} children={container?.freight_type||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Release Type:'} /></div>
        <div><Typography.Text style={{textTransform: 'capitalize'}} children={container?.release_type||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Move Type:'} /></div>
        <div><Typography.Text children={container?.move_type||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Typography.Title level={2} children={'Logistics'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Trucking Company:'} /></div>
        <div><Typography.Text children={container?.trucking_companies_uuid||'-'} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Steam Ship Line:'} /></div>
        <div><Typography.Text children={container?.steam_ship_lines_uuid||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Booking #:'} /></div>
        <div><Typography.Text children={container?.booking_number||'-'} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Seal #:'} /></div>
        <div><Typography.Text children={container?.seal_number||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'Pickup Terminal #:'} /></div>
        <div><Typography.Text children={container?.pickup_terminal||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Return Terminal:'} /></div>
        <div><Typography.Text children={container?.return_terminal||'-'} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Port of Export:'} /></div>
        <div>
          <Typography.Text children={`${container?.port_of_export_city||'-'}, ${container?.port_of_export_state||'-'}, ${container?.port_of_export_country||'-'}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed />

    <Typography.Title level={2} children={'Timeline'} />

    <div style={{marginTop: '50px'}}>
      <Steps
        progressDot
        items={[
          { 
            title: container?.cut_off_date||'Pending',  
            subTitle: 'Cut Off Date', 
            description: 'Packing process has started', 
            status: container?.cut_off_date ? 'finish' : 'wait'
          },
          { 
            title: container?.departure_date||'Pending', 
            subTitle: 'Departure Date', 
            description: 'Container is set to departure', 
            status: container?.departure_date ? 'finish' : 'wait'
          },
          { 
            title: container?.arrival_date||'Pending', 
            subTitle: 'Arrival Date', 
            description: 'Container expexted to arrive', 
            status: container?.arrival_date ? 'finish' : 'wait'
          },
          { 
            title: container?.received_date||'Pending',  
            subTitle: 'Received Date',
            description: 'Container received at the warehouse', 
            status: container?.received_date ? 'finish' : 'wait'
          },
          { 
            title: container?.packing_date||'Pending', 
            subTitle: 'Packing Date', 
            description: 'Packing process completed', 
            status: container?.packing_date ? 'finish' : 'wait'
          },
          { 
            title: container?.return_date||'Pending', 
            subTitle: 'Return Date', 
            description: 'Container returned to port', 
            status: container?.return_date ? 'finish' : 'wait'
          }
        ]}
      />
    </div>

    <Divider dashed/>

    <PackingOrdersListing
      showTitle
      showView
      containerUuid={container.uuid}
    />

    <Divider dashed/>

    <Typography.Title level={2} children={'Notes'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={24}>
        <div><Typography.Text children={container?.public_notes||'-'} /></div>
      </Col>
    </Row>
  </BlankPage>
}
