/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import BlankPage, { LayoutModalProps } from '../../framework/blank_page';
import { Alert, Button, Col, Divider, Empty, Form, Image, Input, Row, Segmented, Skeleton, Space, Steps, Typography, message } from 'antd';
import { LeftCircleFilled, ReloadOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';
import RecordNotFound from '../../framework/states/not_found';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import EVAPI from '../../lib/ev_lib/main';
import Documents from '../../common/documents';
import { URLQuery } from '../../util/url_queries';
import { displayName } from '../../lib/ev_lib/builders';

export default function CargoesViewGuest(props:any) {
  interface CargoProps {
    uuid: string|undefined,
    packing_orders_uuid?: string|null,
    warehouses_uuid?: string|null,
    warehouses_name?: string|null|undefined,
    warehouses_city?: string|null|undefined,
    warehouses_state?: string|null|undefined,
    warehouses_country?: string|null|undefined,
    exporters_uuid?: string|null,
    exporters_reference_id?: string|null,
    exporters_company_name?: string|null|undefined,
    exporters_name_prefix?: string|null|undefined,
    exporters_first_name?: string|null|undefined,
    exporters_last_name?: string|null|undefined,
    exporters_consignees_uuid?: string|null,
    modules_records_statuses_uuid?: string|null,
    modules_records_statuses_label?: string|null|undefined,
    modules_records_statuses_highlight_type?: string|null|undefined,
    modules_records_statuses_highlight_color?: string|null|undefined,
    date_in?: string|null,
    date_in_obj?: any,
    destination_country?: string|null,
    destination_state?: string|null,
    destination_city?: string|null,
    type?: 'general'|'vehicle'|null,
    is_owner_known?: 'y'|'n'|null,
    quantity?: string|null,
    dimensions?: string|null,
    value?: string|null,
    weight?: string|null,
    insurance_value?: string|null,
    insurance_type?: 'partial coverage'|'full coverage'|'total loss'|'none'|null,
    mbl_notes?: string|null,
    public_notes?: string|null,
    vin_number?: string|null,
    year?: number|string|null,
    make?: string|null,
    model?: string|null,
    fuel_type?: string|null,
    color?: string|null,
    mileages?: number|string|null,
    number_of_keys?: number|string|null,
    has_title?: 'y'|'n'|null,
    title?: string|null,
    title_country?: string|null,
    title_state?: string|null,
    lot_number?: string|null,
    is_salvage?: 'y'|'n'|null,
    is_dismantled?: 'y'|'n'|null,
    is_running?: 'y'|'n'|null,
    documents?: any[]|null,
    is_public?: 'y'|'n'|null
  }

  const navigate = useNavigate();

  let { uuid } = useParams<any>();
  let urlAccessCode:string|undefined = URLQuery('accessCode', window);

  const [messageApi, contextHolder] = message.useMessage();

  const [accessCodeMissing, setAccessCodeMissing] = useState<boolean>(false);
  const [accessCodeRequired, setAccessCodeRequired] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string|undefined>(urlAccessCode);

  const [errors, setErrors] = useState<any[]>([]);
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [cargo, setCargo] = useState<CargoProps>({
    uuid: uuid||undefined,
    type: 'general',
    date_in_obj: undefined
  });

  const [documents, setDocuments] = useState<any[]>([]);
  
  useEffect(() => {
    if(localStorage.getItem('auth_token')) {
      // redirect to the login page
      navigate('/inventory/cargoes/view/' + uuid, { replace: true });
      return;
    }

    if(accessCode) {
      getCargo();
    }
    else {
      setAccessCodeRequired(true);
    }
    
    setIsReady(true);
  }, []);

  const getCargo = async () => {
    if(!uuid) {
      setErrors([{
        en: 'Missing cargo UUID'
      }]);
      
      return;
    }

    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.access_code = accessCode;
    EV.uuid = uuid;

    var res:any = await EV.getCargo();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if ([400, 404].includes(res.code)) {
      // record not found
      setNotFound(true);
    }

    else if(res.data) {
      // convert the date recieved from the server to
      // from date tiem to date only format
      var cargo = res.data.cargo;
      
      if(cargo.date_in) {
        var convertedDate = momentTz(cargo.date_in).tz(moment.tz.guess());
        cargo.date_in_obj = dayjs(convertedDate.format())
        
        try {
          cargo.date_in = convertedDate.format();
          cargo.date_in = cargo.date_in.split('T')[0]; 
        }
        catch(e) {
          console.error(
            'Failed to convert date_in time', cargo.date_in, e
          );
        }
      }
      
      setCargo(cargo);
      setDocuments(res.data.documents);
    }

    setIsLoading(false);
    return null;
  }

  const _renderYesNo = (value:string|null|undefined) => {
    if(value === 'n') return 'No';
    else if (value === 'y') return 'Yes';
    else return '-';
  }

  if(accessCodeRequired) return <div 
    style={{
      display: 'flex', 
      justifyContent: 'center',
      margin: '0 auto',
      width: 400,
      marginTop: 20, 
      flexDirection: 'column'
    }}
  >
    <Typography.Title level={2}>Access Code Required</Typography.Title>

    <Alert
      message={'This file is protect with an access code. Please check '
        + 'Your email for the access code or request a new link from '
        + 'the sender.'}
      type='error'
    />

    <div style={{marginTop: 20}} />
    
    <Form.Item
      name={'containers_uuid'}
      required
    >
      <Input.Password
        placeholder={'6 digits security code'}
        value={`${accessCode}`}
        status={(accessCodeMissing) ? 'error' : undefined}
        onChange={(e) => {
          setAccessCodeMissing(false);
          setAccessCode(e.target.value)
        }}
      />
    </Form.Item>

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => {
        if(!accessCode || accessCode.length < 6) {
          setAccessCodeMissing(true)
        }
        else {
          setAccessCodeRequired(false);
          getCargo();
        }
      }}
    >
      Access
    </Button>
  </div>

  else if(errors && errors.length > 0) return <div style={{
    display: 'flex', 
    justifyContent: 'center',
    margin: '0 auto',
    width: 400,
    marginTop: 20, 
    flexDirection: 'column'
  }} >
    <Typography.Title level={2}>Oops!</Typography.Title>

    <Alert
      message={errors?.map((error:any, key:number) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <div>{error}</div>;
      })}
      style={{marginBottom: 20}}
      type='error'
    />

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => window.close()}
    >
      Close and Try Again
    </Button>
  </div>

  else if(
    !cargo 
    || !isReady
    || isLoading
  ) return <div 
    style={{
      display: 'flex', 
      justifyContent: 'center',
      margin: '0 auto',
      minWidth: 840,
      maxWidth: 1200,
    }}
  >
    <Skeleton />
  </div>
  
  return <div 
    style={{
      margin: '0 auto',
      minWidth: 840,
      maxWidth: 1200,
      overflow: 'auto',
      height: '100vh',
      paddingTop: '15px',
      paddingBottom: '15px' 
    }}
  >
    <Space>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Login'}
        onClick={() => navigate('/inventory/cargoes/view/' + uuid, { replace: true })}
      />
      <Button
        type={'primary'}
        icon={<ReloadOutlined />}
        children={'Reload'}
        onClick={getCargo}
      />
    </Space>
    
    <Typography.Title level={1} children={'Cargo Details'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'ID:'} /></div>
        <div><Typography.Text children={cargo?.uuid} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Exporter:'}/></div>
        <div>
          <Typography.Text children={cargo?.exporters_company_name} /><br />
          <Typography.Text 
            italic 
            style={{textTransform: 'capitalize'}} 
            children={displayName(cargo?.exporters_name_prefix, cargo?.exporters_first_name, cargo?.exporters_last_name)} 
          />
        </div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={11}>
        <div><Typography.Text strong children={'Warehouse:'}/></div>
        <div>
          <Typography.Text children={`${cargo?.warehouses_city}, ${cargo?.warehouses_state}, ${cargo?.warehouses_country}`} /><br />
          <Typography.Text italic strong children={cargo?.warehouses_name} />
          <span style={{marginLeft: 10}}>
            <Typography.Text italic children={'@ ' + cargo?.date_in} />
          </span>
        </div>
      </Col>
      <Col span={1}>
        <Typography.Title level={5} children={'\u2794'} />
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Destination:'} /></div>
        <div>
          <Typography.Text children={`${cargo?.destination_city}, ${cargo?.destination_state}, ${cargo?.destination_country}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed/>

    {(cargo?.type === 'vehicle') && <>
      <Typography.Title level={2} children={'Vehicle Details'} />

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'VIN #:'} /></div>
          <div><Typography.Text children={cargo?.vin_number||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Make:'} /></div>
          <div><Typography.Text children={cargo?.make||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Model:'} /></div>
          <div><Typography.Text children={`${cargo?.model||'-'} ${cargo?.fuel_type}`} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Year:'} /></div>
          <div><Typography.Text children={cargo?.year||'-'} /></div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Color:'} /></div>
          <div><Typography.Text children={cargo?.color||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Mileages:'} /></div>
          <div><Typography.Text children={cargo?.mileages||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Number of Keys:'} /></div>
          <div><Typography.Text children={cargo?.number_of_keys||'-'} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'LOT #:'} /></div>
          <div><Typography.Text children={cargo?.lot_number||'-'} /></div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Has Title:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.has_title)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Title #:'} /></div>
          <div><Typography.Text children={cargo?.title||'-'} /></div>
        </Col>
        <Col span={8}>
          <div><Typography.Text strong children={'Title Origin:'} /></div>
          <div>
            <Typography.Text children={cargo?.title_state||'-'} />
            <Typography.Text children={', '} />
            <Typography.Text children={cargo?.title_country||'-'} />
          </div>
        </Col>
      </Row>

      <Row gutter={10} style={{margin: '30px 0'}}>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Salvage:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_salvage)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Dismantled:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_dismantled)} /></div>
        </Col>
        <Col span={6}>
          <div><Typography.Text strong children={'Is Running:'} /></div>
          <div><Typography.Text children={_renderYesNo(cargo?.is_running)} /></div>
        </Col>
        <Col span={6}></Col>
      </Row>

      <Divider dashed/>
    </>}

    <Typography.Title level={2} children={'General Details'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'Is Owner Known:'} /></div>
        <div><Typography.Text children={_renderYesNo(cargo?.is_owner_known)} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Quantity:'} /></div>
        <div><Typography.Text children={cargo?.quantity||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Value:'} /></div>
        <div><Typography.Text children={'$' + Number(cargo?.value).toFixed(2)||'-'} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Weight:'} /></div>
        <div><Typography.Text children={(Number(cargo?.weight).toFixed(2)||'-') + ' KG'} /></div>
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Insurance Details'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={12}>
        <div><Typography.Text strong children={'Insurance Type:'} /></div>
        <div><Typography.Text style={{textTransform: 'capitalize'}} children={cargo?.insurance_type} /></div>
      </Col>
      <Col span={12}>
        <div><Typography.Text strong children={'Insurance Value:'} /></div>
        <div><Typography.Text children={'$' + cargo?.insurance_value||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Documents'} />
      
    <Row gutter={10} style={{marginBottom: 20}}>
      {(cargo?.type === 'vehicle' && cargo?.has_title === 'y') && <>
        <Col span={8}>
          <Documents
            purpose={'cargo'}
            purposeUuid={cargo.uuid}
            category={'title'}
            title={'Title Images & Documents'}
            titleLevel={5}
            documents={documents}
            accessCode={accessCode}
          />
        </Col>
      </>}
      <Col span={(cargo?.type === 'vehicle' && cargo?.has_title === 'y') ? 16 : 24}>
        <Documents
          purpose={'cargo'}
          purposeUuid={cargo.uuid}
          category={'general'}
          title={'Cargo Images & Documents'}
          titleLevel={5}
          documents={documents}
          accessCode={accessCode}
        />
      </Col>
    </Row>

    <Divider dashed/>

    <Typography.Title level={2} children={'Notes'} />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={24}>
        <div><Typography.Text children={cargo?.public_notes||'-'} /></div>
      </Col>
    </Row>
  </div>
}
