/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Col, Row, Statistic, Typography } from "antd";
import { ExporterProps } from "./form";
import EVAPI from '../../lib/ev_lib/main';
import { URLQuery } from '../../util/url_queries';

interface ComponentProps {
  exporterUuid:string,
  style?:any
}

export default function ExportersSummaryView(props:ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [exporter, setExporter] = useState<ExporterProps>({
    uuid: props.exporterUuid,
    company_name: null,
    is_active: 'y'
  });

  useEffect(() => {
    getExporter();
  }, [])

  const getExporter = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = exporter.uuid;

    var res:any = await EV.getExporter();
    
    if(!res) {
      // > handle error
      console.warn('Failed to load exporter at ExportersSummaryView')
    }

    else if(res.data) {
      setExporter(res.data);
    }

    setIsLoading(false);
    return null;
  }

  if(!props.exporterUuid) return null;

  return <>
    <Typography.Title level={4} children={exporter.company_name} />

    <Alert
      style={{marginTop: 15, marginBottom: 15}}
      showIcon
      type={'warning'}
      message={'This feature is not available at the moment.'}
    />

    <Row gutter={[15, 15]}>
      <Col span={6} xs={12} md={12} lg={6} xl={6}>
        <Statistic
          title={'Total Cargoes'}
          value={0}
        />
      </Col>
      <Col span={6} xs={12} md={12} lg={6} xl={6}>
        <Statistic
          title={'Unpacked Cargoes'}
          value={0}
        />
      </Col>
      <Col span={6} xs={12} md={12} lg={6} xl={6}>
        <Statistic
          title={'Arriving Containers'}
          value={0}
        />
      </Col>
      <Col span={6} xs={12} md={12} lg={6} xl={6}>
        <Statistic
          title={'Due Balance'}
          value={0}
        />
      </Col>
    </Row>
  </>
}