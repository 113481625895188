/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Input, Space, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import EVAPI from '../lib/ev_lib/main';
import { URLQuery } from '../util/url_queries';

export interface commentsProps {
  module:'cargoes'|'packing orders'|'containers'|'packing plans',
  moduleUuid:string|undefined,
  title?:string|undefined,
  titleLevel?:1|2|3|4|5|undefined
}

export interface commentProps {
  uuid:string|undefined,
  users_uuid:string|undefined,
  users_username:string|undefined,
  users_type:'management'|'exporter',
  module:'cargoes'|'packing orders'|'containers'|'packing plans',
  module_uuid:string|undefined,
  content:string|undefined,
  created_at:Date|string|undefined,
  updated_at?:Date|string|undefined
}

export default function Comments(props:commentsProps) {
  let profile:any = localStorage.getItem('profile');

  try {
    profile = JSON.parse(profile);
  }
  catch(e) {
    console.error('Error parsing the user profile', e);
  }

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [comments, setComments] = useState<commentProps[]>([]);
  const [content, setContent] = useState<string|null>(null);

  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    if(isLoading) return;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = props.module;
    EV.moduleUuid = props.moduleUuid;
    EV.showAll = true;
    EV.orderDirection = 'ASC';
    
    var res:any = await EV.getComments();
    
    if(res.data) {
      setComments(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isProcessing) return;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = props.module;
    EV.module_uuid = props.moduleUuid;
    EV.content = content;
    
    var res:any = await EV.createComment();
    
    if(!res) {
      // > network error
    }

    else if (res.status === 'fail') {
      // >
    }

    else {
      setComments([
        ...comments,
        {
          ...res.data,
          users_type: profile.type,
          users_uuid: profile.uuid,
          users_username: profile.username,
          created_at: new Date()
        }
      ]);

      setContent(null);
    }

    setIsProcessing(false);
    return null;
  }

  const processButtonState = ():boolean => {
    if(isProcessing) return true;
    else if (!content || content.length < 1) return true;
    else return false;
  }
  
  return <>
    <Typography.Title level={props.titleLevel||2}>
      {props.title||'Comments'}
    </Typography.Title>

    <Typography.Paragraph>
      <Typography.Text type={'secondary'}>
        Comments are only visible to management
      </Typography.Text>
    </Typography.Paragraph>

    {comments.map((comment:commentProps, key:number) => {
      var createdAt:string|undefined = undefined;
      var updatedAt:string|undefined = undefined;

      if(comment.created_at) {
        createdAt = moment(comment.created_at).fromNow();
      }
      else {
        createdAt = '...';
      }
      
      if(comment.updated_at) {
        updatedAt = moment(comment.updated_at).fromNow();
      }
      else {
        updatedAt = '...';
      }

      var borderColor:string = '#ddd';

      if(comment.users_uuid === profile.uuid) {
        borderColor = blue[2]!;
      }

      // return the comment
      return <div
        style={{
          padding: 10, 
          paddingRight: 0,
          marginBottom: 10,
          borderLeft: `3px solid ${borderColor}`
        }}
      >
        <div style={{marginBottom: 10}}>
          <Avatar size={25} icon={<UserOutlined />} />
          <Typography.Text strong style={{marginLeft: 5}}>{comment.users_username}</Typography.Text>
          <Typography.Text type='secondary' style={{marginLeft: 10}}>{createdAt}</Typography.Text>
        </div>
        <Typography.Text>{comment.content}</Typography.Text>
      </div>
    })}

    {/** Add new comment form */}
    <Form
      form={form}
      layout="vertical"
      onFinish={processSubmit}
    >
      <Form.Item>
        <Input.TextArea
          placeholder={'Leave your comment...'}
          value={content||''}
          onChange={(e) => setContent(e.target.value)}
          disabled={isProcessing}
        />
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isProcessing}
            disabled={processButtonState()}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </>
}