/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Skeleton, Space, Switch, Typography, message } from "antd";
import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import { LeftCircleFilled, WarningFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import EVAPI from '../../../lib/ev_lib/main';
import { ContactProps } from '../../../address_book/general/contact_form';
import { displayName } from '../../../lib/ev_lib/builders';
import { UserProps } from './form';
import { ExporterProps } from '../../../address_book/exporters/form';
import ExportersSummaryView from '../../../address_book/exporters/summary_view';
import { LoginProfileProps } from '../../../auth/login';
import { UserSettingsProps } from '../../../common/ts_props/users';
import { URLQuery } from '../../../util/url_queries';
import { setClearFormFields } from '../../../util/clear_empty';
import { 
  convertUserSettingsArrayDatasetToObject,
  convertPermissionsKeyDatasetToStringArray
 } from '../../../lib/ev_lib/data_processors';

export default function SettingsUsersView(props:any) {
  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined);

  const [isLoadingContact, setIsLoadingContact] = useState<boolean>(false);
  const [contact, setContact] = useState<ContactProps|undefined>(undefined);

  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [user, setUser] = useState<UserProps|undefined>(undefined);
  const [userSettings, setUserSettings] = useState<UserSettingsProps>({});

  const [isLoadingExporter, setIsLoadingExporter] = useState<boolean>(false);
  const [exporters, setExporters] = useState<[ExporterProps]|undefined>(undefined);

  let loginProfile:LoginProfileProps|undefined = undefined;
  
  useEffect(() => {
    var tempProfile:string|null = localStorage.getItem('profile');

    try {
      if(typeof tempProfile !== 'object') {
        loginProfile = JSON.parse(tempProfile);
      }

      if(
        loginProfile?.type
        && ['management', 'exporter'].includes(loginProfile?.type)
      ) {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };
    
    getUser();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
    getContact();
  }, [user]);

  const getUser = async () => {
    if(isLoadingUser) return null;
    setIsLoadingUser(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;
    var res:any = await EV.getUser();
    
    if(!res) {
      messageApi.open({
        key: 'getUserNetworkError',
        type: 'error',
        content: 'Failed to load user'
      })
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getUserFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else if(res.code === 200) {
      var user = res.data;
      var tempSettings = {};

      setUser(user);
    
      var tempUserSettings = res.data.settings;
      tempUserSettings = convertUserSettingsArrayDatasetToObject(tempUserSettings);
      localStorage.setItem('user_settings', JSON.stringify(tempUserSettings));
      setUserSettings(tempUserSettings);
  
      var tempPermissions = res.data.permissions;
      tempPermissions = convertPermissionsKeyDatasetToStringArray(tempPermissions);
      localStorage.setItem('user_permissions', JSON.stringify(tempPermissions));

      if(user.type === 'exporter') {
        getExporters();
      }
    }

    setIsLoadingUser(false);
    return null;
  }

  const getContact = async () => {
    if(isLoadingContact) return null;
    setIsLoadingContact(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = user?.contacts_uuid;

    var res:any = await EV.getContact();
    
    if(!res) {
      messageApi.open({
        key: 'getContactNetworkError',
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      var tempContact = res.data;
      var tags = tempContact?.tags;

      if(tags) tags = JSON.parse(tags);

      tempContact = {
        ...tempContact,
        tags: tags
      }

      setContact(tempContact);
    }

    setIsLoadingContact(false);
    return null;
  }

  const getExporters = async () => {
    if(isLoadingExporter) return null;
    setIsLoadingExporter(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.primaryContactsuuid = contact?.uuid;

    var res:any = await EV.getExporters();
    
    if(!res) {
      messageApi.open({
        key: 'geExporterNetworkError',
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getExporterFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else if(res.data) {
      setExporters(res.data);
    }

    setIsLoadingExporter(false);
    return null;
  }

  const updateUserSettings = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.users_uuid = user?.uuid;

    EV.clear = setClearFormFields(userSettings)||undefined;

    EV = Object.assign(EV, userSettings);
    
    var res:any = await EV.updateUserSettings();

    if(!res) {
      messageApi.open({
        key: 'processUserSettingsNetworkError',
        type: 'error',
        content: 'Failed to process your request connection error'
      });
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else {
      messageApi.open({
        key: 'processUserSettingsSuccess',
        type: 'success',
        content: 'Process completed successfully'
      });

      getUser();
    }

    setIsProcessing(false);
    return null;
  }
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
    </Space>}
  >
    {contextHolder}

    <Typography.Title level={1} children={'Profile'} />

    <Typography.Title level={2} children={'Contact Information'} />

    {(isLoadingContact) ? <Skeleton /> : <Row gutter={[15, 15]}>
      <Col span={12} xs={24} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>ID #:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.uuid}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Full Name:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{displayName(contact?.name_prefix, contact?.first_name, contact?.last_name)}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Company Name:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.company_name||'-'}</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={12} xs={24} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Phone:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.phone_country_code||'-'} {contact?.phone||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Fax:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.fax_country_code||'-'} {contact?.fax||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Email:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.email||'-'}</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={12} xs={24} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Street Address:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.address_1||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Unit / Apt / Bld #:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.address_2||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Country:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{contact?.city}, {contact?.state}, {contact?.country}</Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>}

    <Divider className={'compact-view-hide'} dashed />

    <Typography.Title 
      level={2} 
      children={'Login Information'} 
    />

    {(isLoadingUser) ? <Skeleton /> : <Row gutter={[15, 15]}>
      <Col span={12} xs={24} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>ID #:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{user?.uuid||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Account Type:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text style={{textTransform: 'capitalize'}}>{user?.type||'-'}</Typography.Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <Typography.Text strong>Username:</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{user?.username||'-'}</Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={12} xs={24} md={12} lg={12} xl={12}></Col>
    </Row>}

    <Divider className={'compact-view-hide'} dashed />

    {(user?.type === 'exporter') && <>
      <Typography.Title level={2} children={'Exporter Information'} />

      {(isLoadingExporter) ? <Skeleton /> : <>
        {exporters?.map((record:any, key:number) => <ExportersSummaryView 
          key={key}
          exporterUuid={record.uuid} 
        />)}
      </>}
    </>}

    <Divider className={'compact-view-hide'} dashed />

    <Typography.Title level={2} children={'Settings & Preferences'} />

    {(isLoadingUser) ? <Skeleton /> : <>
      {(errors && errors.length > 0) && <Alert
        type={'error'}
        message={'Errors submitting your data'}
        description={<ul>{errors.map((error, key) => {
          if(typeof error === 'object') {
            error = error.en
          }

          return <li>{error}</li>;
        })}</ul>}
        closable
        onClose={() => setErrors(null)}
        style={{marginBottom: 20}}
      />}
      
      <Form
        form={form}
        layout="vertical"
        initialValues={userSettings}
        onFinish={updateUserSettings}
      >
        <Row gutter={[15, 15]}>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item
              label={'Timezone'} 
              name={'timezone'}
            >
              <Select
                placeholder={'Select one'}
                onChange={(value) => setUserSettings({
                  ...userSettings,
                  timezone: value
                })}
                options={[]}
              />
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item
              label={'Date Format'} 
              name={'date_format'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  date_format: e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider dashed />

        <Row gutter={[15, 15]}>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item>
              <Switch 
                checked={userSettings?.auto_fill_cargo_date_in === 'y' ? true : false} 
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  auto_fill_cargo_date_in: e ? 'y' : 'n'
                })}
              />
              <Typography.Paragraph strong style={{marginTop: 15}}>
                Auto Fill Cargo Date In
              </Typography.Paragraph>
              <Typography.Paragraph>When creating a new cargo the system will auto 
                fill the date in.
              </Typography.Paragraph>
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item>
              <Switch 
                checked={userSettings?.image_optimization_default_enabled === 'y' ? true : false} 
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  image_optimization_default_enabled: e ? 'y' : 'n'
                })}
              />
              <Typography.Paragraph strong style={{marginTop: 15}}>
                Enable Image Optimizer on Upload by Default
              </Typography.Paragraph>
              <Typography.Paragraph>When uploading new images in documents section the system will 
                automatically set the optimized as "Enabled".
              </Typography.Paragraph>
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item>
              <Switch 
                checked={userSettings?.hide_right_side_menu_by_default === 'y' ? true : false} 
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  hide_right_side_menu_by_default: e ? 'y' : 'n'
                })}
              />
              <Typography.Paragraph strong style={{marginTop: 15}}>
                Hide Right Side Panel by Default
              </Typography.Paragraph>
              <Typography.Paragraph>The right side panel holds additional related information and actions. 
                Some users may prefer to have it hidden by default.
              </Typography.Paragraph>
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item>
              <Switch 
                checked={userSettings?.compact_view === 'y' ? true : false} 
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  compact_view: e ? 'y' : ''
                })}
              />
              <Typography.Paragraph strong style={{marginTop: 15}}>
                Compact View
              </Typography.Paragraph>
              <Typography.Paragraph>Switch to on to enable the compact view. Restart 
                is required after updating this option.
              </Typography.Paragraph>
            </Form.Item>
          </Col>
        </Row>

        <Divider dashed />

        <Row gutter={[15, 15]}>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item
              label={'Auto Fill Container Port of Export Country'} 
              name={'auto_fill_container_port_of_export_country'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  auto_fill_container_port_of_export_country: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item
              label={'Auto Fill Container Port of Export State'} 
              name={'auto_fill_container_port_of_export_state'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  auto_fill_container_port_of_export_state: e.target.value
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={16} sm={16} md={8} lg={6} xl={6}>
            <Form.Item
              label={'Auto Fill Container Port of Export City'} 
              name={'auto_fill_container_port_of_export_city'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setUserSettings({
                  ...userSettings,
                  auto_fill_container_port_of_export_city: e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button 
              type={"primary"} 
              htmlType={"submit"}
              loading={isProcessing}
            >
              Update
            </Button>
            <Button type={"default"}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>}
  </BlankPage>
}