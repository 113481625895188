/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EVAPI from '../../../lib/ev_lib/main';
import { config } from '../../../config';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { Alert, Button, Form, Input, Skeleton, Typography } from 'antd';

import jsPDF from 'jspdf';
import { URLQuery } from '../../../util/url_queries';

export default function ExportCargoesListing(props:any) {  
  const searchTerm = URLQuery('searchTerm', window);
  const warehouses_uuids = URLQuery('warehouses_uuids', window);
  const exporters_uuid = URLQuery('exporters_uuid', window);
  const destination_country = URLQuery('destination_country', window);
  const destination_state = URLQuery('destination_state', window);
  const destination_city = URLQuery('destination_city', window);
  const vin_number = URLQuery('vin_number', window);
  const has_title = URLQuery('has_title', window);
  const title = URLQuery('title', window);
  const insurance_type = URLQuery('insurance_type', window);
  const date_in_from = URLQuery('date_in_from', window);
  const date_in_to = URLQuery('date_in_to', window);
  const execludePackedCargoes = URLQuery('execludePackedCargoes', window);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cargoesCount, setCargoesCount] = useState<number>(0);
  const [cargoes, setCargoes] = useState<any>(null);

  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    getCargoes();
    getCargoes(true);
  }, []);

  useEffect(() => {
    updateDocumentMeta();
  }, cargoes);

  const getCargoes = async (count:boolean = false) => {
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    EV.orderBy = localStorage.getItem('prefCargoesListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefCargoesListingOrderDirection');

    EV.searchTerm = searchTerm;
    EV.warehousesUuids = warehouses_uuids||undefined;
    EV.exportersUuid = exporters_uuid||undefined;
    EV.destinationCountry = destination_country||undefined;
    EV.destinationState = destination_state||undefined;
    EV.destinationCity = destination_city||undefined;
    EV.vinNumber = vin_number||undefined;
    EV.hasTitle = has_title||undefined;
    EV.title = title||undefined;
    EV.insuranceType = insurance_type||undefined;
    EV.dateInFrom = date_in_from||undefined;
    EV.dateInTo = date_in_to||undefined;

    if(execludePackedCargoes) {
      EV.linkedToPackingOrder = 'n';
    }

    // handle count if set
    EV.count = count;

    var res:any = await EV.getCargoes();
    
    if(count) { 
      if(res.data?.count) {
        setCargoesCount(Number(res.data.count)); 
      }
      else setCargoesCount(0);
    }
    else setCargoes(res.data);
    
    return;
  }

  const updateDocumentMeta = () => {
    document.title = 'Cargoes List';
  }

  const _renderDate = (date:any) => {
    try {
      var processedDate = moment(date);
      
      return processedDate.format('YYYY/MM/DD');
    }
    catch(e) {
      // console the error
      return '//** Err'
    }
  }

  if(!cargoes || isLoading) return <div>
    <Skeleton />
  </div>
  
  else if(errors && errors.length > 0) return <div style={{
    width: 400,
    marginTop: 20, 
    flexDirection: 'column'
  }} >
    <Typography.Title level={2}>Oops!</Typography.Title>

    <Alert
      message={errors?.map((error:any, key:number) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <div>{error}</div>;
      })}
      style={{marginBottom: 20}}
      type='error'
    />

    <Button 
      type={"primary"}
      loading={isLoading}
      onClick={() => window.close()}
    >
      Close and Try Again
    </Button>
  </div>

  return <div className='doc-page'>
    <div className={'document-action-bar execlude-print'}>
      <button className={'document-button'} onClick={() => {
        var doc = new jsPDF("landscape", "mm", [1400, 1000]);
        var pdfjs:any= document.querySelector('#element-to-print');

        doc.html(
          pdfjs, {
            callback: function(doc) {
              doc.save(`cargoes_list_${Date.now()}.pdf`);
            },
            x: 12,
            y: 12,
            margin: 15
          }
        );    
      }}>
        Save as PDF
      </button>
    </div>
    <div id={'element-to-print'} style={{flex: 1, flexDirection: 'column', padding: 10}}>
      <div style={{marginBottom: 20}}>
        <h2 style={{padding: 0}}>Cargo List</h2>
        <div>{cargoesCount} results found</div>
      </div>
      <table style={{width: '1330px'}} className={'docDataTable'} cellSpacing={0}>
        <thead>
          <tr>
            <th>Ref #</th>
            <th>Warehouse</th>
            <th>Exporter</th>
            <th>Date In</th>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Color</th>
            <th>VIN #</th>
            <th>Keys</th>
            <th>Has Title</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {cargoes?.map((cargo:any, index:number) => {
            var rowBackgroundColor = '#fff';
            
            if(index % 2 > 0) {
              rowBackgroundColor = '#f9f9f9';
            }

            return <tr style={{backgroundColor: rowBackgroundColor}}>
              <td width={"90px"}>{cargo.reference_id}</td>
              <td width={"300px"}>{cargo.warehouses_name}</td>
              <td width={"300px"}>{cargo.exporters_company_name}</td>
              <td width={"100px"}>{_renderDate(cargo.date_in)}</td>
              <td width={"90px"}>{cargo.year}</td>
              <td width={"120px"}>{cargo.make}</td>
              <td width={"120px"}>{cargo.model}</td>
              <td width={"120px"}>{cargo.color}</td>
              <td width={"300px"}>{cargo.vin_number}</td>
              <td width={"50px"}>{cargo.number_of_keys}</td>
              <td width={"50px"}>{cargo.has_title}</td>
              <td width={"150px"}>{cargo.modules_records_statuses_label}</td>
            </tr>
          })}
        </tbody>
      </table>
      <div style={{padding: '10px 0px', marginTop: 20, borderTop: '1px dashed #aaa'}}>
        <h4 style={{margin: '3px 0'}}>Filters:</h4> 
        <p style={{margin: '3px 0'}}>Following are the fitlers used to generate the list above:</p>
        {searchTerm && <div>- Search Term: {searchTerm}</div>}
        {warehouses_uuids && <div>- Warehouses UUIDs: {warehouses_uuids}</div>}
        {exporters_uuid && <div>- Exporter UUID: {exporters_uuid}</div>}
        {destination_country && <div>- Destination Country: {destination_country}</div>}
        {destination_state && <div>- Destination State: {destination_state}</div>}
        {destination_city && <div>- Destination city: {destination_city}</div>}
        {vin_number && <div>- VIN #: {vin_number}</div>}
        {has_title && <div>- Has Title: {has_title}</div>}
        {title && <div>- Title: {title}</div>}
        {insurance_type && <div>- Insurance Type: {insurance_type}</div>}
        {date_in_from && <div>- Date in from: {date_in_from}</div>}
        {date_in_to && <div>- Date in to: {date_in_to}</div>}
      </div>

      <div style={{borderTop: '1px dashed #aaa'}}>
        <p style={{fontSize: '9pt'}}>Generated using EVessel - <a href="https://evessel.io/" target="_blank">https://evessel.io</a></p>
      </div>
    </div>
  </div>
}