/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export const userPermissions = (
  key?:string
) => {
  let userPermissions:any = localStorage.getItem('user_permissions');

  if(!userPermissions) return false;

  try {
    // > This should be fixed in the future to insure TypeScript is affective
    userPermissions = JSON.parse(userPermissions);

    if(key) {
      return userPermissions.includes(key)
    }
    else return userPermissions
  }
  catch(e) {
    console.error(
      'System failed to parse user permissions',
      e
    );

    return false;
  }
}