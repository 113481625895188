import { blue, gold, red } from "@ant-design/colors"

export const progressColor = (
  value:number, 
  value_warning:number = 70, 
  value_urgent:number = 90
) => {
  if(value > value_urgent) return red[6];
  else if (value > value_warning) return gold[4];

  return blue[5];
}