/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { InvoiceProps } from "../form/general";
import { useParams } from "react-router-dom";
import { URLQuery } from "../../../../util/url_queries";
import EVAPI from "../../../../lib/ev_lib/main";
import { InvoiceItemProps } from "./industrial_invoice";
import { InvoiceTransactionProps } from "../form/transactions";
import moment from "moment";

export default function InvoiceModernView(props:any) {
  // set the invoice information
  require('./modern_style.css');
  
  const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  let { uuid } = useParams<any>();

  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(false);
  const [isLoadingInvoiceItems, setIsLoadingInvoiceItems] = useState<boolean>(false);
  const [isLoadingInvoiceTransactions, setIsLoadingInvoiceTransactions] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);

  const [invoice, setInvoice] = useState<any>({
    uuid: uuid||undefined,
    reference_number: undefined
  });

  const [containers, setContainers] = useState<any[]|undefined>(undefined);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemProps[]>([]);
  const [invoiceTransactions, setInvoiceTransactions] = useState<InvoiceTransactionProps[]>([]);

  const [companyInfo, setCompanyInfo] = useState<any>(undefined);
  const [exporterInfo, setExporterInfo] = useState<any>(undefined);

  useEffect(() => {
    getInvoice();
  }, []);

  // get the invoice
  const getInvoice = async () => {
    setIsLoadingInvoice(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getInvoiceIndustrial();
    
    if(!res) {
      // handle error
    }

    else if(res.code === 500) {
      // handle error
    }

    else if(res.data) {
      setInvoice(res.data.invoice);
      setContainers(res.data.containers);
      setInvoiceItems(res.data.invoice_items);
      getInvoicesTransactions();

      //
      if(res.data.invoice.company_info) try {
        setCompanyInfo(JSON.parse(res.data.invoice.company_info));
      }     
      catch(e) {
        console.error(e);
      }

      if(res.data.invoice.exporter_info) try {
        setExporterInfo(JSON.parse(res.data.invoice.exporter_info));
      }     
      catch(e) {
        console.error(e);
      }
    }

    setIsLoadingInvoice(false);
    return null;
  }

  const getInvoicesTransactions = async () => {
    setIsLoadingInvoiceTransactions(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoiceUuid = uuid;
    EV.showAll = 'y';

    var res:any = await EV.getInvoicesTransactions();
    
    if(!res) {
      // handle error
    }

    else if(res.code === 500) {
      // handle error
    }

    else if(res.data) {
      setInvoiceTransactions(res.data);
    }

    setIsLoadingInvoiceTransactions(false);
    return null;
  }

  const _renderContainersArrayAsList = (arrayProperty:string) => {
    var containersTracker:string[] = [];
    var render = '';

    containers?.map((container, index) => {
      if(containersTracker.includes(container[`${arrayProperty}`])) return null;
      else containersTracker.push(container[`${arrayProperty}`]);

      render += container[`${arrayProperty}`];

      if(index + 1 < containers.length) render += ', ';
    })

    return render;
  }
  
  return <div className='doc-page modern-invoice '>
    <div className={'document-action-bar execlude-print'}>
      <button className={'document-button'} onClick={() => {
        var doc = new jsPDF("p", "mm", [1000, 1400]);
        var pdfjs:any= document.querySelector('#element-to-print');

        doc.html(
          pdfjs, {
            callback: function(doc) {
              doc.save(`invoice_${Date.now()}.pdf`);
            },
            autoPaging: "text",
            margin: 10,
            x: 12,
            y: 12
          }
        );    
      }}>
        Save as PDF
      </button>
    </div>
    <div className="printable-cotnainer">
      <div className="modern-invoice" id={'element-to-print'}>
        <table className="container table">
          <tr>
            <td valign="top">
              <h1 
                style={{
                  marginBottom: 70
                }}
              >
                {exporterInfo?.company_name}
              </h1>
              <div>{exporterInfo?.street_address}</div>
              {exporterInfo?.address_2 && <div>
                {exporterInfo?.address_2}
              </div>}
              <div>
                {exporterInfo?.city}, {exporterInfo?.state}
              </div>
              <div>
                <strong>{exporterInfo?.country}</strong> {exporterInfo?.zip_code}
              </div>
            </td>
            <td valign="top" align="right">
              <h1 
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 'normal',
                  marginBottom: 70
                }}
              >
                Invoice # {invoice.reference_number}
              </h1>
              <div>
                Container(s) No. {_renderContainersArrayAsList('container_number')}<br />
                Booking(s) No. {_renderContainersArrayAsList('booking_number')} <br />
                Invoice ID. {invoice.uuid?.substring(invoice.uuid.length - 10)}<br />
                Issue Date: {moment(invoice.issue_date).format('Do MMM YYYY')}<br />
                Due Date: {moment(invoice.due_date).format('Do MMM YYYY')}
              </div>
            </td>
          </tr>
        </table>

        <table className="container table dark total-bar">
          <tr>
            <td style={{paddingBottom: 0}}>
              <h3 className="title">Total</h3>
            </td>
            <td style={{paddingBottom: 0}} align="right">
              <h2 style={{fontWeight: 'normal'}}>{formatter.format(Number(invoice.grand_total))}</h2>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom: 0, paddingTop: 0}}>
              <h3 className="title">Total Paid</h3>
            </td>
            <td style={{paddingBottom: 0, paddingTop: 0}} align="right">
              <h2 style={{fontWeight: 'normal'}}>{formatter.format(Number(invoice.total_paid))}</h2>
            </td>
          </tr>
          <tr>
            <td style={{paddingTop: 0}}>
              <h1 className="amount">Remaining Balance</h1>
            </td>
            <td style={{paddingTop: 15}} align="right">
              <h1 className="amount">
                {formatter.format((Number(invoice.grand_total) - Number(invoice.total_paid)))}
              </h1>
            </td>
          </tr>
        </table>

        <table className="container table items-table" style={{marginTop: 30}}>
          <thead>
            <tr>
              <th align="left">Item & Description</th>
              <th align="right">Unit Price</th>
              <th align="right">Qty.</th>
              <th align="right">Tax</th>
              <th align="right">Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems?.map((item:InvoiceItemProps, index:number) => {
              var tax:any = 0;

              if(item.taxable === 'y') {
                tax = Number(item.price) * Number(item.quantity) * Number(invoice.tax_rate)
              }

              return <tr className={((index + 1) === invoiceItems.length) ? 'last' : ''}>
                <td width="350">
                  <div><strong className="capitalize">{item.item}</strong></div>
                  <div>{item.description}</div>
                </td>
                <td width="150" align="right">{formatter.format(Number(item.price))}</td>
                <td width="80" align="right">{Number(item.quantity)}</td>
                <td width="100" align="right">{formatter.format(Number(tax))}</td>
                <td width="150" align="right">{formatter.format(Number(item.total))}</td>
              </tr>
            })}
          </tbody>
          <tfoot>
            <tr>
              <th align="right"></th>
              <th align="right"></th>
              <th align="right"></th>
              <th align="right">{formatter.format(Number(invoice.tax_total))}</th>
              <th align="right">{formatter.format(Number(invoice.grand_total))}</th>
            </tr>
          </tfoot>
        </table>

        <table className="container table">
          <tr>
            <td>
              <h2>Transactions <span className="transactions-date-proof">- As of {moment().format('Do MMM YYYY')}</span></h2>
            </td>
          </tr>
        </table>

        <table className="container table items-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Reference #</th>
              <th>Date</th>
              <th align="right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoiceTransactions?.map((item:any, index:number) => {
              return <tr className={((index + 1) === invoiceTransactions.length) ? 'last' : ''}>
                <td width="80">
                  {item.uuid.substring(item.uuid.length - 5)}
                </td>
                <td width="150" className="capitalize">{item.type}</td>
                <td width="80">{item.reference_number}</td>
                <td width="100">{moment(item.date).format('Do MMM YYYY')}</td>
                <td width="150" align="right">{formatter.format(Number(item.amount))}</td>
              </tr>
            })}
          </tbody>
          <tfoot>
            <tr>
              <th align="right"></th>
              <th align="right"></th>
              <th align="right"></th>
              <th align="right"></th>
              <th align="right">{formatter.format(Number(invoice.total_paid))}</th>
            </tr>
          </tfoot>
        </table>

        <table className="container table">
          <tr>
            <td>
              <h1>{companyInfo?.company_name}</h1>
            </td>
          </tr>
        </table>

        <table className="container table">
          <tr>
            <td width="30%" valign="top">
              <div>{companyInfo?.company_address_1}</div>
              {companyInfo?.company_address_2 && <div>
                {companyInfo?.company_address_2}
              </div>}
              <div>
                {companyInfo?.company_city}, {companyInfo?.company_state}
              </div>
              <div>
                <strong>{companyInfo?.company_country}</strong> {companyInfo?.company_zip_code}
              </div>
            </td>
            <td width="30%" valign="top">
              <div>
                <div><strong>Phone:</strong> {companyInfo?.company_phone}</div>
                <div><strong>Email:</strong> {companyInfo?.company_email}</div>
              </div>
            </td>
            <td valign="top">
              <div style={{whiteSpace: 'pre-line'}}>
                {invoice.remittince}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
}