export const getValueFromArrayDatasetWithKey = (
  key, dataset
) => {
  if(
    !key
    || !dataset
  ) {
    return undefined;
  }

  if(typeof key !== 'string') {
    return undefined;
  }

  if(typeof dataset !== 'object') {
    return undefined;
  }

  if(dataset.length < 1) {
    return undefined;
  }

  for(var i = 0; i < dataset.length; i++) {
    if(dataset[i].key === key) return dataset[i].value;
  }
}

export const convertSystemSettingsArrayDatasetToObject = (
  dataset
) => {
  if(!dataset) {
    return undefined;
  }

  if(typeof dataset !== 'object') {
    return undefined;
  }

  if(dataset.length < 1) {
    return {};
  }

  var tempObj = {};

  for(var i = 0; i < dataset.length; i++) {
    tempObj[`${dataset[i].uuid}`] = dataset[i].value;
  }

  return tempObj;
}

export const convertUserSettingsArrayDatasetToObject = (
  dataset
) => {
  if(!dataset) {
    return undefined;
  }

  if(typeof dataset !== 'object') {
    return undefined;
  }

  if(dataset.length < 1) {
    return {};
  }

  var tempObj = {};

  for(var i = 0; i < dataset.length; i++) {
    tempObj[`${dataset[i].key}`] = dataset[i].value;
  }

  return tempObj;
}

export const convertPermissionsKeyDatasetToStringArray = (
  dataset
) => {
  if(!dataset) return undefined;

  try {
    if(typeof dataset === 'string') {
      dataset = JSON.parse(dataset)
    }

    if(dataset.length < 1) return false;

    var tempArray = [];
    
    for(var i = 0; i < dataset.length; i++) {
      tempArray.push(dataset[i][`key`])
    }

    return tempArray;
  }
  catch(e) {
    console.error(
      'System failed to parse user permissions',
      e
    );

    return undefined;
  }
}