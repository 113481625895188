/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect, ReactElement } from 'react';

import { ApiFilled, CheckOutlined, CloseCircleFilled, DeleteFilled, DownloadOutlined, EditOutlined, ExportOutlined, FileAddFilled, InfoCircleOutlined, LeftCircleFilled, LoadingOutlined, MailFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Empty, Form, Input, message, Modal, Popover, Row, Segmented, Select, Space, Spin, Table, Tag, Typography } from "antd";

import SelectionStyledOption from '../../../../common/SelectionStyledOption';

import BlankPage, { LayoutModalProps } from "../../../../framework/blank_page";
import EVAPI from '../../../../lib/ev_lib/main';
import { config } from '../../../../config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { setClearFormFields } from '../../../../util/clear_empty';
import { URLQuery } from '../../../../util/url_queries';
import InvoicesFormItemsCargoesDrawer from './items_cargoes_drawer';
import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import { grey } from '@ant-design/colors';
import { userSettings } from '../../../../lib/user_settings';

export interface InvoiceItemProps {
  uuid?: string | undefined,
  invoices_uuid?: string,
  cargoes_uuid?: string | undefined,
  item?: string | undefined,
  description?: string | undefined,
  taxable?: 'y' | 'n',
  billable?: 'y' | 'n',
  listing_order?: string | number | undefined,
  price?: string | number,
  quantity?: string | number,
  total?:number|string,
  clear?: string[] | undefined
}

export default function InvoicesFormItems(props: any) {
  const formatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();

  const [form] = Form.useForm();

  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingItems, setIsGeneratingItems] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openInvoiceItemForm, setOpenInvoiceItemForm] = useState<boolean>(false);

  const [errors, setErrors] = useState<any[] | null>(null);

  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemProps[]>([]);
  const [invoiceItem, setInvoiceItem] = useState<InvoiceItemProps | undefined>(undefined);

  const [isLoadingAutoCompleteList, setIsLoadingAutoCompleteList] = useState<boolean>(false);
  const [autoCompleteList, setAutoCompleteList] = useState<any[]>([])
  const [autoCompleteSearchTerm, setAutoCompleteSearchTerm] = useState<string|null>(null);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({ open: false });

  let profile: any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if (typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }

      if (profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch (e) {
      console.warn(e);
    };

    if (uuid) getInvoiceItems();

    setTimeout(() => setIsReady(true), 1500);
  }, []);

  useEffect(() => {
    if(
      autoCompleteSearchTerm
      && autoCompleteSearchTerm?.length > 0
    ) {
      autoCompleteInvoiceItem();
    }
    else {
      setAutoCompleteList([]);
    }
  }, [autoCompleteSearchTerm])

  const autoCompleteInvoiceItem = async () => {
    if(!autoCompleteSearchTerm) return;

    setIsLoadingAutoCompleteList(true);

    var EV: any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window) || localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.searchTerm = autoCompleteSearchTerm;
    EV.resPerPage = 3;
    EV.orderBy = 'invoices_items.created_at';
    EV.orderDirection = 'DESC';

    var res: any = await EV.getInvoiceItems();
    
    setIsLoadingAutoCompleteList(false);

    if (!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });
    }

    else if (res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });
    }

    else if (res.data) {
      // Make sure ready is set here as well as the form will not be
      // rendered when the data is suppose to be set
      setAutoCompleteList(res.data);
      console.log('item search results:', res.data)
    }

    return;
  }

  const getInvoiceItems = async () => {
    setIsLoading(true);

    var EV: any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window) || localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoicesUuid = uuid;
    EV.showAll = 'y';

    var res: any = await EV.getInvoiceItems();
    
    setIsLoading(false);

    if (!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });
    }

    else if (res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });
    }

    else if (res.data) {
      // Make sure ready is set here as well as the form will not be
      // rendered when the data is suppose to be set
      setIsReady(true);
      setInvoiceItems(res.data);
      props.refreshStates();
    }

    return null;
  }

  const autoGenerateInvoiceItems = async () => {
    setIsGeneratingItems(true);

    var EV: any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window) || localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.invoice_uuid = uuid;

    var res: any = await EV.autoGenerateInvoiceItemsFromPackingOrders();

    if (!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull invoice data from server',
      });
    }

    else if (res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else if (res.status === "success") {
      getInvoiceItems();
      props.refreshStates();
    }

    setIsGeneratingItems(false);
    return null;
  }

  const processInvoiceItem = async () => {
    if (isLoading) return null;
    setIsLoading(true);

    var EV: any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window) || localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    // handle cleared fields
    if (
      invoiceItem
      && invoiceItem.uuid
    ) {
      // check clearables
      invoiceItem.clear = setClearFormFields(invoiceItem) || undefined;
    }

    EV = Object.assign(EV, invoiceItem);

    if (!invoiceItem?.invoices_uuid) {
      EV.invoices_uuid = uuid;
    }

    var res: any = null;

    if (
      invoiceItem
      && invoiceItem.uuid
    ) res = await EV.updateInvoiceItem();
    else res = await EV.createInvoiceItem();

    setIsLoading(false);

    if (!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });
    }

    else if (res.status === 'fail') {
      setErrors(res.errors);
    }

    else if (res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getInvoiceItems();
      setOpenInvoiceItemForm(false);
    }

    return null;
  }

  const deleteInvoiceItem = async (deleteUuid: string) => {
    if (isLoading) return null;
    setIsLoading(true);

    var EV: any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window) || localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = deleteUuid;

    var res: any = res = await EV.deleteInvoiceItem();

    if (!res || res.status === 'fail') {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });
    }

    else if (res.status === 'fail') {
      setErrors(res.errors);
    }

    else if (res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      getInvoiceItems();
    }

    setIsLoading(false);
    return null;
  }

  return <>
    {contextHolder}

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{ marginBottom: 20 }}
    />}

    <Modal
      open={openInvoiceItemForm}
      onCancel={() => setOpenInvoiceItemForm(false)}
      onOk={() => form.submit()}
      children={<>
        <Typography.Title
          level={4}
          children={"Invoice Item"}
        />

        <Form
          form={form}
          layout="vertical"
          initialValues={invoiceItem}
          onFinish={processInvoiceItem}
        >
          <Form.Item
            label={'Item'}
            name={'item'}
            required
          >
            <Input
              placeholder={'Type here'}
              value={invoiceItem?.item}
              onChange={(e) => {
                setAutoCompleteSearchTerm(e.target.value);
                
                setInvoiceItem({
                  ...invoiceItem,
                  item: e.target.value
                })
              }}
            />
            {(autoCompleteList?.length > 0) && <>
              <Typography.Text strong type="secondary" className='small-font'>
                Found mathing items:
              </Typography.Text>

              {autoCompleteList.map((item, index) => {
                return <div 
                  className='item-search-selection'
                  onClick={() => {
                    setInvoiceItem({
                      ...invoiceItem,
                      item: item.item,
                      description: item.description,
                      quantity: item.quantity,
                      price: item.price,
                      taxable: item.taxable,
                      billable: item.billable
                    });

                    form.setFieldsValue({
                      ...invoiceItem,
                      item: item.item,
                      description: item.description,
                      quantity: item.quantity,
                      price: item.price,
                      taxable: item.taxable,
                      billable: item.billable
                    });

                    // clear the set at the end
                    setAutoCompleteList([]);
                  }}
                >
                  <Row>
                    <Col span={18}>{item.item}</Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                        {formatter.format(item.price)}
                    </Col>
                    {item.description && <Col span={24}>
                      <Typography.Text italic className='small-font' type='secondary'>
                        {item.description}
                      </Typography.Text>
                    </Col>}
                  </Row>
                </div>
              })}
            </>}
            {(invoiceItem?.cargoes_uuid) ? <Typography.Paragraph style={{ marginTop: 5, marginBottom: 0 }}>
              <Typography.Link
                type={'danger'}
                onClick={() => setInvoiceItem({
                  ...invoiceItem,
                  cargoes_uuid: ''
                })}
              >
                [X] Remove linked cargo
              </Typography.Link>
            </Typography.Paragraph>
            : <Typography.Paragraph 
              style={{ marginTop: 5, marginBottom: 0 }}
            >
              <Typography.Text>
                <InvoicesFormItemsCargoesDrawer
                  onLinking={(record: any) => {
                    setInvoiceItem({
                      ...invoiceItem,
                      cargoes_uuid: record.uuid,
                      item: `${record.type} VIN #: ${record.vin_number}`,
                      description: `${record.make} ${record.model} (${record.fuel_type}) ${record.year}`
                    });

                    form.setFieldsValue({
                      ...invoiceItem,
                      cargoes_uuid: record.uuid,
                      item: `${record.type} VIN #: ${record.vin_number}`,
                      description: `${record.make} ${record.model} (${record.fuel_type}) ${record.year}`
                    });
                  }}
                />
              </Typography.Text>
            </Typography.Paragraph>}
          </Form.Item>
          <Form.Item
            label={'Description'}
            name={'description'}
          >
            <TextArea
              rows={2}
              placeholder={'Type here'}
              value={invoiceItem?.description}
              onChange={(e) => setInvoiceItem({
                ...invoiceItem,
                description: e.target.value
              })}
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={'taxable'}
          >
            <Checkbox
              checked={(invoiceItem?.taxable === 'y') ? true : false}
              onChange={(e) => {
                var value: 'y' | 'n' = 'n';
                var billable:'y'|'n' = invoiceItem?.billable||'n';

                if (e.target.checked) {
                  value = 'y';
                  billable = 'y';
                }

                setInvoiceItem({
                  ...invoiceItem,
                  taxable: value,
                  billable: billable
                });

                form.setFieldsValue({
                  ...invoiceItem,
                  taxable: value,
                  billable: billable
                });
              }}
              children={<Typography.Paragraph style={{ marginBottom: 0 }}>
                <Typography.Text strong>Taxable item </Typography.Text>
                <Typography.Text> - The item will be included in the invoice tax.</Typography.Text>
              </Typography.Paragraph>}
            />
          </Form.Item>
          <Form.Item
            name={'billable'}
          >
            <Checkbox
              checked={(invoiceItem?.billable === 'y') ? true : false}
              onChange={(e) => {
                var value: 'y' | 'n' = 'n';
                var taxable: 'y' | 'n' = invoiceItem?.taxable||'n';
                
                if (e.target.checked) {
                  value = 'y';
                }
                else {
                  taxable = 'n';
                }

                setInvoiceItem({
                  ...invoiceItem,
                  billable: value,
                  taxable: taxable
                });

                form.setFieldsValue({
                  ...invoiceItem,
                  billable: value,
                  taxable: taxable
                });
              }}
              children={<Typography.Paragraph style={{ marginBottom: 0 }}>
                <Typography.Text strong>Billable item </Typography.Text>
                <Typography.Text> - The item will be included in the invoice sub total.</Typography.Text>
              </Typography.Paragraph>}
            />
          </Form.Item>
          <Row gutter={[15, 15]}>
            <Col span={8}>
              <Form.Item
                label={'Listing Order'}
                name={'listing_order'}
                tooltip={"The ordered of the item on the invoice."}
              >
                <Input
                  placeholder={'Type here'}
                  value={invoiceItem?.listing_order}
                  onChange={(e) => setInvoiceItem({
                    ...invoiceItem,
                    listing_order: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'Price'}
                name={'price'}
                required
              >
                <Input
                  defaultValue={1}
                  placeholder={'Type here'}
                  value={invoiceItem?.price}
                  onChange={(e) => setInvoiceItem({
                    ...invoiceItem,
                    price: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'Quantity'}
                name={'quantity'}
                required
              >
                <Input
                  defaultValue={1}
                  placeholder={'Type here'}
                  value={invoiceItem?.quantity}
                  onChange={(e) => setInvoiceItem({
                    ...invoiceItem,
                    quantity: e.target.value
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>}
    />

    <Table
      size={userSettings('compact_view') ? 'small' : undefined}
      pagination={false}
      columns={[
        {
          key: 'uuid',
          dataIndex: 'uuid',
          title: "UUID",
          width: 70,
          render: (_: any, record: any) => {
            return <Popover
              placement='top'
              title='UUID'
              content={<Typography.Text copyable>{record.uuid}</Typography.Text>}
            >
              <InfoCircleOutlined />
            </Popover>
          }
        },
        {
          key: 'listing_order',
          dataIndex: 'listing_order',
          title: ' '
        },
        {
          key: 'item_description',
          title: 'Item / Description',
          width: 500,
          render: (_: any, record: any) => {
            return <div>
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                <Typography.Text strong>{record.item}</Typography.Text>
              </Typography.Paragraph>
              {record.description && <Typography.Paragraph style={{ marginBottom: 0, marginTop: 5 }}>
                <Typography.Text>{record.description}</Typography.Text>
              </Typography.Paragraph>}
            </div>
          }
        },
        {
          key: 'carges_uuid',
          title: ' ',
          width: 3,
          render: (_: any, record: any) => {
            if (record.cargoes_uuid) return <Button
              type="link"
              size="small"
              icon={<ExportOutlined />}
              onClick={() => window.open(
                `/inventory/cargoes/form/${record.cargoes_uuid}`,
                '_blank'
              )}
            />
          }
        },
        {
          key: 'taxable',
          dataIndex: 'taxable',
          title: 'Taxable',
          width: 40,
          render: (_: any, record: any) => {
            if (record.taxable === 'y') return 'Yes'
            else if (record.taxable === 'n') return 'No'
            else return '-'
          }
        },
        {
          key: 'billable',
          dataIndex: 'billable',
          title: 'Billable',
          width: 40,
          render: (_: any, record: any) => {
            if (record.billable === 'y') return 'Yes'
            else if (record.billable === 'n') return 'No'
            else return '-'
          }
        },
        {
          key: 'price',
          dataIndex: 'price',
          title: 'Unit Price',
          align: 'right',
          width: 140,
          render: (_: any, record: any) => {
            return record.price?.toFixed(2);
          }
        },
        {
          key: 'quantity',
          dataIndex: 'quantity',
          title: 'Qty.',
          width: 80,
          align: 'right'
        },
        {
          key: 'total',
          dataIndex: 'total',
          title: 'Total (Tax excl.)',
          align: 'right',
          width: 180,
          render: (_: any, record: any) => {
            return record.total?.toFixed(2);
          }
        },
        {
          key: 'action',
          title: ' ',
          align: 'right',
          render: (_: any, record: any) => {
            return <Space>
              <Button
                type='link'
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setInvoiceItem(record);
                  form.setFieldsValue(record);
                  setOpenInvoiceItemForm(true);
                }}
              />
              <Button
                type='link'
                size="small"
                icon={<CloseCircleFilled />}
                danger
                onClick={() => {
                  deleteInvoiceItem(record.uuid)
                }}
              />
            </Space>
          }
        }
      ]}
      dataSource={invoiceItems || []}
    />

    <div style={{ marginBottom: 35 }} />

    <Space>
      <Button
        icon={<PlusCircleOutlined />}
        type={"primary"}
        onClick={() => {
          var defaultFormValues: InvoiceItemProps = {
            item: undefined,
            description: undefined,
            listing_order: undefined,
            taxable: 'y',
            billable: 'y',
            price: 1,
            quantity: 1
          }

          setInvoiceItem(defaultFormValues);
          form.setFieldsValue(defaultFormValues);
          setOpenInvoiceItemForm(!openInvoiceItemForm);
        }}
      >
        Add Item
      </Button>
      <Button
        icon={<DownloadOutlined />}
        type={"default"}
        onClick={() => {
          autoGenerateInvoiceItems();
        }}
      >
        Auto Generate
      </Button>
    </Space>
  </>
}