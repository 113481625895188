/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, DeleteFilled, LeftCircleFilled } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, message, Row, Select, Space, Switch, Tabs, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import ContactsListing from '../general/contacts_listing';
import Documents from '../../common/documents';
import { setClearFormFields } from '../../util/clear_empty';
import { URLQuery } from '../../util/url_queries';

export interface ExporterProps {
  uuid: string|null|undefined,
  reference_id?: string|null|undefined,
  company_name?: string|null|undefined,
  gov_id_type?: 'EIN'|'Passport'|'SSN'|null|undefined,
  gov_id_number?: string|null|undefined,
  primary_contact_uuid?: string|null|undefined,
  is_active?: 'y'|'n'|null|undefined,
  clear?:string[]|undefined
}
export default function ExportersForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [newExporter, setNewExporter] = useState<boolean>(true);
  const [errors, setErrors] = useState<any[]|null>(null);
  const [selectedTab, setSelectedTab] = useState<'contacts'|'consignees'>('contacts');
  const [exporter, setExporter] = useState<ExporterProps>({
    uuid: uuid||null,
    is_active: 'y'
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  useEffect(() => {
    if(uuid) {
      getExporter();
    }
  }, [])

  const getExporter = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getExporter();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      setExporter(res.data);
      form.setFieldsValue(res.data)
      
      setNewExporter(false);
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async (customValues:any) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    EV.primary_contact_uuid = customValues?.primary_contact_uuid||exporter.primary_contact_uuid
    
    // handle cleared fields
    if(!newExporter) {
      // check clearables
      exporter.clear = setClearFormFields(exporter)||undefined;
    }
    
    Object.assign(EV, exporter)

    var res:any = null;

    if(exporter.uuid && !newExporter) res = await EV.updateExporter();
    else res = await EV.createExporter();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(res.data?.uuid && newExporter) {
          setExporter({
          ...exporter,
          uuid: res.data.uuid
        });

        navigate('/exporters/form/' + res.data.uuid, { replace: true });
      }
      
      setNewExporter(false);
    }

    setIsLoading(false);
    return null;
  }

  const deleteExporter = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = exporter?.uuid;
    
    var res:any = await EV.deleteExporter();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(exporter.uuid && !newExporter) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteExporter();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={exporter?.company_name||'New Exporter'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={exporter.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        uuid: uuid,
        company_name: exporter.company_name,
        gov_id_type: exporter.gov_id_type,
        gov_id_number: exporter.gov_id_number
      }}
      onFinish={processSubmit}
    >
      <Form.Item
        label={'Reference #'} 
        name={'reference_id'}
        tooltip={
          'The company internal identification number' 
          + ' for documentation and other purposes'
        }
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setExporter({
            ...exporter,
            reference_id: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Company Name'} 
        name={'company_name'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setExporter({
            ...exporter,
            company_name: e.target.value
          })}
        />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label={'Government ID Type'} 
            name={'gov_id_type'}
          >
            <Select 
              placeholder={'Select one'}
              onChange={(value) => setExporter({
                ...exporter,
                gov_id_type: value
              })}
              options={[
                { value: 'EIN', label: 'EIN' },
                { value: 'Passport', label: 'Passport' },
                { value: 'SSN', label: 'SSN' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Government ID #'} 
            name={'gov_id_number'}
          >
            <Input 
              placeholder={'Type here'}
              onChange={(e) => setExporter({
                ...exporter,
                gov_id_number: e.target.value
              })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Switch 
          checked={exporter.is_active === 'y' ? true : false} 
          onChange={(e) => setExporter({
            ...exporter,
            is_active: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Active Status
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>

    {(exporter.uuid && !newExporter) && <Tabs
      defaultActiveKey={selectedTab||'contacts'}
      onChange={(e:any) => setSelectedTab(e)}
      items={[
        {
          key: 'contacts',
          label: 'Contacts',
          children: <ContactsListing 
            showView
            showLinking
            additionalColumns={[
              {
                key: 'primary',
                title: 'Primary',
                render: (_:any, record:any) => {
                  if (exporter.primary_contact_uuid === record.uuid)
                    return <CheckOutlined style={{color: 'green'}} />
                  else {
                    return <Button 
                      type={'default'}
                      color={'red'}
                      size={'small'}
                      children={'Set Primary'}
                      onClick={() => {
                        setExporter({
                          ...exporter,
                          primary_contact_uuid: record.uuid
                        });

                        processSubmit({
                          primary_contact_uuid: record.uuid
                        })
                      }}
                    />
                  }
                }
              }
            ]}
            showTags
            type='exporters'
            typeUuid={exporter.uuid}
          />
        },
        {
          key: 'consignees',
          label: 'Consignees',
          children: <ContactsListing 
            showView
            showLinking
            showTags
            type={'exporters-consignees'}
            typeUuid={exporter.uuid}
            linkButtonLabel={'Link New Consignee'}
          />
        },
        {
          key: 'notify-parties',
          label: 'Notify Parties',
          children: <ContactsListing 
            showView
            showLinking
            showTags
            type={'exporters-notify-parties'}
            typeUuid={exporter.uuid}
            linkButtonLabel={'Link New Notify Party'}
          />
        },
        {
          key: 'forwarding-agents',
          label: 'Forwarding Agents',
          children: <ContactsListing 
            showView
            showLinking
            showTags
            type={'exporters-forwarding-agents'}
            typeUuid={exporter.uuid}
            linkButtonLabel={'Link New Forwarding Agents'}
          />
        },
        {
          key: 'documents',
          label: 'Documents',
          children: <Documents 
            allowUpload
            allowManage
            purpose={'exporter'}
            purposeUuid={exporter.uuid}
            category={'general'}
          />
        },
      ]}
    />}
  </BlankPage>
}