/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { Alert, Button, Col, Divider, Form, Input, MessageArgsProps, Row, Select, Space, Table, Typography, message } from "antd";
import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import { CheckOutlined, EditFilled, FormOutlined, LeftCircleFilled, PlusCircleFilled, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import EVAPI from '../../../lib/ev_lib/main';
import { URLQuery } from '../../../util/url_queries';
import { userSettings } from '../../../lib/user_settings';

export default function SettingsUsersGroupsListing(props:any) {
  const navigate = useNavigate();
  
  const [form] = Form.useForm();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined);
  
  const [filters, setFilters] = useState<{
    username?:string,
    email?:string,
    type?:string
  }>({});

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [usersGroups, setUsersGroups] = useState<any[]>([]);

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getUsersGroups();
    setIsReady(true);
  }, []);
  
  useEffect(() => {
  }, [searchTerm, filters])

  const getUsersGroups = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getUsersGroups();

    if(!res) {
      messageApi.open({
        key: 'getUsersGroupsServerError',
        type: 'error',
        content: 'Failed to load users groups'
      })
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getUsersGroupsFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });

      if(res.code === 404) {
        setNotFound(true);
      }
    }

    else if(res.code === 200) {
      setUsersGroups(res.data);
    }

    setIsLoading(false);
    return null;
  }
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    notFound={notFound}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized) && <>
        <Button
          type={'primary'}
          icon={<PlusCircleFilled />}
          children={'Add New'}
          onClick={() => navigate('/system/settings/users-groups/form')}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title level={1} children={'Users Groups'} />
    
    <Input
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
      onChange={(e) => setSearchTerm(e.target.value)}
    />

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={usersGroups} 
      columns={[
        { 
          key: 'uuid', 
          title: 'UUID', 
          dataIndex: 'uuid',
          render: (_:any, record:any) => {
            return record.uuid?.substr(record.uuid.length - 10);
          } 
        },
        { key: 'name', title: 'Name', dataIndex: 'name', width: '75%' },
        { 
          key: 'action', 
          title: 'Action', 
          width: 100,
          render: (_:any, record:any) => (
            <Button
              type='link'
              children={<FormOutlined />}
              onClick={() => navigate(`/system/settings/users-groups/form/${record.uuid}`)}
            />
          )
        },
      ]}
    />
  </BlankPage>
}