/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { Typography } from "antd";

export default function Unauthorized(props:any) {

  return <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
    <div className="not-found-container" style={{textAlign: 'center'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <img src={require('../assets/unauthorized.png')} width={300} />
      </div>
      <Typography.Title level={3} children={'Unauthorized Attempt'} />
      <Typography.Text>
        The connent you are trying to view is restricted to the
        logged in account.
      </Typography.Text>
    </div>
  </div>
}