/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import BlankPage, { LayoutModalProps } from '../../framework/blank_page';
import { Button, Col, Divider, Empty, Row, Segmented, Space, Steps, Typography, message } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';

import { URLQuery } from '../../util/url_queries';

import moment from 'moment';
import momentTz from 'moment-timezone';
import dayjs from 'dayjs';
import EVAPI from '../../lib/ev_lib/main';
import PackingOrdersCargoes from './cargoes';
import Documents from '../../common/documents';
import Comments from '../../common/comments';

export default function PackingOrdersView(props:any) {
  interface PackingOrderProps {
    uuid: string|undefined,
    modules_records_statuses_uuid?: string|null,
    containers_uuid: string|null,
    packages_type?: 'units'|'vehicles'|'packages'|'items'|'lots'|null,
    exporters_uuid?: string|null,
    exporters_reference_id?: string|null,
    exporters_company_name?:string|null|undefined,
    exporters_name_prefix?:string|null|undefined,
    exporters_first_name?:string|null|undefined,
    exporters_last_name?:string|null|undefined,
    exporters_consignees_contacts_uuid?: string|null,
    consignees_company_name?:string|null|undefined,
    consignees_name_prefix?:string|null|undefined,
    consignees_first_name?:string|null|undefined,
    consignees_last_name?:string|null|undefined,
    exporters_notify_party_contacts_uuid?: string|null,
    notify_party_company_name?:string|null|undefined,
    notify_party_name_prefix?:string|null|undefined,
    notify_party_first_name?:string|null|undefined,
    notify_party_last_name?:string|null|undefined,
    reference_number?: string|null,
    intermediate_consignees_uuid?: string|null,
    intermediate_consignee_company_name?:string|null|undefined,
    intermediate_consignee_name_prefix?:string|null|undefined,
    intermediate_consignee_first_name?:string|null|undefined,
    intermediate_consignee_last_name?:string|null|undefined,
    aes_itn?: string|null,
    steam_ship_lines_uuid?: string|null,
    internal_rate?: string|number|null,
    sales_total?: string|number|null,
    create_at?: string|null,
    update_at?: string|null,
    deleted_at?: string|null
  }

  interface ContainerProps {
    uuid: string|undefined,
    container_number?: string|null,
    destination_country?: string|null,
    destination_state?: string|null,
    destination_city?: string|null
  }

  const navigate = useNavigate();

  let { uuid } = useParams<any>();
  let containerUuid = URLQuery('containerUuid', window)

  const [messageApi, contextHolder] = message.useMessage();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [isManagement, setIsManagement] = useState<boolean>(false);
  
  const [packingOrdersStatuses, setPackingOrdersStatuses] = useState<any[]>([]);
  const [packingOrderStatusIndex, setPackingOrderStatusIndex] = useState<number|undefined>(undefined);
  const [isLoadingPackingOrdersStatuses, setIsLoadingPackingOrdersStatuses] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  const [packingOrder, setPackingOrder] = useState<PackingOrderProps>({
    uuid: uuid||undefined,
    containers_uuid: (!uuid && containerUuid) ? containerUuid : null
  });

  const [isLoadingContainer, setIsLoadingContainer] = useState<boolean>(false);
  const [container, setContainer] = useState<ContainerProps>({
    uuid: containerUuid||undefined,
    destination_country: null,
    destination_city: null
  });

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management', 'exporter'].includes(profile.type)) {
        setAuthorized(true);
      }

      if(['management'].includes(profile.type)) {
        setIsManagement(true);
      }
    }
    catch(e) {
      console.warn(e);
    };
    
    getPackingOrdersStatuses();

    // add a delay to load the screen required prequests
    setTimeout(() => {
      if(uuid) getPackingOrder();
      else if(containerUuid) getContainer();
      setIsReady(true);
    }, 2000)
  }, []);

  useEffect(() => {
    if(packingOrdersStatuses?.length > 0) {
      packingOrdersStatuses.map((status:any, key:number) => {
        if(status.value === packingOrder.modules_records_statuses_uuid) {
          setPackingOrderStatusIndex(key);
        }
      })
    }
  }, [packingOrder])

  const getPackingOrdersStatuses = async () => {
    if(isLoadingPackingOrdersStatuses) return null;
    setIsLoadingPackingOrdersStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'packing orders';
    EV.showAll = true;

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          title: item.label,
          description: item.description
        });
      });
    }

    setPackingOrdersStatuses(sortedData);

    setIsLoadingPackingOrdersStatuses(false);
    return null;
  }

  const getContainer = async (containerUuid:string|null = null) => {
    if(!packingOrder?.containers_uuid && !containerUuid) return null;
    setIsLoadingContainer(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = packingOrder?.containers_uuid||containerUuid;

    var res:any = await EV.getContainer();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull container data from server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      setContainer(res.data);
    }
    
    setIsLoadingContainer(false);
    return null;
  }

  const getPackingOrder = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getPackingOrder();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to download the packing order data from the server',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      var packingOrderRes = res.data;

      setPackingOrder(packingOrderRes);

      // get the container
      getContainer(packingOrderRes.containers_uuid);
    }

    setIsLoading(false);
    return null;
  }
  
  return <BlankPage
    isLoading={isLoading || !isReady}
    unauthorized={!authorized}
    notFound={notFound}
    padded
    showSideMenu
    modal={layoutModal}
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      <Button
        type={'default'}
        children={'House Bill of Lading (HBL)'}
        onClick={() => window.open(
          `/containers/packing-orders/docs/${packingOrder.uuid}/?type=houseBillOfLading`, 
          '_blank'
        )}
        disabled={!packingOrder.uuid}
      />
      <Button
        type={'default'}
        children={'Doc Receipt (DR)'}
        onClick={() => window.open(
          `/containers/packing-orders/docs/${packingOrder.uuid}/?type=docReceipt`, 
          '_blank'
        )}
        disabled={!packingOrder.uuid}
      />
      <Button
        type={'default'}
        children={'House Hold Goods (HHG)'}
        onClick={() => window.open(
          `/containers/packing-orders/docs/${packingOrder.uuid}/?type=houseHoldGoods`, 
          '_blank'
        )}
        disabled={!packingOrder.uuid}
      />
    </Space>}
  >
    {contextHolder}

    <Typography.Title level={1} children={'Packing Order Details'} />

    <div style={{marginTop: '30px'}}>
      <Steps
        type="navigation"
        current={packingOrderStatusIndex}
        items={packingOrdersStatuses}
      />
    </div>

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'ID:'} /></div>
        <div><Typography.Text children={packingOrder?.uuid} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Job / Reference #:'}/></div>
        <div><Typography.Text children={packingOrder?.reference_number} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Container #:'}/></div>
        <div><Typography.Text children={container?.container_number} /></div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Package Type:'}/></div>
        <div><Typography.Text style={{textTransform: 'capitalize'}} children={packingOrder?.packages_type||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={6}>
        <div><Typography.Text strong children={'Exporter:'} /></div>
        <div>
          <Typography.Text children={packingOrder?.exporters_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${packingOrder?.exporters_name_prefix}. ${packingOrder?.exporters_first_name}, ${packingOrder?.exporters_last_name}`} />
        </div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Consignee:'}/></div>
        <div>
          <Typography.Text children={packingOrder?.consignees_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${packingOrder?.consignees_name_prefix}. ${packingOrder?.consignees_first_name}, ${packingOrder?.consignees_last_name}`} />
        </div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Intermediate Consignee:'}/></div>
        <div>
          <Typography.Text children={packingOrder?.intermediate_consignee_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${packingOrder?.intermediate_consignee_name_prefix}. ${packingOrder?.intermediate_consignee_first_name}, ${packingOrder?.intermediate_consignee_last_name}`} />
        </div>
      </Col>
      <Col span={6}>
        <div><Typography.Text strong children={'Notify Party:'}/></div>
        <div>
          <Typography.Text children={packingOrder?.notify_party_company_name} /><br />
          <Typography.Text italic style={{textTransform: 'capitalize'}} children={`${packingOrder?.notify_party_name_prefix}. ${packingOrder?.notify_party_first_name}, ${packingOrder?.notify_party_last_name}`} />
        </div>
      </Col>
    </Row>

    <Divider dashed />

    <Row gutter={10} style={{margin: '30px 0'}}>
      <Col span={24}>
        <div><Typography.Text strong children={'AES ITN:'} /></div>
        <div><Typography.Text children={packingOrder?.aes_itn||'-'} /></div>
      </Col>
    </Row>

    <Divider dashed />

    <PackingOrdersCargoes 
      showTitle
      showView
      packingOrderUuid={packingOrder.uuid}
      exporterUuid={packingOrder.exporters_uuid}
      containerDestination={{
        country: container?.destination_country,
        state: container?.destination_state,
        city: container?.destination_city
      }}
    />

    <Documents
      purpose={'packing order'}
      purposeUuid={packingOrder?.uuid}
      title={'Images and Documents'}
    />
  </BlankPage>
}
