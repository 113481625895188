/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';

import { CheckCircleFilled, CheckCircleOutlined, ExpandAltOutlined, FilePdfOutlined, FormOutlined, Loading3QuartersOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, WarningFilled } from "@ant-design/icons";
import { Alert, Button, Col, Dropdown, Form, Input, Menu, MenuProps, Modal, ModalProps, Pagination, Row, Segmented, Select, Space, Spin, Table, Tag, Typography, message } from "antd";
import { useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export default function PackingOrdersListing(props:any) {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [containerUuid, setContainerUuid] = useState<string|null>(props.containerUuid);
  const [packingOrdersStatuses, setPackingOrdersStatuses] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string|undefined>(undefined);
  const [packingOrders, setPackingOrders] = useState<any[]|null>(null);

  const [messageApi, contextHolder] = message.useMessage();

  const [bulkUpdateModal, setBulkUpdateModal] = useState<ModalProps|null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    getPackingOrdersStatuses();
    getPackingOrders();
  }, []);

  const getPackingOrdersStatuses = async () => {
    if(isLoadingStatuses) return null;
    setIsLoadingStatuses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderDirection = 'ASC';
    EV.module = 'packing orders';
    EV.showAll = true;
    EV.active = 'y';

    var res:any = await EV.getModulesRecordsStatuses();
    
    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          ...item
        });
      });
    }

    setPackingOrdersStatuses(sortedData);

    setIsLoadingStatuses(false);
    return null;
  }

  const getPackingOrders = async () => {
    if(
      isLoading 
      || !containerUuid
    ) return null;

    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.containersUuid = containerUuid;
    EV.showAll = 'y';

    var res:any = await EV.getPackingOrders();
    setIsLoading(false);
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to download packing orders list',
      });  
    }

    else if(res.code === 500) {
      messageApi.open({
        type: 'error',
        content: res?.errors[0]?.en
      });  
    }

    else if(res.data) {
      setPackingOrders(res.data);
    }
  }

  const updatePackingOrders = async () => {
    // update packing orders
    if(
      isProcessing 
      || !selectedStatus 
      || !packingOrders 
      || packingOrders.length < 1
    ) return null;

    setIsProcessing(true);
    
    for(var i = 0; i < packingOrders.length; i++) {
      var EV:any = new EVAPI;
      EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
      EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
      EV.authToken = localStorage.getItem('auth_token');
      
      EV = Object.assign(EV, {
        uuid: packingOrders[i].uuid,
        modules_records_statuses_uuid: selectedStatus
      });
      
      var res:any =  await EV.updatePackingOrder();
    }

    setIsProcessing(false);
    setBulkUpdateModal(null);
    getPackingOrders();
    return null;
  }

  const columns:any[] = [
    {
      key: 'modules_records_statuses_label', 
      title: 'Status',
      width: 90,
      fixed: 'left' as 'left',
      render: (_:any, record:any) => <Tag
        color={record.modules_records_statuses_highlight_color}
        children={record.modules_records_statuses_label} 
      />
    },
    {
      key: 'uuid',
      title: 'UUID',
      dataIndex: 'uuid',
      fixed: 'left' as 'left',
      width: 120,
      render: (_:any, record:any) => {
        return record.uuid.substr(record.uuid.length - 10)
      }
    },
    {
      key: 'id', 
      title: 'Job #',
      width: 200,
      render: (_:any, record:any) => (
        <div>
          <Typography.Text strong>{record.exporters_company_name}</Typography.Text>
          <div style={{fontSize: '9pt'}}>
            <span>{record.exporters_reference_id||record.exporters_uuid}</span>
            <span style={{marginLeft: 5}}>
              - {record.reference_number}
            </span>
          </div>
        </div>
      )
    },
    {
      key: 'number_of_cargoes',
      title: 'Cargoes', 
      dataIndex: 'number_of_cargoes'
    }
  ];

  if(props.showInternalRate) columns.push({
    key: 'internal_rate', 
    title: 'Internal Rate', 
    dataIndex: 'internal_rate',
    render: (_:any, record:any) => {
      return <Typography.Text type='danger'>
        ${record.internal_rate?.toFixed(2)}
      </Typography.Text>
    }
  });

  if(props.showSalesTotal) columns.push({
    key: 'sales_total', 
    title: 'Sales Total', 
    dataIndex: 'sales_total',
    render: (_:any, record:any) => {
      return <Typography.Text type='success'>
        ${record.sales_total?.toFixed(2)}
      </Typography.Text>
    }
  });

  if(props.showDueBalance) columns.push({
    key: 'due_balance', 
    title: 'Due Balance', 
    dataIndex: 'due_balance',
    render: (_:any, record:any) => {
      return '-';
    }
  });

  if(
    props.showView
    || props.showEdit
  ) {
    columns.push({
      key: 'action',
      title: 'Action',
      fixed: 'right' as 'right',
      width: 140,
      render: (_:any, record:any) => {
        const items:MenuProps['items'] = [
          {
            key: '1',
            label: (
              <a
                rel="House Bill of Lading" 
                onClick={() => window.open(
                  `/containers/packing-orders/docs/${record.uuid}/?type=houseBillOfLading`, 
                  '_blank'
                )}
              >
                House Bill of Lading
              </a>
            ),
          },
          {
            key: '2',
            label: (
              <a
                rel="Custom Clearance" 
                onClick={() => window.open(
                  `/containers/packing-orders/docs/${record.uuid}/?type=customClearance`, 
                  '_blank'
                )}
              >
                Custom Clearance
              </a>
            ),
          },
          {
            key: '3',
            label: (
              <a
                rel="Doc Receipt" 
                onClick={() => window.open(
                  `/containers/packing-orders/docs/${record.uuid}/?type=docReceipt`, 
                  '_blank'
                )}
              >
                Doc Receipt
              </a>
            ),
          },
          {
            key: '4',
            label: (
              <a
                rel="House Hold Goods" 
                onClick={() => window.open(
                  `/containers/packing-orders/docs/${record.uuid}/?type=houseHoldGoods`, 
                  '_blank'
                )}
              >
                House Hold Goods
              </a>
            ),
          },
        ];

        return <Space size={'small'}>
          <Dropdown 
            arrow
            placement="bottomLeft" 
            menu={{items}} 
          >
            <Button
              type={'link'}
              icon={<FilePdfOutlined />}
            />
          </Dropdown>
          {props.showView && <Button 
            type={'link'}
            icon={<ExpandAltOutlined />}
            onClick={() => navigate(`/containers/packing-orders/view/${record.uuid}`)}
          />}
          {props.showEdit && <Button 
            type={'link'}
            icon={<FormOutlined />}
            onClick={() => navigate(`/containers/packing-orders/form/${record.uuid}`)}
          />}
        </Space>
      }
    })
  }
  
  return <>
    {props.showTitle && <>
      <Typography.Title level={2} children={'Packing Orders'} />
      <div style={{marginBottom: 20}} />
    </>}
    
    {(!containerUuid) ? <Alert 
      message="Save the record enable this section." 
      type="warning" 
    /> : <Input 
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
    />}

    <Modal
      open={bulkUpdateModal?.open}
      title={'Bulk Update Status'}
      children={<>
        <Alert
          type={'warning'}
          message={'This process cannot be revesed and '
            + 'will require manual modification to each '
            + 'record to reset.'}
        />
        <Select 
          style={{
            display: 'block', 
            width: '100%', 
            marginTop: '10px',
            marginBottom: '10px'
          }}
          showSearch
          placeholder={'Select one'}
          onChange={(value) => setSelectedStatus(value)}
          options={packingOrdersStatuses}
          disabled={isProcessing}
        />
        {(isProcessing) && <div>
          <Spin size={'small'}  style={{marginRight: 10}} />
          <Typography.Text type={'secondary'}>Processing...</Typography.Text>
        </div>}
      </>}
      onOk={updatePackingOrders}
      onCancel={() => {
        setIsProcessing(false);
        setBulkUpdateModal(null);
      }}
    />

    <div style={{marginBottom: 20}} />

    <Table 
      size={userSettings('compact_view') ? 'small' : undefined}
      scroll={{ x: 1000 }}
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={packingOrders||[]} 
      columns={columns} 
    />
    
    {(props.showBulkUpdate || props.showNewButton) && <>
      <div style={{marginBottom: 20}} />

      <Row>
        <Col span={24} style={{display: 'flex', justifyContent: 'right'}}>
          <Space>
            {props.showBulkUpdate && <Button
              type={'default'}
              children={'Bulk Update Status'}
              onClick={() => setBulkUpdateModal({open: true})}
            />}
            {props.showNewButton && <Button
              type={'primary'}
              icon={<PlusCircleFilled />}
              children={'New Packing Order'}
              onClick={() => navigate(`/containers/packing-orders/form/?containerUuid=${containerUuid}`)}
            />}
          </Space>
        </Col>
      </Row>
    </>}
  </>
}