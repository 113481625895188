/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CloseCircleOutlined, DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Divider, Form, Input, Menu, message, MessageArgsProps, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import BlankPage, { LayoutModalProps } from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate, useParams } from 'react-router-dom';
import { URLQuery } from '../../../util/url_queries';

export interface UserProps {
  uuid: string|undefined,
  contacts_uuid?: string|undefined,
  username: string|undefined,
  passport?: string|undefined,
  type: 'management'|'exporter'|undefined,
  users_groups_uuid?: string|undefined,
  is_blocked?: 'y'|'n'|undefined,
  settings?:{users_uuid:string, key:string, value?:string}[]|undefined|null

}

export default function SettingsUsersForm(props:any) {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});
  const [layoutMessageApi, setLayoutMessageApi] = useState<MessageArgsProps|undefined>(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUsersGroups, setIsLoadingUsersGroups] = useState<boolean>(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errors, setErrors] = useState<any[]|null>(null);

  const [passwordErrors, setPasswordErrors] = useState<any[]|undefined>(undefined);
  
  const [user, setUser] = useState<UserProps>({
    uuid: uuid||undefined,
    username: undefined,
    type: undefined,
    is_blocked: 'n'
  });
  
  const [password, setPassword] = useState<string|undefined>(undefined);
  const [contacts, setContacts] = useState<any[]>([]);
  const [usersGroups, setUsersGroups] = useState<any[]>([]);
  
  const passwordValidationList = [
    {en: 'Must contain at least 1 small letter.'},
    {en: 'Must contain at least 1 capital letter.'},
    {en: 'Must contain at least 1 number.'},
    {en: 'Must contain at least 1 special characters.'},
    {en: 'Must be more than 7 characters.'}
  ]
  
  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getContacts();
    getUsersGroups();

    if(uuid) getUser();
    setIsReady(true);
  }, []);

  const getUsersGroups = async () => {
    if(isLoadingUsersGroups) return null;
    setIsLoadingUsersGroups(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getUsersGroups();

    if(!res) {
      messageApi.open({
        key: 'getUsersGroupsServerError',
        type: 'error',
        content: 'Failed to load users groups'
      })
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getUsersGroupsFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });
    }

    else if(res.code === 200) {
      var data = res.data;
      var options:any[] = [];

      data.map((record:any, key:number) => {

        options.push({
          value: record.uuid,
          label: record.name
        })
      });

      setUsersGroups(options);

      if(user?.users_groups_uuid) {
        form.setFieldValue('users_groups_uuid', user.users_groups_uuid)
      }
    }

    setIsLoadingUsersGroups(false);
    return null;
  }

  const getContacts = async () => {
    if(isLoadingContacts) return null;
    setIsLoadingContacts(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.orderBy = 'first_name';
    EV.orderDirection = 'ASC';
    EV.showAll = true;

    var res:any = await EV.getContacts();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      var data = res.data;
      var contactsOptions:any[] = [];

      data.map((record:any, key:number) => {
        var nameBuild = '';

        nameBuild += `${record.uuid.substr(record.uuid.length - 8)} -`;
        nameBuild += `${record.name_prefix?.toUpperCase()}. `;
        nameBuild += `${record.first_name} `;
        nameBuild += `${record.last_name} `;
        nameBuild += `[${record.email||'...'} | `;
        nameBuild += `${record.phone_country_code||''}${record.phone||'...'}] `;
        nameBuild += `(${record.company_name}) `;

        contactsOptions.push({
          value: record.uuid,
          label: nameBuild
        })
      });

      setContacts(contactsOptions);

      if(user?.contacts_uuid) {
        form.setFieldValue('contacts_uuid', user.contacts_uuid)
      }
    }

    setIsLoadingContacts(false);
    return null;
  }

  const getUser = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getUser();

    if(!res) {
      messageApi.open({
        key: 'getUserServerError',
        type: 'error',
        content: 'Failed to load user'
      })
    }

    if(res.status === 'fail') {
      messageApi.open({
        key: 'getUserFail',
        type: 'error',
        content: res?.errors[0]?.en,
      });

      if(res.code === 404) {
        setNotFound(true);
      }
    }

    else if(res.code === 200) {
      setUser(res.data);
      form.setFieldsValue(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const processUser = async () => {
    // if(isProcessing) return null;
    // setIsProcessing(true);
    setErrors(null);
    var passwordValidation = await validatePassword();

    if(
      password
      && !passwordValidation
    ) { return null; }

    if(
      !user.uuid
      && !password
    ) {
      setErrors([
        {en: 'Password is required to create a new user.'}
      ]);

      return null;
    }

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    EV = Object.assign(EV, user);

    if(password) {
      EV.password = password;
    }
    
    var res:any = null;
    
    if(user.uuid) res = await EV.updateUser();
    else res = await EV.createUser();

    if(!res) {
      messageApi.open({
        key: 'processUserNetworkError',
        type: 'error',
        content: 'Failed to process your request connection error'
      });
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else {
      messageApi.open({
        key: 'processUserSuccess',
        type: 'success',
        content: 'Process completed successfully'
      });

      if(!user.uuid) {
        setUser({
          ...user,
          uuid: res?.data?.uuid
        });

        navigate('/system/settings/users/form/' + res?.data?.uuid, {replace: true})
      }
    }

    setIsProcessing(false);
    return null;
  }

  const validatePassword = async () => {
    // validate input
    var passwordValidation:any[] = [];

    if(password) {
      if(!password.match(/.*[a-z]/g)) {
        passwordValidation.push({en: 'Must contain at least 1 small letter.'});
      }

      if(!password.match(/.*[A-Z]/g)) {
        passwordValidation.push({en: 'Must contain at least 1 capital letter.'});
      }

      if(!password.match(/.*[!@#$&*]/g)) {
        passwordValidation.push({en: 'Must contain at least 1 special characters.'});
      }

      if(!password.match(/.*[0-9]/g)) {
        passwordValidation.push({en: 'Must contain at least 1 number.'});
      }

      if(password.length < 8) {
        passwordValidation.push({en: 'Must be more than 7 characters.'});
      }

      setPasswordErrors([...passwordValidation]);
      if(passwordValidation.length > 0) return false;
      else return true;
    } 

    return true;
  }

  const deleteUser = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = user?.uuid;
    
    var res:any = await EV.deleteUser();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady || isLoading}
    unauthorized={!authorized}
    notFound={notFound}
    messageApi={layoutMessageApi}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(user.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteUser();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >
    {contextHolder}

    <Typography.Title 
      level={1}
      children={(user?.uuid) ? 'Manage User' : 'New User'}
    />

    <Typography.Paragraph>
      <Typography.Text type={'secondary'} children={'# '} />
      <Typography.Text
        children={user?.uuid||'Unassigned'}
        copyable
        type={'secondary'}
      />
    </Typography.Paragraph>

    {(errors && errors.length > 0) && <Alert
      type={'error'}
      message={'Errors submitting your data'}
      description={<ul>{errors.map((error, key) => {
        if(typeof error === 'object') {
          error = error.en
        }

        return <li>{error}</li>;
      })}</ul>}
      closable
      onClose={() => setErrors(null)}
      style={{marginBottom: 20}}
    />}
    
    <Form
      form={form}
      layout="vertical"
      initialValues={user}
      onFinish={processUser}
    >
      <Form.Item
        label={'Type'} 
        name={'type'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Select
          placeholder={'Select one'}
          onChange={(value) => setUser({
            ...user,
            type: value
          })}
          options={[
            {value: 'management', label: 'Management'},
            {value: 'exporter', label: 'Exporter'}
          ]}
        />
      </Form.Item>

      {(user?.type === 'management') && <Form.Item
        label={'User Group'} 
        name={'users_groups_uuid'}
      >
        <Select
          placeholder={'Select one'}
          onChange={(value) => setUser({
            ...user,
            users_groups_uuid: value
          })}
          allowClear
          onClear={() => setUser({
            ...user,
            users_groups_uuid: undefined
          })}
          options={usersGroups}
        />
      </Form.Item>}

      <Form.Item
        label={'Contact'} 
        name={'contacts_uuid'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Select
          placeholder={'Select one'}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value) => setUser({
            ...user,
            contacts_uuid: value
          })}
          options={contacts}
        />
      </Form.Item>

      <Form.Item
        label={'Username'} 
        name={'username'}
        rules={[{ required: true, message: 'Field required'}]}
      >
        <Input 
          placeholder={'Type here'}
          onChange={(e) => setUser({
            ...user,
            username: e.target.value
          })}
        />
      </Form.Item>
      <Form.Item
        label={'Password'} 
        name={'password'}
      >
        <Input.Password
          status={(passwordErrors && passwordErrors?.length > 0) ? 'error' : undefined}
          placeholder={'Type here'}
          onKeyUp={validatePassword}
          onChange={(e) => {
            setPassword(e.target.value)
            validatePassword();
          }}
        />
      </Form.Item>
      
      <Alert
        type={'info'}
        showIcon
        message={'Password Requirements'}
        description={passwordValidationList?.map((error:any, key:number) => {
          if(typeof error === 'object') {
            error = error.en
          }

          var prefix:any = [];
          
          if(passwordErrors && passwordErrors?.length > 0) {
            passwordErrors.map((record:any, mkey:number) => {
              if(record.en === error) {
                prefix = <CloseCircleOutlined style={{color: 'red'}} />
              }
            })
          }

          return <div>
            {prefix} {error}
          </div>;
        })}
        closable
        onClose={() => setErrors(null)}
        style={{marginBottom: 20}}
      />

      <Form.Item>
        <Switch 
          checked={user.is_blocked === 'y' ? true : false} 
          onChange={(e) => setUser({
            ...user,
            is_blocked: e ? 'y' : 'n'
          })}
        />
        <span style={{marginLeft: 15}}>
          Block user
        </span>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isProcessing}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  </BlankPage>
}