/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { ReactElement, useEffect, useState } from 'react';

import { AppstoreAddOutlined, BorderOuterOutlined, BoxPlotOutlined, ExpandAltOutlined, ExportOutlined, EyeOutlined, FireTwoTone, FormOutlined, PlusCircleFilled, PlusCircleOutlined, ReadOutlined, SearchOutlined, TableOutlined, ThunderboltTwoTone } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Dropdown, Empty, Form, Input, Menu, MenuProps, Pagination, Row, Segmented, Select, Space, Switch, Table, Tag, Typography } from "antd";

import BlankPage from "../../framework/blank_page";
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import EVAPI from '../../lib/ev_lib/main';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useForm } from 'antd/es/form/Form';
import { filterGeoArray } from '../../lib/cities';
import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../../common/TableColumnsCuztomization';

import TableColumnSortIcon from '../../common/TableColumnSortIcon';
import { resortTable } from '../../lib/table_sorting';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';
import { lime, red } from '@ant-design/colors';

export function Cargo(props:any) {
  const navigate = useNavigate();
  
  const _renderDestination = () => {
    var dataSource = props.dataSource;
    var destination = '';
    var warehouse = '';

    if(dataSource.destination_country) {
      destination += dataSource.destination_country;
    }
    

    if(dataSource.destination_city) {
      if(destination.length > 0) destination += ', ';
      destination += dataSource.destination_city;
    }

    if(destination.length < 1) {
      destination = 'Destination not set';
    }

    if(dataSource.warehouses_name) {
      warehouse = dataSource.warehouses_name;
    }
    else {
      warehouse = 'Warehouse not set';
    }

    return warehouse + ' \u2794 ' + destination;
  }

  const _renderDateIn = () => {
    if(!props.dataSource.date_in) return null;

    var dateIn:any = momentTz(props.dataSource.date_in).tz(moment.tz.guess()).toISOString();
    dateIn = dateIn.split('T')[0];
    return dateIn;
  }

  return <Card 
    style={{marginBottom: 20}}
    title={
      '# ' 
      + (props.dataSource?.uuid?.substr(props.dataSource?.uuid?.length - 10)||'-')
      + ' (' + props.dataSource?.reference_id + ')'
    } 
    extra={<Space size={'small'}>
      <div>{_renderDestination()}</div>
      <Button
        type='link'
        icon={<ExpandAltOutlined />}
        title={'View'}
        onClick={() => navigate(`/inventory/cargoes/View/${props.dataSource.uuid}`)}
      />
      {(props.allowEdit) && <Button
        type='link'
        icon={<FormOutlined />}
        title={'Manage'}
        onClick={() => navigate(`/inventory/cargoes/form/${props.dataSource.uuid}`)}
      />}
    </Space>}
  >
    <Row gutter={10}>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Exporter'} />
          <Typography.Text>{props.dataSource?.exporters_company_name||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Date In'} />
          <Typography.Text>{_renderDateIn()||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Status'} />
          <Typography.Text>
            <Tag
              color={props.dataSource?.modules_records_statuses_highlight_color}
              children={props.dataSource?.modules_records_statuses_label} 
            />
          </Typography.Text>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'VIN #'} />
          <Typography.Text>{props.dataSource?.vin_number||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Title #'} />
          <Typography.Text>{props.dataSource?.title||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Vehicle'} />
          <Space size={'middle'}>
            <Typography.Text>{props.dataSource?.make||'-'}</Typography.Text>
            <Typography.Text>{props.dataSource?.model||'-'} {props.dataSource?.fuel_type||'-'}</Typography.Text>
            <Typography.Text>{props.dataSource?.year||'-'}</Typography.Text>
            <Typography.Text>{props.dataSource?.color||'-'}</Typography.Text>
          </Space>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Insurance Type'} />
          <Typography.Text style={{textTransform: 'capitalize'}}>{props.dataSource?.insurance_type||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Dimensions'} />
          <Typography.Text>{props.dataSource?.dimensions||'-'}</Typography.Text>
        </div>
      </Col>
    </Row>
  </Card>
}

export default function CargoesListing(props:any) {
  interface AdvanceFiltersProps {
    warehouses_uuids?: string|null|undefined,
    exporters_uuid?: string|null|undefined,
    destination_country?: string|null|undefined,
    destination_state?: string|null|undefined,
    destination_city?: string|null|undefined,
    vin_number?: string|null|undefined,
    has_title?: 'y'|'n'|null|undefined,
    title?: string|null|undefined,
    insurance_type?: 'partial coverage'|'full coverage'|'total loss'|'none'|null|undefined,
    date_in_from?:string|string[],
    date_in_from_obj?: any,
    date_in_to?:string|string[],
    date_in_to_obj?: any,
  }

  const navigate = useNavigate();
  const [form] = Form.useForm();

  let profile:any = localStorage.getItem('profile');
  
  let prefCargoesListingView:() => 'table'|'boxes' = () => {
    var stored = localStorage.getItem('prefCargoesListingView');
    
    if(
      stored 
      && stored === 'boxes'
    ) {
      return stored;
    }

    else return 'table';
  }
  
  let prefCargoesListingExecludePacked:() => boolean = () => {
    var stored = localStorage.getItem('prefCargoesListingExecludePacked');
    
    if(
      stored
      && stored === 'y'
    ) return true;

    else return false;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const [allowCreateNew, setAllowCreateNew] = useState<boolean>(false);

  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [cargoesStatusesUuid, setCargoesStatusesUuid] = useState<any>(null);

  const [toggleAdvancedFilters, setToggleAdvancedFilters] = useState<boolean>(false);
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({});

  const [cargoesStatuses, setCargoesStatuses] = useState<any[]>([]);
  const [cargoes, setCargoes] = useState<any[]>([]);
  const [cargoesCount, setCargoesCount] = useState<number>(0);
  const [cargoesPerPage, setCargoesPerPage] = useState<number>(15);
  const [cargoesCurrentPage, setCargoesCurrentPage] = useState<number>(1);

  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [exporters, setExporters] = useState<any[]|null>(null);
  const [isLoadingExporters, setisLoadingExporters] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [listingView, setListingView] = useState<'boxes'|'table'>(prefCargoesListingView);
  const [execludePackedCargoes, setExecludePackedCargoes] = useState<boolean>(prefCargoesListingExecludePacked)
  
  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'reference_id', label: 'Ref #', default: true},
    { id: 'fuel_type', label: 'Fuel Type', default: true},
    { id: 'vin_number', label: 'VIN #', default: true},
    { id: 'warehouse', label: 'Warehouse', default: true},
    { id: 'destination', label: 'Destination', default: true},
    { id: 'exporter', label: 'Exporter', default: true},
    { id: 'container_number', label: 'Container #', default: true},
    { id: 'booking_number', label: 'Booking #', default: true},
    { id: 'make', label: 'Make', default: true},
    { id: 'model', label: 'Model', default: true},
    { id: 'color', label: 'Color', default: true},
    { id: 'year', label: 'Year', default: true},
    { id: 'has_title', label: 'Has Title'},
    { id: 'number_of_keys', label: 'Keys'},
    { id: 'insurance_type', label: 'Insurance Type'},
    { id: 'insurance_value', label: 'Insurance Value'}
  ];

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management'].includes(profile.type)) {
        setAllowEdit(true);
        setAllowCreateNew(true);
      }
    }
    catch(e) {
      console.warn(e)
    };

    // set the default ResPerPage
    if(localStorage.getItem('prefCargoesListingResPerPage')) {
      setCargoesPerPage(Number(localStorage.getItem('prefCargoesListingResPerPage')));
    }

    processPrefTableColumns();
    
    getCargoesStatuses();
    getWarehouses();
    getExporters();
    getCities();
    getStates();
    getCountries();

    getCargoes();
    getCargoes(true);
  }, []);
  
  useEffect(() => {
    getCargoes();
    getCargoes(true);
  }, [
    execludePackedCargoes,
    searchTerm, 
    cargoesStatusesUuid,
    cargoesCurrentPage,
    cargoesPerPage
  ]);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefCargoesListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getExporters = async () => {
    if(isLoadingExporters) return null;
    setisLoadingExporters(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;

    var res:any = await EV.getExporters();

    setisLoadingExporters(false);

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${(!item.is_active || item.is_active !== 'y') ? '[Inactive] ' : ''}${item.company_name} (${item.reference_id||'-'} / ${item.uuid})`
        });
      });
    }

    setExporters(sortedData);
  }

  const getCargoesStatuses = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'cargoes';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [{
      value: null,
      label: 'All'
    }];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(item.is_default === 'y') {
          setCargoesStatusesUuid(item.uuid);
        }

        sortedData.push({
          value: item.uuid,
          label: item.label,
          highlight_type: item.highlight_type,
          highlight_color: item.highlight_color
        });
      });
    }

    setCargoesStatuses(sortedData);
  }

  const getCargoes = async (count:boolean = false) => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.resPerPage = cargoesPerPage;
    EV.page = cargoesCurrentPage;
    EV.searchTerm = searchTerm;
    EV.modulesRecordsStatusesUuid = cargoesStatusesUuid;

    EV.orderBy = localStorage.getItem('prefCargoesListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefCargoesListingOrderDirection');

    EV.warehousesUuids = advanceFilters?.warehouses_uuids||undefined;
    EV.exportersUuid = advanceFilters?.exporters_uuid||undefined;
    EV.destinationCountry = advanceFilters?.destination_country||undefined;
    EV.destinationState = advanceFilters?.destination_state||undefined;
    EV.destinationCity = advanceFilters?.destination_city||undefined;
    EV.vinNumber = advanceFilters?.vin_number||undefined;
    EV.hasTitle = advanceFilters?.has_title||undefined;
    EV.title = advanceFilters?.title||undefined;
    EV.insuranceType = advanceFilters?.insurance_type||undefined;
    EV.dateInFrom = advanceFilters?.date_in_from||undefined;
    EV.dateInTo = advanceFilters?.date_in_to||undefined;

    if(execludePackedCargoes) {
      EV.linkedToPackingOrder = 'n';
    }

    // handle count if set
    EV.count = count;

    var res:any = await EV.getCargoes();
    
    if(count) { 
      if(res.data?.count) {
        setCargoesCount(Number(res.data.count)); 
      }
      else setCargoesCount(0);
    }
    else setCargoes(res.data);

    setIsLoading(false);
    return;
  }

  const _renderFitlersToURL = () => {
    var linkQuery:string = '';
    
    if(searchTerm) {
      linkQuery += 'searchTerm=' + searchTerm + '&&';
    }

    if(
      advanceFilters
      && Object.keys(advanceFilters).length > 0
    ) {
      Object.entries(advanceFilters).map((property:[string, any], index:number) => {
        linkQuery += `${property[0]}=` 
          + property[1] 
          + '&&';
      })
    }

    if(execludePackedCargoes) {
      linkQuery += `execludePackedCargoes=y`;
    }

    return linkQuery;
  }

  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      {(allowCreateNew) && <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate('/inventory/cargoes/form')}
      />}
    </Space>}
  >
    
    <Typography.Title children={'Cargoes'} />

    <Row gutter={15} align={"middle"}>
      <Col>
        <Segmented 
          value={cargoesStatusesUuid||0}
          style={{textTransform: 'capitalize'}}
          options={cargoesStatuses}
          onChange={(value:any) => setCargoesStatusesUuid(value)}
        />
      </Col>
      <Col>
        <Switch
          defaultChecked={execludePackedCargoes}
          onChange={(e) => {
            setExecludePackedCargoes(e);
            localStorage.setItem(
              'prefCargoesListingExecludePacked', 
              (e) ? 'y' : 'n'
            );
          }}
        />
        <Typography.Text style={{marginLeft: 10}}>
          Exclude packed cargoes
        </Typography.Text>
      </Col>
      <Col>
        <Segmented
          value={listingView}
          options={[
            { value: 'table', icon: <TableOutlined />},
            { value: 'boxes', icon: <BorderOuterOutlined />},
          ]}
          onChange={(value:any) => {
            setListingView(value);
            localStorage.setItem('prefCargoesListingView', value);
          }}
        />
      </Col>
      <Col flex={'auto'} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Space>
          <Button
            type={'default'}
            onClick={() => window.open(`/inventory/cargoes/doc/export-list/?${_renderFitlersToURL()}`)}
            icon={<ExportOutlined />}
            children={'Export PDF'}
          />
          <Button
            type={'default'}
            onClick={() => setShowSideMenu(!showSideMenu)}
            icon={<EyeOutlined />}
            children={'Customize View'}
          />
        </Space>
      </Col>
    </Row>

    <TableColumnsCuztomization
      showDrawer={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      onPrcessComplete={processPrefTableColumns}
      localStorageKey='prefCargoesListingTableViewColumns'
      tableColumnsList={tableColumnsList}
    />

    <div style={{marginBottom: 20}} />

    <Row gutter={15}>
      <Col span={19}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <Button 
          style={{width: '100%'}}
          type={"default"}
          onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
        >
          Toggle Advanced Filters
        </Button>
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    {(toggleAdvancedFilters) && <>
      <Typography.Title level={3}>
        Advance Search
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          getCargoes();
          getCargoes(true);
        }}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'exporters_uuid'}
              label={'Exporter'}
            >
              <Select 
                showSearch
                allowClear
                placeholder={'Select one'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  exporters_uuid: value
                })}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={exporters||[]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'warehouses_uuids'}
              label={'Warehouses'}
            >
              <Select 
                mode="tags"
                allowClear
                placeholder={'Warehouses'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  warehouses_uuids: value
                })}
                options={warehouses||[]}
                tokenSeparators={[',']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              name={'destination_country'}
              label={'Destination Country'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_country: value
                })}
                options={countries||[]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_state'}
              label={'Destination State'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_state: value
                })}
                options={filterGeoArray(
                  'state',
                  states||[],
                  undefined,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_city'}
              label={'Destination City'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_city: value
                })}
                options={filterGeoArray(
                  'city',
                  cities||[],
                  advanceFilters.destination_state,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'vin_number'}
              label={'VIN #'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setAdvanceFilters({
                  ...advanceFilters,
                  vin_number: e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item
              name={'has_title'}
              label={'Has Title'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  has_title: value
                })}
                options={[
                  {label: 'Yes', value: 'y'},
                  {label: 'No', value: 'n'}
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              name={'title_number'}
              label={'Title #'}
            >
              <Input 
                placeholder={'Type here'}
                onChange={(e) => setAdvanceFilters({
                  ...advanceFilters,
                  title: e.target.value
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              name={'insurance_type'}
              label={'Insurance Type'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                value={undefined}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  insurance_type: value
                })}
                options={[
                  {value: 'partial coverage', label: 'Partial Coverage'},
                  {value: 'full coverage', label: 'Full Coverage'},
                  {value: 'total loss', label: 'Total Loss'},
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={'date_in_from'}
              label={'Date In (from)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.date_in_from_obj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  date_in_from: dateString,
                  date_in_from_obj: date
                })}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={'date_in_to'}
              label={'Date In (to)'}
            >
              <DatePicker
                style={{width: '100%'}}
                format="YYYY-MM-DD"
                value={advanceFilters.date_in_to_obj}
                onChange={(date, dateString) => setAdvanceFilters({
                  ...advanceFilters,
                  date_in_to: dateString,
                  date_in_to_obj: date
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                >
                  Search
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => {
                    setAdvanceFilters({});
                    form.resetFields();
                    setTimeout(() => getCargoes, 500);
                  }}
                >
                  Reset
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
                >
                  Toggle Advanced Filters
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>}

    <div style={{marginBottom: 20}} />

    {(listingView === 'table') ? <>
      <Table
        size={userSettings('compact_view') ? 'small' : undefined}
        scroll={{ x: 1300 }}
        loading={isLoading}
        pagination={{
          total: cargoesCount||undefined,
          showSizeChanger: true,
          pageSizeOptions: [15, 50, 100, 200],
          defaultPageSize: 15,
          pageSize: cargoesPerPage,
          onChange: (page:number, pagesSize:number) => {
            localStorage.setItem('prefCargoesListingResPerPage', pagesSize.toString())
            setCargoesPerPage(pagesSize);
            setCargoesCurrentPage(page);
          }
        }}
        columns={[
          {
            key: 'modules_records_statuses_label', 
            title: 'Status',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => <Tag
              color={record.modules_records_statuses_highlight_color}
              children={record.modules_records_statuses_label} 
            />
          },
          (selectedColumns?.includes('uuid')) ? {
            key: 'uuid',
            title: 'UUID',
            fixed: 'left',
            dataIndex: 'uuid',
            width: 130,
            render: (_:any, record:any) => {
              return record.uuid.substr(record.uuid.length - 10)
            }
          } : {},
          (selectedColumns?.includes('reference_id')) ? {
            key: 'reference_id', 
            title: 'Ref #', 
            width: 200, 
            dataIndex: 'reference_id',
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefCargoesListingOrder'}
              columnId={'cargoes.reference_id'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'Cargoes',
                  'cargoes.reference_id',
                  getCargoes
                )
              };
            },
          } : {},
          (selectedColumns?.includes('fuel_type')) ? {
            key: 'fuel_type', 
            title: 'Fuel', 
            dataIndex: 'fuel_type', 
            render: (_:any, record:any) => {
              if(['electric', 'hybrid'].includes(record.fuel_type)) {
                return <ThunderboltTwoTone
                  twoToneColor={lime[5]}
                  style={{fontSize: 22}}
                />
              }

              else if (['gasoline'].includes(record.fuel_type)) {
                return <FireTwoTone
                  twoToneColor={red[4]}
                  style={{fontSize: 22}}
                />
              }

              else return null;
            },
            width: 70
          } : {},
          (selectedColumns?.includes('vin_number')) ? {
            key: 'vin_number', 
            title: 'VIN #', 
            dataIndex: 'vin_number', 
            width: 300
          } : {},
          (selectedColumns?.includes('warehouse')) ? {
            key: 'warehouses_name', 
            title: 'Warehouse', 
            width: 200, 
            dataIndex: 'warehouses_name'
          } : {},
          (selectedColumns?.includes('destination')) ? {
            key: 'destination', 
            title: 'Destination', 
            width: 250,
            dataIndex: 'destination',
            render: (_:any, record:any) => {
              var destination = '';
          
              if(record.destination_country) {
                destination += record.destination_country;
              }
              
              if(record.destination_city) {
                if(destination.length > 0) destination += ', ';
                destination += record.destination_city;
              }

              return destination || null;
            }
          } : {},
          (selectedColumns?.includes('exporter')) ? {
            key: 'exporters_company_name', 
            title: 'Exporter', 
            width: 200,
            dataIndex: 'exporters_company_name',
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefCargoesListingOrder'}
              columnId={'exporters.company_name'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'Cargoes',
                  'exporters.company_name',
                  getCargoes
                )
              };
            },
          } : {},
          (selectedColumns?.includes('container_number')) ? {
            key: 'container_number', 
            title: 'Container #', 
            dataIndex: 'container_number', 
            width: 200,
            render: (_:any, record:any) => {
              return <div>
                <Typography.Link onClick={() => navigate(`/containers/containers/?searchTerm=${record.container_number}`)}>
                  {record.container_number}
                </Typography.Link>
              </div>
            }
          } : {},
          (selectedColumns?.includes('booking_number')) ? {
            key: 'booking_number', 
            title: 'Booking #', 
            dataIndex: 'booking_number', 
            width: 200,
            render: (_:any, record:any) => {
              return <div>
                <Typography.Link onClick={() => navigate(`/containers/containers/?searchTerm=${record.booking_number}`)}>
                  {record.booking_number}
                </Typography.Link>
              </div>
            }
          } : {},
          (selectedColumns?.includes('has_title')) ? {
            key: 'has_title', 
            title: 'Has Title', 
            width: 100,
            dataIndex: 'has_title',
            render: (_:any, record:any) => {
              if(record.has_title === 'y') return 'Yes';
              else if(record.has_title === 'n') return 'No';
              else return '-';
            }
          } : {},
          (selectedColumns?.includes('number_of_keys')) ? {
            key: 'number_of_keys', 
            title: 'Keys', 
            dataIndex: 'number_of_keys', 
            width: 80
          } : {},
          (selectedColumns?.includes('make')) ? {
            key: 'make', 
            title: 'Make', 
            dataIndex: 'make', 
            width: 200
          } : {},
          (selectedColumns?.includes('model')) ? {
            key: 'model', 
            title: 'Model', 
            dataIndex: 'model', 
            render: (_:any, record:any) => {
              return <div>
                <span style={{textTransform: 'capitalize'}}>
                  {record.model} 
                </span>
                <span style={{marginLeft: 10}}>
                  {(record.fuel_type && ['electric','hybrid'].includes(record.fuel_type)) && <Tag 
                    color={'lime-inverse'}
                    children={record.fuel_type}
                  />}
                </span>
              </div>
            },
            width: 200
          } : {},
          (selectedColumns?.includes('year')) ? {
            key: 'year', 
            title: 'Year', 
            dataIndex: 'year', 
            width: 200
          } : {},
          (selectedColumns?.includes('color')) ? {
            key: 'color', 
            title: 'Color', 
            dataIndex: 'color', 
            width: 200
          } : {},
          (selectedColumns?.includes('insurance_type')) ? {
            key: 'insurance_type', 
            title: 'Insurance Type', 
            dataIndex: 'insurance_type', 
            width: 200
          } : {},
          (selectedColumns?.includes('insurance_value')) ? {
            key: 'insurance_value', 
            title: 'Insurance Value', 
            dataIndex: 'insurance_value', 
            width: 200
          } : {},
          {
            key: 'created_at', 
            title: 'Created', 
            dataIndex: 'created_at',
            width: 170,
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefCargoesListingOrder'}
              columnId={'cargoes.created_at'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'Cargoes',
                  'cargoes.created_at',
                  getCargoes
                )
              };
            },
            render: (_:any, record:any) => {
              return momentTz(record.created_at)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY hh:mm A');
            }
          },
          {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 130,
            render: (_:any, record:any) => <Space size={'small'}>
              <Button
                type='link'
                icon={<AppstoreAddOutlined />}
                title={'Packing Order'}
                onClick={() => navigate(`/containers/packing-orders/view/${record.packing_orders_uuid}`)}
                disabled={!record.packing_orders_uuid}
              />
              <Button
                type='link'
                icon={<ExpandAltOutlined />}
                title={'View'}
                onClick={() => navigate(`/inventory/cargoes/View/${record.uuid}`)}
              />
              {(allowEdit) && <Button
                type='link'
                icon={<FormOutlined />}
                title={'Manage'}
                onClick={() => navigate(`/inventory/cargoes/form/${record.uuid}`)}
              />}
            </Space>
          }
        ]}
        dataSource={cargoes||[]}
      />
    </>
    : <>
      {(!cargoes || cargoes.length < 1) && <>
        <Empty style={{margin: 100}}/>
      </>}

      {(cargoes && cargoes.length > 0) && cargoes.map((cargo:any, key:number) => {
        return <Cargo key={key} allowEdit={allowEdit} dataSource={cargo} />
      })}
    </>}
    
  </BlankPage>
}