/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */
import { useEffect, useState } from 'react';

import { BorderOuterOutlined, CheckCircleFilled, CheckCircleOutlined, ExpandAltOutlined, ExportOutlined, EyeOutlined, FormOutlined, PlusCircleFilled, PlusCircleOutlined, SearchOutlined, TableOutlined, WarningFilled } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Input, Menu, Pagination, Row, Segmented, Select, Space, Table, Tag, Typography, message } from "antd";

import BlankPage from "../../framework/blank_page";
import { useNavigate } from 'react-router-dom';
import EVAPI from '../../lib/ev_lib/main';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { filterGeoArray } from '../../lib/cities';
import TableColumnSortIcon from '../../common/TableColumnSortIcon';
import { resortTable } from '../../lib/table_sorting';
import TableColumnsCuztomization, { TableColumnsProps as SelectedTableColumnProps} from '../../common/TableColumnsCuztomization';
import { URLQuery } from '../../util/url_queries';
import { userSettings } from '../../lib/user_settings';

export function Container(props:any) {
  const navigate = useNavigate();
  
  const warningIcon = <WarningFilled size={20} style={{color: 'orange'}} />

  const _renderDestination = () => {
    var dataSource = props.dataSource;
    var destination = '';
    var warehouse = '';

    if(dataSource.destination_country) {
      destination += dataSource.destination_country;
    }
    

    if(dataSource.destination_city) {
      if(destination.length > 0) destination += ', ';
      destination += dataSource.destination_city;
    }

    if(destination.length < 1) {
      destination = 'Destination not set';
    }

    if(dataSource.warehouses_name) {
      warehouse = dataSource.warehouses_name;
    }
    else {
      warehouse = 'Warehouse not set';
    }

    return warehouse + ' \u2794 ' + destination;
  }

  // render the job numbers
  var jobNumbers:any = props.dataSource.job_numbers;
  var jobNumbersRender:any = [];

  if(jobNumbers) {
    if(typeof jobNumbers === 'string') {
      jobNumbers = jobNumbers.split(',');
    }

    if(jobNumbers.length > 0) {
      jobNumbers.map((job:string, key:number) => {
        jobNumbersRender.push(<Tag
          style={{marginBottom: 5}}
          children={job} 
        />)
      })
    }
  }

  if(jobNumbersRender.length < 1) {
    jobNumbersRender = [<>-</>]
  }

  return <Card 
    style={{marginBottom: 20}}
    title={'# ' + props.dataSource?.uuid?.substr(props.dataSource?.uuid?.length - 10)||'-'} 
    extra={<Space size={'large'}>
      <div>{_renderDestination()}</div>
      <Button
        type='link'
        icon={<ExpandAltOutlined />}
        onClick={() => navigate(`/containers/containers/view/${props.dataSource?.uuid}`)}
      />
      {(props.allowEdit) && <Button
        type='link'
        icon={<FormOutlined />}
        title={'Manage'}
        onClick={() => navigate(`/containers/containers/form/${props.dataSource.uuid}`)}
      />}
    </Space>}
  >
    <Row gutter={10}>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Status'} />
          <Typography.Text>
            <Tag
              color={props.dataSource?.modules_records_statuses_highlight_color}
              children={props.dataSource?.modules_records_statuses_label} 
            />
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Packing Orders'} />
          <Typography.Text>{jobNumbersRender}</Typography.Text>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Container #'} />
          <Typography.Text>{props.dataSource?.container_number||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Seal #'} />
          <Typography.Text>{props.dataSource?.seal_number||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Booking #'} />
          <Typography.Text>{props.dataSource?.booking_number||'-'}</Typography.Text>
        </div>
      </Col>
      <Col span={8}>
        <div>
          <Typography.Title level={5} children={'Vessel / Equipment Type'} />
          <Typography.Text>{props.dataSource?.vessel||'-'} / {props.dataSource?.equipments_types_label||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Trucking'} />
          <Typography.Text>{props.dataSource?.trucking_companies_company_name||'-'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5} children={'Steam Ship Line'} />
          <Typography.Text>{props.dataSource?.steam_ship_lines_company_name||'-'}</Typography.Text>
        </div>
      </Col>
    </Row>
  </Card>
}

export default function ContainersListing(props:any) {
  interface AdvanceFiltersProps {
    warehouses_uuids?: string|null|undefined,
    exporters_uuid?: string|null|undefined,
    exporters_reference_id?: string|null|undefined,
    destination_country?: string|null|undefined,
    destination_state?: string|null|undefined,
    destination_city?: string|null|undefined
  }

  const navigate = useNavigate();
  const [form] = Form.useForm();

  let profile:any = localStorage.getItem('profile');
  let prefListingView:() => 'table'|'boxes' = () => {
    var stored = localStorage.getItem('prefContainersListingView');
    
    if(stored && stored === 'boxes') return stored;
    else return 'table';
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const [allowCreateNew, setAllowCreateNew] = useState<boolean>(false);

  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [containersStatusesUuid, setContainersStatusesUuid] = useState<any>(null);

  const [toggleAdvancedFilters, setToggleAdvancedFilters] = useState<boolean>(false);
  const [advanceFilters, setAdvanceFilters] = useState<AdvanceFiltersProps>({});

  const [containers, setContainers] = useState<any[]>([]);
  const [containersCount, setContainersCount] = useState<number>(0);
  const [containersPerPage, setContainersPerPage] = useState<number>(15);
  const [containersCurrentPage, setContainersCurrentPage] = useState<number>(1);

  const [warehouses, setWarehouses] = useState<any[]|null>(null);
  const [isLoadingWarehouses, setisLoadingWarehouses] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]|null>(null);
  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [states, setStates] = useState<any[]|null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [cities, setCities] = useState<any[]|null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);

  const [containersStatuses, setContainersStatuses] = useState<any[]>([]);

  const [listingView, setListingView] = useState<'boxes'|'table'>(prefListingView);
  const [selectedColumns, setSelectedColumns] = useState<string[]>();

  const [messageApi, contextHolder] = message.useMessage();

  const tableColumnsList:{id:string, label:string, default?:boolean}[] = [
    { id: 'uuid', label: 'UUID', default: true},
    { id: 'warehouse', label: 'Warehouse', default: true},
    { id: 'destination', label: 'Destination', default: true},
    { id: 'job_numbers', label: 'Job(s) #', default: true},
    { id: 'container_number', label: 'Container #', default: true},
    { id: 'booking_number', label: 'Booking #', default: true},
    { id: 'seal_number', label: 'Seal #', default: true},
    { id: 'steam_ship_line', label: 'S.S.L.', default: true}
  ];

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management'].includes(profile.type)) {
        setAllowEdit(true);
        setAllowCreateNew(true);
      }
    }
    catch(e) {
      console.warn(e)
    };

    // set the default ResPerPage
    if(localStorage.getItem('prefContainersListingResPerPage')) {
      setContainersPerPage(Number(localStorage.getItem('prefContainersListingResPerPage')));
    }
    
    processPrefTableColumns();

    setSearchTerm(URLQuery('searchTerm', window)||null);

    getCities();
    getStates();
    getCountries();
    getWarehouses();

    getContainersStatuses();
    
    getContainers();
  }, []);
  
  useEffect(() => {
    getContainers();
  }, [
    searchTerm, 
    containersStatusesUuid,
    containersCurrentPage,
    containersPerPage
  ]);

  const processPrefTableColumns = () => {
    var columns:string[] = [];
    var storedColumnsString:string|null = localStorage.getItem('prefContainersListingTableViewColumns');
    var storedColumnArray:string[] = [];

    if(storedColumnsString) {
      storedColumnArray = storedColumnsString.split(',');
    }

    var tempColumns:string[] = [];

    tableColumnsList.map((column:SelectedTableColumnProps, key:number) => {
      if(column.default) tempColumns.push(column.id);
    });

    if(storedColumnArray.length > 0) {
      columns = storedColumnArray;
    }

    else {
      columns = tempColumns;
    }

    setSelectedColumns(columns);
    return;
  }

  const getWarehouses = async () => {
    if(isLoadingWarehouses) return null;
    setisLoadingWarehouses(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getWarehouses();

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          value: item.uuid,
          label: `${item.name} (${item.city}, ${item.state}, ${item.country})`,
          ...item
        });
      });
    }

    setWarehouses(sortedData);

    setisLoadingWarehouses(false);
    return null;
  }

  const getCities = async () => {
    if(isLoadingCities) return;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name,
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }


  const getContainersStatuses = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = 'containers';
    EV.showAll = true;
    EV.isActive = 'y';

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoading(false);

    var data = res.data;
    var sortedData:any = [{
      value: null,
      label: 'All'
    }];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        if(item.is_default === 'y') {
          setContainersStatusesUuid(item.uuid);
        }
        
        sortedData.push({
          value: item.uuid,
          label: item.label,
          ...item
        });
      });
    }

    setContainersStatuses(sortedData);
  }

  const getContainers = async() => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');

    EV.resPerPage = containersPerPage;
    EV.page = containersCurrentPage;
    EV.searchTerm = searchTerm;
    EV.modulesRecordsStatusesUuid = containersStatusesUuid;

    EV.orderBy = localStorage.getItem('prefContainersListingOrderBy');
    EV.orderDirection = localStorage.getItem('prefContainersListingOrderDirection');

    EV.warehousesUuids = advanceFilters?.warehouses_uuids||undefined;
    EV.destinationCountry = advanceFilters?.destination_country||undefined;
    EV.destinationState = advanceFilters?.destination_state||undefined;
    EV.destinationCity = advanceFilters?.destination_city||undefined;

    var res:any = await EV.getContainers();
    
    if(!res) {
      // trigger error
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.code === 200) {
      setContainers(res.data);
      setContainersCount(Number(res.count)); 
    }
    
    else {
      // trigger error
      messageApi.open({
        type: 'error',
        content: res.errors[0].en,
      }); 
    }

    setIsLoading(false);
    return;
  }


  return <BlankPage
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      {(allowCreateNew) && <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate('/containers/containers/form')}
      />}
    </Space>}
  >
    {contextHolder}
    
    <Typography.Title children={'Containers'} />

    <Row gutter={15}>
      <Col>
        <Segmented 
          value={containersStatusesUuid||0}
          style={{textTransform: 'capitalize'}}
          options={containersStatuses}
          onChange={(value:any) => setContainersStatusesUuid(value)}
        />
      </Col>
      <Col>
        <Segmented
          value={listingView}
          options={[
            { value: 'table', icon: <TableOutlined />},
            { value: 'boxes', icon: <BorderOuterOutlined />},
          ]}
          onChange={(value:any) => {
            setListingView(value);
            localStorage.setItem('prefContainersListingView', value);
          }}
        />
      </Col>
      <Col flex={'auto'} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        <Space>
          <Button
            type={'default'}
            onClick={() => setShowSideMenu(!showSideMenu)}
            icon={<EyeOutlined />}
            children={'Customize View'}
          />
        </Space>
      </Col>
    </Row>

    <TableColumnsCuztomization
      showDrawer={showSideMenu}
      onClose={() => setShowSideMenu(!showSideMenu)}
      onPrcessComplete={processPrefTableColumns}
      localStorageKey='prefContainersListingTableViewColumns'
      tableColumnsList={tableColumnsList}
    />

    <div style={{marginBottom: 20}} />

    <Row gutter={15}>
      <Col span={19}>
        <Input 
          placeholder={"Type to search"}
          suffix={<SearchOutlined />}
          allowClear
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Col>
      <Col span={5}>
        <Button 
          style={{width: '100%'}}
          type={"default"}
          onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
        >
          Toggle Advanced Filters
        </Button>
      </Col>
    </Row>

    <div style={{marginBottom: 20}} />

    {(toggleAdvancedFilters) && <>
      <Typography.Title level={3}>
        Advance Search
      </Typography.Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={getContainers}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name={'warehouses_uuids'}
              label={'Warehouses'}
            >
              <Select 
                mode="tags"
                showSearch
                allowClear
                placeholder={'Warehouses'}
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  warehouses_uuids: value
                })}
                options={warehouses||[]}
                tokenSeparators={[',']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              name={'destination_country'}
              label={'Destination Country'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_country: value
                })}
                options={countries||[]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_state'}
              label={'Destination State'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_state: value
                })}
                options={filterGeoArray(
                  'state',
                  states||[],
                  undefined,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'destination_city'}
              label={'Destination City'}
            >
              <Select 
                placeholder={'Select one'}
                allowClear
                showSearch
                onChange={(value) => setAdvanceFilters({
                  ...advanceFilters,
                  destination_city: value
                })}
                options={filterGeoArray(
                  'city',
                  cities||[],
                  advanceFilters.destination_state,
                  advanceFilters.destination_country
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button 
                  type={"primary"} 
                  htmlType={"submit"}
                  loading={isLoading}
                >
                  Search
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => {
                    setAdvanceFilters({});
                    form.resetFields();
                    setTimeout(() => getContainers, 500);
                  }}
                >
                  Reset
                </Button>
                <Button 
                  type={"default"}
                  onClick={() => setToggleAdvancedFilters(!toggleAdvancedFilters)}
                >
                  Toggle Advanced Filters
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>}

    <div style={{marginBottom: 20}} />

    {(listingView === 'table') ? <>
      <Table
        scroll={{ x: 1300 }}
        loading={isLoading}
        size={userSettings('compact_view') ? 'small' : undefined}
        pagination={{
          total: containersCount||undefined,
          showSizeChanger: true,
          pageSizeOptions: [15, 50, 100, 200],
          defaultPageSize: 15,
          pageSize: containersPerPage,
          onChange: (page:number, pagesSize:number) => {
            localStorage.setItem('prefContainersListingResPerPage', pagesSize.toString())
            setContainersPerPage(pagesSize);
            setContainersCurrentPage(page);
          }
        }}
        columns={[
          {
            key: 'modules_records_statuses_label', 
            title: 'Status',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => <Tag
              color={record.modules_records_statuses_highlight_color}
              children={record.modules_records_statuses_label} 
            />
          },
          (selectedColumns?.includes('uuid')) ? {
            key: 'uuid',
            title: 'UUID',
            dataIndex: 'uuid',
            fixed: 'left',
            width: 130,
            render: (_:any, record:any) => {
              return record.uuid.substr(record.uuid.length - 10)
            }
          } : {},
          (selectedColumns?.includes('warehouse')) ? {
            key: 'warehouses_name', 
            title: 'Warehouse', 
            width: 200, 
            dataIndex: 'warehouses_name'
          } : {},
          (selectedColumns?.includes('destination')) ? {
            key: 'destination', 
            title: 'Destination', 
            width: 250,
            dataIndex: 'destination',
            render: (_:any, record:any) => {
              var destination = '';
          
              if(record.destination_country) {
                destination += record.destination_country;
              }
              
          
              if(record.destination_city) {
                if(destination.length > 0) destination += ', ';
                destination += record.destination_city;
              }

              return destination || null;
            }
          } : {},
          (selectedColumns?.includes('job_numbers')) ? {
            key: 'job_numbers', 
            title: 'Job(s) #', 
            width: 200, 
            dataIndex: 'job_numbers'
          } : {},
          (selectedColumns?.includes('container_number')) ? {
            key: 'container_number', 
            title: 'Container #', 
            width: 200, 
            dataIndex: 'container_number'
          } : {},
          (selectedColumns?.includes('booking_number')) ? {
            key: 'booking_number', 
            title: 'Booking #', 
            width: 200, 
            dataIndex: 'booking_number'
          } : {},
          (selectedColumns?.includes('seal_number')) ? {
            key: 'seal_number', 
            title: 'Seal #', 
            width: 200, 
            dataIndex: 'seal_number'
          } : {},
          (selectedColumns?.includes('steam_ship_line')) ? {
            key: 'steam_ship_line', 
            title: 'SSL', 
            width: 200, 
            dataIndex: 'steam_ship_line'
          } : {},
          {
            key: 'created_at', 
            title: 'Created', 
            dataIndex: 'created_at',
            width: 170,
            sorter: true,
            sortIcon: () => <TableColumnSortIcon
              table={'prefContainersListingOrder'}
              columnId={'containers.created_at'}
            />,
            onHeaderCell: (column) => {
              return {
                onClick: () => resortTable(
                  'Containers',
                  'containers.created_at',
                  getContainers
                )
              };
            },
            render: (_:any, record:any) => {
              return momentTz(record.created_at)
                .tz(moment.tz.guess())
                .format('MM/DD/YYYY hh:mm A');
            }
          },
          {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 100,
            render: (_:any, record:any) => <Space size={'small'}>
              <Button
                type='link'
                icon={<ExpandAltOutlined />}
                onClick={() => navigate(`/containers/containers/view/${record.uuid}`)}
              />
              {(allowEdit) && <Button
                type='link'
                icon={<FormOutlined />}
                title={'Manage'}
                onClick={() => navigate(`/containers/containers/form/${record.uuid}`)}
              />}
            </Space>
          }
        ]}
        dataSource={containers||[]}
      />
    </>
    : <>
      {(!containers || containers.length < 1) && <>
        <Empty style={{margin: 100}}/>
      </>}

      {(containers && containers.length > 0) && containers.map((container:any, key:number) => {
        return <Container key={key} dataSource={container} allowEdit={allowEdit} />
      })}
    </>}
  </BlankPage>
}