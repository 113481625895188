/**
 *
 * PROJECT eVessel Library
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * Version: 0.2.5
 * 
 */

export default class EVAPI {
  constructor() {
    this.baseAPI = null;
    this.method = 'GET';
    this.headers = null;
    this.authToken = null;
    this.additionalHeaders = null;
    this.query = null;
    this.body = {};
    this.res = {
      code: null,
      status: null,
      message: null,
      data: null,
      currentPage: null,
      resPerPage: null,
      errors: null,
      update_status: null,
      version: null,
    };
    this.lang = 'en';
  }

  async isReachable() {
    const timeout = new Promise((resolve, reject) => {
        setTimeout(reject, 5000, 'Request timed out');
    });

    const request = fetch(this.baseAPI);

    try {
      const response = await Promise
        .race([timeout, request]);
      return true;
    }
    catch (error) {
      console.error('Cannot reach host', this.baseAPI);
      return false;
    }
  }

  async request() {
    if(!this.url) {
      console.warn('URL is required to make a process');
      return false;
    }

    // set default hedaers
    if(!this.headers && !this.useAppend) {
      this.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    } 
    else {
      this.headers = {};
    }

    if(this.authToken) {
      this.headers.authorization = `Bearer ${this.authToken}`;
    }

    if(this.additionalHeaders) {
      this.headers = {
        ...this.headers,
        ...this.additionalHeaders        
      }
    }

    if(this.method == 'GET') {
      this.body = null;
    }
    else if (this.useAppend) {
      this.body = this.body;
    }
    else {
      this.body = JSON.stringify(this.body);
    }

    await fetch(this.url, {
      method: this.method,
      headers: this.headers,
      body: this.body
    })
    .then(async (response) => {
      return { server: response, content: await response.json() };
    })
    .then((json) => {
      if( this.debug ) {
        console.log('Lib API response', json);
      }
      
      var res = json.content;
      
      // set response status
      this.res.code = json.server.status;
      this.res.status = res.status;

      if(res.errors) {
        var errors = [];
        // this means return errors list
        if(res.errors) {
          res.errors.map( error => {
            errors.push(error[this.lang])
          })

          this.res.errors = errors;
        }
      }
      
      if(res.status == 'fail') {
        // fail errors
      }
      
      this.res = {
        ...this.res,
        ...res
      }
    })
    .catch((error) => {
      console.error(error);
      this.res.errors = ["Could not make the request - check logs"];
    });

    return this.res;
  }

  getErrorsString() {
    var errors = this.res.errors;
    var errorsString = '';

    if(!errors || errors.length < 1) { return ''; }

    errors.map( (error) => {
      if(errorsString != '') { errorsString += '\r\n' }

      if(errors.length > 1) errorsString += '\u2022 ';
      errorsString += error;
    });
    
    return errorsString;
  }

  /**
   * Proceess login
   */ 

  async login() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    var errors = false;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var username = this.username || null;
    var email = this.email || null;
    var password = this.password || null;
    var otpCode = this.otpCode || null;
    var pushToken = this.pushToken || null;

    if(!username && !phone && !email) {
      console.error('Must provide an identification');
      errors = true;
    }

    if(phone && !countryCode) {
      console.error('Country code is required to sign in with phone');
      errors = true;
    }

    if(!password && !otpCode) {
      console.error(
        'Password or OTP is required',
        password,
        otpCode
      );

      errors = true;
    }

    if(errors) { console.error('1x255875'); return false; }

    this.url = `${this.baseAPI}auth/login`;
    this.method = 'POST';

    if(password) {
      this.body = {
        password: password
      };
    }
    
    else if(otpCode) {
      this.body = {
        otp_code: otpCode
      };
    }

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }
    
    if(username) {
      this.body = {
        ...this.body,
        username: username
      }
    }

    if(this.pushToken) {
      this.body = {
        ...this.body,
        push_token: pushToken
      }
    }

    return await this.request();
  }

  /**
   * Proceess logout
   */ 

  async logout() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.deviceUuid && !this.allDevices) {
      console.error('deviceUuid is required [deviceUuid, allDevices]');
      return null; 
    }

    if(this.deviceUuid) {
      this.query = `device_uuid=${this.deviceUuid}`
    }

    else if (this.allDevices) {
      this.query = `all_devices=${this.allDevices}`
    }

    this.url = `${this.baseAPI}users/logout/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET warehouses
   */ 

  async getWarehouses() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.isActive)
      this.query += `isActive=${this.isActive}&`;

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.showAll)
      this.query += `showAll=y&`;  

    this.url = `${this.baseAPI}system/warehouses/list?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET warehouse
   */ 

  async getWarehouse() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/warehouses/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST warehouse
   */ 

  async createWarehouse() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.name) {
      this.body.name = this.name
    }

    if(this.country_code) {
      this.body.country_code = this.country_code
    }

    if(this.phone) {
      this.body.phone = this.phone
    }

    if(this.email) {
      this.body.email = this.email
    }

    if(this.address_1) {
      this.body.address_1 = this.address_1
    }

    if(this.address_2) {
      this.body.address_2 = this.address_2
    }

    if(this.city) {
      this.body.city = this.city
    }

    if(this.state) {
      this.body.state = this.state
    }

    if(this.country) {
      this.body.country = this.country
    }

    if(this.zip_code) {
      this.body.zip_code = this.zip_code
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }
    
    this.url = `${this.baseAPI}system/warehouses/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT warehouse
   */ 

  async updateWarehouse() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.name) {
      this.body.name = this.name
    }

    if(this.country_code) {
      this.body.country_code = this.country_code
    }

    if(this.phone) {
      this.body.phone = this.phone
    }

    if(this.email) {
      this.body.email = this.email
    }

    if(this.address_1) {
      this.body.address_1 = this.address_1
    }

    if(this.address_2) {
      this.body.address_2 = this.address_2
    }

    if(this.city) {
      this.body.city = this.city
    }

    if(this.state) {
      this.body.state = this.state
    }

    if(this.country) {
      this.body.country = this.country
    }

    if(this.zip_code) {
      this.body.zip_code = this.zip_code
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}system/warehouses/update/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE warehouse
   */

  async deleteWarehouse() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}system/warehouses/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET Equipments Types
   */ 

  async getEquipmentsTypes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.isActive)
      this.query += `isActive=${this.isActive}&`;

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.showAll)
      this.query += `showAll=y&`;  

    this.url = `${this.baseAPI}system/equipments-types/list?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET Equipments Types
   */ 

  async getEquipmentType() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/equipments-types/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST Equipment Type
   */ 

  async createEquipmentType() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.label) {
      this.body.label = this.label
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }
    
    this.url = `${this.baseAPI}system/equipments-types/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT Equipment Type
   */ 

  async updateEquipmentType() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.label) {
      this.body.label = this.label
    }
    
    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}system/equipments-types/update/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE equipmentType
   */

  async deleteEquipmentType() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}system/equipments-types/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET modules records statuses
   */ 

  async getModulesRecordsStatuses() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.isActive)
      this.query += `&isActive=${this.isActive}&`;

    if(this.module) {
      this.query += `&module=${this.module}&`;
    }

    if(this.searchTerm) {
      this.query += `&searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `&page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `&resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `&showAll=y&`;
    }

    this.url = `${this.baseAPI}system/modules-records-statuses/list?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET modules records statuses
   */ 

  async getModuleRecordStatus() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/modules-records-statuses/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST modules records statuses
   */ 

  async createModuleRecordStatus() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.module) {
      this.body.module = this.module
    }

    if(this.listing_order) {
      this.body.listing_order = this.listing_order
    }

    if(this.label) {
      this.body.label = this.label
    }
    
    if(this.description) {
      this.body.description = this.description
    }
    
    if(this.highlight_type) {
      this.body.highlight_type = this.highlight_type
    }
    
    if(this.highlight_color) {
      this.body.highlight_color = this.highlight_color
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.is_default) {
      this.body.is_default = this.is_default
    }
    
    this.url = `${this.baseAPI}system/modules-records-statuses/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT modules records statuses
   */ 

  async updateModuleRecordStatus() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.module) {
      this.body.module = this.module
    }

    if(this.listing_order) {
      this.body.listing_order = this.listing_order
    }

    if(this.label) {
      this.body.label = this.label
    }
    
    if(this.description) {
      this.body.description = this.description
    }
    
    if(this.highlight_type) {
      this.body.highlight_type = this.highlight_type
    }
    
    if(this.highlight_color) {
      this.body.highlight_color = this.highlight_color
    }
    
    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.is_default) {
      this.body.is_default = this.is_default
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}system/modules-records-statuses/update/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE modules records statuses
   */ 

  async deleteModuleRecordStatus() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/modules-records-statuses/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET exporters
   */ 

  async getExporters() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.primaryContactsUuid) {
      this.query += `primaryContactsUuid=y&`;
    }

    if(this.showDeleted) {
      this.query += `showDeleted=y&`;
    }

    if(this.isActive) {
      this.query += `isActive=${this.isActive}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;  
    }

    this.url = `${this.baseAPI}parties/exporters/list/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET exporter
   */ 

  async getExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('exporter uuid is required to getExporter');
      return null; 
    }

    this.url = `${this.baseAPI}parties/exporters/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST exporter
   */ 

  async createExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.company_name) {
      console.error('Must provide a company name');
      return null; 
    }

    this.body = {};

    if(this.uuid) {
      this.body.uuid = this.uuid
    }

    if(this.reference_id) {
      this.body.reference_id = this.reference_id
    }

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.gov_id_type) {
      this.body.gov_id_type = this.gov_id_type
    }

    if(this.gov_id_number) {
      this.body.gov_id_number = this.gov_id_number
    }

    if(this.isActive) {
      this.body.isActive = this.isActive
    }
    
    this.url = `${this.baseAPI}parties/exporters/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * PUT exporter
   */

  async updateExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.reference_id) {
      this.body.reference_id = this.reference_id
    }

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.gov_id_type) {
      this.body.gov_id_type = this.gov_id_type
    }

    if(this.gov_id_number) {
      this.body.gov_id_number = this.gov_id_number
    }

    if(this.primary_contact_uuid) {
      this.body.primary_contact_uuid = this.primary_contact_uuid
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}parties/exporters/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE exporter
   */

  async deleteExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}parties/exporters/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * POST exporter link new contact
   */ 

  async linkContactToExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/exporters/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE exporter unlink contact
   */ 

  async unlinkContactFromExporter() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an exporter UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/exporters/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * PUT exporter consignee link new contact
   */ 

  async linkContactToExporterAsConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a exporter UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/exporters-consignees/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE exporter notify party unlink contact
   */ 

  async unlinkContactFromExporterAsConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an exporter UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };

    this.url = `${this.baseAPI}parties/exporters-consignees/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * PUT exporter notify party link new contact
   */ 

  async linkContactToExporterAsNotifyParty() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a exporter UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/exporters-notify-parties/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE exporter notify party unlink contact
   */ 

  async unlinkContactFromExporterAsNotifyParty() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an exporter UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };

    this.url = `${this.baseAPI}parties/exporters-notify-parties/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * PUT exporter forwarding agent link new contact
   */ 

    async linkContactToExporterAsForwardingAgent() {
      if(!this.baseAPI) {
        console.error('baseAPI path is not set');
        return null; 
      }
  
      if(!this.uuid) {
        console.error('Must provide a exporter UUID');
        return null; 
      }
  
      if(!this.contact_uuid) {
        console.error('Must provide a contact UUID');
        return null; 
      }
  
      this.body = {
        contact_uuid: this.contact_uuid
      };
  
      this.url = `${this.baseAPI}parties/exporters-forwarding-agents/link/contact/${this.uuid}`;
      this.method = 'PUT';
  
      return await this.request();
    }
  
    /**
     * DELETE exporter forwarding agent unlink contact
     */ 
  
    async unlinkContactFromExporterAsForwardingAgent() {
      if(!this.baseAPI) {
        console.error('baseAPI path is not set');
        return null; 
      }
  
      if(!this.uuid) {
        console.error('Must provide an exporter UUID');
        return null; 
      }
  
      if(!this.contact_uuid) {
        console.error('Must provide a contact UUID');
        return null; 
      }
  
      this.body = {
        contact_uuid: this.contact_uuid
      };
  
      this.url = `${this.baseAPI}parties/exporters-forwarding-agents/unlink/contact/${this.uuid}`;
      this.method = 'DELETE';
  
      return await this.request();
    }

  /**
   * GET intermediate consignees
   */ 

  async getIntermediateConsignees() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.showDeleted) {
      this.query += `showDeleted=y&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.isActive) {
      this.query += `isActive=${this.isActive}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    this.url = `${this.baseAPI}parties/intermediate-consignees/list/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET intermediate consignee
   */ 

  async getIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}parties/intermediate-consignees/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST intermediate consignee
   */ 

  async createIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }
    
    this.url = `${this.baseAPI}parties/intermediate-consignees/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT intermediate consignee
   */ 

  async updateIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.primary_contact_uuid) {
      this.body.primary_contact_uuid = this.primary_contact_uuid
    }
    
    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}parties/intermediate-consignees/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE Intermediate Consignee
   */

  async deleteIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}parties/intermediate-consignees/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * PUT exporter consignee link new contact
   */ 

  async linkContactToIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a intermediate consignee UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/intermediate-consignees/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE exporter consignee unlink contact
   */ 

  async unlinkContactFromIntermediateConsignee() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an intermediate consignee UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };

    this.url = `${this.baseAPI}parties/intermediate-consignees/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * GET trucking companies
   */ 

  async getTruckingCompanies() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.showDeleted) {
      this.query += `showDeleted=y&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;  
    }

    this.url = `${this.baseAPI}parties/trucking-companies/list/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET trucking company
   */ 

  async getTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}parties/trucking-companies/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST Trucking Company
   */ 

  async createTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }
    
    this.url = `${this.baseAPI}parties/trucking-companies/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT Trucking Company
   */ 

  async updateTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }
    
    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}parties/trucking-companies/update/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE TruckingCompany
   */

  async deleteTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}parties/trucking-companies/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * PUT trucking company link new contact
   */ 

  async linkContactToTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a trucking company UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/trucking-companies/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE trucking company unlink contact
   */ 

  async unlinkContactFromTruckingCompany() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an trucking company UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };

    this.url = `${this.baseAPI}parties/trucking-companies/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * GET steam ships lines
   */ 

  async getSteamShipLines() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.showDeleted) {
      this.query += `showDeleted=y&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;  
    }

    this.url = `${this.baseAPI}parties/steam-ship-lines/list/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET steam ships lines
   */ 

  async getSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}parties/steam-ship-lines/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST steam ship line
   */ 

  async createSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.is_active) {
      this.body.is_active = this.is_active
    }
    
    this.url = `${this.baseAPI}parties/steam-ship-lines/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * PUT steam ship line
   */ 

  async updateSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.company_name) {
      this.body.company_name = this.company_name
    }
    
    if(this.is_active) {
      this.body.is_active = this.is_active
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}parties/steam-ship-lines/update/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE Steam Ship Line
   */

  async deleteSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}parties/steam-ship-lines/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * PUT steam ship line link new contact
   */ 

  async linkContactToSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a steam ship line UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };
    
    this.url = `${this.baseAPI}parties/steam-ship-lines/link/contact/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE steam ship line unlink contact
   */ 

  async unlinkContactFromSteamShipLine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide an steam ship line UUID');
      return null; 
    }

    if(!this.contact_uuid) {
      console.error('Must provide a contact UUID');
      return null; 
    }

    this.body = {
      contact_uuid: this.contact_uuid
    };

    this.url = `${this.baseAPI}parties/steam-ship-lines/unlink/contact/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * GET contacts
   */ 

  async getContacts() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.type)
      this.query += `type=${this.type}&`;

    if(this.typeUuid)
      this.query += `typeUuid=${this.typeUuid}&`;

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.showAll)
      this.query += `showAll=y&`;  

    if(this.showDeleted)
      this.query += `showDeleted=${this.showDeleted}&`;  

    this.url = `${this.baseAPI}general/contacts/list?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET contact
   */ 

  async getContact() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContact requires contact UUID');
      return null; 
    }

    this.url = `${this.baseAPI}general/contacts/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST contact
   */ 

  async createContact() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.name_prefix) {
      this.body.name_prefix = this.name_prefix
    }

    if(this.first_name) {
      this.body.first_name = this.first_name
    }

    if(this.last_name) {
      this.body.last_name = this.last_name
    }

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.phone_country_code) {
      this.body.phone_country_code = this.phone_country_code
    }

    if(this.phone) {
      this.body.phone = this.phone
    }

    if(this.fax_country_code) {
      this.body.fax_country_code = this.fax_country_code
    }

    if(this.fax) {
      this.body.fax = this.fax
    }

    if(this.email) {
      this.body.email = this.email
    }

    if(this.address_1) {
      this.body.address_1 = this.address_1
    }

    if(this.address_2) {
      this.body.address_2 = this.address_2
    }

    if(this.city) {
      this.body.city = this.city
    }

    if(this.state) {
      this.body.state = this.state
    }

    if(this.country) {
      this.body.country = this.country
    }

    if(this.zip_code) {
      this.body.zip_code = this.zip_code
    }

    if(this.tags) {
      this.body.tags = this.tags
    }
    
    this.url = `${this.baseAPI}general/contacts/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * PUT contact
   */ 

  async updateContact() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.name_prefix) {
      this.body.name_prefix = this.name_prefix
    }

    if(this.first_name) {
      this.body.first_name = this.first_name
    }

    if(this.last_name) {
      this.body.last_name = this.last_name
    }

    if(this.company_name) {
      this.body.company_name = this.company_name
    }

    if(this.phone_country_code) {
      this.body.phone_country_code = this.phone_country_code
    }

    if(this.phone) {
      this.body.phone = this.phone
    }

    if(this.fax_country_code) {
      this.body.fax_country_code = this.fax_country_code
    }

    if(this.fax) {
      this.body.fax = this.fax
    }

    if(this.email) {
      this.body.email = this.email
    }

    if(this.address_1) {
      this.body.address_1 = this.address_1
    }

    if(this.address_2) {
      this.body.address_2 = this.address_2
    }

    if(this.city) {
      this.body.city = this.city
    }

    if(this.state) {
      this.body.state = this.state
    }

    if(this.country) {
      this.body.country = this.country
    }

    if(this.zip_code) {
      this.body.zip_code = this.zip_code
    }

    if(this.tags) {
      this.body.tags = this.tags
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}general/contacts/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE contact
   */

  async deleteContact() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}general/contacts/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET cities
   */ 

  async getCities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.type) {
      if(!['cities', 'states', 'countries'].includes(this.type)) {
        console.error(
          'Failed to getCities, provided type is not valid', 
          this.type
        )

        return null;
      }

      this.query += `type=${this.type}&`;
    }

    if(this.city) {
      this.query += `city=${this.city}&`;
    }

    if(this.state) {
      this.query += `state=${this.state}&`;
    }

    if(this.country) {
      this.query += `country=${this.country}&`;
    }

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;  
    }

    if(this.showDeleted) {
      this.query += `showDeleted=${this.showDeleted}&`;
    }

    this.url = `${this.baseAPI}system/cities/list?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * Check UUID availability
   */

  async checkCargoeReferenceIdAvailability() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    } 

    if(!this.reference_id) {
      console.error('Must provide a UUID');
      return null; 
    } 


    this.url = `${this.baseAPI}containers/cargoes/check-reference-id-availability/${this.reference_id}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * VIN Decoder
   */

  async vinDecoder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    } 

    if(!this.vin_number) {
      console.error('Must provide vin_number');
      return null; 
    } 

    var query = '?';

    if(this.skip_history_check) {
      query += 'skipHistoryCheck=y' 
    }

    this.url = `${this.baseAPI}containers/cargoes/vin-decoder/${this.vin_number}/${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET cargoes
   */ 

  async getCargoes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.type) {
      this.query += `type=${this.type}&`;
    }

    if(this.modulesRecordsStatusesUuid) {
      this.query += `modulesRecordsStatusesUuid=${this.modulesRecordsStatusesUuid}&`;
    }

    if(this.exportersUuid) {
      this.query += `exportersUuid=${this.exportersUuid}&`;
    }

    if(this.warehousesUuids) {
      this.query += `warehousesUuids=${this.warehousesUuids}&`;
    }

    if(this.warehousesUuid) {
      this.query += `warehousesUuid=${this.warehousesUuid}&`;
    }

    if(this.packingOrdersUuid) {
      this.query += `packingOrdersUuid=${this.packingOrdersUuid}&`;
    }

    if(this.destinationCountry) {
      this.query += `destinationCountry=${this.destinationCountry}&`;
    }

    if(this.destinationState) {
      this.query += `destinationState=${this.destinationState}&`;
    }

    if(this.destinationCity) {
      this.query += `destinationCity=${this.destinationCity}&`;
    }

    if(this.dateInFrom) {
      this.query += `dateInFrom=${this.dateInFrom}&`;
    }

    if(this.dateInTo) {
      this.query += `dateInTo=${this.dateInTo}&`;
    }

    if(this.insuranceType) {
      this.query += `insuranceType=${this.insuranceType}&`;
    }

    if(this.vinNumber) {
      this.query += `vinNumber=${this.vinNumber}&`;
    }

    if(this.make) {
      this.query += `make=${this.make}&`;
    }

    if(this.year) {
      this.query += `year=${this.year}&`;
    }

    if(this.model) {
      this.query += `model=${this.model}&`;
    }

    if(this.hasTitle) {
      this.query += `hasTitle=${this.hasTitle}&`;
    }

    if(this.title) {
      this.query += `title=${this.title}&`;
    }

    if(this.isRunning) {
      this.query += `isRunning=${this.isRunning}&`;
    }

    if(this.isSalvage) {
      this.query += `isSalvage=${this.isSalvage}&`;
    }

    if(this.isDismentled) {
      this.query += `isDismantled=${this.isDismentled}&`;
    }

    if(this.includeUuids) {
      this.query += `includeUuids=${this.includeUuids}&`
    }

    if(this.excludeUuids) {
      this.query += `excludeUuids=${this.excludeUuids}&`
    }

    if(this.linkedToPackingOrder) {
      this.query += `linkedToPackingOrder=${this.linkedToPackingOrder}&`
    }

    if(this.searchTerm) this.query += `searchTerm=${this.searchTerm}&`;

    if(this.orderBy) this.query += `orderBy=${this.orderBy}&`;

    if(this.orderDirection) this.query += `orderDirection=${this.orderDirection}&`;

    if(this.page) this.query += `page=${this.page}&`;

    if(this.resPerPage) this.query += `resPerPage=${this.resPerPage}&`;

    if(this.showAll) this.query += `showAll=y&`;  

    if(this.showDeleted)  this.query += `showDeleted=${this.showDeleted}&`;

    if(this.count) {
      this.url = `${this.baseAPI}containers/cargoes/count?${this.query}`;
    }
    else {
      this.url = `${this.baseAPI}containers/cargoes/list?${this.query}`;
    }

    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET cargoe
   */ 

  async getCargo() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getCargo requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/cargoes/show/${!this.authToken ? 'guest/' : ''}${this.uuid}/?accessCode=${this.access_code}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET cargorelated mailing list
   */

  async getCargoRelatedEmailingList() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getCargoRelatedEmailingList requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/cargoes/related-mailing-list/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }
  

  /**
   * POST cargoe
   */ 

  async createCargo() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.uuid) {
      this.body.uuid = this.uuid
    }

    if(this.reference_id) {
      this.body.reference_id = this.reference_id
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid
    }

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid
    }

    if(this.date_in) {
      this.body.date_in = this.date_in
    }

    if(this.type) {
      this.body.type = this.type
    }

    if(this.is_owner_known) {
      if(!['n', 'y'].includes(this.is_owner_known)) {
        console.error(
          'Failed to createCargo, provided is_owner_known is not valid', 
          this.is_owner_known
        )

        return null;
      }

      this.body.is_owner_known = this.is_owner_known
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city
    }

    if(this.quantity) {
      this.body.quantity = this.quantity
    }

    if(this.dimensions) {
      this.body.dimensions = this.dimensions
    }

    if(this.value) {
      this.body.value = this.value
    }

    if(this.weight) {
      this.body.weight = this.weight
    }

    if(this.insurance_type) {
      if(!['none', 'full coverage', 'partial coverage', 'total loss'].includes(this.insurance_type)) {
        console.error(
          'Failed to createCargo, provided type is not valid', 
          this.type
        )

        return null;
      }

      this.body.insurance_type = this.insurance_type
    }

    if(this.insurance_value) {
      this.body.insurance_value = this.insurance_value
    }

    if(this.mbl_notes) {
      this.body.mbl_notes = this.mbl_notes
    }

    if(this.private_notes) {
      this.body.private_notes = this.private_notes
    }

    if(this.public_notes) {
      this.body.public_notes = this.public_notes
    }

    if(this.is_public) {
      if(!['n', 'y'].includes(this.is_public)) {
        console.error(
          'Failed to createCargo, provided is_public is not valid', 
          this.is_public
        )

        return null;
      }

      this.body.is_public = this.is_public
    }

    if(this.vin_number) {
      this.body.vin_number = this.vin_number
    }

    if(this.make) {
      this.body.make = this.make
    }

    if(this.model) {
      this.body.model = this.model
    }

    if(this.fuel_type) {
      this.body.fuel_type = this.fuel_type
    }

    if(this.year) {
      this.body.year = this.year
    }

    if(this.color) {
      this.body.color = this.color
    }

    if(this.mileage) {
      this.body.mileage = this.mileage
    }

    if(this.number_of_keys) {
      this.body.number_of_keys = this.number_of_keys
    }

    if(this.has_title) {
      this.body.has_title = this.has_title
    }

    if(this.title) {
      this.body.title = this.title
    }

    if(this.title_country) {
      this.body.title_country = this.title_country
    }

    if(this.title_state) {
      this.body.title_state = this.title_state
    }

    if(this.lot_number) {
      this.body.lot_number = this.lot_number
    }

    if(this.is_salvage) {
      this.body.is_salvage = this.is_salvage
    }

    if(this.is_dismantled) {
      this.body.is_dismantled = this.is_dismantled
    }

    if(this.is_running) {
      this.body.is_running = this.is_running
    }
    
    this.url = `${this.baseAPI}containers/cargoes/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * PUT cargoe
   */ 

  async updateCargo() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.reference_id) {
      this.body.reference_id = this.reference_id
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid
    }

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid
    }

    if(this.date_in) {
      this.body.date_in = this.date_in
    }

    if(this.type) {
      this.body.type = this.type
    }

    if(this.is_owner_known) {
      if(!['n', 'y'].includes(this.is_owner_known)) {
        console.error(
          'Failed to updateCargo, provided is_owner_known is not valid', 
          this.is_owner_known
        )

        return null;
      }

      this.body.is_owner_known = this.is_owner_known
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city
    }

    if(this.quantity) {
      this.body.quantity = this.quantity
    }

    if(this.dimensions) {
      this.body.dimensions = this.dimensions
    }

    if(this.value) {
      this.body.value = this.value
    }

    if(this.weight) {
      this.body.weight = this.weight
    }

    if(this.insurance_type) {
      if(!['none', 'full coverage', 'partial coverage', 'total loss'].includes(this.insurance_type)) {
        console.error(
          'Failed to updateCargo, provided type is not valid', 
          this.type
        )

        return null;
      }

      this.body.insurance_type = this.insurance_type
    }

    if(this.insurance_value) {
      this.body.insurance_value = this.insurance_value
    }

    if(this.mbl_notes) {
      this.body.mbl_notes = this.mbl_notes
    }

    if(this.private_notes) {
      this.body.private_notes = this.private_notes
    }

    if(this.public_notes) {
      this.body.public_notes = this.public_notes
    }

    if(this.is_public) {
      if(!['n', 'y'].includes(this.is_public)) {
        console.error(
          'Failed to updateCargo, provided is_public is not valid', 
          this.is_public
        )

        return null;
      }

      this.body.is_public = this.is_public
    }

    if(this.vin_number) {
      this.body.vin_number = this.vin_number
    }

    if(this.make) {
      this.body.make = this.make
    }

    if(this.model) {
      this.body.model = this.model
    }

    if(this.fuel_type) {
      this.body.fuel_type = this.fuel_type
    }

    if(this.year) {
      this.body.year = this.year
    }

    if(this.color) {
      this.body.color = this.color
    }

    if(this.mileage) {
      this.body.mileage = this.mileage
    }

    if(this.number_of_keys) {
      this.body.number_of_keys = this.number_of_keys
    }

    if(this.has_title) {
      this.body.has_title = this.has_title
    }

    if(this.title) {
      this.body.title = this.title
    }

    if(this.title_country) {
      this.body.title_country = this.title_country
    }

    if(this.title_state) {
      this.body.title_state = this.title_state
    }

    if(this.lot_number) {
      this.body.lot_number = this.lot_number
    }

    if(this.is_salvage) {
      this.body.is_salvage = this.is_salvage
    }

    if(this.is_dismantled) {
      this.body.is_dismantled = this.is_dismantled
    }

    if(this.is_running) {
      this.body.is_running = this.is_running
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}containers/cargoes/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * email cargoes
   */ 

  async emailCargoes() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailCargoes requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/cargoes/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * DELETE cargo
   */

  async deleteCargo() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}containers/cargoes/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET containers
   */

  async getContainers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.searchTerm)
      this.query += `searchTerm=${this.searchTerm}&`;

    if(this.modulesRecordsStatusesUuid) {
      this.query += `&modulesRecordsStatusesUuid=${this.modulesRecordsStatusesUuid}&`;
    }

    if(this.warehousesUuids) {
      this.query += `warehousesUuids=${this.warehousesUuids}&`;
    }

    if(this.warehousesUuid) {
      this.query += `warehousesUuid=${this.warehousesUuid}&`;
    }

    if(this.destinationCountry) {
      this.query += `destinationCountry=${this.destinationCountry}&`;
    }

    if(this.destinationState) {
      this.query += `destinationState=${this.destinationState}&`;
    }

    if(this.destinationCity) {
      this.query += `destinationCity=${this.destinationCity}&`;
    }

    if(this.page)
      this.query += `page=${this.page}&`;

    if(this.resPerPage)
      this.query += `resPerPage=${this.resPerPage}&`;

    if(this.showAll)
      this.query += `showAll=y&`; 

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;  
    }
    
    if(this.count) {
      this.url = `${this.baseAPI}containers/containers/count?${this.query}`;
    }
    else {
      this.url = `${this.baseAPI}containers/containers/list?${this.query}`;
    }

    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET container
   */

  async getContainer() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET container related mailing list
   */

  async getContainerRelatedEmailingList() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainerRelatedEmailingList requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/related-mailing-list/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET container MBL
   */

  async getContainerMasterBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getContainer requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getContainer requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/docs/master-bill-of-lading/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * POST container MBL
   */

  async emailContainerMasterBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/master-bill-of-lading/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * GET container MBL
   */

  async getContainerBookingMasterBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getContainer requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getContainer requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/docs/booking-master-bill-of-lading/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code||''}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * GET container Packing Order
   */

  async getContainerPackingOrderDoc() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainerPackingOrderDoc requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getContainerPackingOrderDoc requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getContainerPackingOrderDoc requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/docs/packing-order/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code||''}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * email container booking MBL
   */

  async emailContainerBookingMasterBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/booking-master-bill-of-lading/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * email container booking MBL
   */

  async emailContainerBookingMasterBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/booking-master-bill-of-lading/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * email container booking MBL
   */

  async emailContainerDockReceipt() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailContainerDockReceipt requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/dock-receipt/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * POST container Packing Order Doc
   */

  async emailContainerPackingOrderDoc() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/packing-order/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * POST container Packing Order Doc
   */

  async emailContainerShippingInstructionForm() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getContainer requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/containers/docs/shipping-instruction-form/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * CREATE container
   */

  async createContainer() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid
    }

    if(this.consignee_contacts_uuid) {
      this.body.consignee_contacts_uuid = this.consignee_contacts_uuid
    }

    if(this.intermediate_consignees_uuid) {
      this.body.intermediate_consignees_uuid = this.intermediate_consignees_uuid
    }

    if(this.notify_party_contacts_uuid) {
      this.body.notify_party_contacts_uuid = this.notify_party_contacts_uuid
    }

    if(this.forwarding_agent_contacts_uuid) {
      this.body.forwarding_agent_contacts_uuid = this.forwarding_agent_contacts_uuid
    }

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid
    }

    if(this.container_number) {
      this.body.container_number = this.container_number
    }

    if(this.equipments_types_uuid) {
      this.body.equipments_types_uuid = this.equipments_types_uuid
    }

    if(this.vessel) {
      this.body.vessel = this.vessel
    }

    if(this.trucking_companies_uuid) {
      this.body.trucking_companies_uuid = this.trucking_companies_uuid
    }

    if(this.steam_ship_lines_uuid) {
      this.body.steam_ship_lines_uuid = this.steam_ship_lines_uuid
    }

    if(this.booking_number) {
      this.body.booking_number = this.booking_number
    }

    if(this.seal_number) {
      this.body.seal_number = this.seal_number
    }

    if(this.received_date) {
      this.body.received_date = this.received_date
    }

    if(this.packing_date) {
      this.body.packing_date = this.packing_date
    }

    if(this.return_date) {
      this.body.return_date = this.return_date
    }

    if(this.departure_date) {
      this.body.departure_date = this.departure_date
    }

    if(this.arrival_date) {
      this.body.arrival_date = this.arrival_date
    }

    if(this.cut_off_date) {
      this.body.cut_off_date = this.cut_off_date
    }

    if(this.port_of_export_country) {
      this.body.port_of_export_country = this.port_of_export_country
    }

    if(this.port_of_export_state) {
      this.body.port_of_export_state = this.port_of_export_state
    }

    if(this.port_of_export_city) {
      this.body.port_of_export_city = this.port_of_export_city
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city
    }

    if(this.pickup_terminal) {
      this.body.pickup_terminal = this.pickup_terminal
    }

    if(this.return_terminal) {
      this.body.return_terminal = this.return_terminal
    }

    if(this.place_of_delivery_country) {
      this.body.place_of_delivery_country = this.place_of_delivery_country
    }

    if(this.place_of_delivery_state) {
      this.body.place_of_delivery_state = this.place_of_delivery_state
    }

    if(this.place_of_delivery_city) {
      this.body.place_of_delivery_city = this.place_of_delivery_city
    }

    if(this.freight_type) {
      if(!['prepaid', 'collect'].includes(this.freight_type)) {
        console.warn(
          'Failed to createContainer, provided freight type is not valid', 
          this.freight_type
        )
      }

      else {
        this.body.freight_type = this.freight_type
      }
    }

    if(this.release_type) {
      if(!['express release', 'obl', 'telex release'].includes(this.release_type)) {
        console.warn(
          'Failed to createContainer, provided release type is not valid', 
          this.release_type
        )
      }

      else {
        this.body.release_type = this.release_type
      }
    }

    if(this.move_type) {
      if(!['CY - CY','door - door', 'door - port', 'port - door', 'port - port'].includes(this.move_type)) {
        console.warn(
          'Failed to createContainer, provided move type is not valid', 
          this.move_type
        )
      }

      else {
        this.body.move_type = this.move_type
      }
    }

    if(this.public_notes) {
      this.body.public_notes = this.public_notes
    }

    if(this.private_notes) {
      this.body.private_notes = this.private_notes
    }

    if(this.is_custom_hold) {
      if(!['n', 'y'].includes(this.is_custom_hold)) {
        console.warn(
          'Failed to createContainer, provided is_custom_hold is not valid', 
          this.is_custom_hold
        )
      }
      else {
        this.body.is_custom_hold = this.is_custom_hold
      }
    }

    this.url = `${this.baseAPI}containers/containers/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * UPDATE container
   */

  async updateContainer() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid
    }

    if(this.consignee_contacts_uuid) {
      this.body.consignee_contacts_uuid = this.consignee_contacts_uuid
    }

    if(this.intermediate_consignees_uuid) {
      this.body.intermediate_consignees_uuid = this.intermediate_consignees_uuid
    }

    if(this.notify_party_contacts_uuid) {
      this.body.notify_party_contacts_uuid = this.notify_party_contacts_uuid
    }

    if(this.forwarding_agent_contacts_uuid) {
      this.body.forwarding_agent_contacts_uuid = this.forwarding_agent_contacts_uuid
    }

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid
    }

    if(this.container_number) {
      this.body.container_number = this.container_number
    }

    if(this.equipments_types_uuid) {
      this.body.equipments_types_uuid = this.equipments_types_uuid
    }

    if(this.vessel) {
      this.body.vessel = this.vessel
    }

    if(this.trucking_companies_uuid) {
      this.body.trucking_companies_uuid = this.trucking_companies_uuid
    }

    if(this.steam_ship_lines_uuid) {
      this.body.steam_ship_lines_uuid = this.steam_ship_lines_uuid
    }

    if(this.booking_number) {
      this.body.booking_number = this.booking_number
    }

    if(this.seal_number) {
      this.body.seal_number = this.seal_number
    }

    if(this.received_date) {
      this.body.received_date = this.received_date
    }

    if(this.packing_date) {
      this.body.packing_date = this.packing_date
    }

    if(this.return_date) {
      this.body.return_date = this.return_date
    }

    if(this.departure_date) {
      this.body.departure_date = this.departure_date
    }

    if(this.arrival_date) {
      this.body.arrival_date = this.arrival_date
    }

    if(this.cut_off_date) {
      this.body.cut_off_date = this.cut_off_date
    }

    if(this.port_of_export_country) {
      this.body.port_of_export_country = this.port_of_export_country
    }

    if(this.port_of_export_state) {
      this.body.port_of_export_state = this.port_of_export_state
    }

    if(this.port_of_export_city) {
      this.body.port_of_export_city = this.port_of_export_city
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city
    }

    if(this.pickup_terminal) {
      this.body.pickup_terminal = this.pickup_terminal
    }

    if(this.return_terminal) {
      this.body.return_terminal = this.return_terminal
    }

    if(this.place_of_delivery_country) {
      this.body.place_of_delivery_country = this.place_of_delivery_country
    }

    if(this.place_of_delivery_state) {
      this.body.place_of_delivery_state = this.place_of_delivery_state
    }

    if(this.place_of_delivery_city) {
      this.body.place_of_delivery_city = this.place_of_delivery_city
    }

    if(this.freight_type) {
      if(!['prepaid', 'collect'].includes(this.freight_type)) {
        console.warn(
          'Failed to updateContainer, provided type is not valid', 
          this.freight_type
        )
      }

      else {
        this.body.freight_type = this.freight_type
      }
    }

    if(this.release_type) {
      if(!['express release', 'obl', 'telex release'].includes(this.release_type)) {
        console.warn(
          'Failed to updateContainer, provided release type is not valid', 
          this.release_type
        )
      }

      else {
        this.body.release_type = this.release_type
      }
    }

    if(this.move_type) {
      if(!['CY - CY','door - door', 'door - port', 'port - door', 'port - port'].includes(this.move_type)) {
        console.warn(
          'Failed to updateContainer, provided move type is not valid', 
          this.move_type
        )
      }

      else {
        this.body.move_type = this.move_type
      }
    }

    if(this.public_notes) {
      this.body.public_notes = this.public_notes
    }

    if(this.private_notes) {
      this.body.private_notes = this.private_notes
    }

    if(this.is_custom_hold) {
      if(!['n', 'y'].includes(this.is_custom_hold)) {
        console.warn(
          'Failed to updateContainer, provided is_custom_hold is not valid', 
          this.is_custom_hold
        )
      }
      else {
        this.body.is_custom_hold = this.is_custom_hold
      }
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}containers/containers/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE container
   */

  async deleteContainer() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}containers/containers/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET packing order HBL
   */

  async getPackingOrderHouseBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrderHouseBillOfLading requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getPackingOrderHouseBillOfLading requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getPackingOrderHouseBillOfLading requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/house-bill-of-lading/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * POST Packing Order HBL
   */

  async emailPackingOrderHouseBillOfLading() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailPackingOrderHouseBillOfLading requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/house-bill-of-lading/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * POST send Packing Order Loading Plan Doc Receipt Mail
   */

  async emailPackingOrderDocReceipt() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailPackingOrderDocReceipt requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/doc-receipt/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * GET packing order HHG
   */

  async getPackingOrderHouseHoldGoods() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrderHouseHoldGoods requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getPackingOrderHouseHoldGoods requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getPackingOrderHouseHoldGoods requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/house-hold-goods/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * POST send Packing Order House Hold Goods Mail
   */

  async emailPackingOrderHouseHoldGoods() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailPackingOrderHouseHoldGoods requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/house-hold-goods/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * GET packing order HBL
   */

  async getPackingOrderCustomClearance() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrderCustomClearance requires UUID');
      return null; 
    }

    if(!this.guest && !this.authToken) {
      console.error('getPackingOrderCustomClearance requires guest param or authToken');
      return null; 
    }

    if(this.guest && !this.access_code) {
      console.error('getPackingOrderCustomClearance requires access code for guest');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/custom-clearance/${this.guest ? 'guest/' : ''}?recordUuid=${this.uuid}&&accessCode=${this.access_code}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * POST send Packing Order custom clearance Mail
   */

  async emailPackingOrderCustomClearance() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailPackingOrderCustomClearance requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}containers/packing-orders/docs/custom-clearance/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * GET packing orders
   */

  async getPackingOrders() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.containersUuid) {
      this.query += `containersUuid=${this.containersUuid}&`;
    }

    if(this.exportersUuid) {
      this.query += `exportersUuid=${this.exportersUuid}&`;
    }

    if(this.invoicesUuid) {
      this.query += `invoicesUuid=${this.invoicesUuid}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`; 
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.count) {
      this.url = `${this.baseAPI}containers/packing-orders/count?${this.query}`;
    }
    else {
      this.url = `${this.baseAPI}containers/packing-orders/list?${this.query}`;
    }

    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET packing order
   */

  async getPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrder requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET packing order related mailing list
   */

  async getPackingOrderRelatedEmailingList() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrderRelatedEmailingList requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/related-mailing-list/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * POST packing orders
   */

  async createPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.containers_uuid) {
      this.body.containers_uuid = this.containers_uuid;
    }

    if(this.packages_type) {
      if(!['units','vehicles','packages','items','lots'].includes(this.packages_type)) {
        console.warn(
          'Failed to createPackingOrder, provided packages type is not valid', 
          this.packages_type
        )
      }

      else {
        this.body.packages_type = this.packages_type
      }
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.exporters_consignees_contacts_uuid) {
      this.body.exporters_consignees_contacts_uuid = this.exporters_consignees_contacts_uuid;
    }

    if(this.exporters_notify_party_contacts_uuid) {
      this.body.exporters_notify_party_contacts_uuid = this.exporters_notify_party_contacts_uuid;
    }

    if(this.exporters_forwarding_agent_contacts_uuid) {
      this.body.exporters_forwarding_agent_contacts_uuid = this.exporters_forwarding_agent_contacts_uuid;
    }

    if(this.intermediate_consignees_uuid) {
      this.body.intermediate_consignees_uuid = this.intermediate_consignees_uuid;
    }

    if(this.aes_itn) {
      this.body.aes_itn = this.aes_itn
    }

    if(this.internal_rate) {
      this.body.internal_rate = this.internal_rate;
    }

    if(this.sales_total) {
      this.body.sales_total = this.sales_total;
    }

    this.url = `${this.baseAPI}containers/packing-orders/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * Check reference number availability
   */

  async checkPackingOrderReferenceNumberAvailability() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    } 

    if(!this.exporter_uuid) {
      console.error('Must provide an exporter UUID');
      return null; 
    }

    if(!this.reference_number) {
      console.error('Must provide a reference number to check');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/check-ref-number-availability/${this.exporter_uuid}/?refNumber=${this.reference_number}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * Check reference number availability
   */

  async getExportersPackingOrderLastReferenceNumber() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    } 

    if(!this.exporter_uuid) {
      console.error('Must provide an exporter UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/exporter-last-ref-number/${this.exporter_uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * UPDATE packing orders
   */

  async updatePackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('updatePackingOrder require UUID');
      return null; 
    }

    this.body = {};

    if(this.containers_uuid) {
      this.body.containers_uuid = this.containers_uuid;
    }

    if(this.packages_type) {
      if(!['units','vehicles','packages','items','lots'].includes(this.packages_type)) {
        console.warn(
          'Failed to updatePackingOrder, provided packages type is not valid', 
          this.packages_type
        )
      }

      else {
        this.body.packages_type = this.packages_type
      }
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.exporters_consignees_contacts_uuid) {
      this.body.exporters_consignees_contacts_uuid = this.exporters_consignees_contacts_uuid;
    }

    if(this.exporters_notify_party_contacts_uuid) {
      this.body.exporters_notify_party_contacts_uuid = this.exporters_notify_party_contacts_uuid;
    }

    if(this.exporters_forwarding_agent_contacts_uuid) {
      this.body.exporters_forwarding_agent_contacts_uuid = this.exporters_forwarding_agent_contacts_uuid;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.intermediate_consignees_uuid) {
      this.body.intermediate_consignees_uuid = this.intermediate_consignees_uuid;
    }

    if(this.aes_itn) {
      this.body.aes_itn = this.aes_itn
    }

    if(this.internal_rate) {
      this.body.internal_rate = this.internal_rate;
    }

    if(this.sales_total) {
      this.body.sales_total = this.sales_total;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}containers/packing-orders/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE link cargo to packing order
   */

  async linkCargoToPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a packing order UUID');
      return null; 
    }

    if(!this.cargo_uuid) {
      console.error('Must provide a cargo UUID');
      return null; 
    }

    this.body = {
      cargo_uuid: this.cargo_uuid
    };
    
    this.url = `${this.baseAPI}containers/packing-orders/link/cargo/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE unlink cargo from packing order
   */

  async unlinkCargoFromPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a packing order UUID');
      return null; 
    }

    if(!this.cargo_uuid) {
      console.error('Must provide a cargo UUID');
      return null; 
    }

    this.body = {
      cargo_uuid: this.cargo_uuid
    };
    
    this.url = `${this.baseAPI}containers/packing-orders/unlink/cargo/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * DELETE packing orders
   */

  async deletePackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-orders/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * POST packing plan
   */

  async createPackingPlan() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.exporters_uuid) {
      console.error('exporters_uuid is required to create new packing plan');
      return null; 
    }

    this.body = {};

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.exporters_consignees_contacts_uuid) {
      this.body.exporters_consignees_contacts_uuid = this.exporters_consignees_contacts_uuid;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city;
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state;
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country;
    }

    if(this.cargoes_uuids) {
      this.body.cargoes_uuids = this.cargoes_uuids
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    this.url = `${this.baseAPI}containers/packing-plans/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * GET packing plans
   */

  async getPackingPlans() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.exportersUuid) {
      this.query += `exportersUuid=${this.exportersUuid}&`;
    }

    if(this.destinationCountry) {
      this.query += `destinationCountry=${this.destinationCountry}&`;
    }

    if(this.destinationState) {
      this.query += `destinationState=${this.destinationState}&`;
    }

    if(this.destinationCity) {
      this.query += `destinationCity=${this.destinationCity}&`;
    }

    if(this.unprocessed) {
      this.query += `unprocessed=${this.unprocessed}&`;
    }

    if(this.modulesRecordsStatusesUuid) {
      this.query += `modulesRecordsStatusesUuid=${this.modulesRecordsStatusesUuid}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`; 
    }

    if(this.showDeleted) {
      this.query += `showDeleted=${this.showDeleted}&`; 
    }

    if(this.showSubmitted) {
      this.query += `showSubmitted=${this.showSubmitted}&`; 
    }
    
    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.count) {
      this.url = `${this.baseAPI}containers/packing-plans/count?${this.query}`;
    }
    else {
      this.url = `${this.baseAPI}containers/packing-plans/list?${this.query}`;
    }

    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET packing plan
   */

  async getPackingPlan() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getPackingOrder requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-plans/show/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * UPDATE packing plan
   */

  async updatePackingPlan() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('updatePackingOrder require UUID');
      return null; 
    }

    this.body = {};

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.exporters_consignees_contacts_uuid) {
      this.body.exporters_consignees_contacts_uuid = this.exporters_consignees_contacts_uuid;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.destination_city) {
      this.body.destination_city = this.destination_city;
    }

    if(this.destination_state) {
      this.body.destination_state = this.destination_state;
    }

    if(this.destination_country) {
      this.body.destination_country = this.destination_country;
    }

    if(this.cargoes_uuids) {
      this.body.cargoes_uuids = this.cargoes_uuids
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }
    
    if(this.submitted_at) {
      this.body.submitted_at = this.submitted_at;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}containers/packing-plans/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE packing plan
   */

  async deletePackingPlan() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}containers/packing-plans/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET documents
   */

  async getDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    var query = ``;

    if(this.purposeUuid) {
      query += `purposeUuid=${this.purposeUuid}&`
    }

    if(this.purpose) {
      query += `purpose=${this.purpose}&`
    }

    if(this.category) {
      query += `category=${this.category}&`
    }

    if(this.isPublic) {
      query += `isPublic=${this.isPublic}&`
    }

    if(this.accessCode) {
      query += `accessCode=${this.accessCode}&`
    }

    if(this.showDeleted) {
      query += `showDeleted=${this.showDeleted}&`
    }
    
    this.url = `${this.baseAPI}general/documents/list?${query}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * GET document
   */

  async openDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('uuid is not set');
      return null; 
    }
    
    this.url = `${this.baseAPI}general/documents/open/`;
    this.method = 'GET';
    this.headers = {}

    if(this.authToken) {
      this.headers.authorization = `Bearer ${this.authToken}`;
      this.url += `${this.uuid}/?`
    }
    else {
      this.url += `guest/${this.uuid}/?`
    }

    if(this.purpose) {
      this.url += `&&purpose=${this.purpose}`
    }

    if(this.purposeUuid) {
      this.url += `&&purposeUuid=${this.purposeUuid}`
    }

    if(this.purposeUuid) {
      this.url += `&&accessCode=${this.accessCode}`
    }

    return fetch(this.url, {
      method: this.method,
      headers: this.headers
    })
    .then(response => response.blob())
    .then(imageBlob => {
      // Then create a local URL for that image and print it 
      return imageBlob;
    });
  }

  /**
   * Upload document
   */

  async uploadDocument() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    this.url = `${this.baseAPI}general/documents/upload`;
    this.method = 'POST';
    
    return await this.request();
  } 

  /**
   * Upload document
   */

  async updateDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.category) {
      if(!['general', 'title'].includes(this.category)) {
        console.error(
          'Failed to updateDocument, category type is not valid', 
          this.type
        )

        return null;
      }

      this.body.category = this.category;
    }

    if(this.is_public) {
      this.body.is_public = this.is_public;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }
    
    this.url = `${this.baseAPI}general/documents/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  } 

  /**
   * Delete document
   */

  async deleteDocument() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};
    
    this.url = `${this.baseAPI}general/documents/delete/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * Clean documents
   */

  async cleanDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};
    
    this.url = `${this.baseAPI}general/documents/clean/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * Clean documents
   */

  async restoreDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};
    
    this.url = `${this.baseAPI}general/documents/restore/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * GET Settings
   */

  async getSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    this.url = `${this.baseAPI}system/settings/list${!this.authToken ? '/guest/' : ''}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * UPDATE Settings
   */
  async updateSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}system/settings/update`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * GET users
   */

  async getUsers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.isBlocked) {
      this.query += `isBlocked=${this.isBlocked}&`;
    }

    if(this.type) {
      this.query += `type=${this.type}&`;
    }

    if(this.contactUuid) {
      this.query += `contactUuid=${this.contactUuid}&`;
    }
    
    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    this.url = `${this.baseAPI}users/list?${this.query}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * GET user
   */

  async getUser() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID or Contact UUID');
      return null; 
    }

    this.url = `${this.baseAPI}users/show/${this.uuid}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * POST / CREATE user
   */

  async createUser() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.type) {
      if(!['management', 'exporter'].includes(this.type)) {
        console.error('Provided type is not supported');
        return null;
      }

      this.body.type = this.type;
    }

    if(this.contacts_uuid) {
      this.body.contacts_uuid = this.contacts_uuid
    }

    if(this.users_groups_uuid) {
      this.body.users_groups_uuid = this.users_groups_uuid
    }

    if(this.username) {
      this.body.username = this.username
    }

    if(this.password) {
      this.body.password = this.password
    }

    if(this.is_blocked) {
      if(!['y', 'n'].includes(this.is_blocked)) {
        console.error('Provided is_blocked is not supported');
        return null;
      }

      this.body.is_blocked = this.is_blocked
    }

    this.url = `${this.baseAPI}users/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * UPDATE user
   */

  async updateUser() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.type) {
      if(!['management', 'exporter'].includes(this.type)) {
        console.error('Provided type is not supported');
        return null;
      }

      this.body.type = this.type;
    }

    if(this.users_groups_uuid) {
      this.body.users_groups_uuid = this.users_groups_uuid;
    }

    if(this.contacts_uuid) {
      this.body.contacts_uuid = this.contacts_uuid;
    }

    if(this.username) {
      this.body.username = this.username;
    }

    if(this.password) {
      this.body.password = this.password;
    }

    if(this.is_blocked) {
      if(!['y', 'n'].includes(this.is_blocked)) {
        console.error('Provided is_blocked is not supported', this.is_blocked);
        return null;
      }

      this.body.is_blocked = this.is_blocked
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}users/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE user
   */

  async deleteUser() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}users/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET users
   */

  async getUsersGroups() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.usersGroupsUuid) {
      this.query += `usersGroupsUuid=${this.usersGroupsUuid}&`;
    }

    if(this.key) {
      this.query += `key=${this.key}&`;
    }
    
    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    this.url = `${this.baseAPI}system/users-groups/list?${this.query}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * GET user group
   */

  async getUserGroup() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}system/users-groups/show/${this.uuid}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * Update user group
   */

  async updateUserGroup() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {};

    if(this.name) {
      this.body.name = this.name;
    }

    if(this.permissions) {
      this.body.permissions = this.permissions;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}system/users-groups/update/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  } 

  /**
   * Create user group
   */

  async createUserGroup() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.name) {
      this.body.name = this.name;
    }

    if(this.permissions) {
      this.body.permissions = this.permissions;
    }
    
    this.url = `${this.baseAPI}system/users-groups/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * DELETE user group
   */

  async deleteUserGroup() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}system/users-groups/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET COMMENTS
   */

  async getComments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.module) {
      this.query += `module=${this.module}&`;
    }

    if(this.moduleUuid) {
      this.query += `moduleUuid=${this.moduleUuid}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    this.url = `${this.baseAPI}general/comments/list?${this.query}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * CREATE COMMENT
   */

  async createComment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.module) {
      console.error('Module is required to create new comment');
      return null; 
    }
    
    if(!this.module_uuid) {
      console.error('Module UUID is required to create new comment');
      return null; 
    }
    
    if(!this.content) {
      console.error('Content is required to create new comment');
      return null; 
    }
    
    this.body = {
      module: this.module,
      module_uuid: this.module_uuid,
      content: this.content
    }

    this.url = `${this.baseAPI}general/comments/create`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * DELETE comment
   */

  async deleteComment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('UUID was not set');
      return null; 
    }

    this.url = `${this.baseAPI}general/comments/delete/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }


  /**
   * 
   * 
   * DORES DISPATCH URLs
   * 
   * 
   */

  /**
   * GET DOMAIN DETAILS
   */

  async getDomain() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    if(!this.domain) {
      console.error('Domain is required to getDomain');
      return null;
    }

    this.url = `${this.baseAPI}cores/${this.domain}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET GLOBAL SETTINGS
   */

  async getDispatchGlobalSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.url = `${this.baseAPI}dispatch/settings`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * UPDATE USER SETTINGS
   */

  async updateUserSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.users_uuid) {
      console.error('Must provide a users UUID');
      return null; 
    }

    this.body = {};

    if(this.timezone) {
      this.body.timezone = this.timezone
    }

    if(this.date_format) {
      this.body.date_format = this.date_format
    }

    if(this.time_format) {
      this.body.time_format = this.time_format
    }

    if(this.auto_fill_cargo_date_in) {
      if(!['y', 'n'].includes(this.auto_fill_cargo_date_in)) {
        console.error(
          'Provided auto_fill_cargo_date_in is not supported', 
          this.auto_fill_cargo_date_in
        );
        
        return null;
      }

      this.body.auto_fill_cargo_date_in = this.auto_fill_cargo_date_in;
    }

    if(this.image_optimization_default_enabled) {
      if(!['y', 'n'].includes(this.image_optimization_default_enabled)) {
        console.error(
          'Provided image_optimization_default_enabled is not supported', 
          this.image_optimization_default_enabled
        );
        
        return null;
      }

      this.body.image_optimization_default_enabled = this.image_optimization_default_enabled;
    }

    if(this.hide_right_side_menu_by_default) {
      if(!['y', 'n'].includes(this.hide_right_side_menu_by_default)) {
        console.error(
          'Provided hide_right_side_menu_by_default is not supported', 
          this.hide_right_side_menu_by_default
        );
        
        return null;
      }

      this.body.hide_right_side_menu_by_default = this.hide_right_side_menu_by_default;
    }

    if(this.compact_view) {
      if(!['y', 'n'].includes(this.compact_view)) {
        console.error(
          'Provided compact_view is not supported', 
          this.compact_view
        );
        
        return null;
      }

      this.body.compact_view = this.compact_view;
    }

    if(this.auto_fill_container_port_of_export_country) {
      this.body.auto_fill_container_port_of_export_country = this.auto_fill_container_port_of_export_country;
    }

    if(this.auto_fill_container_port_of_export_state) {
      this.body.auto_fill_container_port_of_export_state = this.auto_fill_container_port_of_export_state;
    }

    if(this.auto_fill_container_port_of_export_city) {
      this.body.auto_fill_container_port_of_export_city = this.auto_fill_container_port_of_export_city;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}users/update-settings/${this.users_uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DROP OFF FEATURE
   */

  /**
   * LIST DROP OFF SLOTS
   */

  async getDropOffSlots() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.day) {
      this.query += `day=${this.day}&`;
    }

    if(this.time) {
      this.query += `time=${this.time}&`;
    }

    if(this.capacity) {
      this.query += `capacity=${this.capacity}&`;
    }

    if(this.warehouseUuid) {
      this.query += `warehouseUuid=${this.warehouseUuid}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    this.url = `${this.baseAPI}drop-off${!this.authToken ? '/guest' : '' }/slots/?${this.query}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * ADD DROP OFF SLOTS
   */

  async addDropOffSlots() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.warehouse_uuid) {
      this.body.warehouse_uuid = this.warehouse_uuid
    }
    
    if(this.day) {
      this.body.day = this.day
    }

    if(this.time) {
      this.body.time = this.time
    }

    if(this.capacity) {
      this.body.capacity = this.capacity
    }

    this.url = `${this.baseAPI}drop-off/slots`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * DELETE DROP OFF SLOTS
   */

  async deleteDropOffSlots() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.warehouse_uuid) {
      this.body.warehouse_uuid = this.warehouse_uuid
    }

    if(this.day) {
      this.body.day = this.day
    }

    if(this.time) {
      this.body.time = this.time
    }

    this.url = `${this.baseAPI}drop-off/slots`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * LIST DROP OFF DISABLED DATES
   */

  async getDropOffDisabledDates() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.fromDate) {
      this.query += `fromDate=${this.fromDate}&`;
    }

    if(this.toDate) {
      this.query += `toDate=${this.toDate}&`;
    }

    if(this.warehouseUuid) {
      this.query += `warehouseUuid=${this.warehouseUuid}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    this.url = `${this.baseAPI}drop-off${!this.authToken ? '/guest' : '' }/disabled-dates/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * ADD DROP OFF DISABLED DATES
   */

  async addDropOffDisabledDates() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.warehouse_uuid) {
      this.body.warehouse_uuid = this.warehouse_uuid
    }

    if(this.date) {
      this.body.date = this.date
    }

    if(this.notes) {
      this.body.notes = this.notes
    }
    
    this.url = `${this.baseAPI}drop-off/disabled-dates`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * DELETE DROP OFF DISABLED DATES
   */

  async deleteDropOffDisabledDates() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {};

    if(this.warehouse_uuid) {
      this.body.warehouse_uuid = this.warehouse_uuid
    }

    if(this.date) {
      this.body.date = this.date
    }

    this.url = `${this.baseAPI}drop-off/disabled-dates`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * SHOW DROP OFF APPOINTMENT
   */

  async getDropOffAppointment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}drop-off/appointments/${this.uuid}`;
    this.method = 'GET';
    
    return await this.request();
  }

  /**
   * LIST DROP OFF APPOINTMENTS
   */

  async getDropOffAppointments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.warehousesUuids) {
      this.query += `warehousesUuids=${this.warehousesUuids}&`;
    }

    if(this.truckingCompanName) {
      this.query += `truckingCompanName=${this.truckingCompanName}&`;
    }

    if(this.privateNotes) {
      this.query += `privateNotes=${this.privateNotes}&`;
    }

    if(this.publicNotes) {
      this.query += `publicNotes=${this.publicNotes}&`;
    }

    if(this.modulesRecordsStatusesUuid) {
      this.query += `modulesRecordsStatusesUuid=${this.modulesRecordsStatusesUuid}&`;
    }

    if(this.dropOffAtFrom) {
      this.query += `dropOffAtFrom=${this.dropOffAtFrom}&`;
    }

    if(this.dropOffAtTo) {
      this.query += `dropOffAtTo=${this.dropOffAtTo}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    this.url = `${this.baseAPI}drop-off/appointments/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * CHECK AVAILABILITY DROP OFF APPOINTMENT
   */

  async checkAvailabilityDropOffAppointment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid;
    }

    if(this.drop_off_at) {
      this.body.drop_off_at = this.drop_off_at;
    }

    if(this.number_of_items) {
      this.body.number_of_items = this.number_of_items;
    }

    this.url = `${this.baseAPI}drop-off/appointments/check-availability`;
    this.method = 'POST';
    
    return await this.request();
  }

  /**
   * CREATE DROP OFF APPOINTMENT
   */

  async createDropOffAppointment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid;
    }

    if(this.drop_off_at) {
      this.body.drop_off_at = this.drop_off_at;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.number_of_items) {
      this.body.number_of_items = this.number_of_items;
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.trucking_company_name) {
      this.body.trucking_company_name = this.trucking_company_name;
    }

    if(this.driver_full_name) {
      this.body.driver_full_name = this.driver_full_name;
    }

    if(this.contact_phone) {
      this.body.contact_phone = this.contact_phone;
    }

    if(this.contact_email) {
      this.body.contact_email = this.contact_email;
    }

    this.url = `${this.baseAPI}drop-off/appointments`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * UPDATE DROP OFF APPOINTMENT
   */

  async updateDropOffAppointment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.body = {}

    if(this.warehouses_uuid) {
      this.body.warehouses_uuid = this.warehouses_uuid;
    }

    if(this.drop_off_at) {
      this.body.drop_off_at = this.drop_off_at;
    }

    if(this.modules_records_statuses_uuid) {
      this.body.modules_records_statuses_uuid = this.modules_records_statuses_uuid;
    }

    if(this.number_of_items) {
      this.body.number_of_items = this.number_of_items;
    }

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.trucking_company_name) {
      this.body.trucking_company_name = this.trucking_company_name;
    }

    if(this.driver_full_name) {
      this.body.driver_full_name = this.driver_full_name;
    }

    if(this.contact_phone) {
      this.body.contact_phone = this.contact_phone;
    }

    if(this.contact_email) {
      this.body.contact_email = this.contact_email;
    }

    if(this.private_notes) {
      this.body.private_notes = this.private_notes;
    }

    if(this.public_notes) {
      this.body.public_notes = this.public_notes;
    }

    this.url = `${this.baseAPI}drop-off/appointments/${this.uuid}`;
    this.method = 'PUT';
    
    return await this.request();
  }

  /**
   * DELETE DROP OFF APPOINTMENT
   */

  async deleteDropOffAppointment() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID');
      return null; 
    }

    this.url = `${this.baseAPI}drop-off/appointments/${this.uuid}`;
    this.method = 'DELETE';
    
    return await this.request();
  }

  /**
   * INVOICES APIS
   */

  /**
   * GET INDUSTRIAL INVOICE
   */

  async getInvoiceIndustrial() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to get an invoice.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/document/industrial-invoice/${this.uuid}${!this.authToken ? '/guest/' : ''}?${this.access_code ? 'accessCode=' + this.access_code : ''}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET invoice related mailing list
   */

  async getInvoiceRelatedEmailingList() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('getCargoRelatedEmailingList requires UUID');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/related-mailing-list/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET INVOICE
   */

  async getInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to get an invoice.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET INVOICE
   */

  async getInvoices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.exportersUuids) {
      this.query += `exportersUuids=${this.exportersUuids}&`;
    }

    if(this.issueDateFrom) {
      this.query += `issueDateFrom=${this.issueDateFrom}&`;
    }

    if(this.issueDateTo) {
      this.query += `issueDateTo=${this.issueDateTo}&`;
    }

    if(this.dueDateFrom) {
      this.query += `dueDateFrom=${this.dueDateFrom}&`;
    }

    if(this.dueDateTo) {
      this.query += `dueDateTo=${this.dueDateTo}&`;
    }

    if(this.status) {
      this.query += `status=${this.status}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    if(this.packingOrdersUuids) {
      this.query += `packingOrdersUuids=${this.packingOrdersUuids}&`;
    }

    if(this.containerUuid) {
      this.query += `containerUuid=${this.containerUuid}&`;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * CREATE INVOICE
   */

  async createInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.exporters_uuid) {
      console.error('Missing requirements exporter UUID.');
      return null; 
    }

    this.body = {}

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporters_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.tax_rate) {
      this.body.tax_rate = this.tax_rate;
    }

    if(this.issue_date) {
      this.body.issue_date = this.issue_date;
    }

    if(this.due_date) {
      this.body.due_date = this.due_date;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.remittince) {
      this.body.remittince = this.remittince;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * UPDATE INVOICE
   */

  async updateInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to update an invoice.');
      return null; 
    }

    this.body = {}

    if(this.exporters_uuid) {
      this.body.exporters_uuid = this.exporter_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.tax_rate) {
      this.body.tax_rate = this.tax_rate;
    }

    if(this.issue_date) {
      this.body.issue_date = this.issue_date;
    }

    if(this.due_date) {
      this.body.due_date = this.due_date;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.remittince) {
      this.body.remittince = this.remittince;
    }

    if(this.status) {
      this.body.status = this.status;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * LINK INVOICE RELATED PACKING ORDER 
   */

  async linkInvoiceRelatedPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}

    if(this.invoices_uuid) {
      this.body.invoices_uuid = this.invoices_uuid;
    }

    if(this.packing_orders_uuid) {
      this.body.packing_orders_uuid = this.packing_orders_uuid;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-related-packing-orders/link`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * UNLINK INVOICE RELATED PACKING ORDER 
   */

  async unlinkInvoiceRelatedPackingOrder() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}

    if(this.invoices_uuid) {
      this.body.invoices_uuid = this.invoices_uuid;
    }

    if(this.packing_orders_uuid) {
      this.body.packing_orders_uuid = this.packing_orders_uuid;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-related-packing-orders/unlink`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * EMAIL INVOICE
   */

  async emailInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('emailInvoice requires UUID');
      return null; 
    }

    this.body = {
      to: this.to,
      cc: this.cc,
      reply_to: this.replyTo,
      record_uuid: this.uuid
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices/${this.uuid}/email`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * INVOICES BALANCE
   */

  async getInvoicesBalance() {}

  /**
   * RECALCULATE INVOICE 
   */

  async recalculateInvoice() {}

  /**
   * DELETE INVOICE
   */

  async deleteInvoice() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to delete an invoice.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET INVOICE ITEMS
   */

  async getInvoiceItem() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to get an invoice item.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-items/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET INVOICE
   */

  async getInvoiceItems() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.invoicesUuid) {
      this.query += `invoicesUuid=${this.invoicesUuid}&`;
    }

    if(this.item) {
      this.query += `item=${this.item}&`;
    }

    if(this.taxable) {
      this.query += `taxable=${this.taxable}&`;
    }

    if(this.billable) {
      this.query += `billable=${this.billable}&`;
    }

    if(this.cargoesUuid) {
      this.query += `cargoesUuid=${this.cargoesUuid}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderBy) {
      this.query += `orderBy=${this.orderBy}&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-items/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * CREATE INVOICE
   */

  async createInvoiceItem() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.invoices_uuid) {
      console.error('Missing requirements invoices UUID.');
      return null; 
    }

    this.body = {}

    if(this.invoices_uuid) {
      this.body.invoices_uuid = this.invoices_uuid;
    }

    if(this.listing_order) {
      this.body.listing_order = this.listing_order;
    }

    if(this.item) {
      this.body.item = this.item;
    }

    if(this.description) {
      this.body.description = this.description;
    }

    if(this.price) {
      this.body.price = this.price;
    }

    if(this.quantity) {
      this.body.quantity = this.quantity;
    }

    if(this.taxable) {
      if(!['n', 'y'].includes(this.taxable)) {
        console.error(
          'Failed to create invoice item, provided taxable is not valid', 
          this.taxable
        )

        return null;
      }

      this.body.taxable = this.taxable
    }

    if(this.billable) {
      if(!['n', 'y'].includes(this.billable)) {
        console.error(
          'Failed to create invoice item, provided billable is not valid', 
          this.billable
        )

        return null;
      }

      this.body.billable = this.billable
    }

    if(this.cargoes_uuid) {
      this.body.cargoes_uuid = this.cargoes_uuid;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-items`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * AUTO POPULATE ITEMS FROM PACKING ORDERS
   */

  async autoGenerateInvoiceItemsFromPackingOrders() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.invoice_uuid) {
      console.error('Must provide a UUID to auto populate items of an invoice.');
      return null; 
    }

    this.body = {
      invoice_uuid: this.invoice_uuid
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-items/auto-generate`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * UPDATE INVOICE
   */

  async updateInvoiceItem() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to update an invoice item.');
      return null; 
    }

    this.body = {}

    if(this.listing_order) {
      this.body.listing_order = this.listing_order;
    }

    if(this.item) {
      this.body.item = this.item;
    }

    if(this.description) {
      this.body.description = this.description;
    }

    if(this.price) {
      this.body.price = this.price;
    }

    if(this.quantity) {
      this.body.quantity = this.quantity;
    }

    if(this.taxable) {
      this.body.taxable = this.taxable;
    }

    if(this.billable) {
      this.body.billable = this.billable;
    }

    if(this.cargoes_uuid) {
      this.body.cargoes_uuid = this.cargoes_uuid;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }
    
    this.url = `${this.baseAPI}accounting/invoices/invoices-items/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE INVOICE
   */

  async deleteInvoiceItem() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to delete an invoice item.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-items/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET INVOICE TRANSACTION
   */

  async getInvoiceTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to get an invoice transaction.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-transactions/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET INVOICE
   */

  async getInvoicesTransactions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.uuid) {
      this.query += `uuid=${this.uuid}&`;
    }

    if(this.invoiceUuid) {
      this.query += `invoiceUuid=${this.invoiceUuid}&`;
    }

    if(this.referenceNumber) {
      this.query += `referenceNumber=${this.referenceNumber}&`;
    }

    if(this.type) {
      this.query += `type=${this.type}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-transactions/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * CREATE INVOICE
   */

  async createInvoiceTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.invoices_uuid) {
      console.error('Missing requirements invoices UUID.');
      return null; 
    }

    this.body = {}

    if(this.invoices_uuid) {
      this.body.invoices_uuid = this.invoices_uuid;
    }

    if(this.income_deposit_accounts_uuid) {
      this.body.income_deposit_accounts_uuid = this.income_deposit_accounts_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.amount) {
      this.body.amount = this.amount;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.response_details) {
      this.body.response_details = this.response_details;
    }

    if(this.type) {
      this.body.type = this.type;
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-transactions`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * UPDATE INVOICE
   */

  async updateInvoiceTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to update an invoice transaction.');
      return null; 
    }

    this.body = {}

    if(this.income_deposit_accounts_uuid) {
      this.body.income_deposit_accounts_uuid = this.income_deposit_accounts_uuid;
    }

    if(this.reference_number) {
      this.body.reference_number = this.reference_number;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.amount) {
      this.body.amount = this.amount;
    }

    if(this.notes) {
      this.body.notes = this.notes;
    }

    if(this.response_details) {
      this.body.response_details = this.response_details;
    }

    if(this.type) {
      this.body.type = this.type;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-transactions/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE INVOICE
   */

  async deleteInvoiceTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to delete an invoice transaction.');
      return null; 
    }

    this.url = `${this.baseAPI}accounting/invoices/invoices-transactions/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * GET INCOME DEPOSIT ACCOUNT
   */

  async getIncomeDepositAccount() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to get income deposit account.');
      return null; 
    }

    this.url = `${this.baseAPI}system/accounting/income-deposit-accounts/${this.uuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * GET INVOICE
   */

  async getIncomeDepositAccounts() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.query) this.query = '';

    if(this.uuid) {
      this.query += `uuid=${this.uuid}&`;
    }

    if(this.name) {
      this.query += `name=${this.name}&`;
    }

    if(this.isActive) {
      this.query += `isActive=${this.isActive}&`;
    }

    if(this.page) {
      this.query += `page=${this.page}&`;
    }

    if(this.resPerPage) {
      this.query += `resPerPage=${this.resPerPage}&`;
    }

    if(this.showAll) {
      this.query += `showAll=y&`;
    }

    if(this.orderDirection) {
      this.query += `orderDirection=${this.orderDirection}&`;
    }

    if(this.searchTerm) {
      this.query += `searchTerm=${this.searchTerm}&`;
    }

    this.url = `${this.baseAPI}system/accounting/income-deposit-accounts/?${this.query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * CREATE INVOICE
   */

  async createIncomeDepositAccount() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    this.body = {}

    if(this.name) {
      this.body.name = this.name;
    }

    if(this.description) {
      this.body.description = this.description;
    }

    if(this.is_active) {
      if(!['n', 'y'].includes(this.is_active)) {
        console.error(
          'Failed to createIncomeDepositAccount, provided is_active is not valid', 
          this.is_active
        )

        return null;
      }

      this.body.is_active = this.is_active;
    }

    this.url = `${this.baseAPI}system/accounting/income-deposit-accounts`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * UPDATE INVOICE
   */

  async updateIncomeDepositAccount() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to update income deposit account.');
      return null; 
    }

    this.body = {}

    if(this.name) {
      this.body.name = this.name;
    }

    if(this.description) {
      this.body.description = this.description;
    }

    if(this.is_active) {
      if(!['n', 'y'].includes(this.is_active)) {
        console.error(
          'Failed to updateIncomeDepositAccount, provided is_active is not valid', 
          this.is_active
        )

        return null;
      }

      this.body.is_active = this.is_active;
    }

    if(this.clear) {
      this.body.clear = this.clear
    }

    this.url = `${this.baseAPI}system/accounting/income-deposit-accounts/${this.uuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * DELETE INVOICE
   */

  async deleteIncomeDepositAccount() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.uuid) {
      console.error('Must provide a UUID to delete income deposit account.');
      return null; 
    }

    this.url = `${this.baseAPI}system/accounting/income-deposit-accounts/${this.uuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * SUBSCRIPTION
   */

  async getSubscriptionStatForKey() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.key) {
      console.error('Must provide a key.');
      return null; 
    }

    this.body = {
      key: this.key,
      subscription_uuid: this.subscription_uuid
    }

    this.url = `${this.baseAPI}system/subscription/stats/`;
    this.method = 'POST';

    return await this.request();
  }
}