/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { DeleteFilled, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Pagination, Row, Segmented, Select, Space, Switch, Table, Typography } from "antd";

import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import BlankPage, { LayoutModalProps } from '../../framework/blank_page';
import { DefaultOptionType, OptionProps } from 'antd/es/select';
import { displayName } from '../../lib/ev_lib/builders';
import Documents from '../../common/documents';
import { filterGeoArray } from '../../lib/cities';
import { setClearFormFields } from '../../util/clear_empty';
import { URLQuery } from '../../util/url_queries';

export interface ContactProps {
  uuid: string|null,
  name_prefix?: string|null,
  first_name?: string|null,
  middle_name?: string|null,
  last_name?: string|null,
  company_name?: string|null,
  phone_country_code?: string|null,
  phone?: string|null,
  fax_country_code?: string|null,
  fax?: string|null,
  email?: string|null,
  address_1?: string|null,
  address_2?: string|null,
  city?: string|null,
  state?: string|null,
  country?: string|null,
  zip_code?: string|null,
  tags?: any,
  clear?:string[]|undefined
}

export default function ContactsForm(props:any) {

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  let { uuid } = useParams<any>();
  
  const [form] = Form.useForm();
  const [errors, setErrors] = useState<any[]|null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [showTags, setShowTags] = useState<boolean>(false);
  const [showDocuments, setShowDocuments] = useState<boolean>(false);
  const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [cities, setCities] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false);
  const [states, setStates] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [isLoadingCountries, setIsLoadingCountries] = useState<boolean>(false);
  const [countries, setCountries] = useState<{value:string,label:string}[]|undefined>(undefined);

  const [contact, setContact] = useState<ContactProps>({
    uuid: null,
    phone_country_code: '+1',
    fax_country_code: '+1',
  });

  const [layoutModal, setLayoutModal] = useState<LayoutModalProps>({open: false});

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
        setEnableSubmit(true);
        setShowTags(true);
        setShowDocuments(true);
      }
      else if(
        profile.type !== 'management'
        && profile.contacts_uuid === uuid
      ) {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getCities();
    getStates();
    getCountries();

    if(uuid) getContact();

    setIsReady(true);
  }, []);

  const getCities = async () => {
    if(isLoadingCities) return null;
    setIsLoadingCities(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'cities';
    EV.showAll = true;
    
    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          state_name: item.state_name,
          country_name: item.country_name
        })
      })

      setCities(results);
    }

    setIsLoadingCities(false);
    return null;
  }

  const getStates = async () => {
    if(isLoadingStates) return;
    setIsLoadingStates(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'states';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, 
          label: item.name, 
          country_name: item.country_name
        })
      })

      setStates(results);
    }

    setIsLoadingStates(false);
    return null;
  }

  const getCountries = async () => {
    if(isLoadingCountries) return;
    setIsLoadingCountries(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.type = 'countries';
    EV.showAll = true;

    var res:any = await EV.getCities();
    
    if(res.data) {
      var tempObj = res.data;
      var results:any[] = []

      if(tempObj) tempObj.map((item:any, key:number) => {
        results.push({
          value: item.name, label: `${item.name}`
        })
      })

      setCountries(results);
    }

    setIsLoadingCountries(false);
    return null;
  }

  const getContact = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = uuid;

    var res:any = await EV.getContact();
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      });  
    }

    else if(res.data) {
      var tempContact = res.data;
      var tags = tempContact?.tags;

      if(tags) tags = JSON.parse(tags);

      tempContact = {
        ...tempContact,
        tags: tags
      }

      setContact(tempContact);
      form.setFieldsValue(tempContact)
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    
    // handle cleared fields
    if(contact.uuid) {
      // check clearables
      contact.clear = setClearFormFields(contact)||undefined;
    }

    Object.assign(EV, contact)
    
    // handle the tags
    if(contact.tags) {
      EV.tags = JSON.stringify(contact.tags);
    }

    var res:any = null;

    if(contact.uuid) res = await EV.updateContact();
    else res = await EV.createContact();

    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Failed to complete the process',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      if(!contact.uuid) {
        setContact({
          ...contact,
          uuid: res.data.uuid
        })

        navigate('/address-book/form/' + res.data.uuid, { replace: true });
      }
    }

    setIsLoading(false);
    return null;
  }

  const deleteContact = async () => {
    if(isDeleting) return;
    setIsDeleting(true);
    
    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.uuid = contact?.uuid;
    
    var res:any = await EV.deleteContact();
    // var res:any = false;
    
    if(!res) {
      messageApi.open({
        type: 'error',
        content: 'Network error mailing the record',
      });  
    }

    else if(res.status === 'fail') {
      setErrors(res.errors);
    }

    else if(res.code === 200) {
      messageApi.open({
        type: 'success',
        content: 'Process completed',
      });

      navigate(-1);
      return;
    }

    setIsDeleting(false);
    return;
  }

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    modal={layoutModal}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(contact.uuid) && <>
        <Button
          danger
          type={'primary'}
          icon={<DeleteFilled />}
          children={'Delete'}
          onClick={() => setLayoutModal({
            open: true,
            title: 'Are you sure?',
            children: [<>
              <Typography.Text>
                You are about to delete this record. This action
                may not be reversable. Are you sure you want to continue?
              </Typography.Text>
            </>],
            okText: 'Confirm',
            onOk: () => {
              setLayoutModal({open: false});
              deleteContact();
            },
            onCancel: () => setLayoutModal({open: false})
          })}
        />
      </>}
    </Space>}
  >

  {contextHolder}

  <Typography.Title 
    level={1}
    children={(contact?.uuid) ? 'Manage Contact' : 'New Contact'} 
  />

  <Typography.Paragraph>
    <Typography.Text type={'secondary'} children={'# '} />
    <Typography.Text
      children={contact?.uuid||'Unassigned'}
      copyable
      type={'secondary'}
    />
  </Typography.Paragraph>

  {(!enableSubmit) && 
    <Alert
      style={{marginBottom: 15}}
      showIcon
      type={'warning'}
      message={'Cannot create or update contact information'}
      description={'Creating and updating a contact is limited to management '
        + 'at the moment. Please contact us for help updating any of the '
        + 'information.'}
    />}

  {(errors && errors.length > 0) && <Alert
    type={'error'}
    message={'Errors submitting your data'}
    description={<ul>{errors.map((error, key) => {
      return <li>{error}</li>;
    })}</ul>}
    closable
    onClose={() => setErrors(null)}
    style={{marginBottom: 20}}
  />}

  <Form
    form={form}
    layout="vertical"
    initialValues={{
      uuid: uuid||contact.uuid,
      name_prefix: contact?.name_prefix,
      first_name: contact?.first_name,
      last_name: contact?.last_name,
      phone_country_code: contact?.phone_country_code || '+1',
      phone: contact?.phone,
      fax_country_code: contact?.fax_country_code || '+1',
      fax: contact?.fax,
      email: contact?.email,
      address_1: contact?.address_1,
      address_2: contact?.address_2,
      city: contact?.city,
      state: contact?.state,
      country: contact?.country,
      zip_code: contact?.zip_code
    }}
    onFinish={processSubmit}
  >
    <Row gutter={5}>
      <Col span={4}>
        <Form.Item
          label={'Name Prefix'} 
          name={'name_prefix'}
        >
          <Select
            placeholder="Select one"
            allowClear
            onChange={(value) => setContact({
              ...contact,
              name_prefix: value||''
            })}
            options={[
              { value: '', label: 'None' },
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' }
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          label={'First Name'} 
          name={'first_name'}
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              first_name: e.target.value
            })}
          />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          label={'Last Name'} 
          name={'last_name'}
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              last_name: e.target.value
            })}
          />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label={'Company Name'} 
      name={'company_name'}
    >
      <Input 
        placeholder={'Type here'}
        onChange={(e) => setContact({
          ...contact,
          company_name: e.target.value
        })}
      />
    </Form.Item>

    <Typography.Title level={2} children={'Contact Info'} />


    <Row gutter={15}>
      <Col span={8}>
        <Form.Item
          label={'Phone'} 
          name={'phone'}
        >
          <Input 
            addonBefore={<Select 
              style={{width: 100}} 
              value={contact.phone_country_code||'+1'}
              onChange={(value) => setContact({
                ...contact, 
                phone_country_code: value
              })}
            >
              <Select.Option value="+1">+1</Select.Option>
              <Select.Option value="+44">+44</Select.Option>
            </Select>}
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              phone: e.target.value
            })}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={'Fax'} 
          name={'fax'}
        >
          <Input 
            addonBefore={<Select 
              style={{width: 100}} 
              value={contact.fax_country_code||'+1'}
              onChange={(value) => setContact({
                ...contact, 
                fax_country_code: value
              })}
            >
              <Select.Option value="+1">+1</Select.Option>
              <Select.Option value="+44">+44</Select.Option>
            </Select>}
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              fax: e.target.value
            })}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={'Email'} 
          name={'email'}
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              email: e.target.value
            })}
          />
        </Form.Item>
      </Col>
    </Row>
    
    <Typography.Title level={2} children={'Address'} />

    <Row gutter={5}>
      <Col span={16}>
        <Form.Item
          label={'Street Address'} 
          name={'address_1'}
        >
          <Input 
            placeholder={'Type here'}
            onChange={(e) => setContact({
              ...contact,
              address_1: e.target.value
            })}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label={'Unit #'} 
          name={'address_2'}
        >
          <Input 
            placeholder={'Building or Unit #'}
            onChange={(e) => setContact({
              ...contact,
              address_2: e.target.value
            })}
          />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label={'Country'} 
      name={'country'}
    >
      <Select 
        showSearch
        allowClear
        placeholder={'Select one'}
        onChange={(value) => setContact({
          ...contact,
          country: value||''
        })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={countries}
      />
    </Form.Item>
    
    <Form.Item
      label={'State'}
      name={'state'}
    >
      <Select 
        showSearch
        allowClear
        placeholder={'Select one'}
        onChange={(value) => setContact({
          ...contact,
          state: value||''
        })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={filterGeoArray(
          'state',
          states||[],
          undefined,
          contact.country
        )}
      />
    </Form.Item>

    <Form.Item
      label={'City'} 
      name={'city'}
    >
      <Select 
        showSearch
        allowClear
        placeholder={'Select one'}
        onChange={(value) => setContact({
          ...contact,
          city: value||''
        })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={filterGeoArray(
          'city',
          cities||[],
          contact.state,
          contact.country
        )}
      />
    </Form.Item>

    <Form.Item
      label={'Zip Code'} 
      name={'zip_code'}
    >
      <Input 
        placeholder={'Type here'}
        onChange={(e) => setContact({
          ...contact,
          zip_code: e.target.value
        })}
      />
    </Form.Item>
    
    {(showTags) && <>
      <Typography.Title level={2} children={'Tags'} />

      <Select 
        mode={'tags'}
        style={{width: '100%'}}
        placeholder={'Type here to add'}
        onChange={(value) => {
          setContact({
            ...contact,
            tags: value
          })
        }}
        value={contact.tags ?? []}
      />
    </>}

    {(showDocuments) && <Documents
      purpose={'contact'}
      purposeUuid={contact?.uuid}
      category={'general'}
      allowUpload
      allowManage
      title={'Images & Documents'}
    />}

    {(enableSubmit) && <>
      <div style={{marginBottom: 15}} />

      <Form.Item>
        <Space>
          <Button 
            type={"primary"} 
            htmlType={"submit"}
            loading={isLoading}
          >
            Save
          </Button>
          <Button type={"default"}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </>}
  </Form>
  </BlankPage>
}