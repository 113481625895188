/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { blue } from '@ant-design/colors';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Checkbox, Divider, Drawer, Form, Input, Space, Typography } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import EVAPI from '../lib/ev_lib/main';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export interface TableColumnsProps {
  id:string,
  label:string,
  default?:boolean
}

export interface TableColumnsCuztomizationProps {
  showDrawer:boolean,
  onClose:()=>any,
  localStorageKey:string,
  tableColumnsList:TableColumnsProps[],
  onPrcessComplete?:()=>any
}

export default function TableColumnsCuztomization(props:TableColumnsCuztomizationProps) {
  const [selectedColumns, setSelectedColumns] = useState<string[]|null>(null);
  
  const tableColumnsList = props.tableColumnsList;

  useEffect(() => {
    reloadList();
  }, []);

  const reloadList = () => {
    var tempSelectedColumns:string|null = localStorage.getItem(props.localStorageKey);
    var tempSelectedColumnsArray:string[] = tempSelectedColumns?.split(',')||[];
    setSelectedColumns([...tempSelectedColumnsArray]);
  }

  const updateSelectedColumns = () => {
    var columns:string = '';

    if(selectedColumns) {
      for(var i = 0; i < selectedColumns.length; i++) {
        if(columns.length > 0) {
          columns +=',';
        }

        columns +=selectedColumns[i];
      }
    }

    localStorage.setItem(props.localStorageKey, columns);
    props.onPrcessComplete && props.onPrcessComplete();
  }

  // reset the columns to the original ones
  const resetSelectedColumns = () => {
    var columns:string = '';

    if(props.tableColumnsList) {
      for(var i = 0; i < props.tableColumnsList.length; i++) {
        if(props.tableColumnsList[i].default) {
          if(columns.length > 0) {
            columns +=',';
          }

          columns += props.tableColumnsList[i].id;
        }
      }
    }

    localStorage.setItem(props.localStorageKey, columns);
    reloadList();

    // triger post update
    props.onPrcessComplete && props.onPrcessComplete();
  }

  return <Drawer
    title="View Customization"
    placement={'right'}
    closable={false}
    onClose={props.onClose}
    open={props.showDrawer}
    key={'right'}
  >
    {tableColumnsList?.map((column:{id:string,label:string,default?:boolean}, key:number) => {
      var selected:boolean = false;

      if(selectedColumns?.includes(column.id)) {
        selected = true;
      }

      return <div>
        <Checkbox 
          checked={selected} 
          children={column.label} 
          onChange={(e:CheckboxChangeEvent) => {
            if(selected && selectedColumns) {
              setSelectedColumns(selectedColumns?.filter((value:string) => value !== column.id))
            }

            else if (selectedColumns) {
              // add it
              setSelectedColumns([
                ...selectedColumns,
                column.id
              ])
            }
            
            else {
              setSelectedColumns([
                column.id
              ])
            }
          }}
        />
      </div>
    })}
    <Divider />
    <Space>
      <Button
        type={'primary'}
        children={'Save'}
        onClick={updateSelectedColumns}
      />
      <Button
        type={'default'}
        children={'Reset Default'}
        onClick={resetSelectedColumns}
      />
    </Space>
  </Drawer>
}