/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Menu, message, Pagination, Row, Segmented, Select, Space, Switch, Table, Tabs, Typography } from "antd";

import BlankPage from "../../framework/blank_page";
import EVAPI from '../../lib/ev_lib/main';
import { config } from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import ContactsListing from '../general/contacts_listing';

export default function AddressBook(props:any) {
  const navigate = useNavigate();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  let profile:any = localStorage.getItem('profile');
  
  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(['management', 'exporter'].includes(profile.type)) {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };
    
    setIsReady(true);
  }, []);

  // manage additional columns
  let additionalColumns:any[] = [];
  
  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'primary'}
        icon={<PlusCircleFilled />}
        children={'Add New'}
        onClick={() => navigate('/address-book/form')}
      />
    </Space>}
  >

    <Typography.Title children={'Contacts'} />
    
    <ContactsListing 
      showView
      showPagination
      additionalColumns={additionalColumns}
    />
  </BlankPage>
}