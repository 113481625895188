/**
 *
 * PROJECT eVessel
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from 'react';

import { CheckOutlined, FormOutlined, LeftCircleFilled, PlusCircleFilled, PlusCircleOutlined, PushpinFilled, PushpinOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Menu, Pagination, Row, Segmented, Space, Table, Tag, Typography, message } from "antd";

import BlankPage from "../../../framework/blank_page";
import EVAPI from '../../../lib/ev_lib/main';
import { useNavigate } from 'react-router-dom';
import ForwardTable from 'antd/es/table/Table';
import { URLQuery } from '../../../util/url_queries';
import { green, grey } from '@ant-design/colors';

export default function SettingsModulesRecordsStatusesList(props:any) {
  const navigate = useNavigate();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();
  
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [selectedModule, setSelectedModule] = useState<'cargoes'|'packing orders'|'containers'|'all'>('all');

  const [modulesRecordsStatuses, setModulesRecordsSatuses] = useState<any>(null);

  let profile:any = localStorage.getItem('profile');

  useEffect(() => {
    try {
      if(typeof profile !== 'object') {
        profile = JSON.parse(profile);
      }
      
      if(profile.type === 'management') {
        setAuthorized(true);
      }
    }
    catch(e) {
      console.warn(e);
    };

    getModulesRecordsStatuses();
    setIsReady(true);
  }, [])
  
  useEffect(() => {
    getModulesRecordsStatuses();
  }, [searchTerm, selectedModule])

  const getModulesRecordsStatuses = async () => {
    setIsLoading(true);

    var EV:any = new EVAPI;
    EV.debug = process.env.REACT_APP_MODE === "development" ? true : false;
    EV.baseAPI = URLQuery('targetDomain', window)||localStorage.getItem('target_domain');
    EV.authToken = localStorage.getItem('auth_token');
    EV.module = (selectedModule !== 'all') && selectedModule;
    EV.showAll = true;
    EV.searchTerm = searchTerm;

    var res:any = await EV.getModulesRecordsStatuses();

    setIsLoading(false);

    if(!res || res.code !== 200) {
      messageApi.open({
        type: 'error',
        content: 'Failed to pull data from server',
      }); 
    }

    var data = res.data;
    var sortedData:any = [];

    if(data.length > 0) {
      data.map((item:any, key:number) => {
        sortedData.push({
          key: item.uuid,
          ...item
        });
      });
    }

    setModulesRecordsSatuses(sortedData);
  }

  const columns = [
    {
      key: 'uuid', 
      title: 'UUID', 
      dataIndex: 'uuid',
      width: 130,
      render: (_:any, record:any) => {
        return record.uuid?.substr(record.uuid.length - 10);
      }
    },
    {key: 'module', title: 'Module', dataIndex: 'module'},
    {key: 'listing-order', title: 'Listing', dataIndex: 'listing_order'},
    {
      key: 'is_default', 
      title: 'Default', 
      dataIndex: 'is_default',
      render: (_:any, record:any) => {
        if(record.is_default === 'y') {
          return <CheckOutlined style={{color: green[5]}} />
        }

        return null;
      }
    },
    {
      key: 'label', 
      title: 'Label', 
      dataIndex: 'label',
      render: (_:any, record:any) => {
        if(record.is_active !== 'y') {
          return <>
            <Tag>Inactive</Tag>
            <Typography.Text type={'secondary'} delete>{record.label}</Typography.Text>
          </>
        }

        return <>
          <Typography.Text>{record.label}</Typography.Text>
        </>
      }
    },
    {key: 'highlight-type', title: 'Highlight Type', dataIndex: 'highlight_type'},
    {
      key: 'highlight-color', 
      title: 'Highlight Color', 
      render: (_:any, record:any) => (
        <div style={{
          width: 20, 
          height: 20, 
          backgroundColor: record.highlight_color, 
          borderRadius: 5
        }} />
      )
    },
    {
      key: 'action',
      title: 'Action', 
      width: 100,
      render: (_:any, record:any) => <Space size={'small'}>
        <Button
          type='link'
          icon={<FormOutlined />}
          title={'Manage'}
          onClick={() => navigate(`/system/settings/modules-records-statuses/form/${record.uuid}`)}
        />
      </Space>
    },
  ];

  return <BlankPage
    isLoading={!isReady}
    unauthorized={!authorized}
    showSideMenu
    padded
    customHeader={<Space size={'small'}>
      <Button
        type={'default'}
        icon={<LeftCircleFilled />}
        children={'Back'}
        onClick={() => navigate(-1)}
      />
      {(authorized) && <>
        <Button
          type={'primary'}
          icon={<PlusCircleFilled />}
          children={'Add New'}
          onClick={() => navigate('/system/settings/modules-records-statuses/form')}
        />
      </>}
    </Space>}
  >
    {contextHolder}
    
    <Typography.Title children={'Modules Records Statuses'} />

    <Segmented 
      value={selectedModule}
      style={{textTransform: 'capitalize'}}
      options={[
        {value: 'all', label: 'All'},
        {value: 'cargoes', label: 'Cargoes'},
        {value: 'packing plans', label: 'Packing Plans'},
        {value: 'packing orders', label: 'Packing Orders'},
        {value: 'containers', label: 'Containers'},
        {value: 'drop off appointments', label: 'Drop Off Appointments'},
      ]}
      onChange={(value:any) => setSelectedModule(value)}
    />

    <div style={{marginBottom: 20}} />

    <Input 
      placeholder={"Type to search"}
      suffix={<SearchOutlined />}
      onChange={(e) => setSearchTerm(e.target.value)}
    />

    <div style={{marginBottom: 20}} />

    <ForwardTable
      pagination={{ showSizeChanger: true }} 
      loading={isLoading}
      dataSource={modulesRecordsStatuses} 
      columns={columns} 
    />
  </BlankPage>
}